// See documentation on https://www.goodreads.com/style_guide

import _ from "lodash";
import GrComponentFactory from "../gr_component_factory";
import GrPropTypes from "../shared/gr_prop_types";
import GrSpoilerHandler from "../shared/gr_spoiler_handler";
import classNames from "classnames";

export default GrComponentFactory.createClass({
  displayName: "ExpandableHtml",

  propTypes: GrPropTypes.expandableHtml(),

  getInitialState() {
    return {
      expanded: false,
    };
  },

  collapse() {
    this.setState({ expanded: false });
  },

  expand() {
    this.setState({ expanded: true });
  },

  expandable() {
    return !_.isEmpty(this.props.truncatedHtml);
  },

  visibleHtml() {
    if (!this.expandable() || this.state.expanded === true) {
      return this.props.html;
    } else {
      return this.props.truncatedHtml;
    }
  },

  renderToggleButton() {
    if (!this.expandable()) {
      return null;
    }

    const action = this.state.expanded ? this.collapse : this.expand;
    const label = this.state.expanded ? "Less" : "More";

    const cx = classNames("gr-buttonAsLink", "u-marginLeftTiny");

    return <button className={cx} onClick={action}>
             {label}
           </button>;
  },

  componentDidMount(){
    if(this.refs.rootNode){
      GrSpoilerHandler.replaceSpoilerHTMLWithReactComponents(this.refs.rootNode);
    }
  },

  componentDidUpdate(){
    if(this.refs.rootNode){
      GrSpoilerHandler.replaceSpoilerHTMLWithReactComponents(this.refs.rootNode);
    }
  },

  render() {
    if (_.isEmpty(this.props.html)) {
      return null;
    }

    const expandableClass = classNames("expandableHtml", this.props.className);
    return <div className={expandableClass} ref="rootNode">
             <span dangerouslySetInnerHTML={{ __html: this.visibleHtml() }}/>
             {this.renderToggleButton()}
           </div>;
  },
});
