import { PropTypes } from "react";
import GetACopyLink from "./get_a_copy_link";
import { httpPost } from "../../modules/ajax/ajax_helper";

const GlideButton = ({ price, url, bookId }) => {
  function handleClick() {
    httpPost("/track/track_clickstream", {
      data: {
        hit_type: "actionOnly",
        action_type: `bb_click-amazon`,
      },
    });
  }
  return (
    <div onClick={handleClick}>
      <GetACopyLink name={`Kindle Store ${price}`} url={url} />
    </div>
  );
};

GlideButton.propTypes = {
  price: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  bookId: PropTypes.string.isRequired,
};

export default GlideButton;
