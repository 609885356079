import { Component } from "react";
import React from "react";
import ClickTrackingWrapper from "../../click_tracking_wrapper";
import { TRACKED_CLASSES } from "../../../modules/ads/click_tracking_helper";

export class NativeAdSponsoredLabel extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const sponsoredLabel = this.props.sponsoredBy ? "Sponsored by " : "Sponsored";
    const sponsoredFooter = <div className="nativeAd__sponsoredLabel">
      <span className="googleNativeAd__sponsoredBadgeIcon"/>
      <span className="googleNativeAd__sponsoredLabel">{sponsoredLabel}</span>
      <a target="_blank" href={this.props.sponsoredUrl} className="gr-hyperlink">
        {this.props.sponsoredBy}
      </a>
    </div>;
    return <ClickTrackingWrapper trackingOptions={TRACKED_CLASSES.sponsored_label} children={sponsoredFooter} />;
  }
}

NativeAdSponsoredLabel.propTypes = {
  sponsoredBy: React.PropTypes.string,
  sponsoredUrl: React.PropTypes.string,
};

export default NativeAdSponsoredLabel;
