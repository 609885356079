import CurrentUserStore from "../react_stores/current_user_store";
import FavoriteGenresStore from "../react_stores/favorite_genres_store";
import NotificationsStore from "../react_stores/notifications_store";
import ShelvingsStore from "../react_stores/shelvings_store";
import TabsStore from "../react_stores/tabs_store";

import FlashMessage from "../react_components/flash_message";
import FeaturedContentModule from "../react_components/featured_content_module";
import PageSkin from "../react_components/page_skin";
import GoogleBannerAd from "../react_components/ads/google_banner_ad";
import NativeAd from "../react_components/ads/native_ads/native_ad";
import GooglePageSkin from "../react_components/ads/google_page_skin";
import GoogleFeaturedContentModule from "../react_components/ads/google_featured_content_module";
import ScrollTracker from "../react_components/scroll_tracker";
import HeaderStoreConnector from "../react_components/header/header_store_connector";
import SiteAnnouncement from "../react_components/site_announcement/site_announcement";
import ChoiceSiteAnnouncement from "../react_components/site_announcement/choice_site_announcement";
import WelcomeAnnouncement from "../react_components/site_announcement/welcome_announcement";
import PreviewBroadcast from "../react_components/group/preview_broadcast";
import JobsList from "../react_components/jobs_list";
import ShareableLinkButton from "../react_components/shared/shareable_link_button";
import LibrarianQuiz from "../react_components/librarian_quiz/quiz";
import EditorialBlogThumbnail from "../react_components/home_page/editorial_blog_thumbnail";

// Temp placement of share dialog component and store
import ShareDialog from "../react_components/share_dialog";
import ShareSocial from "../react_components/share_social";
import ShareDialogStore from "../react_stores/share_dialog_store";

export const HeaderStores = {
  CurrentUserStore,
  FavoriteGenresStore,
  NotificationsStore,
  ShareDialogStore,
  ShelvingsStore,
  TabsStore,
};

export const HeaderComponents = {
  FlashMessage,
  HeaderStoreConnector,
  PageSkin,
  FeaturedContentModule,
  GoogleBannerAd,
  GooglePageSkin,
  GoogleFeaturedContentModule,
  ScrollTracker,
  SiteAnnouncement,
  ChoiceSiteAnnouncement,
  WelcomeAnnouncement,
  ShareDialog,
  ShareSocial,
  PreviewBroadcast,
  NativeAd,
  JobsList,
  ShareableLinkButton,
  LibrarianQuiz,
  EditorialBlogThumbnail,
};
