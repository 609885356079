/*
React component that renders a survey prompt in a Modal that shows by default,
and then renders a Qualtrics survey in an iframe if user consents.
*/
import { Component, PropTypes } from "react";
import Modal from "../modal";
import Cookies from "../shared/cookies";
import { httpPost } from "../../modules/ajax/ajax_helper";

export const QUALTRICS_COOKIE = "hide_qualtrics_survey";

class QualtricsSurveyModal extends Component {
  constructor(props) {
    super(props);
    this.state = { isShown: false };
  }

  componentDidMount() {
    const isShown = this.shouldShow();
    if (isShown) {
      httpPost("/track/track", {
        data: { tid: `QualtricsSurveyModal:${this.props.type || "default"}` },
      });
      this.setState({ isShown });
    }
  }

  shouldShow() {
    // No repeat showings before a certain time period
    if (Cookies.get(QUALTRICS_COOKIE)) {
      return false;
    }
    // If user is signed out, make sure they've seen the login interstitial
    // before prompting a survey.
    if (this.props.signedOut) {
      return !!Cookies.get("blocking_sign_in_interstitial");
    }
    return true;
  }

  onCloseHandler() {
    Cookies.set(QUALTRICS_COOKIE, true, 90);
  }

  renderSurvey() {
    return (
      <div className="qualtricsSurvey">
        <iframe
          className="qualtricsSurvey__iframe"
          src={this.props.qualtricsUrl}
        />
      </div>
    );
  }

  render() {
    if (!this.state.isShown) {
      return null;
    }

    return (
      <Modal
        id="QualtricsSurveyModal"
        showByDefault={true}
        onCloseHandler={this.onCloseHandler}>
        {this.renderSurvey()}
      </Modal>
    );
  }
}

QualtricsSurveyModal.propTypes = {
  signedOut: PropTypes.bool.isRequired,
  qualtricsUrl: PropTypes.string.isRequired,
  type: PropTypes.string,
};

export default QualtricsSurveyModal;
