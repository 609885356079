/*
 WantToReadMenuFilter is used to filter shelf names within the Want to Read
 button
*/

import React from "react";
import GrComponentFactory from "../gr_component_factory";

import WantToReadMenuActions from "../../react_actions/want_to_read_menu_actions";

export default GrComponentFactory.createClass({
  displayName: "WantToReadMenuFilter",
  propTypes: {
    bookId: React.PropTypes.string.isRequired,
    uniqueId: React.PropTypes.string.isRequired,
    shelvings: React.PropTypes.object.isRequired,
    wantToReadMenu: React.PropTypes.object.isRequired,
  },

  handleKeyPress(event) {
    let exclusiveShelfName = null;
    if (this.props.shelvings.exclusiveShelfName !== null) {
      exclusiveShelfName = this.props.shelvings.exclusiveShelfName;
    }
    WantToReadMenuActions.updateShelfNameFilter(event.target.value, this.props.uniqueId, exclusiveShelfName);
  },

  render() {
    const classNames = this.withBemModifiers("wantToReadMenuFilter");
    return <form className={classNames} onSubmit={(ev) => ev.preventDefault()}>
             <input className="gr-textInput
                               gr-textInput--small
                               wantToReadMenuFilter--textInput"
                     type="text"
                     placeholder="Filter Shelves"
                     aria-label="Filter Shelf Names"
                     value={this.props.wantToReadMenu.shelfNameFilter}
                     onChange={this.handleKeyPress} />
           </form>;
  },
});
