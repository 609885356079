// Confirmation modal that handles block/unblocking display user

import { PropTypes } from "react";
import GrComponentFactory from "../../gr_component_factory";
import Modal from "../../modal";
import BlockUserActions from "../../../react_actions/user/show/block_user_actions";
import { getUnblockUserModalId, getBlockUserModalId } from "../../../modules/modal_id_helper";

export default GrComponentFactory.createClass({
  displayName: "BlockUserConfirmationModal",

  propTypes: {
    displayUser: PropTypes.shape({
      firstName: React.PropTypes.string.isRequired,
      viewableName: React.PropTypes.string.isRequired,
      userId: React.PropTypes.number.isRequired,
    }).isRequired,

    fromDropdown: PropTypes.bool,
    isBlocked: PropTypes.bool.isRequired,
  },

  getDefaultProps() {
    return { fromDropdown: false };
  },

  handleConfirmBlockUser() {
    BlockUserActions.blockMember(this.props.displayUser.userId);
  },

  render() {
    if (this.props.isBlocked) {
      return(
        <Modal id={getUnblockUserModalId(this.props.displayUser.userId, this.props.fromDropdown)}
               bemModifiers="friendFollowModule"
               onConfirmHandler={this.handleConfirmBlockUser}>
          <div className="friendFollowConfirmModal">
            <h2 className="gr-h2 gr-h2--serif">Unblock {this.props.displayUser.viewableName}?</h2>
            <div className="friendFollowConfirmModal__body">
              Your profile will appear to {this.props.displayUser.firstName} as it would to any other Goodreads member.
              Their comments on discussion boards will be displayed normally. Are you sure?
            </div>
          </div>
        </Modal>
      );
    } else {
      return(
        <Modal id={getBlockUserModalId(this.props.displayUser.userId, this.props.fromDropdown)}
               bemModifiers="friendFollowModule"
               onConfirmHandler={this.handleConfirmBlockUser}>
          <div className="friendFollowConfirmModal">
            <h2 className="gr-h2 gr-h2--serif">Block {this.props.displayUser.viewableName}?</h2>
            <div className="friendFollowConfirmModal__body">
              This will prevent {this.props.displayUser.firstName} from sending you
              messages, friend request or from viewing your profile. They will
              not be notified. Comments on discussion boards from them will
              be hidden by default.
            </div>
          </div>
        </Modal>
      );
    }
  },
});
