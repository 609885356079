import React from "react";
import _ from "lodash";
import appendQuery from "append-query";
import GrComponentFactory from "../gr_component_factory";
import { trackNavEngage, trackNavEngageClick } from "../../modules/click_and_view_tracking";
import { URLTrackingParams } from "../../analytics/url_tracking_params";

// COMPONENTS
import Dropdown from "../dropdown";
import Notifications from "./notifications";
import ProfileMenu from "./profile_menu";
import ProfileMenuJewel from "./profile_menu_jewel";

export default GrComponentFactory.createClass({
  displayName: "PersonalNav",

  propTypes: {
    author: React.PropTypes.shape({
      id: React.PropTypes.number,
      editProfileUrl: React.PropTypes.string,
      profileUrl: React.PropTypes.string,
      dashboardUrl: React.PropTypes.string,
      listAGiveawayUrl: React.PropTypes.string,
    }),
    commentsUrl: React.PropTypes.string,
    discussionsUrl: React.PropTypes.string,
    editFavGenresUrl: React.PropTypes.string,
    includeProfileMenu: React.PropTypes.bool,
    groupInvitesCount: React.PropTypes.number,
    messageIconUrl: React.PropTypes.string,
    myFriendsUrl: React.PropTypes.string,
    myQuotesUrl: React.PropTypes.string,
    myReadingChallengeUrl: React.PropTypes.string,
    name: React.PropTypes.string,
    pendingRecsCount: React.PropTypes.number,
    preferences: React.PropTypes.object,
    profileEditUrl: React.PropTypes.string,
    profileImage: React.PropTypes.string,
    profileUrl: React.PropTypes.string,
    signOutUrl: React.PropTypes.string,
    tempFriendRequestCount: React.PropTypes.number,
    tempUnreadMessageCount: React.PropTypes.number,
    withDropdowns: React.PropTypes.bool,   // Does not affect profile menu. See also includeProfileMenu.
  },

  getDefaultProps() {
    return { includeProfileMenu: true, withDropdowns: true };
  },

  trackClickIncludeDropdownsSuffix(e, prefix) {
    const trackingFeatureName = this.props.withDropdowns ? `${prefix}_WithDropdowns`
      : `${prefix}_WithoutDropdowns`;
    trackNavEngageClick(e, trackingFeatureName);
  },

  render() {
    const groupDiscussionTrigger = <span className="headerPersonalNav__icon headerPersonalNav__icon--discussions"
                                    aria-label="My group discussions">
                                   </span>;
    const messageTrigger = <span className="headerPersonalNav__icon headerPersonalNav__icon--inbox"
                               aria-label="Inbox">
                           { this.props.tempUnreadMessageCount > 0 ?
                             <span className="headerPersonalNav__flag">{this.props.tempUnreadMessageCount}</span>
                             : null }
                         </span>;

    const friendRequestTrigger = <span className="headerPersonalNav__icon headerPersonalNav__icon--friendRequests"
                                     aria-label="Friend Requests">
                                 { this.props.tempFriendRequestCount > 0 ?
                                   <span className="headerPersonalNav__flag">{this.props.tempFriendRequestCount}</span>
                                   : null }
                               </span>;

    return (
      <ul className="personalNav">
        <li className="personalNav__listItem">
          {this.renderNotifications()}
        </li>
        <li className="personalNav__listItem"
            onClick={(e) => trackNavEngageClick(e, "DiscussionsLink")}>
          <a href={appendQuery(this.props.discussionsUrl,
                  { discussion_filter: "groups",
                   ref: URLTrackingParams.DISCUSSIONS_PANE_DISCUSSION })}
             title="My group discussions" className="headerPersonalNav">
            {groupDiscussionTrigger}
          </a>
        </li>
        <li className="personalNav__listItem"
            onClick={(e) => this.trackClickIncludeDropdownsSuffix(e, "MessagesLink")}>
          <a href={this.props.messageIconUrl} title="Messages" className="headerPersonalNav">
             {messageTrigger}
          </a>
        </li>
        <li className="personalNav__listItem"
            onClick={(e) => this.trackClickIncludeDropdownsSuffix(e, "FriendsLink")}>
          <a href={this.props.friendIconUrl} title="Friends" className="headerPersonalNav">
            {friendRequestTrigger}
          </a>
        </li>
        {this.renderProfileMenu()}
      </ul>
    );
  },

  renderNotifications() {
    return <Notifications notificationIconUrl={this.props.notificationIconUrl}
                          preferences={this.props.preferences}
                          withDropdowns={this.props.withDropdowns} />;
  },

  renderProfileName() {
    // limit of 23 characters is about the width of the dropdown menu
    // visually approved by @ericf and @ohyes
    let name = this.props.name;
    if (name.length > 23) {
      name = `${name.substring(0, 22)  }…`;
    }
    return (<span className="siteHeader__subNavLink gr-h3 gr-h3--noMargin" > {name} </span>);
  },

  renderProfileMenu() {
    if (this.props.includeProfileMenu === true) {
      const user = { viewableName: this.props.name,
                     profileImage: this.props.profileImage,
                     profileUrl:this.props.profileUrl };
      const profileIcon =
        <ProfileMenuJewel {...this.props} user={user} showNotificationsFlag={false} />;
      const profileUrl = _.size(this.props.author) === 0 ?
        this.props.profileUrl :
        this.props.author.profileUrl;

      return (
        <li className="personalNav__listItem">
          <Dropdown trigger={profileIcon}
                    fallbackUrl={profileUrl}
                    bemModifiers="profileMenu"
                    isInPersonalNav="true"
                    onShowCallback={() => trackNavEngage("ProfileShow")}>
            <div className="siteHeader__subNav siteHeader__subNav--profile gr-box gr-box--withShadowLarge">
              {this.renderProfileName()}
              <ProfileMenu {...this.props} profileUrl={profileUrl} />
            </div>
          </Dropdown>
        </li>
      );
    } else {
      return null;
    }
  },
});
