import GrComponentFactory from "../gr_component_factory";
import React from "react";

export default GrComponentFactory.createClass({
  displayName: "ServiceTermsAndPrivacy",

  propTypes: {
    privacyUrl: React.PropTypes.string.isRequired,
    termsUrl: React.PropTypes.string.isRequired,
  },

  render() {
    const serviceTermsAndPrivacy = (
      <small>
        By clicking “Sign up” I agree to the Goodreads <a href={ this.props.termsUrl } rel="nofollow" target="_blank">Terms of Service</a> and confirm that I am at least 13 years old.  Read our <a href={ this.props.privacyUrl } rel="nofollow" target="_blank">Privacy Policy</a>
      </small>
    );
    return (serviceTermsAndPrivacy);
  },
});
