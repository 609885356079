// React Component for interview preview card in share dialog

import { PropTypes } from "react";
import GrComponentFactory from "../../gr_component_factory";
import TruncatedHtml from "../../user_content/truncated_html";

export default GrComponentFactory.createClass({
  displayName: "ShareDialogInterviewPreview",

  propTypes: {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    author: PropTypes.string.isRequired,
    authorImageUrl: PropTypes.string,
    bookImageUrl: PropTypes.string,
    bookTitle: PropTypes.string,
    imageUrl: PropTypes.string,
  },

  renderImage() {
    if (this.props.imageUrl) {
      return (
        <img
          className="gr-mediaFlexbox__media sharePreview__imageFullWidth"
          src={this.props.imageUrl}
          alt={this.props.title}
        />
      );
    } else if (this.props.authorImageUrl &&
        this.props.bookImageUrl) {
      return (
        <div className="gr-mediaFlexbox__media sharePreview__image" style={{ display: "contents" }}>
          <div className="editorialCard__image--interview
                          editorialCard__image--interviewAuthor"
            style={{ backgroundImage: `url(${this.props.authorImageUrl})` }}>
            <span className="u-visuallyHidden">
              {this.props.author}
            </span>
          </div>
          <div className="editorialCard__image--interview
                          editorialCard__image--interviewBook"
            style={{ backgroundImage: `url(${this.props.bookImageUrl})` }}>
            <span className="u-visuallyHidden">
              {this.props.bookTitle}
            </span>
          </div>
        </div>
      );
    } else {
      return null;
    }
  },

  // Because of unique image, cannot reuse ShareDialogPreview
  render() {
    return(
      <div>
        <div className="gr-mediaFlexbox shareModal__previewContainer" style={{ display: "block" }}>
          {this.renderImage()}

          <div className="gr-mediaFlexbox__desc">
            <div className="gr-h3 gr-h3--serif gr-h3--noMargin">
              {this.props.title}
            </div>

            <div className="u-marginTopXSmall">
              <div className="sharePreview__description">
                <TruncatedHtml html={this.props.description} truncated={false} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  },
});
