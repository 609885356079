/*doc
---
title: Pinterest share
name: pinterest_share
category: React
---

This component displays the pinterest share button that is used in the share module.

Required props:

* `options` (object): Options for pinterest share (description, url, media)

Optional props:

* `metricName` (string): Full name or modifier for metric name
* `windowSpecs` (shape): Options for pinterest child window

*/

import React from "react";
import GrComponentFactory from "./../gr_component_factory";
import { httpPost } from "../../modules/ajax/ajax_helper";
import ChildWindowHelper from "../../modules/child_window_helper";

export default GrComponentFactory.createClass({
  displayName: "PinterestShare",

  propTypes: {
    options: React.PropTypes.shape({
      desription: React.PropTypes.string,
      url: React.PropTypes.string,
      media: React.PropTypes.string,
    }).isRequired,
    metricName: React.PropTypes.string,
    windowSpecs: React.PropTypes.object,
  },

  getDefaultProps() {
    return {
      // default window specs hard-coded here - cannot access browser window on server-side for centering
      windowSpecs: { left: 400,
                     top: 200,
                     width: 700,
                     height: 800 },
    };
  },

  onPinterestButtonClick() {
    if (this.props.metricName) {
      httpPost("/track/track_click", {
        data: {
          feature: this.props.metricName,
        },
      });
    }
    ChildWindowHelper.openSocialShareWindow("https://pinterest.com/pin/create/link/", this.props.options, this.props.windowSpecs);
  },

  render() {
    return (
      <button className={`${this.withBemModifiers("shareSocialButtons__button")} shareSocialButtons__button--pinterest`} 
              aria-label="Share to Pinterest"
              type="button"
              onClick={() => this.onPinterestButtonClick()} />
    );
  },
});
