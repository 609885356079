import { Component, PropTypes } from "react";
import NativeBookAdHeader from "./native_book_ad_header";
import Book, { VALID_BOOK_SIZES } from "../../book";
import BookWithWtrButton from "../../book_with_wtr_button";
import PreviewBook from "../../preview_book/preview_book";
import ExpandableHtml from "../../user_content/expandable_html";
import BookDescription from "../../book_description";
import CommunityRating from "../../community_rating";
import ClickTrackingWrapper from "../../click_tracking_wrapper";
import { TRACKED_CLASSES } from "../../../modules/ads/click_tracking_helper";
import ShapeIcon from "../../shape_icon";
import UserIcon from "../../user/user_icon";
import { ICON_SIZES } from "../../../modules/shape_icon_values";
import NativeAdCaret from "./native_ad_caret";
import NativeAdSponsoredLabel from "./native_ad_sponsored_label";
import classNames from "classnames";

export class NativeBookAd extends Component {

  constructor(props) {
    super(props);
  }

  isDesktopNewsfeed() {
    return this.props.isNewsfeed && !this.props.isMobile;
  }

  isMobileNewsfeed() {
    return this.props.isNewsfeed && this.props.isMobile;
  }

  renderBookDescription() {
    const previewBook = !this.props.isMobile ?
     <PreviewBook book={this.props.creative.book}/> : null;
    if (this.props.creative.customText) {
      return (
        <div className="gr-book__description u-marginTopXSmall">
          <ExpandableHtml {...this.props.creative.customText} />
          <div className="u-paddingTopSmall">
            {previewBook}
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <BookDescription className="u-marginTopXSmall" bookDescription={this.props.creative.book.description} />
          <div className="u-paddingTopSmall">
            {previewBook}
          </div>
        </div>
      );
    }
  }

  renderLearnMoreButton() {
    const bookLink = <a href={this.props.creative.book.bookUrl} rel="noopener noreferrer" className="gr-button u-marginTopMedium">
      Learn More
    </a>;
    return <ClickTrackingWrapper trackingOptions={TRACKED_CLASSES.cta_link} children={bookLink} />;
  }

  renderBook() {
    const bodyContainerClassnames = classNames({
      "gr-newsfeedItem__body": this.isDesktopNewsfeed(),
      bookAdBody: !this.isDesktopNewsfeed(),
    });
    const book = this.props.creative.book;
    if (this.props.signedIn) {
      return <BookWithWtrButton
          body={this.renderBookDescription()}
          book={book}
          responsiveWtrButton={this.props.isMobile}
          bookClassName={bodyContainerClassnames} />;
    } else {
      return <Book
        {...book}
        className={bodyContainerClassnames}
        size={VALID_BOOK_SIZES.LARGE}>
          { this.props.isMobile ?
              <CommunityRating rating={parseFloat(book.avgRating)}
                            displayAvgRatingText={false}
                            ratingsCount={book.ratingsCount}
                            size={CommunityRating.ICON_SIZES.MEDIUM} /> : null }
          {this.renderBookDescription()}
          {this.renderLearnMoreButton()}
      </Book>;
    }
  }

  renderCaret() {
    return <div className="googleNativeAd__caret">
      <NativeAdCaret book={this.props.creative.book}
        helpLink={this.props.creative.helpLink}
        updateId={this.props.id}
        signedIn={this.props.signedIn}/>
    </div>;
  }

  renderIconBody() {
    if (this.props.creative.firstFriend) {
      return <UserIcon user={this.props.creative.firstFriend} size={ICON_SIZES.MEDIUM}/>;
    } else {
      return <ShapeIcon imageUrl={this.props.creative.defaultIcon}
        size={ICON_SIZES.MEDIUM}
        imageAlt="Sponsored Newsfeed Item Icon" border={true}/>;
    }
  }

  renderIcon() {
    return <div className="gr-newsfeedItem__headerIcon gr-mediaBox__media gr-mediaBox__media--marginSmall">
      {this.renderIconBody()}
    </div>;
  }

  render() {
    const sponsoredLine = <NativeAdSponsoredLabel
      sponsoredBy={this.props.creative.sponsoredBy}
      sponsoredUrl={this.props.creative.sponsoredUrl} />;

    const bodyClasses = classNames("gr-mediaBox", {
      "feedItem feedItem--ad": this.isMobileNewsfeed(),
      "gr-newsfeedItem gr-mediaBox": this.isDesktopNewsfeed(),
      "googleNativeAd__item ": !this.isDesktopNewsfeed(),
    });
    const bookClasses = classNames({
      nativeAd__newsfeedBody: this.isDesktopNewsfeed(),
    });
    return <div className={bodyClasses} ref='nativeAd'>
      {this.isDesktopNewsfeed() && this.renderIcon()}
      <div className="u-marginBottomSmall">
        {this.renderCaret()}
        <div className="nativeAd__headerText">
          <NativeBookAdHeader creative={this.props.creative}
                                  signedIn={this.props.signedIn}
                                  allowBehavioralTargeting={this.props.allowBehavioralTargeting} />
        </div>
        {this.props.creative.isSponsored ? sponsoredLine : null}

        <div className={bookClasses}>
          {this.renderBook()}
        </div>
      </div>
    </div>;
  }
}

NativeBookAd.propTypes = {
  isNewsfeed: PropTypes.bool,
  isMobile: PropTypes.bool,
  creative: PropTypes.shape().isRequired,
  adDeviceType: PropTypes.string,
  signedIn: PropTypes.bool,
  allowBehavioralTargeting: React.PropTypes.bool,
  id: PropTypes.string,
};

export default NativeBookAd;
