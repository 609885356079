import GrComponentFactory from "../gr_component_factory";
import GrPropTypes from "../shared/gr_prop_types";
import { PropTypes } from "react";

import AuthorFollowButton from "../author/author_follow_button";
import { ICON_SIZES } from "../../modules/shape_icon_values";
import UserFollowWidget from "../user/user_follow_widget";
import UserIcon from "../user/user_icon";
import UserProfileLink from "../user/user_profile_link";

export default GrComponentFactory.createClass({
  displayName: "ReadingNotesAnnotator",

  propTypes: {
    displayUser: GrPropTypes.user().isRequired,
    isSignedIn: React.PropTypes.bool.isRequired,
    showFollowWidget: PropTypes.bool,
    signInRedirectUrl: React.PropTypes.string.isRequired,
  },

  defaultProps: {
    showFollowWidget: false,
  },

  renderFollowComponentIfNeeded() {
    if (this.props.showFollowWidget) {
      if (this.props.isSignedIn) {
        if (this.props.displayUser.authorId) {
          return (
            <AuthorFollowButton authorId={this.props.displayUser.authorId}
                                className="gr-button--small u-displayBlock" />
          );
        } else {
          return <UserFollowWidget displayUser={this.props.displayUser} />;
        }
      } else {
        return (
          <a href={this.props.signInRedirectUrl}
             className="gr-button gr-button--small gr-box gr-box--inline gr-box--marginRightSmall"
             rel="nofollow">
             Follow
          </a>
        );
      }
    }
  },

  render() {
    return (
      <div className="readingNotesAnnotator gr-mediaFlexbox gr-mediaFlexbox--alignItemsCenter">
         <div className="readingNotesAnnotator__userIcon gr-mediaFlexbox__media">
           <UserIcon user={this.props.displayUser}
                     size={ICON_SIZES.MEDIUM} />
         </div>
         <div className="gr-mediaFlexbox__desc">
            <div className="readingNotesAnnotator__name">by&nbsp;
              <UserProfileLink user={this.props.displayUser}
                               className="u-defaultType" />
            </div>
            {this.renderFollowComponentIfNeeded()}
         </div>
      </div>
    );
  },
});
