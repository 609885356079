import GrComponentFactory from "../gr_component_factory";
import React from "react";

export default GrComponentFactory.createClass({
  displayName: "RegisterButton",

  propTypes: {
    fullWidth: React.PropTypes.bool,
    signUpUrl: React.PropTypes.string.isRequired,
  },

  getDefaultProps() {
    return {
      fullWidth: false,
    };
  },

  render() {
    const registerButton = (
      <a className={ `gr-button gr-button--large ${this.props.fullWidth ? "gr-button--fullWidth" : ""}` }
        href={ this.props.signUpUrl } >
        Sign up with email
      </a>
    );
    return (registerButton);
  },
});
