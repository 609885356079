// Entry point for native ads

import React from "react";
import Reflux from "reflux";
import GrComponentFactory from "../../gr_component_factory";
import GoogleAdsStore from "../../../react_stores/google_ads_store";
import GoogleNativeAd from "./google_native_ad";

export default GrComponentFactory.createClass({
  displayName: "NativeAd",

  mixins: [
    Reflux.connectFilter(GoogleAdsStore, "googleAdsStore", function (adsStoreState) {
      return adsStoreState.nativeAds[this.props.adId];
    }),
  ],

  propTypes: {
    adId: React.PropTypes.string.isRequired,
    trackingOptions: React.PropTypes.shape({
      enableTracking: React.PropTypes.bool,
      adId: React.PropTypes.string,
    }).isRequired,
    className: React.PropTypes.string,
    signedIn: React.PropTypes.bool,
    allowBehavioralTargeting: React.PropTypes.bool,
    isMobile: React.PropTypes.bool,
    isNewsfeed: React.PropTypes.bool,
  },

  getAdsStoreState() {
    return this.state.googleAdsStore;
  },

  getDefaultProps() {
    return {
      signedIn: true,
      isMobile: false,
      isNewsfeed: true,
    };
  },

  render() {
    if (!this.getAdsStoreState()) {
      return null;
    }
    return <GoogleNativeAd {...this.props} />;
  },
});
