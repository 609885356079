import { PropTypes } from "react";

const GetACopyLink = ({ name, url }) => (
  <div className="u-marginBottomTiny">
    <a href={url}
       target="_blank"
       rel="noopener noreferrer"
       className="gr-button gr-button--fullWidth">
      {name}
    </a>
  </div>
);

GetACopyLink.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default GetACopyLink;
