import ReactDOM from "react-dom";
import { each, keys, isUndefined } from "lodash";
import { handleDataTracking } from "../modules/data_tracking/data_tracking_helper";

/**
 * This function is called on every component when it mounts and attaches 
 * click tracking data attributes that are provided by the trackingOptions props. 
 * The event listener is added to the top most element, that has enabledTracking = true
 */

export default {
  componentDidMount() {
    const trackingOptions = this.props.trackingOptions;
    if (trackingOptions) {
      const node = ReactDOM.findDOMNode(this);
      if (node) {
        // Store specified tracking values as data attributes on the component's DOM node
        each(keys(trackingOptions), (key) => {
          const value = trackingOptions[key];
          if (!isUndefined(value)) {
            node.setAttribute(`data-tracking-${  key}`, JSON.stringify(value));
          }
        });

        if(trackingOptions.enableTracking === true) {
          // register click tracking for requested components
          node.addEventListener("click", handleDataTracking);
        }
      }
    }
  },
};
