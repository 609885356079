// React Component for friend/follow button module on user show page.
// Handles logic for when to render Friend/Follow buttons - "Un" states are
// handled by the individual button components

import React from "react";
import Reflux from "reflux";
import classNames from "classnames";

import GrComponentFactory from "../gr_component_factory";

import FollowButton from "./follow_button";
import FriendButton from "./friend_button";
import FriendFollowDropdown from "./friend_follow_dropdown";
import Spinner from "../spinner";
import ModalTrigger from "../modal_trigger";
import BlockUserConfirmationModal from "./confirmation_modals/block_user_confirmation_modal";
import { getUnblockUserModalId } from "../../modules/modal_id_helper";
import FriendFollowModuleStore from "../../react_stores/user_relationships/friend_follow_module_store";
import BlockUserStore from "../../react_stores/user/show/block_user_store";

export default GrComponentFactory.createClass({
  displayName: "FriendFollowModule",

  mixins: [
    Reflux.connectFilter(FriendFollowModuleStore, "ffStore", function(store) {
      return store[this.props.displayUser.userId];
    }),
    Reflux.connect(BlockUserStore, "buStore"),
  ],

  propTypes: {
    displayUser: React.PropTypes.shape({
      firstName: React.PropTypes.string.isRequired,
      viewableName: React.PropTypes.string.isRequired,
      userId: React.PropTypes.number.isRequired,
    }).isRequired,
    hideDropdown: React.PropTypes.bool.isRequired,
    displayMobileDropdown: React.PropTypes.bool.isRequired,
    sendMessageUrl: React.PropTypes.string.isRequired,
    recommendBooksUrl: React.PropTypes.string.isRequired,
    compareBooksUrl: React.PropTypes.string.isRequired,
    flagAbuseUrl: React.PropTypes.string.isRequired,
    addFriendUrl: React.PropTypes.string.isRequired,
    friendRequestToId: React.PropTypes.number,
    friendRequestFromId: React.PropTypes.number,
    renderFollowButton: React.PropTypes.bool.isRequired,
    renderFriendButton: React.PropTypes.bool.isRequired,
    friendButtonInDropdown: React.PropTypes.bool.isRequired,

    // adds additional tracking param for Follows on user show page
    fromUserShowPage: React.PropTypes.bool,
  },

  getDefaultProps() {
    return {
      fromUserShowPage: false,
    };
  },

  shouldRenderFriendButton() {
    return this.props.renderFriendButton && !this.props.friendButtonInDropdown;
  },

  shouldRenderFollowButton() {
    return this.props.renderFollowButton && !this.state.ffStore.isFriend;
  },

  render() {
    if (this.state.ffStore.inProgress || this.state.buStore.blockInProgress) {
      return (
        <div className="u-clearBoth">
          <Spinner />
        </div>
      );
    }

    const followCx = classNames("u-inlineBlock", {
      "u-marginRightMedium": this.shouldRenderFriendButton(),
    });
    
    return (
      <div className="friendFollowModule">
        {this.state.buStore.isBlocked && (
          <div
            className="friendFollowModule__blockedUserText"
            data-testid="blockText">
            You've blocked {this.props.displayUser.firstName}.
            <div className="friendFollowModule__blockedUserTextTrigger">
              <ModalTrigger
                id={getUnblockUserModalId(this.props.displayUser.userId)}>
                <button
                  className="gr-buttonAsLink friendFollowModule__blockedUserText friendFollowModule__blockedUserText--red"
                  type="button">
                  Unblock
                </button>
              </ModalTrigger>

              <BlockUserConfirmationModal
                isBlocked={this.state.buStore.isBlocked}
                displayUser={this.props.displayUser}
                isPendingFriendRequestTo={
                  this.state.ffStore.isPendingFriendRequestTo
                }
              />
            </div>
          </div>
        )}

        <div className="u-marginAuto">
          {this.shouldRenderFollowButton() && (
            <div className={followCx}>
              <FollowButton
                displayUser={this.props.displayUser}
                isFollowing={this.state.ffStore.isFollowing}
                isPendingFriendRequestTo={
                  this.state.ffStore.isPendingFriendRequestTo
                }
                friendRequestToId={this.props.friendRequestToId}
                fromUserShowPage={this.props.fromUserShowPage}
              />
            </div>
          )}

          {this.shouldRenderFriendButton() && (
            <FriendButton
              displayUser={this.props.displayUser}
              addFriendUrl={this.props.addFriendUrl}
              isFriend={this.state.ffStore.isFriend}
              isPendingFriendRequestFrom={
                this.state.ffStore.isPendingFriendRequestFrom
              }
              isPendingFriendRequestTo={
                this.state.ffStore.isPendingFriendRequestTo
              }
              friendRequestFromId={this.props.friendRequestFromId}
            />
          )}

          {!this.props.hideDropdown && <FriendFollowDropdown {...this.props} />}
        </div>
      </div>
    );
  },
});
