import React from "react";
import GrComponentFactory from "./gr_component_factory";

export default GrComponentFactory.createClass({
  displayName: "PaginationSinglePageLink",

  propTypes: {
    pageNumber: React.PropTypes.number,
    currentPageNumber: React.PropTypes.number,
    changePageAction: React.PropTypes.func.isRequired,
  },

  render() {
    if (this.props.pageNumber === this.props.currentPageNumber) {
      return (
        <span aria-label={`${this.props.pageNumber} current page`}
              className="gr-paginationLinks__pageNumLink
                         gr-paginationLinks__pageNumLink--selected">
          {this.props.pageNumber}
        </span>
      );
    } else {
      return (
        <button className="gr-paginationLinks__pageNumLink"
                onClick={this.jumpToPage}>{this.props.pageNumber}
        </button>
      );
    }
  },

  jumpToPage(event) {
    event.preventDefault();
    this.props.changePageAction(this.props.pageNumber);
  },
});
