import { PropTypes } from "react";
import { connect, Provider  } from "react-redux";
import GetACopyStore from "../../react_stores/buy_buttons/get_a_copy_store";
import { getACopyClickedHandler } from "../../react_actions/buy_buttons/get_a_copy_actions";


export const GetACopyButton = ({ getACopyDataUrl, getACopyClickedHandler }) => (
  <div>
    <button className="gr-button gr-button--fullWidth u-paddingTopTiny u-paddingBottomTiny u-defaultType"
            onClick={() => getACopyClickedHandler(getACopyDataUrl)}>
      Get a copy
    </button>
  </div>
);

GetACopyButton.propTypes = {
  getACopyDataUrl: PropTypes.string.isRequired,
  getACopyClickedHandler: PropTypes.func.isRequired,
};

const stateToProps = () => ({});
const dispatchToProps = (dispatch) => ({
  getACopyClickedHandler: (dataUrl) => dispatch(getACopyClickedHandler(dataUrl)),
});

const ConnectedButton = connect(stateToProps, dispatchToProps)(GetACopyButton);

const ButtonWithProviderRoot = (props) => (
  <Provider store={GetACopyStore}>
    <ConnectedButton {...props} />
  </Provider>
);

export default ButtonWithProviderRoot;

