/*doc
---
title: Facebook share
name: facebook_share
category: React
---

This component displays the facebook share button that is used in the share module.

Required props:

* `options` (object): Options for facebook share, generated with fb_feed_options

Optional props:

* `metricName` (string): Full name or modifier for metric name

*/

import React from "react";
import GrComponentFactory from "./../gr_component_factory";
import { httpPost } from "../../modules/ajax/ajax_helper";

export default GrComponentFactory.createClass({
  displayName: "FacebookShare",

  propTypes: {
    options: React.PropTypes.shape({
      name: React.PropTypes.string,
      link: React.PropTypes.string,
      description: React.PropTypes.string,
      caption: React.PropTypes.string,
      picture: React.PropTypes.string,
      properties: React.PropTypes.object,
      action_link_text: React.PropTypes.string,
      resource_type: React.PropTypes.string,
    }).isRequired,
    metricName: React.PropTypes.string,
  },

  onFacebookButtonClick() {
    if (this.props.metricName) {
      httpPost("/track/track_click", {
        data: {
          feature: this.props.metricName,
        },
      });
    }
    $grfb.feedDialog(this.props.options);
  },

  render() {
    return (
      <button className={`${this.withBemModifiers("shareSocialButtons__button")} shareSocialButtons__button--facebook`} 
              aria-label="Share to Facebook"
              type="button"
              onClick={() => this.onFacebookButtonClick()} />
    );
  },
});
