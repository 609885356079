import Reflux from "reflux";
import GrComponentFactory from "../../gr_component_factory";
import UserNotInterestedStore from "../../../react_stores/user_not_interested_store";
import UserNotInterestedActions from "../../../react_actions/user_not_interested_actions";
import NativeBookAd from "./native_book_ad";
import NativeFlexAd from "./native_flex_ad";
import UserNotInterestedMessage from "./user_not_interested_message";

export default GrComponentFactory.createClass({
  displayName: "NativeAdBody",

  mixins: [
    Reflux.connect(UserNotInterestedStore, "userNotInterestedStore"),
  ],

  propTypes: {
    adId: React.PropTypes.string,
    creative: React.PropTypes.object.isRequired,
    adDeviceType: React.PropTypes.string,
    signedIn: React.PropTypes.bool,
    allowBehavioralTargeting: React.PropTypes.bool,
    isMobile: React.PropTypes.bool,
    isNewsfeed: React.PropTypes.bool,
  },

  getDefaultProps() {
    return {
      isNewsfeed: true,
      isMobile: false,
      signedIn: true,
    };
  },

  getUserNotInterestedStoreState() {
    return this.state.userNotInterestedStore;
  },

  render() {
    if(this.props.creative && (this.props.creative.book)){
      const bookId = this.props.creative.book.bookId;
      if (this.getUserNotInterestedStoreState()[bookId]) {
        if(this.getUserNotInterestedStoreState()[bookId].isClosed){
          return null;
        } else if (this.getUserNotInterestedStoreState()[bookId].isFlashMessage){
          return <UserNotInterestedMessage
            id={this.props.adId}
            isNewsfeed={this.props.isNewsfeed}
            className={this.props.className}
            creative={this.props.creative} />;
        }
      } else {
        UserNotInterestedActions.addBookIDToState(bookId);
      }
    }
    if (this.props.creative && this.props.creative.book){
      return <NativeBookAd 
        isNewsfeed={this.props.isNewsfeed}
        isMobile={this.props.isMobile}
        creative={this.props.creative}
        adDeviceType={this.props.adDeviceType}
        signedIn={this.props.signedIn}
        allowBehavioralTargeting={this.props.allowBehavioralTargeting}
        id={this.props.adId} />;
    }
    else {
      return <NativeFlexAd 
        id={this.props.adId}
        creative={this.props.creative}
        signedIn={this.props.signedIn}
        allowBehavioralTargeting={this.props.allowBehavioralTargeting}
        isNewsfeed={this.props.isNewsfeed}
        isMobile={this.props.isMobile} />;
    }
  },
});
