export const clearFlashMessage = () => {
  $j("#flashMessages").trigger("clearFlashMessage");
};

// Hide any error message displayed in flash; display success flash message
export const flashSuccessMessage = (msg) => {
  clearFlashMessage();
  $j("#flashMessages").trigger("addFlashMessage", [msg, "success"]);
};

export const flashErrorMessage = (msg) => {
  $j("#flashMessages").trigger("addFlashMessage", [msg, "error"]);
};
