/*doc
---
title: Share social
name: share_social
category: React
---

This component displays sharing buttons for external social platforms and a copy link button.

Platforms supported:
  - Facebook
  - Twitter
  - Pinterest

Required props:

  None

Optional props:

* `facebook` (bool): Inclusion/exclusion switch for Facebook sharing
    default: false
* `twitter` (bool): Inclusion/exclusion switch for Twitter sharing
    default: false
* `pinterest` (bool): Inclusion/exclusion switch for Pinterest sharing
    default: false
* `copyLink` (bool): Inclusion/exclusion switch for copy link button
    default: false
* `fbOptions` (object): Options for facebook share, generated with fb_feed_options
* `twitterOptions` (object): Options for twitter share (text, url)
* `pinterestOptions` (object): Options for pinterest share (description, url, media)
* `metricName` (string): Full name or modifier for metric name
    default: ""
*/

// TODO-GR-38350, add copy link functionality

import React from "react";
import GrComponentFactory from "./gr_component_factory";
import FacebookShare from "./sharing/facebook_share";
import TwitterShare from "./sharing/twitter_share";
import PinterestShare from "./sharing/pinterest_share";
import classnames from "classnames";

export default GrComponentFactory.createClass({
  displayName: "ShareSocial",

  propTypes: {
    facebook: React.PropTypes.bool,
    twitter: React.PropTypes.bool,
    pinterest: React.PropTypes.bool,
    copyLink: React.PropTypes.bool,
    fbOptions: React.PropTypes.object,
    twitterOptions: React.PropTypes.object,
    pinterestOptions: React.PropTypes.object,
    metricName: React.PropTypes.string,
  },

  getDefaultProps() {
    return {
      facebook: false,
      twitter: false,
      pinterest: false,
      copyLink: false,
      metricName: "",
    };
  },

  renderSocialPlatform(platform) {
    if (this.props[platform]) {
      const fullMetricName = `SocialSharing_${this.props.metricName}_${platform}`;
      switch (platform) {
        case "facebook":
          return <FacebookShare options={this.props.fbOptions}
                                metricName={fullMetricName}
                                bemModifiers={this.props.bemModifiers} />;
        case "twitter":
          return <TwitterShare options={this.props.twitterOptions}
                               metricName={fullMetricName}
                               bemModifiers={this.props.bemModifiers} />;
        case "pinterest":
          return <PinterestShare options={this.props.pinterestOptions}
                                 metricName={fullMetricName}
                                 bemModifiers={this.props.bemModifiers} />;
      }
    }
    else {
      return null;
    }
  },

  render() {
    const classes = classnames(this.withBemModifiers("shareSocialButtons"),
      this.props.className);
    return (
      <div className={classes}>
        {this.renderSocialPlatform("facebook")}
        {this.renderSocialPlatform("twitter")}
        {this.renderSocialPlatform("pinterest")}
      </div>
    );
  },
});
