/*
 This should be merged into a page-wide store.
 */
import { createStore, applyMiddleware } from "redux";
import createLogger from "redux-logger";
import envUtils from "../../modules/env_utils";
import thunk from "redux-thunk";
import reducer from "../../react_reducers/buy_buttons/get_a_copy_reducer";

const middlewares = [thunk];

if (envUtils.isDevelopment() && envUtils.hasDom()) {
  middlewares.push(createLogger());
}

const store = createStore(
  reducer,
  {},
  applyMiddleware(...middlewares)
);

export default store;
