/**
 * A modal id library to help modals find the corresponding modal id.
 */

// friend/follow confirmation modal helpers
const addDropdownSuffix = (prefix, add = false) => add ? `${prefix}Dropdown` : prefix;

export const getUnfollowModalId = (userId, fromDropdown = false) => addDropdownSuffix(`confirmUnfollow${userId}`, fromDropdown);

export const getCancelFriendRequestModalId = (userId, fromDropdown = false) => addDropdownSuffix(`confirmCancelFriendRequest${userId}`, fromDropdown);

export const getUnfriendModalId = (userId, fromDropdown = false) => addDropdownSuffix(`confirmUnfriend${userId}`, fromDropdown);

export const getBlockUserModalId = (userId, fromDropdown = false) => addDropdownSuffix(`confirmBlockUser${userId}`, fromDropdown);

export const getUnblockUserModalId = (userId, fromDropdown = false) => addDropdownSuffix(`confirmUnblockUser${userId}`, fromDropdown);
