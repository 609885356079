// See documentation on https://www.goodreads.com/style_guide

import React from "react";
import GrComponentFactory from "../gr_component_factory";
import GrPropTypes from "../shared/gr_prop_types";
import moment from "moment";
import { fromNow } from "../../modules/date_utils";


export default GrComponentFactory.createClass({
  displayName: "RelativeTime",

  propTypes: {
    time: GrPropTypes.date.isRequired,
    longFormat: React.PropTypes.bool,
  },

  getDefaultProps() {
    return {
      longFormat: false,
    };
  },

  getRelativeTime() {
    if(this.props.longFormat) {
      return moment(new Date(this.props.time)).fromNow();
    } else {
      return fromNow(this.props.time);
    }
  },

  render() {
    return (
      <time dateTime={this.props.time}
            aria-label={moment(new Date(this.props.time)).fromNow()}>
        {this.getRelativeTime()}
      </time>
    );
  },
});
