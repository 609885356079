/*doc
---
title: Click Tracking Wrapper
name: click_tracking_wrapper
category: React
---

Wrappers an object in order to attach click tracking options to component

See also [tracking_data_attributes_mixin].

You can pass in children such as links or buttons that will report to dfp and pmet.

```rails_example
<%= react_component 'ReactComponents.StyleGuideExampleComponentWrapper',
                        componentName: 'ReactComponents.ClickTrackingWrapper',
                        propsToEval: {
                          children: "React.createElement('a', {href: '#'}, 'Random author name');"
                        },
                        trackingOptions: {
                          dfp: true,
                          pmet: {
                            click_type: "author_name"
                          }
                        }
                    %>
```
*/
import React from "react";
import GrComponentFactory from "./gr_component_factory";

export default GrComponentFactory.createClass({
  displayName: "ClickTrackingWrapper",

  propTypes: {
    trackingOptions: React.PropTypes.shape({
      dfp: React.PropTypes.bool,
      pmet: React.PropTypes.object.isRequired,
    }).isRequired,
    children: React.PropTypes.element.isRequired,
  },

  render () {
    return this.props.children;
  },
});
