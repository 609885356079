/*doc
---
title: Share Dialog Preview
name: share_dialog_preview
category: React
---

A generic container for a preview of your Sharing resource in the Share Dialog modal.
Use if your preview card follows media box format with a title and image. Children
are rendered under secondaryText.

Required props:

* `primaryText`: header of preview card
* `imageUrl`: url of image

Optional props:

* `secondaryText`: either string or element

```rails_example
<%= react_component 'ReactComponents.ShareDialogPreview',
                    {
                      primaryText: 'This is the primaryText',
                      imageUrl: 'https://d.gr-assets.com/books/1363934734s/1842.jpg',
                      secondaryText: 'This is the secondaryText'
                    }
%>
```
*/

import { PropTypes } from "react";
import GrComponentFactory from "../../gr_component_factory";

export default GrComponentFactory.createClass({
  displayName: "ShareDialogPreview",

  propTypes: {
    imageUrl: PropTypes.string.isRequired,
    primaryText: PropTypes.string.isRequired,
    secondaryText: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  },

  renderSecondaryText() {
    if (this.props.secondaryText) {
      return (
        <div className="sharePreview__secondaryText">
          {this.props.secondaryText}
        </div>
      );
    } else {
      return null;
    }
  },

  render() {
    return(
      <div className="gr-mediaFlexbox shareModal__previewContainer">
        <img
          className="gr-mediaFlexbox__media sharePreview__image"
          src={this.props.imageUrl}
          alt={this.props.primaryText}
        />

        <div className="gr-mediaFlexbox__desc">
          <div className="gr-h3 gr-h3--serif gr-h3--noMargin">
            {this.props.primaryText}
          </div>

          {this.renderSecondaryText()}

          <div className="u-marginTopXSmall">
            {this.props.children}
          </div>
        </div>
      </div>
    );
  },
});
