import Reflux from "reflux";

export default Reflux.createActions({
  loadMoreUpdates: {},
  newsfeedItemsLoaded: { sync: true },
  updateNativeAdSlot: {},
  userNotInterested: {},
  undoUserNotInterested: {},
  hideStory: {},
  previewBook: {},
  setUpdatesFeedPreferences: {},
});
