import GrPropTypes from "../shared/gr_prop_types";

const renderCta = (annotatedBookData) => {
  if (annotatedBookData.noSharedAnnotations) {
    return (
      <div>
        <div className="annotatedBookItem__bookInfo__knhCta annotatedBookItem__bookInfo__knhCta--noneVisible">
          {annotatedBookData.knhCta}
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div className="annotatedBookItem__bookInfo__knhCta">
          {annotatedBookData.knhCta}
        </div>
      </div>
    );
  }
};

const AnnotatedBookItem = (props) => (
    <div className="annotatedBookItem">
      <a className="annotatedBookItem__knhLink" href={props.annotatedBookData.readingNotesUrl}>
        <div className="annotatedBookItem__box gr-row">
          <div className="annotatedBookItem__imageColumn gr-col-12 gr-col-lg-auto">
            <img src={props.annotatedBookData.imageUrl}/>
          </div>
          <div className="annotatedBookItem__mainColumn gr-col-12 gr-col-lg-auto">
            <div className="annotatedBookItem__bookInfo">
              <div className="annotatedBookItem__bookInfo__bookTitle">{props.annotatedBookData.title}</div>
              <div className="annotatedBookItem__bookInfo__bookAuthor">By {props.annotatedBookData.authorName}</div>
              {renderCta(props.annotatedBookData)}
            </div>
          </div>
        </div>
      </a>
    </div>
);

AnnotatedBookItem.propTypes = {
  annotatedBookData: GrPropTypes.readingNotesAnnotatedBookItem().isRequired,
};

export default AnnotatedBookItem;
