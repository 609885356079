/*
This component displays the 'Report Wrong Book' button.

Required props:
* `book`: Book JSON referring to the book this link lets you preview
* `modalId`: ID of the corresponding modal this button opens (ReportWrongBookForm)
*/

import React from "react";
import GrComponentFactory from "../gr_component_factory";
import GrPropTypes from "../shared/gr_prop_types";
import ModalTrigger from "../modal_trigger";

export default GrComponentFactory.createClass({
  displayName: "ReportWrongBookButton",

  propTypes: {
    book: GrPropTypes.book().isRequired,
    modalId: React.PropTypes.string.isRequired,
  },

  render() {
    return (
      <div className="previewBook__reportWrongBookButtonContainer">
        <ModalTrigger id={this.props.modalId}>
          <button aria-label={"This is not the right book."}
                    className="gr-buttonAsLink
                               previewBook__reportWrongBookButton">
              Not the book you’re looking for?
          </button>
        </ModalTrigger>
      </div>
    );
  },
});
