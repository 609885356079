/*
 WantToReadMenuShelfCreator is a component that lets you add a new shelf to your
 UserShelves for the current user.
*/
import React from "react";
import GrComponentFactory from "../gr_component_factory";
import WantToReadMenuActions from "../../react_actions/want_to_read_menu_actions";
import ShelvingsActions from "../../react_actions/shelvings_actions";
import classNames from "classnames";
import _ from "lodash";

export default GrComponentFactory.createClass({
  displayName: "WantToReadMenuShelfCreator",

  propTypes: {
    userCanAddShelf: React.PropTypes.bool.isRequired,
    shelfNameFilter: React.PropTypes.string,
    uniqueId: React.PropTypes.string.isRequired,
    bookId: React.PropTypes.string.isRequired,
    bookTitle: React.PropTypes.string.isRequired,
    useModalView: React.PropTypes.bool,
  },

  getDefaultProps() {
    return { useModalView: false };
  },

  getInitialState() {
    return {
      unsavedShelfName: "",
    };
  },

  openAddShelfTextbox() {
    WantToReadMenuActions.openAddShelfTextbox(this.props.uniqueId);
  },

  closeAddShelfTextbox() {
    WantToReadMenuActions.closeAddShelfTextbox(this.props.uniqueId);
  },

  checkIfCommentIsEmpty(event) {
    this.setState({ unsavedShelfName: event.target.value });
  },

  shelveAsNewShelfName(shelfName, event) {
    event.preventDefault();
    WantToReadMenuActions.updateShelfNameFilter("", this.props.uniqueId);
    ShelvingsActions.toggleNonExclusiveShelving(this.props.bookId, { name: shelfName }, true, this.props.bookTitle );
  },

  render() {
    if (this.props.shelfNameFilter !== "" && this.props.userCanAddShelf) {
      const buttonClassnames = this.withBemModifiers("wantToReadShelfCreator__addNamedShelfButton");
      return (<span className="wantToReadShelfCreator">
                <button className={buttonClassnames}
                        aria-label={`Add new ${  this.props.shelfNameFilter  } shelf`}
                        onClick={(event) => { this.shelveAsNewShelfName(this.props.shelfNameFilter, event); }}>
                  Add new “
                  <span className="wantToReadShelfCreator__addShelfButtonShelfName">
                    {this.props.shelfNameFilter}
                  </span>” shelf
                </button>
              </span>);
    } else if (this.props.showAddShelfTextbox) {
      const containerCx = classNames(
        this.withBemModifiers("wantToReadShelfCreator"),
        "wantToReadShelfCreator--wrap"
      );

      const textInputCx = classNames(
        "gr-textInput",
        this.withBemModifiers("wantToReadShelfCreator__textInput"),
        {
          "gr-textInput--small": !this.props.useModalView,
        }
      );

      const submitCx = classNames(
        this.withBemModifiers("wantToReadShelfCreator__addShelfButton"),
        "gr-button",
        { "gr-button--small": !this.props.useModalView,
          "gr-button--dark gr-button--fullWidth": this.props.useModalView }
      );

      return (
        <form className={containerCx}
              onSubmit={(event) => { this.shelveAsNewShelfName(this.state.unsavedShelfName, event); }}>
          <input className={textInputCx}
                 type="text"
                 placeholder="New Shelf Name"
                 aria-label="Add new shelf name"
                 autoFocus={true}
                 onChange={this.checkIfCommentIsEmpty} />

          <div className="wantToReadShelfCreator__buttonContainer">
            <input type="submit"
                   className={submitCx}
                   disabled={_.isEmpty(_.trim(this.state.unsavedShelfName))}
                   value="Add" />

            {this.props.useModalView &&
              <button type="button"
                      className="gr-button gr-button--fullWidth wantToReadShelfCreator__cancelButton"
                      onClick={this.closeAddShelfTextbox}
                      aria-label="Close add new shelf form">
                Cancel
              </button>
            }
          </div>
        </form>
      );
    } else if (!this.props.userCanAddShelf) {
      // User cannot create a new shelf if they have typed in the name
      return null;
    } else {
      const buttonClassnames = this.withBemModifiers("wantToReadShelfCreator__showAddShelfTextboxButton");
      return (<span className="wantToReadShelfCreator">
                <button className={buttonClassnames}
                        aria-label="Add new shelf"
                        onClick={this.openAddShelfTextbox}>
                  Add New Shelf
                </button>
              </span>);
    }
  },
});
