import LikeStore from "../../react_stores/like_store";
import LikeSimpleFooter from "../../react_components/like/like_simple_footer";

export const ReviewShowComponents = {
  LikeSimpleFooter,
};

export const ReviewShowStores = {
  LikeStore,
};
