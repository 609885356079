// See documentation on https://www.goodreads.com/style_guide

import { PropTypes } from "react";
import GrComponentFactory from "../gr_component_factory";
import SimpleSiteAnnouncement from "./simple_site_announcement";

export default GrComponentFactory.createClass({
  displayName: "SiteAnnouncement",
  propTypes: {
    title: PropTypes.string.isRequired,
    targetUrl: PropTypes.string,
    displayData: PropTypes.shape({
      description: PropTypes.string,
      mastheadUrl: PropTypes.string,
      imageUrl: PropTypes.string,
    }).isRequired,
  },

  shouldRenderLargeAnnouncement() {
    const largeAnnouncementData = this.props.displayData;
    return largeAnnouncementData &&
           largeAnnouncementData.description &&
           largeAnnouncementData.mastheadUrl;
  },

  renderLargeAnnouncement() {
    return <div className="siteAnnouncement gr-mediaFlexbox gr-mediaFlexbox--columnDirection">
      <a className="gr-mediaFlexbox__media siteAnnouncement__masthead"
         href={this.props.targetUrl}
         aria-label={this.props.title}>
         <img src={this.props.displayData.mastheadUrl}
              alt={this.props.title} />
      </a>
      <div className="gr-mediaFlexbox__desc siteAnnouncement__body">
        <a className="siteAnnouncement__title gr-hyperlink"
            href={this.props.targetUrl}>
          {this.props.title}
        </a>
        <div className="siteAnnouncement__description">
          {this.props.displayData.description}
        </div>
      </div>
    </div>;
  },

  render() {
    if (this.shouldRenderLargeAnnouncement()) {
      return this.renderLargeAnnouncement();
    } else {
      return <SimpleSiteAnnouncement imageUrl={this.props.displayData.imageUrl}
                                     announcementText={this.props.title}
                                     targetUrl={this.props.targetUrl} />;
    }
  },
});
