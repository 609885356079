import React from "react";
import GrComponentFactory from "../gr_component_factory";
import GoogleAdsStore from "../../react_stores/google_ads_store";
import Reflux from "reflux";
import classNames from "classnames";
import { requestCreativeForAdSlot, lazyLoadOnView } from "../../modules/ads/google_ad_helper";

const BLOCK_CLASS_NAME = "googleBannerAd";

export default GrComponentFactory.createClass({
  displayName: "GoogleBannerAd",

  mixins: [Reflux.connectFilter(GoogleAdsStore, "googleAdsStore", function(adsStoreState) {
    return adsStoreState.ads[this.props.adId];
  })],

  propTypes: {
    adId: React.PropTypes.string.isRequired,
    className: React.PropTypes.string,
    delayImpressionTracking: React.PropTypes.bool,
  },

  getDefaultProps() {
    return {
      delayImpressionTracking: false,
    };
  },

  getStoreState() {
    return this.state.googleAdsStore;
  },

  componentDidMount() {
    const adId = this.props.adId;
    if (this.getStoreState()) {
      const params = {
        googleHookSelector: this.refs.googleHook,
        creativeSelector: this.getStoreState().creativeSelector,
        dimensions: this.getStoreState().dimensions,
        isNativeAd: this.getStoreState().isNativeAd,
        delayImpressionTracking: this.props.delayImpressionTracking,
        isLazyLoaded: this.getStoreState().isLazyLoaded,
        path: this.getStoreState().path,
      };
      if (this.getStoreState().isLazyLoaded) {
        lazyLoadOnView(function(event) {
          if (event.target.id === this.adId) {
            requestCreativeForAdSlot(this.adId, this.params);
          }
        }.bind({ adId, params }));
      } else {
        requestCreativeForAdSlot(this.props.adId, params);
      }
    }
  },

  getWidthFromDimensions(dimensionsStr) {
    return parseInt(dimensionsStr.split("x")[0]);
  },

  getBannerAdSizingStyle(dimensions) {
    let width;
    if (Array.isArray(dimensions)) {
      // Per DFP recommendations regarding multi-size ads, we should set the
      // width to the widest creative to avoid ads being cropped.
      width = Math.max(...dimensions.map((x) => this.getWidthFromDimensions(x)));
    } else {
      width = this.getWidthFromDimensions(dimensions);
    }
    return { width, height: "auto" };
  },

  render() {
    if (this.getStoreState()) {
      const classes = classNames(
        this.props.className,
        BLOCK_CLASS_NAME,
        {
          lazyload: this.getStoreState().isLazyLoaded,
        }
      );
      return (
        <div className={classes}
             id={this.props.adId}
             style={this.getBannerAdSizingStyle(this.getStoreState().dimensions)}
             ref="googleHook" />
      );
    } else {
      return null;
    }
  },
});
