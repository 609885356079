/**
 * Methods from our TextHelpers Ruby module can return text marked with <spoiler>
 * tags, possibly including inline JavaScript to show/hide their contents. This
 * library replaces that markup with the Spoiler React component.
 */
import React from "react";
import ReactDOM from "react-dom";
import _ from "lodash";
import Spoiler from "../user_content/spoiler";

export default {
  replaceSpoilerHTMLWithReactComponents(rootDOMNode, jQuery){
    /* istanbul ignore next */
    const $j = jQuery || window.$j;
    const spoilerLinks = $j(".jsShowSpoiler", rootDOMNode);

    _.each(spoilerLinks, (spoilerLink) => {
      const $spoilerLink = $j(spoilerLink);
      const spoilerContainer = $spoilerLink.next(".spoilerContainer");
      if (spoilerContainer) {
        const spoilerText = spoilerContainer.text().replace("(hide spoiler)", "");
        const spoiler = React.createElement(Spoiler, { html: spoilerText });
        const placeholder = $j("<span></span>");
        $spoilerLink.replaceWith(placeholder);
        ReactDOM.render(spoiler, $j(placeholder)[0]);
      }
    });
  },
};
