import Reflux from "reflux";
import Freezer from "freezer-js";
import BlockUserActions from "../../../react_actions/user/show/block_user_actions";
import FriendFollowModuleStore from "../../user_relationships/friend_follow_module_store";
import { flashSuccessMessage, flashErrorMessage } from "../../../modules/flash_message_helper";
import { httpPost } from "../../../modules/ajax/ajax_helper";
import { get } from "lodash";

const defaultState = { blockInProgress: false };

const state = new Freezer(defaultState);

const getState = () => state.get();

export const DEFAULT_ERROR_MESSAGE = `Something went wrong with your request. Please
  try again later.`;

export default Reflux.createStore({
  listenables: [BlockUserActions],

  getState,

  getInitialState() {
    return getState();
  },

  initializeWith(data) {
    const newState = Object.assign({}, defaultState, data);
    getState().set(newState);
    this.trigger(getState());
  },


  // kind of a misnomer, since hitting block_member toggles blocked status, not
  // set it
  onBlockMember(id) {
    getState().set({ blockInProgress: true });
    this.trigger(getState());
    let message;
    return (httpPost(`/user/${id}/block_member`, { data: { format: "json" } })
      .done((response) => {
        flashSuccessMessage(response.message);
        getState().set({ isBlocked: response.isBlocked });
        FriendFollowModuleStore.receiveUnfriend(id);
        this.trigger(getState());

      }).fail((error) => {
        message = get(error, "message");
        if (!message) { message = DEFAULT_ERROR_MESSAGE; }
        flashErrorMessage(message);

      }).always(() => {
        getState().set({ blockInProgress: false });
        this.trigger(getState());
      })
    );
  },

  reset() {
    getState().reset(defaultState);
  },
});
