/*doc
---
title: Dropdown
name: dropdown
category: React
---

A dropdown component. Includes a trigger and content area.

NOTE: We tried to make Dropdown a single reusable component for anything with
dropdown-style interaction. Unfortunately, because of special styling and
interaction cases (e.g., navbar menus, "want to read" button),
it ended up a giant pile of special cases and logic forks. It's now an unwieldy mess.

PROPS:

REQUIRED - trigger: element that will trigger the dropdown to open
           triggerEventType: the event that will open the dropdown (e.g. click, hover)
                             Note: On touch devices the component will render as if you
                             had specified triggerEventType of "click"

OPTIONAL - tooltipText: text to render as a `title` attribute on the trigger
                        element

```rails_example
<%= react_component 'ReactComponents.Dropdown',
                       {
                        trigger: 'Browse ▾',
                        triggerEventType: 'hover',
                        children: 'Link item one' } %>
```
*/
import React from "react";
import _ from "lodash";
import Reflux from "reflux";
import GrComponentFactory from "./gr_component_factory";
import HoverIntent from "../react_mixins/hover_intent";
import classNames from "classnames";
/* react-onclickoutside is a deprecated package that should not be used for new
   code. Instead of having an outside click close a module, there should be an
   explicit close affordances in the spec. */
import OnClickOutside from "react-onclickoutside";
import keys from "../modules/keys";
import UserAgentCapabilitiesStore
  from "../react_stores/user_agent_capabilities_store";
import { acknowledgeEvent } from "../modules/shared/touch_not_ready_helper";

const TriggerEventTypes = {
  HOVER: "hover",
  CLICK: "click",
};

const alignments = {
  LEFT: "left",
  RIGHT: "right",
};

const mouseOverDelay = 100;
const mouseOutDelay = mouseOverDelay;

export default GrComponentFactory.createClass({
  displayName: "Dropdown",

  mixins: [HoverIntent,
           OnClickOutside,
           Reflux.connect(UserAgentCapabilitiesStore, "userAgentCapabilities")],

  propTypes: {
    alignment: React.PropTypes.oneOf(_.values(alignments)),
    fallbackUrl: React.PropTypes.string,
    onShowCallback: React.PropTypes.func,
    tooltipText: React.PropTypes.string,
    trigger: React.PropTypes.oneOfType([
      React.PropTypes.string,
      React.PropTypes.element,
    ]).isRequired,
    triggerEventType: React.PropTypes.oneOf(_.values(TriggerEventTypes)),
  },

  statics: {
    TriggerEventTypes,
  },

  getTriggerEventType() {
    if (this.state.userAgentCapabilities.touch) {
      return TriggerEventTypes.CLICK;
    }
    return this.props.triggerEventType;
  },

  getInitialState() {
    return {
      dropdownOpen: false,
    };
  },

  getDefaultProps() {
    return {
      triggerEventType: TriggerEventTypes.CLICK,
      alignment: alignments.LEFT,
    };
  },

  hide() {
    this.setState({ dropdownOpen: false });
  },

  show() {
    this.setState({ dropdownOpen: true });
    if (this.props.onShowCallback) {
      this.props.onShowCallback();
    }
  },

  toggle(ev) {
    if (this.props.children) {
      ev.preventDefault();
    }
    this.state.dropdownOpen ? this.hide() : this.show();
  },

  openList(ev) {
    if (keys.isDropdownOpenerKey(ev.keyCode)) {
      ev.preventDefault();
      if (keys.isArrowDown(ev.keyCode)) {
        this.show();
      }
      else {
        this.toggle(ev);
      }
    }
    else if (keys.isDefaultCloserKey(ev.keyCode)) {
      ev.preventDefault();
      this.hide();
    }
  },

  handleClickOutside() {
    // On touch devices that display dropdown components, touch gestures such as
    // panning and zooming trigger this event listener. We disabled hiding in
    // this listener for touch devices because hiding the dropdown on touch
    // gestures is disruptive.
    if (!this.state.userAgentCapabilities.touch) {
      this.hide();
    }
  },

  handleMouseEnter() {
    this.onEnterIntent(this.show, mouseOverDelay);
  },

  handleMouseLeave() {
    this.onLeaveIntent(this.hide, mouseOutDelay);
  },

  handleTriggerClick(event) {
    acknowledgeEvent(event);
    // After hovering to open the dropdown, don't close on click
    if (this.state.dropdownOpen &&
          this.props.triggerEventType === TriggerEventTypes.HOVER &&
          this.state.userAgentCapabilities.touch !== true) {
      event.preventDefault();
      return;
    } else {
      this.toggle(event);
    }
  },

  triggerClasses() {
    return classNames(
      this.withBemModifiers(["dropdown__trigger"]),
      { "dropdown__trigger--buttonReset": _.isUndefined(this.props.fallbackUrl) },
      { "dropdown__trigger--triggerEventTypeHover":
        this.props.triggerEventType === TriggerEventTypes.HOVER },
      { "dropdown__trigger--personalNav": this.props.isInPersonalNav === "true" },
      { "dropdown__trigger--personalNavActive":
        this.props.isInPersonalNav === "true" && this.state.dropdownOpen }
    );
  },

  renderTriggerButton() {
    return <button className={this.triggerClasses()}
                   aria-haspopup="true"
                   aria-expanded={this.state.dropdownOpen}
                   onClick={this.handleTriggerClick}
                   onKeyDown={this.openList}
                   title={this.props.tooltipText}
                   data-ux-click={this.props.isInPersonalNav === "true"}>
             {this.props.trigger}
           </button>;
  },

  renderTriggerLink() {
    return <a className={this.triggerClasses()}
              href={this.props.fallbackUrl}
              onClick={this.handleTriggerClick}
              onKeyDown={this.openList}
              role="button"
              aria-haspopup="true"
              aria-expanded={this.state.dropdownOpen}
              title={this.props.tooltipText}
              data-ux-click={this.props.isInPersonalNav === "true"}>
              {this.props.trigger}
            </a>;
  },

  render() {
    const dropdownClasses = classNames(this.withBemModifiers("dropdown"));
    const dropdownShowOption = (this.props.bemModifiers === "authorModule" || this.props.bemModifiers === "friendFollowModuleMobile") ?
      { "dropdown__menu--showMobile": this.state.dropdownOpen } : { "dropdown__menu--show": this.state.dropdownOpen }
    const dropdownContentsClasses = (classNames(
        this.withBemModifiers("dropdown__menu"),
        "gr-box gr-box--withShadowLarge",
        dropdownShowOption
      ));
    const trigger = _.isUndefined(this.props.fallbackUrl) ?
      this.renderTriggerButton() : this.renderTriggerLink();
    const dropdownContents =
      <div className={dropdownContentsClasses}
           role="menu">
        {this.props.children}
      </div>;

    switch(this.getTriggerEventType()) {
      case TriggerEventTypes.HOVER:
        return(
          <div className={dropdownClasses}
               onMouseEnter={this.handleMouseEnter}
               onMouseLeave={this.handleMouseLeave}>
            {trigger}
            {dropdownContents}
          </div>
        );
      case TriggerEventTypes.CLICK:
        return(
          <div className={dropdownClasses}>
            {trigger}
            {dropdownContents}
          </div>
        );
    }
  },
});
