/*
A lazy store manages data partitioned by keys, where each key has a
sensible starting value that does not need an external source to initialize
it prior to use, e.g. a form would have default values before any user input.

Another important use case is for the absence of values. For example, instead
of requiring external code to initialize the starting value for all keys, a lazy
store only requires external code to initialize the non-absent values. For
example, if storing data about Likes or Comments, the external code would only
need to initialize the store with data for keys that have at least one Like
or Comment, leaving the rest to be lazily created in their empty state when needed.

If a store's data is not partitioned by keys, then it can just create its
state to the sensible starting value at creation time, and consumers do not
need to care about whether or not the store initialized itself or an external
call did. The fact that the keys are dynamic and unknown at initialization time
is why this extra layer is needed.

If the data for a particular key needs external information to initialize it,
then such initialization should be done eagerly before any components try to
connect to the store, removing the need for the store to be lazy.

Usage in a component:

...

propTypes: {
  keyToUse: React.PropTypes.string.isRequired,
},

mixins: [connectToLazyStore(MyStore, "keyToUse")]

...

The lazy store should handle calls to getInitialState that include a single
param representing the key. If the key is not present in its data, it should
be set and returned. This set should not cause a trigger to any existing
listeners.

You can also namespace the state from the store under a different key so that
store state can more easily coexist in the same component.

mixins: [connectToLazyStore(MyStore, "uniqueId", {stateKey: "stateKey"})]

If you want to only listen to a specific value of the state, add a filterValue
param that will act as a filter for the substate you want to listen to.

mixins: [connectToLazyStore(MyStore,
                            "uniqueId",
                            {stateKey: "stateKey", filterValue: "specificStateValue"})]

*/
import _ from "lodash";
import Reflux from "reflux";

export function connectToLazyStore(store, keyPropName, { stateKey=null, filterValue=null } = {}) {
  return {
    getInitialState() {
      let value = store.getInitialState(_.get(this.props, keyPropName));
      if (filterValue) {
        value = value[filterValue];
      }
      if (!_.isNull(stateKey)) {
        value = { [stateKey]: value };
      }
      return value;
    },

    componentDidMount() {
      // This provides this.listenTo, which we need to use so
      // Reflux.ListenerMixin will cleanup on unmount
      Reflux.utils.extend(this, Reflux.ListenerMethods);

      this.listenTo(store, (newState) => {
        let incomingValue = newState[_.get(this.props, keyPropName)];
        if (filterValue) {
          incomingValue = incomingValue[filterValue];
        }
        if (!_.isNull(stateKey)) {
          incomingValue = { [stateKey]: incomingValue };
        }
        this.setState(incomingValue);
      });
    },

    componentWillUnmount: Reflux.ListenerMixin.componentWillUnmount,
  };
}
