/**
 * This helper contains parameters that track actions across pages
 * when appended to request URLs
 */

export const URLTrackingParams = {
  //Tracking used for the nav search
  FROM_SEARCH_PARAM: "from_search=true",
  SEARCH_RANK_PARAM: "rank",
  SEARCH_IDENTIFIER_PARAM: "qid",

  // Interactions with the nav search that redirect to book#show should
  // include this param for tracking purposes:
  // https://w.amazon.com/index.php/Goodreads/Teams/Discovery/Metrics/Search#desktop_web
  BOOK_SHOW_INTERACTION_PARAM: "ac=1",

  // Tracking refs used for the nav bar discussions pane
  DISCUSSIONS_PANE_DISCUSSION: "nav_bar_discussions_pane_discussion"
};
