/*
  This module is a JS version of ruby's #to_sentence method, which converts an
  array to a comma-separated sentence where the last element is joined by a
  connector word.
*/
import _ from "lodash";

export default {

  toSentence(items, lastWordConnector="and") {
    const sentence = [];
    const numItems = items.length;
    switch(numItems) {
      case 0:
        return "";
      case 1:
        sentence.push(items[0]);
        break;
      case 2:
        sentence.push(items[0]);
        sentence.push(` ${  lastWordConnector  } `);
        sentence.push(items[1]);
        break;
      default:
        _.each(items.slice(0, -1), (item) => {
          sentence.push(item);
          sentence.push(", ");
        });
        sentence.push(`${lastWordConnector  } `);
        sentence.push(items[numItems - 1]); // last item
        break;
    }
    return <span>{ sentence }</span>;
  },

  sentenceJoin(items, separator=", ") {
    const sentence = [];
    _.each(items.slice(0, -1), (item) => {
      sentence.push(item);
      sentence.push(separator);
    });
    sentence.push(items[items.length - 1]);
    return <span>{ sentence }</span>;
  },
};
