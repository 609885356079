import GrComponentFactory from "../../gr_component_factory";
import UserProfileLink from "../../user/user_profile_link";
import GrPropTypes from "../../shared/gr_prop_types";
import _ from "lodash";

export default GrComponentFactory.createClass({
  displayName: "NativeBookAdHeader",

  propTypes: {
    creative: GrPropTypes.creative().isRequired,
    signedIn: React.PropTypes.bool,
    allowBehavioralTargeting: React.PropTypes.bool,
  },

  // Setting default to true for signed in homepage newsfeed
  getDefaultProps() {
    return {
      signedIn: true,
    };
  },

  renderLink(text, url){
    return <a className="gr-hyperlink gr-hyperlink--bold" href={url}>
      {text}
    </a>;
  },

  renderFriendHeader() {
    const firstFriend = _.head(this.props.creative.topFriends);
    return <span className="gr-newsfeedItem__headerText">
             <UserProfileLink user={firstFriend}/>
             {this.renderHeaderText()} {this.props.creative.friendActionText}
           </span>;
  },

  renderHeaderText() {
    if (this.props.creative.numAdditionalFriends === 1) {
      return <span>
              {" and "}
              <UserProfileLink user={this.props.creative.topFriends[1]}/>
            </span>;
    } else if(this.props.creative.numAdditionalFriends > 1) {
      return (
        <span> and {this.renderLink(`${this.props.creative.numAdditionalFriends  } other friends`,
          `${this.props.creative.book.bookUrl  }#friendReviews`)}</span>
      );
    } else {
      return null;
    }
  },

  renderSimilarBook() {
    return <div className="gr-newsfeedItem__headerText gr-newsfeedItem__headerText--truncateWithEllipsis">
            This is similar
            to {this.renderLink(this.props.creative.similarBook.title, 
              this.props.creative.similarBook.bookUrl)}
          </div>;
  },

  renderGenres() {
    const topGenre = _.head(this.props.creative.genres);
    return <div className="gr-newsfeedItem__headerText">
            This book has been shelved by members
            as {this.renderLink(topGenre.genre, topGenre.url)} 
            { this.props.creative.genres.length > 1 ?
            <span> and {this.renderLink(this.props.creative.genres[1].genre, 
              this.props.creative.genres[1].url) }</span> : null}
          </div>;
  },

  renderDefault() {
    return <div className="gr-newsfeedItem__headerText">
            Based on your reading preferences, you might enjoy
          </div>;
  },

  renderSignedOutText() {
    return <div className="gr-newsfeedItem__headerText">
            Recommended
          </div>;
  },

  renderContextualHeader() {
    return <div className="gr-newsfeedItem__headerText">
      {this.props.creative.headerText}
    </div>;
  },

  render(){
    if (this.props.creative.headerText) {
      return this.renderContextualHeader();
    } 
    else if(!this.props.allowBehavioralTargeting) {
      return this.renderSignedOutText();
    }
    else if (!this.props.signedIn) {
      return this.renderSignedOutText();
    }
    else if (this.props.creative.topFriends) {
      return this.renderFriendHeader();
    }
    else if (this.props.creative.similarBook) {
      return this.renderSimilarBook();
    }
    else if (this.props.creative.genres) {
      return this.renderGenres();
    }
    else {
      return this.renderDefault();
    }
  },
});
