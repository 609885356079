/*
  Modal dialog: halt/block other page content, display new content, and force
  the user to interact with or dismiss that content before doing anything else.

  Display options, controlled by props:

  * Default: "Lightbox" style modal window overlaying page
  * Centered (centered: true): Center modal window within screen (?). May be
                               combined with Responsive variant. If
                               you're trying to center your contents within the
                               modal area, use your content's markup and
                               styling; Modal is not responsible for
                               styling its contents.
  * Drawer (asDrawer: true): Display as "drawer" sliding in from side of screen.
                             Does not make sense combined with Centered or
                             Responsive.
  * Responsive (responsive: true): Same as Default, but display like drawer at
                                   smaller screen sizes. Mutually exclusive with
                                   Drawer mode.
*/
import React from "react";
import GrComponentFactory from "./gr_component_factory";
import Reflux from "reflux";
import classNames from "classnames";
import ModalStore from "../react_stores/modal_store";
import ModalActions from "../react_actions/modal_actions";
import CloseButton from "./close_button";
import keys from "../modules/keys";

import { isFunction } from "lodash";
import { VARIANTS as closeButtonVariants } from "../modules/close_button_values";

export default GrComponentFactory.createClass({
  displayName: "Modal",

  mixins: [Reflux.connect(ModalStore, "modalStore")],

  propTypes: {
    asDrawer: React.PropTypes.bool,
    id: React.PropTypes.string.isRequired,
    centered: React.PropTypes.bool,
    showByDefault: React.PropTypes.bool,

    // This callback is run after the modal is hidden so it currently cannot
    // prevent closure. If you need such functionality, you need to
    // add another specific prop.
    onCloseHandler: React.PropTypes.func,

    // Provides a pair of Confirm/Cancel buttons that'll fire this callback
    // after user clicks Confirm. Both buttons close the Modal on click.
    onConfirmHandler: React.PropTypes.func,
    lockBodyScroll: React.PropTypes.bool,

    responsive: React.PropTypes.bool,
  },

  getCloseButtonVariant() {
    if (this.props.asDrawer) {
      return closeButtonVariants.DARK_BG;
    } else {
      return closeButtonVariants.DEFAULT;
    }
  },

  getDefaultProps() {
    return {
      asDrawer: false,
      centered: false,
      showByDefault: false,
      lockBodyScroll: true,
      responsive: false,
    };
  },

  componentDidMount() {
    if (this.props.showByDefault) {
      ModalActions.openModal(this.props.id);
    }
  },

  componentDidUpdate() {
    this.updateBodyScrollability();
  },

  // prevent body from scrolling when modal is open
  updateBodyScrollability() {
    if (this.props.lockBodyScroll && this.isActive()) {
      document.body.classList.add("modalOpened");
    } else if (!this.state.modalStore.currentActiveModal) {
      document.body.classList.remove("modalOpened");
    }
  },

  hide() {
    ModalActions.closeModal();
    if (this.props.onCloseHandler && isFunction(this.props.onCloseHandler)) {
      this.props.onCloseHandler();
    }
  },

  isActive() {
    return this.state.modalStore.currentActiveModal === this.props.id;
  },

  hideOnOverlayClick(ev) {
    if (ev.target.className.indexOf("modal--overlay") > -1) {
      this.hide();
    }
  },

  hideOnEscape(ev) {
    if (keys.isDefaultCloserKey(ev.keyCode)) {
      this.hide();
    }
  },

  handleConfirm() {
    if (this.props.onConfirmHandler) { this.props.onConfirmHandler(); }
    this.hide();
  },

  renderConfirmButtons() { // Confirm/Cancel
    return(
      <div className="modal__confirmButtonsContainer">
        <button className="gr-button gr-button--large gr-button--dark u-marginRightMedium"
                data-testid="confirm"
                onClick={this.handleConfirm}
                type="button">
          Confirm
        </button>
        <button className="gr-button gr-button--large"
                data-testid="cancel"
                onClick={this.hide}
                type="button">
          Cancel
        </button>
      </div>
    );
  },

  render() {
    const modalClass = classNames(
      "modal",
      "modal--overlay",
      { "modal--show": this.isActive() && !this.props.centered,
        "modal--centered": this.isActive() && this.props.centered,
        "modal--drawer": this.props.asDrawer && !this.props.responsive,
        "modal--responsive": this.props.responsive }
    );
    return(
      <div className={modalClass} ref="modalContent" tabIndex="0"
        onClick={this.hideOnOverlayClick} onKeyDown={this.hideOnEscape}>
        {this.renderContents()}
      </div>
    );
  },

  renderContents() {
    if (this.props.asDrawer) {
      // In "drawer" mode, close button appears in overlay area, not content area
      return (
        <div>
          <div className="modal__close">
            <CloseButton action={this.hide}
                         variant={this.getCloseButtonVariant()} />
          </div>
          <div className={this.withBemModifiers("modal__content")}>
            {this.props.children}
          </div>
        </div>
      );
    } else {
      return (
        <div className={this.withBemModifiers("modal__content")}>
          <div className="modal__close">
            <CloseButton action={this.hide}
                         variant={this.getCloseButtonVariant()} />
          </div>
          {this.props.children}
          {this.props.onConfirmHandler && this.renderConfirmButtons()}
        </div>
      );
    }
  },
});
