import React from "react";
import _ from "lodash";
import GrComponentFactory from "./gr_component_factory";
import GrPropTypes from "./shared/gr_prop_types";
import ClickTrackingWrapper from "./click_tracking_wrapper";
import { TRACKED_CLASSES } from "../modules/ads/click_tracking_helper";
import classNames from "classnames";
import AuthorBadge from "./author/author_badge";

export const VALID_BOOK_SIZES = {
  SMALL: "small",
  MEDIUM: "medium",
  LARGE: "large",
};

export default GrComponentFactory.createClass({
  displayName: "Book",

  propTypes: {
    imageUrl: React.PropTypes.string.isRequired,
    bookUrl: React.PropTypes.string.isRequired,
    bookCoverUrl: React.PropTypes.string, // if book cover should link somewhere besides book page
    title: React.PropTypes.string.isRequired,
    size: React.PropTypes.oneOf(_.values(VALID_BOOK_SIZES)),
    titleSize: React.PropTypes.oneOf(_.values(VALID_BOOK_SIZES)),
    author: GrPropTypes.author(),
    mediaBoxModifier: React.PropTypes.string,
    className: React.PropTypes.string,
    sponsored: React.PropTypes.bool,
    bookImageTrackingData: React.PropTypes.object,
    bookTitleTrackingData: React.PropTypes.object,
    authorLinkTrackingData: React.PropTypes.object,
    label: React.PropTypes.element,
  },
  getDefaultProps() {
    return {
      size: VALID_BOOK_SIZES.MEDIUM,
      bookImageTrackingData: TRACKED_CLASSES.book_image,
      bookTitleTrackingData: TRACKED_CLASSES.book_title,
      authorLinkTrackingData: TRACKED_CLASSES.author_link,
    };
  },
  render () {
    const bookClasses = classNames("gr-mediaBox",
                                 `gr-book--${  this.props.size}`,
                                 this.withBemModifiers("gr-book"),
                                 this.props.className);
    const bookImageClasses = classNames(
      "gr-mediaBox__media",
      this.withBemModifiers("gr-book__image"),
      { "gr-book__image--large": this.props.size === VALID_BOOK_SIZES.LARGE }
    );
    const titleSize = this.props.titleSize == null ? this.props.size : this.props.titleSize;
    const bookTitleLinkClasses = classNames(this.withBemModifiers("gr-book__titleLink"),
                                          { "gr-book__titleLink--large": titleSize === VALID_BOOK_SIZES.LARGE },
                                          "gr-hyperlink gr-hyperlink--naked");
    const bookTitleClasses = classNames(this.withBemModifiers("gr-book__title"));
    const mediaBoxClasses = this.withBemModifiers("gr-mediaBox__desc", this.props.mediaBoxModifier);
    const bookImage = <img alt={this.props.title}
                         className={bookImageClasses}
                         src={this.props.imageUrl} />;
    const bookLink = <a href={this.props.bookUrl} className={bookTitleLinkClasses}>
                    {this.props.title}
                  </a>;
    return (
      <div className={bookClasses}>
        <a href={this.props.bookCoverUrl || this.props.bookUrl}>
          <ClickTrackingWrapper children={bookImage}
            trackingOptions={this.props.bookImageTrackingData}/>
        </a>
        <div className={mediaBoxClasses}>
          {this.props.label}
          <div className={bookTitleClasses}>
            <ClickTrackingWrapper children={bookLink}
              trackingOptions={this.props.bookTitleTrackingData} />
          </div>
          {this.renderAuthorLink()}
          <div className="gr-book__additionalContent">
            {this.props.children}
          </div>
        </div>
      </div>
    );
  },

  renderAuthorLink() {
    const authorSize = this.props.titleSize == null ? this.props.size : this.props.titleSize;
    const bookAuthorClasses = classNames(
      this.withBemModifiers("gr-book__author"),
      { "gr-book__author--large": authorSize === VALID_BOOK_SIZES.LARGE }
    );

    if (this.props.author) {
      const authorLink = <a href={this.props.author.profileUrl}
                          className="gr-book__authorLink gr-hyperlink gr-hyperlink--naked">
                         {this.props.author.name}
                       </a>;

      return (
        <div className={bookAuthorClasses}>
          {"by "}
          <ClickTrackingWrapper children={authorLink}
                                trackingOptions={this.props.authorLinkTrackingData}/>
          <AuthorBadge {...this.props.author} />
        </div>
      );
    } else {
      return <div className={bookAuthorClasses}>
               {"by deleted author"}
             </div>;
    }

  },

});
