/*
Renders a Modal inviting the user to sign up or sign in. Aimed at signed out
visitors, with server-side business logic checking whether or not it should be
displayed, so calls an AJAX endpoint in componentDidMount.
*/
import AmazonLoginButton from "../../login/amazon_login_button";
import classNames from "classnames";
import FacebookLoginButton from "../../login/facebook_login_button";
import GrComponentFactory from "../../gr_component_factory";
import Modal from "../../modal";
import RegisterButton from "../../login/register_button";
import ServiceTermsAndPrivacy from "../../login/service_terms_and_privacy";

import { httpGet, httpPost } from "../../../modules/ajax/ajax_helper";

export default GrComponentFactory.createClass({
  displayName: "LoginInterstitial",

  propTypes: {
    allowAmazonSignIn: React.PropTypes.bool,
    allowFacebookSignIn: React.PropTypes.bool,
    overrideSignedOutPageCount:  React.PropTypes.bool.isRequired,
    path: React.PropTypes.shape({
      termsUrl: React.PropTypes.string.isRequired,
      privacyUrl: React.PropTypes.string.isRequired,
    }).isRequired,
  },

  componentDidMount() {
    httpGet("/sign_in_prompt",
      {
        data: {
          countOverride: this.props.overrideSignedOutPageCount
        }}).done((promptValues, responseStatus) => {
      if (responseStatus === "success") {
        this.setState({
          signInUrl: promptValues.signInUrl,
          signUpUrl: promptValues.signUpUrl,
          thirdPartyRedirectUrl: promptValues.thirdPartyRedirectUrl,
          amazonSignInUrl: promptValues.amazonSignInUrl,
          deviceType: promptValues.deviceType,
          facebookSignInUrl: promptValues.facebookSignInUrl,
          isShown: promptValues.showPrompt === true && promptValues.isDelayed === false,
        });
        if (promptValues.showPrompt === true && promptValues.isDelayed === true) {
          this.timer = setTimeout(() => {
            this.setState({isShown: true})
          }, 20000);
        }
      } else if (responseStatus === "nocontent") {
        // HTTP 204 means "no prompt"
        this.setState({ isShown: false });
      }
    }).fail(() => {
      // If call returns other failure, don't show.
      this.setState({ isShown: false });
    });
  },

  getDefaultProps() {
    return {
      allowAmazonSignIn: false,
      allowFacebookSignIn: false,
    };
  },

  getInitialState() {
    return {isShown: false,};
  },

  isResponsive() {
    // Design decision to show the responsive variant of the pop-up on mobile
    // devices. I think we want to make that the only variant, eventually.
    return this.state.deviceType === "mobile";
  },

  onCloseHandler() {
    httpPost("/track/track_click", {
      data: { feature: "LoginInterstitial:dismiss" },
    });
    if (this.state.deviceType) {
      httpPost("/track/track_click", {
        data: { feature: `LoginInterstitial:${  this.state.deviceType  }:` + ":dismiss" },
      });
    }
  },

  showPopUpBook() {
    return this.state.deviceType === "desktop";
  },

  renderAmazonLoginButton() {
    if (this.state.amazonSignInUrl) {
      return <div className="loginModal__button">
          <AmazonLoginButton enabled={ this.props.allowAmazonSignIn }
              amazonSignInUrl={ this.state.amazonSignInUrl }
              fullWidth={ true }  />
        </div>;
    }
  },

  renderFacebookLoginButton() {
    if (this.state.facebookSignInUrl) {
      return <div className="loginModal__button">
          <FacebookLoginButton enabled={ this.props.allowFacebookSignIn }
              facebookRedirectUrl={ this.state.thirdPartyRedirectUrl }
              facebookSignInUrl={ this.state.facebookSignInUrl }
              fullWidth={ true } />
        </div>;
    }
  },

  renderContent() {
    const containerClasses =
      classNames("loginModal", { "loginModal--responsive": this.isResponsive() });
    const modalContent = (
      <div className={containerClasses}>
        { this.showPopUpBook() ? "" : <div className="loginModal__icon" alt="Goodreads" /> }
        <div className="loginModal__header">
          <h3 className="gr-h3 gr-h3--serif gr-h3--noMargin">
            Discover & read more
          </h3>
        </div>
        { this.showPopUpBook() ? <div className="loginModal__popUpBook" /> : "" }
        <div className="loginModal__body">
          <span className="u-defaultType">
            Sign up to get better recommendations with a free account.
          </span>
          <div className="loginModal__cta">
            { this.renderAmazonLoginButton() }
            <div className="loginModal__button">
              <RegisterButton fullWidth={ true }
                signUpUrl={ this.state.signUpUrl } />
            </div>
            <span className="u-defaultType">
              Already a member?  <a href={ this.state.signInUrl } rel="nofollow">Sign in</a>
            </span>
          </div>
          <ServiceTermsAndPrivacy termsUrl={ this.props.path.termsUrl }
            privacyUrl={ this.props.path.privacyUrl } />
        </div>
      </div>
    );
    return (modalContent);
  },

  render() {
    if (this.state.isShown) {
      const modal = (
        <Modal id="LoginInterstitial"
              centered={ true }
              onCloseHandler={ this.onCloseHandler }
              responsive={this.isResponsive()}
              showByDefault={ true }>
          { this.renderContent() }
        </Modal>
      );
      return (modal);
    } else {
      return null;
    }
  },
});
