// This holds the stores and components that should be accessible site-wide.
// This file should be included in all React bundles.
import GoogleAdsStore from "../react_stores/google_ads_store";
import LoginInterstitial from "../react_components/login/interstitial/login_interstitial";
import StoresInitializer from "../react_components/stores_initializer";
import GetACopyButton from "../react_components/buy_buttons/get_a_copy_button";
import GetACopyInterstitial from "../react_components/buy_buttons/get_a_copy_interstitial";
import GetACopyInterstitialWrapper from "../react_components/buy_buttons/get_a_copy_interstitial_wrapper";
import QualtricsSurvey from "../react_components/qualtrics/qualtrics_survey";
import ReviewCta from "../react_components/book/review_cta";
import UserIcon from "../react_components/user/user_icon";
import UserNotInterestedStore from "../react_stores/user_not_interested_store";
import UserShelvesStore from "../react_stores/user_shelves_store";

export const GlobalStores = {
  GoogleAdsStore,
  UserNotInterestedStore,
  UserShelvesStore,
};

export const GlobalComponents = {
  LoginInterstitial,
  StoresInitializer,
  GetACopyButton,
  GetACopyInterstitial,
  GetACopyInterstitialWrapper,
  QualtricsSurvey,
  ReviewCta,
  UserIcon,
};
