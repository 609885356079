import React from "react";
import _ from "lodash";
import classNames from "classnames";

import DropDownList from "../drop_down_list";
import BookSearchResult from "./book_search_result";
import GrComponentFactory from "../gr_component_factory";

export default GrComponentFactory.createClass({
  displayName: "BookSearchResults",

  propTypes: {
    maxResults: React.PropTypes.number.isRequired,
    query: React.PropTypes.string,
    results: React.PropTypes.array.isRequired,
    shelfName: React.PropTypes.string,
    searchPath: React.PropTypes.string,
    onChooseResult: React.PropTypes.func.isRequired,
    mouseoverAction: React.PropTypes.func.isRequired,
    activeResult: React.PropTypes.shape({
      title: React.PropTypes.string.isRequired,
      bookId: React.PropTypes.string.isRequired,
    }),
    signedIn: React.PropTypes.string,
    deviceType: React.PropTypes.string
  },

  componentDidMount() {
    $j.post("/track/track_click", {feature: "NavSearch:" +
          this.props.deviceType + ":" +
          (this.props.signedIn ? "SignedIn" : "SignedOut") +
          ":overlay_shown"});
  },

  renderLastSearchResultItem(text) {
    const searchResultsLastItemClasses =
      classNames("gr-buttonAsLink",
                 "gr-bookSearchResults__lastItem",
                 this.withBemElement("gr-bookSearchResults", "lastItem"));

    return <button className={searchResultsLastItemClasses}
                   type="submit">
             {text}
           </button>;
  },

  render() {
    const searchResultsClasses =
      classNames(this.withBemModifiers("gr-bookSearchResults"),
                 "gr-box",
                 "gr-box--withShadow");

    return(
      <div className={searchResultsClasses}
           id={`bookSearchResults${this.props.query}${this.props.results.length}`}
           aria-live="polite"
           aria-label="Book search results">
        <DropDownList maxListItems={this.props.maxResults}>
          { this.props.results.length > 0 ?
            _.map(_.slice(this.props.results, 0, this.props.maxResults), (result) => (
                <div className={classNames("gr-bookSearchResults__item",
                                           { "gr-bookSearchResults__item--active": result === this.props.activeResult })}
                     key={result.bookId}
                     onMouseOver={() => { this.props.mouseoverAction(result.bookId); }}>
                  <BookSearchResult book={result}
                                    onChooseResult={this.props.onChooseResult} />
                </div>
            )) :
            this.renderLastSearchResultItem(`Search for "${this.props.query}"…`)
          }
          { this.props.results.length > 0 ?
            this.renderLastSearchResultItem(`See all results for "${this.props.query}"`) :
            null }
        </DropDownList>
      </div>
    );
  },
});
