import Reflux from "reflux";

export default function() {
  return Reflux.createActions([
    // selects a specific result based on mouse movement
    "selectSpecificResult",
    // selects the next active result index based on arrow key input
    "selectNextResult",
    // selects the previous active result index based on arrow key input
    "selectPreviousResult",
    // queries the search controller for search results based on user input
    "search",
    // hides searching spinner and shows the results
    "searchResultsFound",
    // either shows/hides the results based on the input
    "setShowResultsContainer",
  ]);
}
