import React from "react";
import GrComponentFactory from "./gr_component_factory";

export default GrComponentFactory.createClass({
  displayName: "ShareButton",

  propTypes: {
    textLabel: React.PropTypes.string,
  },

  getDefaultProps() {
    return { textLabel: "Share" };
  },

  render() {
    return (
      <button className="gr-shareButton" type="button">
        <span className="gr-shareButton__innerWrapper">
          <span className="gr-shareButton__icon" />
          <span className="gr-shareButton__textLabel">{this.props.textLabel}</span>
        </span>
      </button>
    );
  },
});
