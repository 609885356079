import React from "react";
import { connect } from "react-redux";
import GrComponentFactory from "../gr_component_factory";
import { values } from "lodash";
import { toggleSearch } from "../../react_actions/header/search_actions";
import { acknowledgeEvent } from "../../modules/shared/touch_not_ready_helper";

export const SEARCH_TOGGLE_TYPES = {
  ICON: "icon",
  CANCEL: "Cancel",
};

export const component = GrComponentFactory.createClass({
  displayName: "SearchToggle",

  propTypes: {
    searchIsActive: React.PropTypes.bool.isRequired,
    toggleType: React.PropTypes.oneOf(values(SEARCH_TOGGLE_TYPES)),
    onToggleSearch: React.PropTypes.func.isRequired,
  },

  getDefaultProps() {
    return { searchIsActive: false };
  },

  toggleSearch(event) {
    acknowledgeEvent(event);
    return this.props.onToggleSearch(!this.props.searchIsActive);
  },

  hideSearch() {
    return this.props.onToggleSearch(false);
  },

  render() {
    switch (this.props.toggleType) {
      case SEARCH_TOGGLE_TYPES.ICON:
        return <button className="siteHeader__searchIcon gr-iconButton"
                       onClick={this.toggleSearch}
                       aria-label="Toggle search"
                       type="button"
                       data-ux-click />;
      case SEARCH_TOGGLE_TYPES.CANCEL:
        return (
          <button className="gr-buttonAsLink"
                  onClick={this.hideSearch}
                  aria-label="Hide search"
                  type="button">
            {SEARCH_TOGGLE_TYPES.CANCEL}
          </button>
        );
      default:
        return null;
    }
  },
});

export const mapStateToProps = (state, ownProps) => Object.assign({}, ownProps, state);

export const mapDispatchToProps = (dispatch) => ({
  onToggleSearch: (searchIsActive) => dispatch(toggleSearch(searchIsActive)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(component);
