/*
* Delayed hover behavior
* This is the react mixin version of the HoverIntent within shared_all_formats/
* hover_intent.js
*/

import _ from "lodash";

const defaultHoverDelay = 250;

export default {

  onEnterIntent(fn, delay) {
    if (!delay) { delay = defaultHoverDelay; }

    // Cancel any pending mouseleave action
    try { clearTimeout(this.leaveTimeout); } catch (e) { /* Do nothing */ }

    // Set up a delayed call to the mouseenter callback
    this.hoverTimeout = _.delay(fn, delay);
  },

  onLeaveIntent(fn, delay) {
    if (!delay) { delay = defaultHoverDelay; }

    // Cancel any pending mouseenter action
    try { clearTimeout(this.hoverTimeout); } catch (e) { /* Do nothing */ }

    // Set up a delayed call to the mouseleave callback
    this.leaveTimeout = _.delay(fn, delay);
  },
};
