import GrComponentFactory from "./gr_component_factory";
import classnames from "classnames";

export default GrComponentFactory.createClass({
  displayName: "Spinner",

  propTypes: {
    isSmall: React.PropTypes.bool,
  },

  getDefaultProps() {
    return {
      isSmall: false,
    };
  },

  render() {
    const cx = classnames("spinnerContainer", this.props.className);
    const bemModifier = this.props.isSmall ? "small" : null;
    return (
      <div className={cx}>
        <div className={this.withBemModifiers("spinner", bemModifier)}>
          <div className={this.withBemModifiers("spinner__mask", bemModifier)}>
            <div className={this.withBemModifiers("spinner__maskedCircle", bemModifier)} />
          </div>
        </div>
        <div className="spinnerFallbackText">Loading…</div>
      </div>
    );
  },
});
