// React Component for friend button in FriendFollowModule. Handles both
// friend and unfriend states

import React from "react";
import GrComponentFactory from "../gr_component_factory";
import ModalTrigger from "../modal_trigger";
import UnfriendConfirmationModal from "./confirmation_modals/unfriend_confirmation_modal";
import FriendFollowActions from "../../react_actions/user_relationships/friend_follow_actions";
import { getUnfriendModalId } from "../../modules/modal_id_helper";

export default GrComponentFactory.createClass({
  displayName: "FriendButton",

  propTypes: {
    displayUser: React.PropTypes.shape({
      firstName: React.PropTypes.string.isRequired,
      viewableName: React.PropTypes.string.isRequired,
      userId: React.PropTypes.number.isRequired,
    }).isRequired,
    addFriendUrl: React.PropTypes.string.isRequired,
    isFriend: React.PropTypes.bool.isRequired,
    isPendingFriendRequestFrom: React.PropTypes.bool.isRequired,
    isPendingFriendRequestTo: React.PropTypes.bool.isRequired,
    friendRequestFromId: React.PropTypes.number,
  },

  getInitialState() {
    return {
      isHovering: false,
    };
  },

  handleMouseEnter() {
    this.setState({ isHovering: true });
  },

  handleMouseLeave() {
    this.setState({ isHovering: false });
  },

  handleAcceptFriendRequest() {
    FriendFollowActions.acceptFriendRequest(this.props.displayUser.userId,
                                            this.props.friendRequestFromId);
  },

  // handles 3 states:
  // 1. isPendingFriendRequestFrom: render confirm request button
  // 2. isPendingFriendRequestTo: render "Pending"
  // 3. no friend requests either direction: link to send friend request page
  renderFriendButton() {
    if (this.props.isPendingFriendRequestFrom && this.props.friendRequestFromId) {
      return (
        <button className="friendFollowButton"
                onClick={this.handleAcceptFriendRequest}
                type="button">
          Accept request
        </button>
      );
    } else if (this.props.isPendingFriendRequestTo) {
      return(
        <button className="friendFollowButton"
                type="button"
                disabled>
          Pending
        </button>
      );
    } else {
      return(
        <a className="friendFollowButton"
           href={this.props.addFriendUrl}>
          Add friend
        </a>
      );
    }

  },

  renderUnfriendButton() {
    const buttonText = this.state.isHovering ?
      "Unfriend" :
      <span>
        <div className="u-inlineBlock friendFollowCheckmark" />
        Friends
      </span>;

    return(
      <div className="u-inlineBlock">
        <ModalTrigger id={getUnfriendModalId(this.props.displayUser.userId)}>
          <button className="friendFollowButton"
                  onMouseEnter={this.handleMouseEnter}
                  onMouseLeave={this.handleMouseLeave}
                  type="button">
            {buttonText}
          </button>
        </ModalTrigger>

        <UnfriendConfirmationModal displayUser={this.props.displayUser} />
      </div>
    );
  },

  render() {
    return this.props.isFriend ?
      this.renderUnfriendButton() :
      this.renderFriendButton();
  },
});
