import GrComponentFactory from "./gr_component_factory";
import React from "react";
import classNames from "classnames";
import ClickTrackingWrapper from "./click_tracking_wrapper";
import { TRACKED_CLASSES_PREMIUM_CAMPAIGN } from "../modules/ads/click_tracking_helper";

const LEARN_MORE = "Learn More";

export default GrComponentFactory.createClass({
  displayName: "FeaturedContentGenre",
  propTypes: {
    genre: React.PropTypes.shape({
      imageUrl: React.PropTypes.string.isRequired,
      genreUrl: React.PropTypes.string.isRequired,
      tagline: React.PropTypes.string.isRequired,
    }).isRequired,
    isMobile: React.PropTypes.bool,
    isInline: React.PropTypes.bool,
  },

  defaultProps: {
    isMobile: false,
    isInline: false,
  },

  renderImage() {
    const imageClassName = classNames("featuredContentImageBody__image", {
      featuredContentImageBody__imageMobile: this.props.isMobile,
    });
    const linkClassName = classNames("featuredContentImageBody__imageLink", {
      "featuredContentImageBody__imageLink--inline": this.props.isInline,
    });
    if (this.props.genre.imageUrl) {
      const bodyImage = (<a className={linkClassName} href={this.props.genre.genreUrl}>
                       <img className={imageClassName}
                       src={this.props.genre.imageUrl}
                       alt={`${this.props.genre.tagline}`} />
                       </a>
      );
      return(<ClickTrackingWrapper trackingOptions={TRACKED_CLASSES_PREMIUM_CAMPAIGN.fcm_genre_image} children={bodyImage} />);
    } else {
      return null;
    }
  },
  renderTitle() {
    const titleClass = this.props.isMobile ? classNames("featuredContentImageBody__title",
      "featuredContentImageBody__title--mobile") : "featuredContentImageBody__title";
    const titleLink = (<a href={this.props.genre.genreUrl} className={titleClass}>
                     {this.props.genre.tagline}
                     </a>);
    return <ClickTrackingWrapper trackingOptions={TRACKED_CLASSES_PREMIUM_CAMPAIGN.fcm_genre_title}
                              children={titleLink} />;
  },

  renderCTAButton() {
    const ctaLinkClassNames = classNames("gr-button gr-microsite-link",
              { featuredContentImageBody__button: this.props.isMobile,
                "u-marginTopMedium": !this.props.isInline });
    const ctaLink = <a href={this.props.genre.genreUrl}
      className={ctaLinkClassNames}>
      {LEARN_MORE}
    </a>;
    return <ClickTrackingWrapper trackingOptions={TRACKED_CLASSES_PREMIUM_CAMPAIGN.fcm_genre_button} children={ctaLink} />;
  },

  render() {
    if (this.props.isInline) {
      return(
        <div className="featuredContentImageBody--inline genreFeatureContainer">
          {this.renderImage()}
          <div>
            {this.renderTitle()}
            {this.renderCTAButton()}
          </div>
        </div>
      );
    } else {
      return(
        <div className="featuredContentImageBody genreFeatureContainer">
          {this.renderTitle()}
          {this.renderImage()}
          {this.props.isMobile ? this.renderCTAButton() : null }
        </div>
      );
    }
  },
});
