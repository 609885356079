/*
This mixin provides a reusable hook for stores to update when the newsfeed has
loaded additional updates. Example usage can be seen in any of the stores in
NEWSFEED_DEPENDENT_STORE_KEYS.

To add a new store, add the appropriate key/value in NEWSFEED_DEPENDENT_STORE_KEYS,
make sure the corresponding data is returned in newsfeed/all_stores_update_data
with the same value as the one defined in NEWSFEED_DEPENDENT_STORE_KEYS, and make
sure your store has an updateWith() method.
*/

import NewsfeedActions from "../../react_actions/newsfeed_actions";
import { includes, values } from "lodash";

const factory = (dependentStoreKey) => {
  const mixin = {
    init() {
      if (includes(values(NEWSFEED_DEPENDENT_STORE_KEYS), dependentStoreKey)) {
        this.listenTo(NewsfeedActions.newsfeedItemsLoaded, this.onNewsfeedItemsLoaded);
      }
    },

    onNewsfeedItemsLoaded(dependentStoreData) {
      if (dependentStoreKey && dependentStoreData[dependentStoreKey]) {
        this.updateWith(dependentStoreData[dependentStoreKey]);
        this.trigger(this.getState());
      }
    },
  };
  return mixin;
};

export default factory;
export const NEWSFEED_DEPENDENT_STORE_KEYS = {
  COMMENTS: "comments",
  LIKES: "likes",
  SHELVINGS: "shelvings",
  AUTHOR_FOLLOWS: "authorFollows",
};
