import { GET_A_COPY_ACTIONS } from "../../react_actions/buy_buttons/get_a_copy_actions";

const reducer = (state = {}, action) => {
  switch (action.type) {
    case GET_A_COPY_ACTIONS.LOADED_SUCCESS:
      return Object.assign({}, state, {
        activeGetACopyInterstitial: action.data,
      });
    case GET_A_COPY_ACTIONS.INTERSTITIAL_CLOSED:
      return Object.assign({}, state, {
        activeGetACopyInterstitial: null,
      });
    default:
      return state;
  }
};

export default reducer;
