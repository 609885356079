import Reflux from "reflux";

export default Reflux.createActions({
  addNewComment : {},
  commentEntered: { sync: true },
  deleteComment: {},
  showCommentsForPage: {},
  showSeeMoreLoadingSpinner: {},
  textareaFocused: {},
  textareaFocusRequested: {},
  textareaUnfocused: {},
});
