import _ from "lodash";
import Reflux from "reflux";
import Freezer from "freezer-js";

import WantToReadMenuActions from "../react_actions/want_to_read_menu_actions";
import UserShelvesStore from "./user_shelves_store";

const SHOW_ADD_SHELF_TEXTBOX_KEY = "showAddShelfTextbox";

const defaultState = {};
const state = new Freezer(defaultState);

const getState = function() {
  return state.get();
};

const getDisplayableExclusiveShelves = (shelfNameFilter, userShelvesStoreState) => {
  let displayableExclusiveShelves = [];
  const unselectedExclusiveShelves = userShelvesStoreState.defaultShelves.concat(userShelvesStoreState.customExclusiveShelves || []);

  if (!_.isEmpty(shelfNameFilter)) {
    displayableExclusiveShelves = _.filter(unselectedExclusiveShelves, (shelf) => shelf.name.match(new RegExp(shelfNameFilter, "i")));
  } else {
    displayableExclusiveShelves = unselectedExclusiveShelves;
  }
  return displayableExclusiveShelves;
};

const getDisplayableNonExclusiveShelves = (shelfNameFilter, userShelvesStoreState) => {
  let displayableNonExclusiveShelves = [];
  if (!_.isEmpty(shelfNameFilter)) {
    displayableNonExclusiveShelves = _.filter(userShelvesStoreState.nonExclusiveShelves, (shelf) => shelf.name.match(new RegExp(shelfNameFilter, "i")));
  } else {
    displayableNonExclusiveShelves = userShelvesStoreState.nonExclusiveShelves;
  }
  return displayableNonExclusiveShelves;
};

const getFilteredShelves = (shelfNameFilter) => {
  const userShelvesStoreState = UserShelvesStore.getState();
  const displayableExclusiveShelves = getDisplayableExclusiveShelves(
                                      shelfNameFilter,
                                      userShelvesStoreState);
  const displayableNonExclusiveShelves = getDisplayableNonExclusiveShelves(
                                          shelfNameFilter,
                                          userShelvesStoreState);

  return {
    displayableExclusiveShelves,
    displayableNonExclusiveShelves,
    shelfNameFilter,
  };
};

const updateStateWithFilter = (shelfNameFilter, uniqueWTRButtonId) => {
  getState().set(uniqueWTRButtonId, getFilteredShelves(shelfNameFilter));
};

const updateShelvesWithoutFilterChange = (uniqueWTRButtonId) => {
  const shelfNameFilter = getState()[uniqueWTRButtonId].shelfNameFilter;
  getState().set(uniqueWTRButtonId, getFilteredShelves(shelfNameFilter));
};

const getStateForKey = (key) => {
  if (!_.has(getState(), key)) {
    updateStateWithFilter("", key);
  }
  return getState()[key];
};

const resetTo = (data) => {
  getState().reset(_.merge({}, defaultState, data));
};

export default Reflux.createStore({
  listenables: [WantToReadMenuActions],
  getInitialState(key) {
    if (!_.isUndefined(key)) {
      return getStateForKey(key);
    } else {
      return getState();
    }
  },
  getState,

  initializeWith(data) {
    resetTo(data);
  },

  reset() {
    resetTo(defaultState);
  },

  updateWith(data) {
    _.each(_.keys(data), (key) => {
      getState().set(key, data[key]);
    });
    this.notifyListeners();
  },

  notifyListeners() {
    this.trigger(getState());
  },

  onUpdateShelfNameFilter(shelfNameFilter, uniqueWTRButtonId) {
    updateStateWithFilter(shelfNameFilter, uniqueWTRButtonId);
    getStateForKey(uniqueWTRButtonId).set(SHOW_ADD_SHELF_TEXTBOX_KEY, false);
    this.notifyListeners();
  },

  onOpenAddShelfTextbox(uniqueWTRButtonId) {
    getStateForKey(uniqueWTRButtonId).set(SHOW_ADD_SHELF_TEXTBOX_KEY, true);
    this.notifyListeners();
  },

  onCloseAddShelfTextbox(uniqueWTRButtonId) {
    getStateForKey(uniqueWTRButtonId).set(SHOW_ADD_SHELF_TEXTBOX_KEY, false);
    this.notifyListeners();
  },

  onUpdateShelves() {
    _.each(_.keys(getState()), (uniqueWTRButtonId) => {
      updateShelvesWithoutFilterChange(uniqueWTRButtonId);
    });
    this.notifyListeners();
  },
});
