// See documentation on https://www.goodreads.com/style_guide

import GrComponentFactory from "../gr_component_factory";
import GrPropTypes from "../shared/gr_prop_types";
import GrSpoilerHandler from "../shared/gr_spoiler_handler";
import ClickTrackingWrapper from "../click_tracking_wrapper";
import { TRACKED_CLASSES } from "../../modules/ads/click_tracking_helper";

export default GrComponentFactory.createClass({
  displayName: "TruncatedHtml",

  propTypes: GrPropTypes.truncatedHtml(),

  getDefaultProps() {
    return {
      fullContentText: "More",
      bookDescriptionTargetingData: TRACKED_CLASSES.full_content_link,
    };
  },

  componentDidMount(){
    GrSpoilerHandler.replaceSpoilerHTMLWithReactComponents(this.refs.rootNode);
  },

  render() {
    const fullContentLink = <a className="u-marginLeftTiny fullContentLink"
               href={this.props.fullContentUrl}>
              {this.props.fullContentText}
            </a>;
    return (
      <div ref="rootNode" className={this.props.className}>
        <span dangerouslySetInnerHTML={{ __html: this.props.html }}/>
        {
          this.props.truncated ?
            <ClickTrackingWrapper children={fullContentLink}
            trackingOptions={this.props.bookDescriptionTargetingData}/>
            : null
        }
      </div>
    );
  },
});
