/*
React Component: PreviewBroadcast

React Component for previewing group broadcast message, rendered in group/broadcast.html.erb.
props: previewBroadcastURL - route for group#preview_broadcast
*/

import React from "react";
import GrComponentFactory from "../gr_component_factory";
import ModalTrigger from "../modal_trigger";
import Modal from "../modal";
import Spinner from "../spinner";
import { httpRequest } from "../../modules/ajax/ajax_helper";

export default GrComponentFactory.createClass({
  displayName: "PreviewBroadcast",

  propTypes: {
    previewBroadcastURL: React.PropTypes.string.isRequired,
  },

  getInitialState() {
    return { fetching: false };
  },

  requestPreview(subject, body) {
    httpRequest(this.props.previewBroadcastURL, {
      method: "GET",
      data: {
        message: {
          subject,
          body,
        },
      },
    }).done((data) => {
      this.setState({ modalHTML: { __html: data }, fetching: false });
    }).fail(() => {
      this.setState({ modalHTML: { __html: "<div>There was an error processing your request.</div>" }, fetching: false });
    });
  },

  // gets subject and body, send AJAX request to group#preview_broadcast for GR specific inline html
  // handling. Sets fetching to true to prevent modal from opening before response
  // is received + replaces "preview" link to loading gif.
  handlePreview() {
    const body = document.getElementById("message_body").value;
    const subject = document.getElementById("message_subject").value;
    this.setState({ fetching: true }, () => this.requestPreview(subject, body));
  },

  renderModal() {
    if (this.state.fetching) {
      return null;
    } else {
      return (
        <Modal id="previewBroadcast"
          centered={ true }
          bemModifiers="previewBroadcast">
          <div className="modalMessageBox">
            <h3 className="modalMessageBox__headerText">Message Preview</h3>
            <div dangerouslySetInnerHTML={ this.state.modalHTML }/>
          </div>
        </Modal>
      );
    }
  },

  renderModalTrigger() {
    if (this.state.fetching) {
      return <Spinner isSmall={ true } />;
    } else {
      return (
        <ModalTrigger id="previewBroadcast">
          <button className="gr-buttonAsLink previewBroadcast__linkText"
            onClick={ this.handlePreview }>
            (preview)
          </button>
        </ModalTrigger>
      );
    }
  },

  render() {
    const modal = this.renderModal();
    const modalTrigger = this.renderModalTrigger();
    return (
      <div className="previewBroadcast">
        <div className="previewBroadcast__openPreviewContainer">
          { modalTrigger }
          { modal }
        </div>
      </div>
    );
  },
});
