import GrComponentFactory from "../gr_component_factory";
import ShapeIcon from "../shape_icon";
import { ICON_SIZES } from "../../modules/shape_icon_values";
import _ from "lodash";
import classnames from "classnames";

export default GrComponentFactory.createClass({
  displayName: "SimpleSiteAnnouncement",

  propTypes: {
    imageUrl: React.PropTypes.string.isRequired,
    announcementText: React.PropTypes.string.isRequired,
    targetUrl: React.PropTypes.string,
  },

  getAriaLabel() {
    return `Read more ${this.props.announcementText}`;
  },

  hasTargetUrl() {
    return !_.isEmpty(this.props.targetUrl);
  },

  renderIcon() {
    return <ShapeIcon
      imageUrl={this.props.imageUrl}
      size={ICON_SIZES.MEDIUM}
      imageAlt="Site Announcement"
      border={true}/>;
  },

  renderAnnouncementText() {
    const isLink = this.hasTargetUrl();
    const textClassName = "siteAnnouncement__simpleText";
    if (isLink) {
      return <a className={classnames(textClassName, "gr-hyperlink")}
                aria-label={this.getAriaLabel()}
                href={this.props.targetUrl}>
        {this.props.announcementText}
      </a>;
    } else {
      return <span className={textClassName}>
        {this.props.announcementText}
      </span>;
    }
  },

  renderSecondaryLinkSection() {
    return this.hasTargetUrl() ? <span className="siteAnnouncement__simpleSecondaryLinkSection">
        <a className="gr-button gr-button--quiet"
           aria-label={this.getAriaLabel()}
           href={this.props.targetUrl}>
          Read more
          </a>
      </span> : null;
  },

  render() {
    return (
      <div className="siteAnnouncement
                      siteAnnouncement--simple
                      gr-mediaFlexbox
                      gr-mediaFlexbox--alignItemsCenter">
        <div className="gr-mediaFlexbox__media">
          {this.renderIcon()}
        </div>
        <div className="gr-mediaFlexbox__desc siteAnnouncement__simpleBody">
          <h5 className="gr-h5 gr-h5--noMargin siteAnnouncement__simpleHeader">
            Goodreads news
          </h5>
          {this.renderAnnouncementText()}
        </div>
        {this.renderSecondaryLinkSection()}
      </div>
    );
  },
});
