/*
Renders a set of links for pagination: previous, next, plus some direct page
links.

Comes in a default (links) flavor and a "buttons" flavor. The "buttons" flavor,
activated by passing the `asButtons` prop, was created for GR-42011. The two
variants are somewhat arbitrary, and might need revision over time...maybe one
becomes a "small"/"one line" variant, and the other becomes a "large"/"multiline"
variant. Or, ideally, we converge on a single way to display pagination.
*/
import React from "react";
import _ from "lodash";
import classNames from "classnames";
import GrComponentFactory from "./gr_component_factory";
import PaginationSinglePageLink from "./pagination_single_page_link";
import GrPropTypes from "./shared/gr_prop_types";

export default GrComponentFactory.createClass({
  displayName: "PaginationLinks",

  propTypes: {
    asButtons: React.PropTypes.bool,
    totalSize: GrPropTypes.naturalNumber.isRequired,
    currentPageNumber: React.PropTypes.number.isRequired,
    className: React.PropTypes.string,
    perPage: GrPropTypes.naturalNumber.isRequired,
    changePageAction: React.PropTypes.func.isRequired,
  },

  getDefaultProps() {
    return { asButtons: false };
  },

  maxPageNumber() {
    return Math.ceil(this.props.totalSize / this.props.perPage);
  },

  renderLinkToPage(pageNumber) {
    return (
      <PaginationSinglePageLink pageNumber={pageNumber}
                                currentPageNumber={this.props.currentPageNumber}
                                key={pageNumber}
                                changePageAction={this.props.changePageAction} />
    );
  },

  renderPageLinks() {
    const numPages = this.maxPageNumber();
    if (numPages !== 1) {
      if (numPages <= 6) {
        return (
          <span className="gr-paginationLinks__pageLinksWrapper">
            {_.times(numPages, (n) => this.renderLinkToPage(n + 1))}
          </span>
        );
      } else {
        if (this.props.currentPageNumber <= 3) {
          // Render links for the the first four pages, then ellipsis, then the last page
          return (
            <span className="gr-paginationLinks__pageLinksWrapper">
              {_.times(4, (n) => this.renderLinkToPage(n + 1))}
              …
              {this.renderLinkToPage(this.maxPageNumber())}
            </span>
          );
        } else if (this.props.currentPageNumber >= this.maxPageNumber() - 3) {
          // First page, ellipsis, last four pages
          return (
            <span className="gr-paginationLinks__pageLinksWrapper">
              {this.renderLinkToPage(1)}
              …
              {this.renderLinkToPage(this.maxPageNumber() - 3)}
              {this.renderLinkToPage(this.maxPageNumber() - 2)}
              {this.renderLinkToPage(this.maxPageNumber() - 1)}
              {this.renderLinkToPage(this.maxPageNumber())}
            </span>
          );
        } else {
          // First page, ellipsis, three middle pages starting from current, ellipsis, last
          return (
            <span className="gr-paginationLinks__pageLinksWrapper">
              {this.renderLinkToPage(1)}
              …
              {this.renderLinkToPage(this.props.currentPageNumber)}
              {this.renderLinkToPage(this.props.currentPageNumber + 1)}
              {this.renderLinkToPage(this.props.currentPageNumber + 2)}
              …
              {this.renderLinkToPage(this.maxPageNumber())}
            </span>
          );
        }
      }
    }
  },

  renderPrevLink() {
    if (this.maxPageNumber() === 1) {
      return null;
    } else {
      const cx = classNames({
        "gr-paginationLinks__previousLink": !this.props.asButtons,
        "gr-paginationLinks__previousButton": this.props.asButtons,
        "gr-button": this.props.asButtons,
        "gr-button--large": this.props.asButtons,
      });
      return (
        <button aria-label="Previous page"
                className={cx}
                onClick={this.moveToPrevPage}
                disabled={this.props.currentPageNumber === 1}>
          &larr;
          Previous
        </button>
      );
    }
  },

  renderNextLink() {
    if (this.maxPageNumber() === 1) {
      return null;
    } else {
      const cx = classNames({
        "gr-paginationLinks__nextLink": !this.props.asButtons,
        "gr-paginationLinks__nextButton": this.props.asButtons,
        "gr-button": this.props.asButtons,
        "gr-button--large": this.props.asButtons,
      });
      return (
        <button aria-label="Next page"
                className={cx}
                onClick={this.moveToNextPage}
                disabled={this.props.currentPageNumber === this.maxPageNumber()}>
          Next
          &rarr;
        </button>
      );
    }
  },

  moveToNextPage(event) {
    event.preventDefault();
    this.props.changePageAction(this.props.currentPageNumber + 1);
  },

  moveToPrevPage(event) {
    event.preventDefault();
    this.props.changePageAction(this.props.currentPageNumber - 1);
  },

  render() {
    const cx = classNames(
      "gr-paginationLinks",
      { "gr-paginationLinks--asButtons": this.props.asButtons },
      this.props.className
    );
    // <div>s around next/prev links so that grid item sizing applied to wrapper
    // instead of the link/buttons themselves
    return (
      <div className={cx}>
        <span className="gr-paginationLinks__previousLinkWrapper">
          {this.renderPrevLink()}
        </span>
        {this.renderPageLinks()}
        <span className="gr-paginationLinks__nextLinkWrapper">
          {this.renderNextLink()}
        </span>
      </div>
    );
  },

});
