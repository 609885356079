import React from "react";
import GrComponentFactory from "../gr_component_factory";
import { connectToLazyStore } from "../../react_mixins/gr_reflux";
import LikeActions from "../../react_actions/like_actions";
import LikeStore from "../../react_stores/like_store";
import Spinner from "../spinner";
import classNames from "classnames";

export default GrComponentFactory.createClass({
  displayName: "LikeButton",

  mixins: [connectToLazyStore(LikeStore, "resourceKey")],

  propTypes: {
    resourceKey: React.PropTypes.string.isRequired,
    resourceLabel: React.PropTypes.string.isRequired,
    showAsLink: React.PropTypes.bool,
  },

  getDefaultProps() {
    return { showAsLink: true };
  },

  componentDidUpdate() {
    if (this.likeFocused && !this.state.showLoadingSpinner) {
      this.refs.likeButton.focus();
      this.likeFocused = false;
    }
  },

  buttonClicked() {
    if (this.state.isLiked) {
      LikeActions.unLike(this.props.resourceKey);
    } else {
      LikeActions.like(this.props.resourceKey);
    }
  },

  render() {
    const linkText = this.state.isLiked ? "Unlike" : "Like";
    const buttonClasses = classNames({ "gr-buttonAsLink": this.props.showAsLink },
                                   { "gr-button gr-button--small": !this.props.showAsLink });
    return (
      <span aria-live="polite">
        { this.state.showLoadingSpinner ?
          <Spinner/> :
          <button aria-label={`${linkText} ${this.props.resourceLabel}`}
                  ref="likeButton"
                  className={buttonClasses}
                  onClick={this.buttonClicked}
                  onFocus={() => { this.likeFocused = true; }}>
            {linkText}
          </button>
        }
      </span>
    );
  },
});
