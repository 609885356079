import Modal from "../modal";
import GrComponentFactory from "../gr_component_factory";
import PersonalNav from "./personal_nav";
import ProfileMenu from "./profile_menu";
import UserIcon from "../user/user_icon";

import { ICON_SIZES } from "../../modules/shape_icon_values";

export const personalNavDrawerModalId = "personalNavDrawerModal";

export default GrComponentFactory.createClass({
  displayName: "PersonalNavDrawer",

  render() {
    const userIconUserProp = { viewableName: this.props.name,
                               profileImage: this.props.profileImage,
                               profileUrl: this.props.profileUrl };
    return (
      <Modal asDrawer={true} id={personalNavDrawerModalId}>
        <div className="personalNavDrawer">
          <div className="personalNavDrawer__personalNavContainer">
            <PersonalNav {...this.props}
                         includeProfileMenu={false}
                         withDropdowns={false} />
          </div>
          <div className="personalNavDrawer__profileAndLinksContainer">
            <div className="personalNavDrawer__profileContainer gr-mediaFlexbox gr-mediaFlexbox--alignItemsCenter">
              <div className="gr-mediaFlexbox__media">
                <UserIcon user={userIconUserProp} size={ICON_SIZES.LARGE} />
              </div>
              <div className="gr-mediaFlexbox__desc">
                <a href={this.props.profileUrl}
                   className="gr-hyperlink gr-hyperlink--bold">
                  {this.props.name}
                </a>
                <div className="u-displayBlock">
                  <a href={this.props.profileUrl}
                    className="gr-hyperlink gr-hyperlink--naked">View profile
                  </a>
                </div>
              </div>
            </div>
            <div className="personalNavDrawer__profileMenuContainer">
              <ProfileMenu {...this.props} />
            </div>
          </div>
        </div>
      </Modal>
    );
  },
});
