/*
 WantToReadMenu component is used only within the WantToReadButton component
 right now. It generates a list of shelves, along with a filter box and 'add
 new shelf' button.
*/

import _ from "lodash";
import GrComponentFactory from "../gr_component_factory";
import { connectToLazyStore } from "../../react_mixins/gr_reflux";
import { checkIconClassnames } from "../../modules/default_shelves";
import WantToReadMenuStore from "../../react_stores/want_to_read_menu_store";
import WantToReadMenuFilter from "./want_to_read_menu_filter";
import ShelvingsActions from "../../react_actions/shelvings_actions";
import WantToReadMenuShelfCreator from "./want_to_read_menu_shelf_creator";
import Spinner from "../spinner";
import classNames from "classnames";

const MIN_SHELVES_TO_SHOW_FILTER = 7;

export default GrComponentFactory.createClass({
  displayName: "WantToReadMenu",

  mixins: [connectToLazyStore(WantToReadMenuStore, "uniqueId")],

  propTypes: {
    bookId: React.PropTypes.string.isRequired,
    bookTitle: React.PropTypes.string.isRequired,
    uniqueId: React.PropTypes.string.isRequired,
    userShelves: React.PropTypes.object.isRequired,
    shelvings: React.PropTypes.object.isRequired,
    useModalView: React.PropTypes.bool,
    onNonExclusiveShelvingWithoutExclusive: React.PropTypes.func,
    onExclusiveShelvingChange: React.PropTypes.func,
  },

  defaultPropTypes() {
    return { useModalView: false };
  },

  shelveBook(shelf) {
    ShelvingsActions.shelveBook(this.props.bookId, shelf);
  },

  unshelveBook() {
    if (window.confirm("Removing a book deletes your rating, review, etc. Remove this book from all your shelves?")) {
      ShelvingsActions.unshelveBook(this.props.bookId);
    }
  },

  modifyExclusiveShelving(shelved, shelf) {
    if (this.props.onExclusiveShelvingChange && _.isFunction(this.props.onExclusiveShelvingChange)) {
      this.props.onExclusiveShelvingChange();
    }
    shelved ? this.unshelveBook() : this.shelveBook(shelf);
  },

  toggleNonExclusiveShelving(shelf) {
    if (this.props.shelvings.exclusiveShelfName === null &&
      this.props.onNonExclusiveShelvingWithoutExclusive && _.isFunction(this.props.onNonExclusiveShelvingWithoutExclusive)) {
      this.props.onNonExclusiveShelvingWithoutExclusive();
    }
    ShelvingsActions.toggleNonExclusiveShelving(this.props.bookId, shelf, false, this.props.bookTitle);
  },

  renderFilter() {
    const allShelves = this.props.userShelves.customExclusiveShelves.concat(this.props.userShelves.nonExclusiveShelves);
    const showFilterMenu = allShelves.length >= MIN_SHELVES_TO_SHOW_FILTER;
    if (showFilterMenu) {
      return <WantToReadMenuFilter
                bookId={this.props.bookId}
                uniqueId={this.props.uniqueId}
                shelvings={this.props.shelvings}
                wantToReadMenu={this.state}
                bemModifiers={this.props.bemModifiers} />;
    }
  },

  renderEmptyMessage() {
    const displayableExclusiveShelves = this.state.displayableExclusiveShelves;
    const displayableNonExclusiveShelves = this.state.displayableNonExclusiveShelves;
    const displayedShelvesCount = displayableNonExclusiveShelves.concat(displayableExclusiveShelves).length;
    if (displayedShelvesCount === 0) {
      return <li className="wantToReadMenu__genericItem">No Matching Shelves</li>;
    }
  },

  renderExclusiveShelves() {
    const displayableExclusiveShelves = this.state.displayableExclusiveShelves;
    return _.map(displayableExclusiveShelves, (shelf) => {
      const shelved = shelf.name === this.props.shelvings.exclusiveShelfName;
      const humanShelfName = shelf.displayName;
      const shelvingLabel = shelved ?
        `Unshelve  ${this.props.bookTitle}` :
        `Shelve ${this.props.bookTitle} as ${humanShelfName}`;
      const exclusiveShelfClassnames = classNames(
        this.withBemModifiers("wantToReadMenu__exclusiveShelf"),
        { "wantToReadMenu__exclusiveShelf--shelved": shelved }
      );
      const shelvingStatusIcon = this.props.useModalView ?
        this.renderExclusiveShelfStatusIcon(shelf) :
        null;
      return (<li className="wantToReadMenu__shelfItem" key={humanShelfName}>
        <button className={exclusiveShelfClassnames}
          aria-label={shelvingLabel}
          onClick={() => { this.modifyExclusiveShelving(shelved, shelf); }}>
          {shelvingStatusIcon &&
            <div className="wantToReadMenu__shelfStatusContainer">
              {shelvingStatusIcon}
            </div>
          }
          {humanShelfName}
        </button>
      </li>);
    });
  },

  renderExclusiveShelfStatusIcon(shelf) {
    // (1) if book is shelved and shelving not in progress, render check icon
    // if shelving in progress:
    // (2) if being unshelved (no target), render spinner on shelved exclusive shelf
    // (3) if being shelved (target == shelf.name), render spinner on target shelf
    let shelvingStatusIcon = null;
    const shelved = shelf.name === this.props.shelvings.exclusiveShelfName;
    if (shelved && !this.props.shelvings.isShelvingInProgress) { // (1)
      const checkIconCx = classNames(
        "wantToReadButton__checkIcon",
        "wantToReadButton__checkIcon--modalView",
        checkIconClassnames(this.props.shelvings.exclusiveShelfName)
      );
      shelvingStatusIcon = <span className={checkIconCx} />;
    } else if (this.props.shelvings.isShelvingInProgress &&
               (!this.props.shelvings.targetExclusiveShelfName && shelved || // (2)
                this.props.shelvings.targetExclusiveShelfName === shelf.name)) { // (3)
      shelvingStatusIcon = <Spinner isSmall={true} />;
    }

    return shelvingStatusIcon;
  },

  renderNonExclusiveShelves() {
    const displayableNonExclusiveShelves = this.state.displayableNonExclusiveShelves;
    return _.map(displayableNonExclusiveShelves, (shelf) => {
      let shelfChecked = false;
      if (this.props.shelvings.exclusiveShelfName !== null && _.includes(this.props.shelvings.nonExclusiveShelfNames, shelf.name)) {
        shelfChecked = true;
      }
      const humanShelfName = shelf.displayName;
      const shelvingLabel = `Shelve ${this.props.bookTitle} as ${humanShelfName}`;
      const nonExclusiveShelfClassnames = this.withBemModifiers("wantToReadMenu__nonExclusiveShelf");
      return (<li className="wantToReadMenu__shelfItem" key={humanShelfName}>
        <label className={nonExclusiveShelfClassnames}>
          <input className="wantToReadMenu__shelfCheckbox"
                 checked={shelfChecked}
                 type="checkbox"
                 name={`shelf[${  shelf.name  }]`}
                 aria-label={shelvingLabel}
                 onChange={() => { this.toggleNonExclusiveShelving(shelf); }} />
              {humanShelfName}
        </label>
      </li>);
    });
  },

  renderAddNewShelfButton() {
    const allShelves = this.props.userShelves.customExclusiveShelves.concat(this.props.userShelves.nonExclusiveShelves).concat(this.props.userShelves.defaultShelves);
    const shelfNames = _.map(allShelves, (shelf) => shelf.name);
    const userCanAddShelf = !_.includes(shelfNames, this.state.shelfNameFilter);
    const newShelfClassnames = this.withBemModifiers("wantToReadMenu__newShelfButtonContainer");
    return (
      <li className={newShelfClassnames}>
        <WantToReadMenuShelfCreator
          shelfNameFilter={this.state.shelfNameFilter}
          userCanAddShelf={userCanAddShelf}
          uniqueId={this.props.uniqueId}
          bookId={this.props.bookId}
          bemModifiers={this.props.bemModifiers}
          useModalView={this.props.useModalView}
          bookTitle={this.props.bookTitle}
          {...this.state} />
      </li>
    );
  },

  render() {
    return (
      <div className="wantToReadMenu">
        {this.props.useModalView &&
          <div className="wantToReadMenu__headerContainer">
            {this.renderFilter()}
          </div>
        }
        <div className="wantToReadMenu__scrollableContainer">
          {!this.props.useModalView && this.renderFilter()}
          { this.renderEmptyMessage() }
          { this.renderExclusiveShelves() }
          { this.renderNonExclusiveShelves() }
        </div>
        { this.renderAddNewShelfButton() }
      </div>
    );
  },
});
