import { redirectOnAuthFailure } from "./default_auth_failure_handler";
import { merge, omit } from "lodash";

/**
 * Make an ajax request for the provided url. The returned promise
 * may never be fulfilled and will instead redirect the browser
 * to an authentication page if the URL requires that the user is
 * signed in and they are currently not signed in.
 *
 * If you want the user to be successfully returned to your
 * page, look into setting session[:jumpto] in your controller
 * action. :return_url and other mechanisms do not work generically.
 *
 * TODO-GR-44007: replace jQuery as underlying library
 *
 * @param url - Required. String URL for the desired resource
 * @param options
 *        options.method = "GET" (default), "POST", "DELETE"
 *        all other options are passed along unchanged to jQuery.ajax
 *        see https://api.jquery.com/jQuery.ajax/ for API. For example, pass
 *        {contentType: "json"} to have response parsed as JSON and returned
 *        as an Object. Pass a `data` key with your request arguments.
 * @return jQuery.Promise
 *         will resolve with the same arguments as the done/success callback
 *         or reject with the same arguments as the fail callback
 */
export function httpRequest(url, options={}) {
  options = merge({}, options, { type: options.method });
  options = omit(options, "method");

  const deferred = $j.Deferred();
  const jQueryPromise = $j.ajax(url, options);

  jQueryPromise.done(deferred.resolve);

  jQueryPromise.fail((jqXHR, textStatus, errorThrown) => {
    if (redirectOnAuthFailure(jqXHR)) {
      return; // never fulfill or reject because we just want the redirect to go through
    } else {
      deferred.reject(jqXHR, textStatus, errorThrown);
    }
  });

  return deferred.promise();
}

/**
 * convenience method for httpRequest with the desired
 * method of POST. see doc for httpRequest for complete
 * details.
 *
 * @param url
 * @param options
 * @return {jQuery.Promise}
 */
export function httpPost(url, options={}) {
  return httpRequest(url, merge({}, options, { method: "POST" }));
}

/**
 * convenience method for httpRequest with the desired
 * method of DELETE. see doc for httpRequest for complete
 * details.

 * @param url
 * @param options
 * @return {jQuery.Promise}
 */
export function httpDelete(url, options={}) {
  return httpRequest(url, merge({}, options, { method: "DELETE" }));
}

/**
 * convenience method for httpRequest with the desired
 * method of GET. see doc for httpRequest for complete
 * details.
 *
 * @param url
 * @param options
 * @return {jQuery.Promise}
 */
export function httpGet(url, options={}) {
  return httpRequest(url, merge({}, options, { method: "GET" }));
}

export const DEFAULT_ERROR_MESSAGE = `Something went wrong with your request. Please
  try again later.`;
