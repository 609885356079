// This is a component used exlusively for initializing a store. Since that is
// its sole purpose, it renders nothing
import GrComponentFactory from "./gr_component_factory";

export default GrComponentFactory.createClass({
  displayName: "StoresInitializer",

  render() {
    return null;
  },
});
