import FriendFollowModule from "../react_components/user_relationships/friend_follow_module";
import FriendFollowModuleStore from "../react_stores/user_relationships/friend_follow_module_store";
import BlockUserStore from "../react_stores/user/show/block_user_store";

export const FriendFollowModuleComponents = {
  FriendFollowModule,
};

export const FriendFollowModuleStores = {
  FriendFollowModuleStore,
  BlockUserStore,
};
