import _ from "lodash";
import Reflux from "reflux";
import Freezer from "freezer-js";
import CurrentUserActions from "../react_actions/current_user_actions";

const defaultState = { currentUser: {} };
const state = new Freezer(defaultState);

const getState = function() {
  return state.get();
};

const resetTo = function(data) {
  getState().reset(_.merge({}, defaultState, data));
};

export default Reflux.createStore({
  listenables: [CurrentUserActions],

  initializeWith(data) {
    _.each(_.keys(data), (key) => {
      if (!_.has(defaultState, key)) {
        throw new Error(`Unexpected key passed to initializeWith. Received ${ 
          key  } Expected one of ${  _.keys(defaultState)}`);
      }
    });
    resetTo(data);
  },

  getState,
  getInitialState: getState,

  onUpdatePreference(prefName, newValue) {
    $j.post("/user/update_preferences", {
      user: { [prefName]: newValue },
    });
  },

  reset() {
    resetTo({});
  },
});
