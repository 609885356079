import GrComponentFactory from "./gr_component_factory";
import DotSeparatedElements from "./dot_separated_elements";
import React from "react";
import classNames from "classnames";
import ClickTrackingWrapper from "./click_tracking_wrapper";
import { TRACKED_CLASSES_PREMIUM_CAMPAIGN } from "../modules/ads/click_tracking_helper";

const READ_MORE = "Read More";

export default GrComponentFactory.createClass({
  displayName: "FeaturedContentBlog",
  propTypes: {
    blog: React.PropTypes.shape({
      likeCount: React.PropTypes.number.isRequired,
      commentCount: React.PropTypes.number.isRequired,
      imageUrl: React.PropTypes.string.isRequired,
      blogUrl: React.PropTypes.string.isRequired,
      title: React.PropTypes.string.isRequired,
    }).isRequired,
    isMobile: React.PropTypes.bool,
    isInline: React.PropTypes.bool,
  },

  defaultProps: {
    isMobile: false,
    isInline: false,
  },

  renderImage() {
    const linkClassName = classNames("featuredContentImageBody__imageLink", {
      "featuredContentImageBody__imageLink--inline": this.props.isInline,
    });
    const imageClassName = classNames("featuredContentImageBody__image", {
      featuredContentImageBody__imageMobile: this.props.isMobile,
    });
    if (this.props.blog.imageUrl) {
      const bodyImage = (<a className={linkClassName} href={this.props.blog.blogUrl}>
                       <img className={imageClassName}
                       src={this.props.blog.imageUrl}
                       alt={`${this.props.blog.title}`} />
                       </a>
      );
      return(<ClickTrackingWrapper trackingOptions={TRACKED_CLASSES_PREMIUM_CAMPAIGN.fcm_blog_image} children={bodyImage} />);
    } else {
      return null;
    }
  },

  renderTitle() {
    const titleClass = classNames("featuredContentImageBody__title",
    {
      "featuredContentImageBody__title--mobile": this.props.isMobile,
      "featuredContentImageBody__title--inline": this.props.isInline,
    });
    const titleLink = (<a href={this.props.blog.blogUrl} className={titleClass}>
                     {this.props.blog.title}
                     </a>);
    return <ClickTrackingWrapper trackingOptions={TRACKED_CLASSES_PREMIUM_CAMPAIGN.fcm_blog_title}
                                  children={titleLink} />;
  },

  renderLikesText(text) {
    return <span className="gr-metaText featuredContentImageBody__likes">
             {text} likes
           </span>;
  },

  renderCommentsText(text) {
    return <span className="gr-metaText featuredContentImageBody__comments">
             {text} comments
           </span>;
  },

  renderReadMore() {
    const readMoreClassName = classNames("gr-button", "featuredContentImageBody__button", {
      "featuredContentImageBody__button--mobile": this.props.isMobile,
      "featuredContentImageBody__button--inline": this.props.isInline,
    });
    const readMore = (<a href={this.props.blog.blogUrl}
                    className={readMoreClassName}>
                    {READ_MORE}
                    </a>
    );
    return(<ClickTrackingWrapper trackingOptions={TRACKED_CLASSES_PREMIUM_CAMPAIGN.fcm_blog_button} children={readMore} />);
  },

  renderLikesAndComments() {
    return(<div className="featuredContentImageBody__likesAndComments">
           {this.renderLikesAndCommentsText()}
           </div>
    );
  },

  renderLikesAndCommentsText() {
    const blog = this.props.blog;
    const likesText = this.renderLikesText(blog.likeCount);
    const commentsText = this.renderCommentsText(blog.commentCount);
    if (blog.likeCount && blog.commentCount) {
      return <DotSeparatedElements elements={[likesText, commentsText]} />;
    } else if (blog.likeCount) {
      return likesText;
    } else if (blog.commentCount) {
      return commentsText;
    } else {
      return null;
    }
  },

  render() {
    if (this.props.isInline) {
      return(
        <div className="featuredContentImageBody--inline genreFeatureContainer">
          {this.renderImage()}
          <div>
            {this.renderTitle()}
            {this.renderLikesAndComments()}
            {this.renderReadMore()}
          </div>
        </div>
      );
    } else {
      return(
        <div className="featuredContentImageBody genreFeatureContainer">
          {this.renderTitle()}
          {this.renderImage()}
          {this.renderLikesAndComments()}
          {this.renderReadMore()}
        </div>
      );
    }
  },
});
