import React from "react";
import GrComponentFactory from "../gr_component_factory";

import { trackNavEngageClick } from "../../modules/click_and_view_tracking";

// COMPONENTS
import CommunityMenu from "./community_menu";
import BrowseMenu from "./browse_menu";

export default GrComponentFactory.createClass({
  displayName: "PrimaryNavMenus",

  propTypes: {
    adId: React.PropTypes.string,
    browseUrl: React.PropTypes.string,
    choiceAwardsUrl: React.PropTypes.string,
    communityUrl: React.PropTypes.string,
    discussionsUrl: React.PropTypes.string,
    favoriteGenres: React.PropTypes.array,
    featuredAskAuthorUrl: React.PropTypes.string,
    genres: React.PropTypes.array.isRequired,
    giveawayUrl: React.PropTypes.string,
    exploreUrl: React.PropTypes.string,
    groupsUrl: React.PropTypes.string,
    homeUrl: React.PropTypes.string,
    listUrl: React.PropTypes.string,
    myBooksUrl: React.PropTypes.string,
    newReleasesUrl: React.PropTypes.string,
    peopleUrl: React.PropTypes.string,
    quotesUrl: React.PropTypes.string,
    recommendationsUrl: React.PropTypes.string,
    signedIn: React.PropTypes.bool,
  },

  render() {
    return(
      <ul role="menu" className="siteHeader__menuList">
        <li className="siteHeader__topLevelItem siteHeader__topLevelItem--home"
            onClick={(e) => trackNavEngageClick(e, "Home")}>
          <a href={this.props.homeUrl}
              className="siteHeader__topLevelLink">
            Home
          </a>
        </li>
        <li className="siteHeader__topLevelItem"
            onClick={(e) => trackNavEngageClick(e, "MyBooks")}>
          <a href={this.props.myBooksUrl}
              className="siteHeader__topLevelLink">
            My Books
          </a>
        </li>
        <li className="siteHeader__topLevelItem">
          <BrowseMenu {...this.props} />
        </li>
        <li className="siteHeader__topLevelItem siteHeader__topLevelItem--community">
          <CommunityMenu {...this.props} />
        </li>
      </ul>
    );
  },
});
