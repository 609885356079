import { PropTypes } from "react";
import { trackNavEngageClick } from "../../modules/click_and_view_tracking";

export const DefaultLogo = ({ clickthroughUrl, altText }) => (
  <a href={clickthroughUrl}
     className="siteHeader__logo"
     aria-label={altText}
     title={altText}
     onClick={(e) => trackNavEngageClick(e, "HeaderLogo")} />
);

DefaultLogo.propTypes = {
  clickthroughUrl: PropTypes.string,
  altText: PropTypes.string,
};

export const CustomDoodleLogo = ({ imageUrl, clickthroughUrl, altText }) => (
  <a href={clickthroughUrl}
     className="siteHeader__logo siteHeader__logo--doodle"
     onClick={(e) => trackNavEngageClick(e, "HeaderLogo")}>
    <picture>
      <img src={imageUrl} alt={altText} />
    </picture>
  </a>
);

CustomDoodleLogo.propTypes = {
  ...DefaultLogo.propTypes,
  imageUrl: PropTypes.string,
};

const HeaderLogo = (props) => (
  props.imageUrl
    ? <CustomDoodleLogo {...props} />
    : <DefaultLogo {...props} />
);

export default HeaderLogo;
