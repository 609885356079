/*doc
---
title: Write a review button
category: React
---

This component displays the "Write a review" button and also redirects to the destinationPath
on button click.
In addition, if an actionType metric is provided, the event will be fired off to Clickstream.

Required props:

* `destinationPath`: The destination Url on clicking on the WTR button or rating widget

Optional props:
* `bookId`: Clickstream event metadata
* `actionType`: The Clickstream metric for the WTR button click
*/

import { Component, PropTypes } from "react";
import { httpPost, httpGet } from "../../modules/ajax/ajax_helper";

export const WRITE_A_REVIEW = "Write a review";

export default class WriteAReviewButton extends Component {
  constructor(props) {
    super(props);
  }

  handleButtonClick() {
    if (this.props.actionType && this.props.bookId) {
      let ratePostUrl = "/review/rateable";
      let ratePostData = {
        id: this.props.bookId
      };
      httpGet(ratePostUrl, {
        data: ratePostData,
        error: ( xhr, aja_xOptions, thrownError ) => {
          if (xhr.status === 403 && xhr.getResponseHeader("errorType") === "frozen") {
            window.alert("This book has temporary limitations on submitting ratings and reviews. This may be because we've detected unusual behavior that doesn't follow our review guidelines.");
          } else if (xhr.status !== 403) {
            window.alert("There was an error saving your rating, please try again.");
          }
        },
        success: ( response ) => {
          httpPost("/track/track_clickstream", {
            data: {
              action_type: this.props.actionType,
              book_id: this.props.bookId,
            },
          });
          window.location.assign(this.props.destinationPath);
        },
      });
    }
  }

  render() {
    const classNames = `gr-button u-marginTopBottomXSmall ${this.props.className}`;
    return (
      <button className={classNames} onClick={this.handleButtonClick.bind(this)} >
        {WRITE_A_REVIEW}
      </button>
    );
  }
}

WriteAReviewButton.propTypes = {
  destinationPath: PropTypes.string.isRequired,
};
