// maps which qualtrics survey goes to which page
import { PropTypes } from "react";
import BookShowSurveyModal from "./book_show_survey_modal";
import QualtricsSurveyModal from "./qualtrics_survey_modal";

const SURVEY_MAP = {
  signed_in_book_show: BookShowSurveyModal,
};

const QualtricsSurvey = (props) => {
  const Component = SURVEY_MAP[props.type] || QualtricsSurveyModal;
  return <Component {...props} />;
};

QualtricsSurvey.propTypes = {
  type: PropTypes.oneOf(Object.keys(SURVEY_MAP)),
};

export default QualtricsSurvey;
