import React from "react";
import _ from "lodash";
import GrComponentFactory from "../gr_component_factory";
import Reflux from "reflux";
import Spinner from "../spinner";
import Dropdown from "../dropdown";
import NotificationsStore from "../../react_stores/notifications_store";
import NotificationsActions from "../../react_actions/notifications_actions";
import Notification from "./notification";
import { trackNavEngage, trackNavEngageClick } from "../../modules/click_and_view_tracking";

export default GrComponentFactory.createClass({
  displayName: "Notifications",

  mixins: [Reflux.connect(NotificationsStore, "notificationsStore")],

  propTypes: {
    notificationIconUrl: React.PropTypes.string.isRequired,
    withDropdowns: React.PropTypes.bool,
  },

  getDefaultProps() {
    return { withDropdowns: true };
  },

  getStoreState() {
    return this.state.notificationsStore;
  },

  generateKey(notification) {
    if (notification.actor) {
      return `${notification.actor.profileUrl}${notification.createdAt}`;
    } else if (notification.resource) {
      return `${notification.resource.url}${notification.createdAt}`;
    } else {
      return `${notification.body}${notification.createdAt}`;
    }
  },

  notificationsCountString() {
    const state = this.getStoreState();
    return state.unreadCount + (state.unreadCountMore ? "+" : "");
  },

  dropdownShowCallback() {
    trackNavEngage("NotificationsShow");
    NotificationsActions.requestNotifications();
  },

  renderNotificationList() {
    const state = this.getStoreState();
    const notifications = state.notifications;
    if (state.errorOccurred) {
      return(
        <div className="dropdown__container
                        gr-notifications
                        gr-notifications--sparse">
          <span>An error occurred loading your notifications</span>
        </div>
      );
    } else if (_.isUndefined(notifications)) {
      return (
        <div className="dropdown__container
                        gr-notifications
                        gr-notifications--sparse">
          <Spinner />
        </div>
      );
    } else if (_.isEmpty(notifications)) {
      return (
        <div className="dropdown__container
                        gr-notifications
                        gr-notifications--sparse">
          <span>No notifications</span>
        </div>
      );
    } else {
      return (
        <section className="dropdown__container">
          <a className="u-displayBlock gr-hyperlink gr-hyperlink--noUnderline"
             href="/notifications"
             onClick={(e) => trackNavEngageClick(e, "Notifications_ViewAll")}>
            <div className="siteHeader__dropdownHeading">
              <h3 className="siteHeader__heading siteHeader__dropdownHeader">
                Notifications
              </h3>
              <span>
                View all notifications
              </span>
            </div>
          </a>
          <div className="gr-notifications gr-box gr-box--forceScrollBar"
               onClick={(e) => trackNavEngageClick(e, "Notifications_List")}>
            { _.map(notifications, (notification) => <Notification key={this.generateKey(notification)}
                                   notification={notification} />) }
          </div>
        </section>
      );
    }
  },

  render() {
    const state = this.getStoreState();
    const notificationIcon =
      <span className="headerPersonalNav__icon
                       headerPersonalNav__icon--notifications"
            aria-label="Notifications">
        { state.unreadCount > 0 ?
          <span className="headerPersonalNav__flag">{this.notificationsCountString()}</span>
          : null }
      </span>;

    if(this.props.withDropdowns) {
      return(
        <div>
          <Dropdown trigger={notificationIcon}
                    triggerEventType={Dropdown.TriggerEventTypes.CLICK}
                    fallbackUrl={this.props.notificationIconUrl}
                    tooltipText="Notifications"
                    onShowCallback={() => this.dropdownShowCallback()}
                    bemModifiers="notifications"
                    isInPersonalNav="true">
            { this.renderNotificationList() }
          </Dropdown>
        </div>
      );
    } else {
      return(
        <a href={this.props.notificationIconUrl}
           className="headerPersonalNav"
           onClick={(e) => trackNavEngageClick(e, "NotificationsLink")}>
          {notificationIcon}
        </a>
      );
    }
  },
});
