// Wrapper component of QualtricsSurveyModal. Adds a couple event listeners
// onto the book show page's nonReact WTR button that fire when a user clicks
// WTR via button or dropdown.
// Note that all it does is render the child modal, which has its own rendering
// logic.
// Also note that this is pretty hacky due to interaction between React and
// nonReact components - pls no copy

import { Component, PropTypes } from "react";
import QualtricsSurveyModal from "./qualtrics_survey_modal";

export default class BookShowSurveyModal extends Component {
  constructor(props) {
    super(props);
    this.state = { renderModal: false };

    this.handleWTR = this.handleWTR.bind(this);
  }

  componentDidMount() {
    const button = document.getElementById(this.props.buttonId);
    if (!button) return null;
    // there are two event listeners to add on the WTR button:
    // 1. when the user clicks WTR on the WTR button
    // 2. when the user clicks WTR on the WTR dropdown
    const nodes = button.querySelectorAll(
      "button.wtrExclusiveShelf[value='to-read'], button.wtrToRead[type='submit']"
    );
    [...nodes].forEach((node) => node.addEventListener("click", this.handleWTR));
  }

  handleWTR() {
    // Hack: set a timeout so you can see that WTR click did something.
    if (!this.state.renderModal) {
      setTimeout(() => this.setState({ renderModal: true }), 2500);
    }
  }

  render() {
    return this.state.renderModal ? (
      <QualtricsSurveyModal {...this.props} />
    ) : null;
  }
}

BookShowSurveyModal.propTypes = {
  buttonId: PropTypes.string.isRequired,
};
