import { isFunction } from "lodash";

export default {

  /*
    Reports an error.
    Always logs the error and message to the console.
    It used to send to bugsnag but no longer.

    params
      - error: the error with relevant stack trace.
      - message: friendly title for searching
   */
  notifyException(error, message) {
    if (error) {
      if (window.console && isFunction(window.console.error)) {
        window.console.error(message, error.stack);  //eslint-disable-line no-console
      }
    } else if (window.console && isFunction(window.console.warn)) {
      window.console.warn(`Attempted to notifyException without providing error,
                           message: ${message}`);
    }
  },

  /**
   * Notify a user of an error. Currently just uses the browser's alert dialog
   * but may eventually use a custom, stylized dialog.
   *
   * @param message (required) text to show the user
   */
  notifyUser(message) {
    if (window && isFunction(window.alert)) {
      window.alert(message);
    }
  },
};
