import _ from "lodash";
import Reflux from "reflux";
import Freezer from "freezer-js";
import FavoriteGenreActions from "../react_actions/favorite_genres_actions";
import FavoriteGenresFormActions from "../react_actions/favorite_genres_form_actions";

const state = new Freezer({ selectedGenres: [] });

const getState = function() {
  return state.get();
};

const reset = function(data=[]) {
  getState().selectedGenres.reset(data);
};

const store = Reflux.createStore({
  listenables: FavoriteGenresFormActions,

  getInitialState: getState,

  getState,

  // selectedGenres should be an array of genre names
  // e.g. ['Art', 'Fiction']
  initializeWith(...selectedGenres) {
    reset(selectedGenres);
  },

  onSubmitSelectedGenres() {
    FavoriteGenreActions.changeFavoriteGenres(getState().selectedGenres);
  },

  onSelectFavoriteGenre(selectedGenre) {
    if (_.includes(getState().selectedGenres, selectedGenre)) {
      return;
    }
    getState().selectedGenres.push(selectedGenre);
    this.notifyListeners();
  },

  onDeselectFavoriteGenre(deselectedGenre) {
    getState().selectedGenres.shift(deselectedGenre);
    this.notifyListeners();
  },

  notifyListeners() {
    this.trigger(getState());
  },

  reset() {
    reset();
  },
});

export default store;
