import Reflux from "reflux";
import Freezer from "freezer-js";
import UserAgentCapabilitiesActions from "../react_actions/user_agent_capabilities_actions";

const defaultState = { touch: false };
const state = new Freezer(defaultState);

export default Reflux.createStore({
  listenables: [UserAgentCapabilitiesActions],

  initializeWith(data) {
    state.get().reset(data);
  },

  reset() {
    state.get().reset(defaultState);
  },

  getInitialState() {
    return state.get();
  },

  onUserAgentGainsTouch() {
    state.get().set("touch", true);
    this.trigger(state.get());
  },

  onUserAgentLosesTouch() {
    state.get().set("touch", false);
    this.trigger(state.get());
  },
});
