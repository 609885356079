import GrComponentFactory from "../gr_component_factory";
import { PropTypes } from "react";
import GrPropTypes from "../shared/gr_prop_types";
import ReadingNotesAnnotator from "./reading_notes_annotator";

export default GrComponentFactory.createClass({
  displayName: "ReadingNotesHeader",

  propTypes: {
    user: GrPropTypes.user().isRequired,
    headline: PropTypes.string.isRequired,
    isSignedIn: React.PropTypes.bool.isRequired,
    signInRedirectUrl: React.PropTypes.string.isRequired,
    viewerIsOwner: PropTypes.bool.isRequired,
  },

  renderAnnotator() {
    if (!this.props.viewerIsOwner) {
      return <ReadingNotesAnnotator displayUser={this.props.user}
                                    isSignedIn={this.props.isSignedIn}
                                    showFollowWidget={!this.props.viewerIsOwner}
                                    signInRedirectUrl={this.props.signInRedirectUrl} />;
    }
  },

  render() {
    return <div className="readingNotesHeader">
             <div className="gr-h3 gr-h3--serif readingNotesHeader__headline">
               {this.props.headline}
             </div>
             {this.renderAnnotator()}
           </div>;
  },
});
