/*
  This component renders a line of basic book details/stats.

  The component always includes an average star rating,
  and optionally includes avg rating text, ratings count,
  text reviews count, publication date, and editions count.
*/

import React from "react";
import GrComponentFactory from "./gr_component_factory";
import classNames from "classnames";
import DotSeparatedElements from "./dot_separated_elements";

const ICON_SIZES = {
  SMALL: "small",
  MEDIUM: "medium",
  LARGE: "large",
};
const MAX_RATING = 5;
export default GrComponentFactory.createClass({
  displayName: "CommunityRating",

  statics: {
    ICON_SIZES,
  },
  propTypes: {
    rating: React.PropTypes.number.isRequired,
    size: React.PropTypes.oneOf([ICON_SIZES.SMALL,
                                 ICON_SIZES.MEDIUM,
                                 ICON_SIZES.LARGE]),
    displayAvg: React.PropTypes.bool,
    displayAvgRatingText: React.PropTypes.bool,
    ratingsCount: React.PropTypes.number,
    textReviewsCount: React.PropTypes.number,
    publication: React.PropTypes.shape({
      toBePublished: React.PropTypes.bool,
      date: React.PropTypes.string,
    }),
    editions: React.PropTypes.shape({
      countString: React.PropTypes.string,
      url: React.PropTypes.string,
    }),
    className: React.PropTypes.string,
  },

  getDefaultProps() {
    return {
      displayAvg: false,
      size: ICON_SIZES.SMALL,
    };
  },

  wrapperClasses(){
    return classNames("communityRating__starsWrapper", {
      "communityRating__starsWrapper--small": this.props.size === ICON_SIZES.SMALL,
      "communityRating__starsWrapper--medium": this.props.size === ICON_SIZES.MEDIUM,
      "communityRating__starsWrapper--large": this.props.size === ICON_SIZES.LARGE,
    });
  },

  starClasses() {
    return classNames("communityRating__stars", {
      "communityRating__stars--small": this.props.size === ICON_SIZES.SMALL,
      "communityRating__stars--medium": this.props.size === ICON_SIZES.MEDIUM,
      "communityRating__stars--large": this.props.size === ICON_SIZES.LARGE,
    });
  },

  // Emulate behavior in application_helper.rb, adjusted for SVG sizing
  getRoundedRating(rating) {
    const flooredRating = Math.floor(rating);
    if (rating === flooredRating) {
      return rating;
    }
    if (rating - flooredRating < 0.5) {
      return flooredRating + 0.45;
    } else {
      return flooredRating + 0.6;
    }
  },

  renderRating() {
    const roundedRating = this.getRoundedRating(this.props.rating);
    const starsStyle = {
      width: `${(roundedRating / MAX_RATING) * 100  }%`,
    };
    const ratingStarsLabel = `Rated ${this.props.rating.toFixed(2)} of ${MAX_RATING}`;

    const displayAvgRatingText = this.props.displayAvgRatingText === null ?
      this.props.displayAvg : this.props.displayAvgRatingText;

    return (
      <span>
        <div className={this.wrapperClasses()}>
          <div className={this.starClasses()}
               style={starsStyle}
               aria-label={ratingStarsLabel} />
        </div>
        {
          this.props.displayAvg ?
            <span className="gr-metaText">
            {this.props.rating.toFixed(2)}{ displayAvgRatingText ? " avg. rating" : "" }
          </span> : null
        }
      </span>
    );
  },

  renderRatingsCount() {
    return (
      <span className="gr-metaText u-inlineBlock">
        {this.props.ratingsCount.toLocaleString()} Ratings
      </span>
    );
  },

  renderTextReviewsCount() {
    return (
      <span className="gr-metaText u-inlineBlock">
        {this.props.textReviewsCount.toLocaleString()} Reviews
      </span>
    );
  },

  renderPublicationDate() {
    return (
      <span className="gr-metaText u-inlineBlock">
        {`${this.props.publication.toBePublished ? "expected publication" : "published"} ${this.props.publication.date}`}
      </span>
    );
  },

  renderEditionsCount() {
    return (
      <span className="gr-metaText">
        <a className="gr-hyperlink gr-hyperlink--naked u-inlineBlock"
          href={this.props.editions.url}>
          {this.props.editions.countString}
        </a>
      </span>
    );
  },

  render() {
    const ratingClassName = classNames("communityRating", this.props.className);
    const ratingStarsLabel = `Rated ${this.props.rating.toFixed(2)} of ${MAX_RATING}`;

    const elements = [this.renderRating()];

    if (this.props.ratingsCount) {
      elements.push(this.renderRatingsCount());
    }

    if (this.props.textReviewsCount) {
      elements.push(this.renderTextReviewsCount());
    }

    if (this.props.publication && this.props.publication.date) {
      elements.push(this.renderPublicationDate());
    }

    if (this.props.editions && this.props.editions.countString) {
      elements.push(this.renderEditionsCount());
    }

    return (
      <div className={ratingClassName} aria-label={ratingStarsLabel}>
        <DotSeparatedElements elements={elements} />
      </div>
    );
  },
});
