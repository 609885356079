import GrComponentFactory from "../gr_component_factory";
import { PropTypes } from "react";
import { map } from "lodash";
import AnnotatedBookItem from "./annotated_book_item";

export default GrComponentFactory.createClass({
  displayName: "AnnotatedBooksList",

  propTypes: {
    annotatedBooksCollection: PropTypes.array.isRequired,
  },

  render() {
    return (
      <div className="annotatedBooksList">
        { map(this.props.annotatedBooksCollection, (annotatedBookData) => (
          <AnnotatedBookItem key={annotatedBookData.asin}
                             annotatedBookData={annotatedBookData}/>
        ))}
      </div>
    );
  },
});
