// See documentation on https://www.goodreads.com/style_guide

import React from "react";
import classNames from "classnames";
import GrComponentFactory from "../gr_component_factory";
import GrPropTypes from "../shared/gr_prop_types";
import ClickTrackingWrapper from "../click_tracking_wrapper";
import { TRACKED_CLASSES } from "../../modules/ads/click_tracking_helper";

export default GrComponentFactory.createClass({
  displayName: "UserProfileLink",

  propTypes: {
    user: GrPropTypes.user().isRequired,
    className: React.PropTypes.string,
  },

  userName() {
    return { __html: this.props.user.viewableName };
  },

  render() {
    const className = classNames("gr-hyperlink",
      "gr-hyperlink--bold",
      "gr-user__profileLink",
      this.props.className);
    const profileLink = <a className={className}
      href={this.props.user.profileUrl}
      dangerouslySetInnerHTML={this.userName()} />;
    if (this.props.user.profileUrl && this.props.user.viewableName) {
      return <ClickTrackingWrapper children={profileLink}
        trackingOptions={TRACKED_CLASSES.user_profile}/>;
    } else if (this.props.user.viewableName) {
      return <b>{this.props.user.viewableName}</b>;
    } else {
      return null;
    }
  },
});
