import LikeStore from "../../react_stores/like_store";
import CommentsStore from "../../react_stores/comments_store";

import CommentForm from "../../react_components/comment/comment_form";
import Comments from "../../react_components/comment/comments";
import LikeButton from "../../react_components/like/like_button";
import LikeInformation from "../../react_components/like/like_information";

export const ConversationsComponents = {
  CommentForm,
  Comments,
  LikeButton,
  LikeInformation,
};

export const ConversationsStores = {
  CommentsStore,
  LikeStore,
};
