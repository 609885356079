import GrComponentFactory from "../gr_component_factory";
import { PropTypes } from "react";
import classNames from "classnames";

const AUTHOR_BADGE_SIZES = {
  SMALL: "small",
  LARGE: "large",
};

export default GrComponentFactory.createClass({
  displayName: "AuthorBadge",

  statics: {
    AUTHOR_BADGE_SIZES,
  },

  propTypes: {
    size: React.PropTypes.oneOf([AUTHOR_BADGE_SIZES.SMALL,
                                 AUTHOR_BADGE_SIZES.LARGE]),
    isGoodreadsAuthor: PropTypes.bool.isRequired,
  },

  getDefaultProps() {
    return {
      size: AUTHOR_BADGE_SIZES.SMALL,
    };
  },

  getClasses() {
    return classNames("gr-icon", {
      "gr-icon--authorBadge" : this.props.size === AUTHOR_BADGE_SIZES.SMALL,
      "gr-icon--authorBadgeLarge" : this.props.size === AUTHOR_BADGE_SIZES.LARGE,
    });
  },

  renderBadge() {
    return <span className={this.getClasses()}
                 title="Goodreads Author" />;
  },

  render() {
    return this.props.isGoodreadsAuthor ? this.renderBadge() : null;
  },
});
