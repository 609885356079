import _ from "lodash";
import React from "react";
import GrComponentFactory from "../gr_component_factory";
import PrimaryNavMenus from "./primary_nav_menus";
import Headroom from "react-headroom";
import Reflux from "reflux";
import SearchMobileNav from "../search/search_mobile_nav";
import TabsStore from "../../react_stores/tabs_store";
import { connect } from "react-redux";
import { PRIMARY_NAV_MENU_TAB_GROUP } from "./primary_nav_menu";

export const component =  GrComponentFactory.createClass({
  displayName: "PrimaryNavSeparateLine",

  mixins: [Reflux.connect(TabsStore, "tabs")],

  propTypes: {
    autocompleteUrl: React.PropTypes.string.isRequired,
    searchPath: React.PropTypes.string.isRequired,
    signedIn: React.PropTypes.bool.isRequired,
    mobviousDeviceType: React.PropTypes.string,
    searchIsActive: React.PropTypes.bool,
    adId: React.PropTypes.string,
    browseUrl: React.PropTypes.string,
    choiceAwardsUrl: React.PropTypes.string,
    communityUrl: React.PropTypes.string,
    discussionsUrl: React.PropTypes.string,
    favoriteGenres: React.PropTypes.array,
    featuredAskAuthorUrl: React.PropTypes.string,
    genres: React.PropTypes.array.isRequired,
    giveawayUrl: React.PropTypes.string,
    groupsUrl: React.PropTypes.string,
    homeUrl: React.PropTypes.string,
    exploreUrl: React.PropTypes.string,
    listUrl: React.PropTypes.string,
    myBooksUrl: React.PropTypes.string,
    newReleasesUrl: React.PropTypes.string,
    peopleUrl: React.PropTypes.string,
    quotesUrl: React.PropTypes.string,
    recommendationsUrl: React.PropTypes.string,
  },

  getDefaultProps() {
    return { searchIsActive: false };
  },

  isNavPinned() {
    // Keep the navbar visible if search or a menu is open
    return this.props.searchIsActive || !_.isEmpty(this.state.tabs[PRIMARY_NAV_MENU_TAB_GROUP]);
  },

  renderDefaultSeparateLine() {
    return <nav className="siteHeader__primaryNavSeparateLine gr-box gr-box--withShadow">
             <PrimaryNavMenus {...this.props} />
           </nav>;
  },

  renderSearch() {
    return <SearchMobileNav {...this.props} />;
  },

  render() {
    return (
      <Headroom disable={this.isNavPinned()}>
        {this.props.searchIsActive ? this.renderSearch() : this.renderDefaultSeparateLine()}
      </Headroom>
    );
  },
});

export const mapStateToProps = (state, ownProps) => Object.assign({}, ownProps, state);

export default connect(
  mapStateToProps
)(component);
