import GrComponentFactory from "../gr_component_factory";
import _ from "lodash";
import classnames from "classnames";

export default GrComponentFactory.createClass({
  displayName: "ChoiceSiteAnnouncement",

  propTypes: {
    imageUrl: React.PropTypes.string.isRequired,
    announcementText: React.PropTypes.string.isRequired,
    description: React.PropTypes.string.isRequired,
    targetUrl: React.PropTypes.string,
    totalVotes: React.PropTypes.number.isRequired,
  },

  getAriaLabel() {
    return `Read more ${this.props.announcementText}`;
  },

  hasTargetUrl() {
    return !_.isEmpty(this.props.targetUrl);
  },


  renderAnnouncementTitle() {
    const isLink = this.hasTargetUrl();
    const textClassName = "siteAnnouncement__title";
    if (isLink) {
      return <a className={classnames(textClassName, "gr-hyperlink")}
                aria-label={this.getAriaLabel()}
                href={this.props.targetUrl}>
        {this.props.announcementText}
      </a>;
    } else {
      return <span className={textClassName}>
        {this.props.announcementText}
      </span>;
    }
  },

  render() {
    return (
      <div className="siteAnnouncement gr-mediaFlexbox gr-mediaFlexbox--columnDirection">
        <a className="gr-mediaFlexbox__media siteAnnouncement__masthead"
           href={this.props.targetUrl}>
           <img src={this.props.imageUrl} alt={this.props.imageAlt}/>
        </a>
        <div className="gr-mediaFlexbox__desc siteAnnouncement__body">
          {this.renderAnnouncementTitle()}
          <div className="siteAnnouncement__description">
            {this.props.description}
          </div>
          <div className="siteAnnouncement__choiceVoteCount">
            {this.props.totalVotes.toLocaleString()} votes
          </div>
        </div>


      </div>
    );
  },
});
