import React from "react";
import GrComponentFactory from "../gr_component_factory";
import Reflux from "reflux";
import { connectToLazyStore } from "../../react_mixins/gr_reflux";
import { TRACKED_CLASSES } from "../../modules/ads/click_tracking_helper";
import { checkIconClassnames } from "../../modules/default_shelves";
import classNames from "classnames";

import ShelvingsStore from "../../react_stores/shelvings_store";
import UserShelvesStore from "../../react_stores/user_shelves_store";
import WantToReadMenuStore from "../../react_stores/want_to_read_menu_store";
import WantToReadMenuActions from "../../react_actions/want_to_read_menu_actions";
import UserAgentCapabilitiesStore from "../../react_stores/user_agent_capabilities_store";

import ShelvingsActions from "../../react_actions/shelvings_actions";

import Dropdown from "../dropdown";
import Modal from "../modal";
import ModalTrigger from "../modal_trigger";
import ModalActions from "../../react_actions/modal_actions";
import ModalStore from "../../react_stores/modal_store";

import WantToReadMenu from "../want_to_read/want_to_read_menu";
import UserRating from "../user_rating";

const SAVING_BUTTON_TEXT = "Saving…";
const BOOK_ALSO_MARKED_AS_READ_TEXT = "This book has also been marked as Read";
export const ALWAYS_DISPLAY_STARS_PROP = "ALWAYS_DISPLAY_STARS_PROP";

export default GrComponentFactory.createClass({
  displayName: "WantToReadButton",

  propTypes: {
    bookId: React.PropTypes.string.isRequired,
    bookTitle: React.PropTypes.string.isRequired,
    uniqueId: React.PropTypes.string.isRequired,
    // Defaults to `display: none` for breakpoints under $large-site-width (1220px).
    // Set `showStars` to ALWAYS_DISPLAY_STARS_PROP to always display regardless of breakpoint
    showStars: React.PropTypes.oneOfType([
      React.PropTypes.bool,
      React.PropTypes.string,
    ]),
    showMenu: React.PropTypes.bool,
    origin: React.PropTypes.string,
    trackingOptions: React.PropTypes.object,
    starsTrackingOptions: React.PropTypes.object,
    withModalShelvingMenu: React.PropTypes.bool,
  },
  mixins: [connectToLazyStore(ShelvingsStore, "bookId", { stateKey: "shelvings" }),
           connectToLazyStore(WantToReadMenuStore, "uniqueId", { stateKey: "wantToReadMenu" }),
           Reflux.connect(UserShelvesStore, "userShelves"),
           Reflux.connect(UserAgentCapabilitiesStore, "userAgentCapabilities"),
           Reflux.connect(ModalStore, "modalStore"),
  ],

  getDefaultProps() {
    return {
      showStars: false,
      showMenu: true,
      trackingOptions: TRACKED_CLASSES.want_to_read_button,
      withModalShelvingMenu: false,
    };
  },

  shelveBook() {
    ShelvingsActions.shelveBook(this.props.bookId,
                                UserShelvesStore.defaultShelves().WANT_TO_READ,
                                this.props.origin);
  },

  unshelveBook() {
    if (window.confirm("Removing a book deletes your rating, review, etc. Remove this book from all your shelves?")) {
      ShelvingsActions.unshelveBook(this.state.shelvings.book.bookId);
    }
  },

  /*
    A note about this.restoreFocusOnUpdate:

    This was a bit tricky because we have to a) save which specific button was
    pressed to change a shelving, and b) refocus on the same button after
    multiple instances of the component updated. We needed some way to uniquely
    identify each button even if they have the same state, which ruled out
    adding another state variable to the store. Also, setting state would in
    turn cause the component to re-render, which made problems for us.

    This feels hacky, but it's the best solution we have at the time.
  */
  focusButton(button) {
    if (button && this.restoreFocusOnUpdate && !this.state.isShelvingInProgress) {
      this.forgetFocus();
      button.focus();
    }
  },

  rememberFocus() {
    this.restoreFocusOnUpdate = true;
  },

  forgetFocus() {
    this.restoreFocusOnUpdate = false;
  },

  checkmarkClass() {
    return checkIconClassnames(this.state.shelvings.exclusiveShelfName);
  },

  unshelved() {
    return this.state.shelvings.exclusiveShelfName === null;
  },

  getBookId() {
    return this.unshelved() ? this.props.bookId : this.state.shelvings.book.bookId;
  },

  getBookTitle() {
    return this.unshelved() ? this.props.bookTitle : this.state.shelvings.book.title;
  },

  renderWantToReadLeftUnshelved() {
    const buttonText = this.state.shelvings.isShelvingInProgress ? SAVING_BUTTON_TEXT : "Want to Read";
    const wantToReadLeftClasses = classNames(
      "wantToReadButton__leftUnshelvedButton",
      { "wantToReadButton__leftUnshelvedButton--noOutline":
        this.state.shelvings.isShelvingInProgress }
    );
    const shelvingLabel = `Add ${this.props.bookTitle} to your want to read shelf`;
    return <button aria-label={shelvingLabel}
                    ref={this.focusButton}
                    className={wantToReadLeftClasses}
                    onClick={this.shelveBook}
                    onFocus={this.rememberFocus}
                    onBlur={this.forgetFocus}>
              {buttonText}
            </button>;
  },

  renderWantToReadLeftShelved() {
    const buttonClasses = classNames("wantToReadButton__leftShelvedButton");
    const checkmarkClasses = classNames({ wantToReadButton__checkIcon: !this.state.shelvings.isShelvingInProgress },
                                      this.checkmarkClass());
    const shelvingLabel = `Remove ${this.state.shelvings.book.title} from my shelves`;
    const buttonText = this.state.shelvings.isShelvingInProgress ? SAVING_BUTTON_TEXT :
      this.state.shelvings.exclusiveShelfDisplayName;
    if (this.state.userAgentCapabilities.touch) {
      return <button aria-label={shelvingLabel}
                     onClick={this.unshelveBook}
                     onFocus={this.rememberFocus}
                     onBlur={this.forgetFocus}
                     ref={this.focusButton}
                     className={buttonClasses}>
               <span className={`${checkmarkClasses} u-inlineBlock`} />
               <div className="wantToReadButton__shelvedButtonText">
                 {buttonText}
               </div>
             </button>;
    } else {
      return <span>
               <button aria-label={shelvingLabel}
                       ref={this.focusButton}
                       onClick={this.unshelveBook}
                       onFocus={this.rememberFocus}
                       onBlur={this.forgetFocus}
                       className={`${buttonClasses} ${checkmarkClasses}`} />
               <div className="wantToReadButton__shelvedButtonText">{buttonText}</div>
            </span>;
    }
  },

  renderWantToReadRight() {
    const bookId = this.getBookId();
    const bookTitle = this.getBookTitle();

    const dropdownModifiers = ["wantToReadMenu"];
    if (this.props.withModalShelvingMenu) {
      dropdownModifiers.push("wtrMenuToggleDropdown");
    }

    if (this.props.showMenu && !this.isWTRModalOpen()) {
      return(
        <Dropdown
          trigger={this.renderWantToReadRightButtonContents()}
          triggerEventType={Dropdown.TriggerEventTypes.HOVER}
          bemModifiers={dropdownModifiers}>
          <WantToReadMenu uniqueId={this.props.uniqueId}
                          bookId={bookId}
                          bookTitle={bookTitle}
                          userShelves={this.state.userShelves}
                          shelvings={this.state.shelvings} />
       </Dropdown>
      );
    } else {
      return null;
    }
  },

  getModalId() {
    return `wtrModal${  this.getBookId()}`;
  },

  isWTRModalOpen() {
    return this.state.modalStore.currentActiveModal &&
      this.state.modalStore.currentActiveModal === this.getModalId();
  },

  hideBookAlsoMarkedAsReadMessage() {
    if (this.state.showBookAlsoMarkedAsReadMessage) {
      this.setState({ showBookAlsoMarkedAsReadMessage: false });
    }
  },

  handleWTRMenuNonExclusiveShelvingWithoutExclusive() {
    this.setState({ showBookAlsoMarkedAsReadMessage: true });
  },

  handleModalClose() {
    WantToReadMenuActions.closeAddShelfTextbox(this.props.uniqueId);
    this.hideBookAlsoMarkedAsReadMessage();
  },

  handleDoneClick() {
    ModalActions.closeModal();
    this.hideBookAlsoMarkedAsReadMessage();
  },

  renderModal() {
    const bookId = this.getBookId();
    const bookTitle = this.getBookTitle();
    if (this.props.showMenu && this.props.withModalShelvingMenu) {
      // Class `wtrMenuToggleModal` sets display to none when window is past
      // sm breakpoint, which can lead to the Modal being in active
      // state while not being displayed (say, if user resizes the window).
      // This is no bueno because we stop body scroll when Modal is active,
      // so instead we check and make sure the modal is closed before adding the
      // toggle class.
      const cx = classNames("wtrModalContainer", {
        wtrMenuToggleModal: !this.isWTRModalOpen(),
      });
      return (
        <div className={cx}>
          <ModalTrigger id={this.getModalId()}>
            <button type="button" className="wtrModalContainer__trigger">
              {this.renderWantToReadRightButtonContents()}
            </button>
          </ModalTrigger>

          <Modal id={this.getModalId()}
                 centered={true}
                 onCloseHandler={ this.handleModalClose }>
          <div className="wtrModal">
            <h3 className="gr-h3 gr-h3--serif gr-h3--noMargin u-paddingBottomXSmall">
              {
                /* `bookTitle` is either the value in ShelvingsStore or a prop to
                 * WantToReadMenu, so here we explicitly use the title prop for
                 * easier control of what's being rendered.
                 */
              }
              Shelve {this.props.bookTitle}
            </h3>
            <WantToReadMenu uniqueId={this.props.uniqueId}
                            bookId={bookId}
                            bookTitle={bookTitle}
                            userShelves={this.state.userShelves}
                            shelvings={this.state.shelvings}
                            useModalView={true}
                            bemModifiers="modalView"
                            onNonExclusiveShelvingWithoutExclusive =
                            { this.handleWTRMenuNonExclusiveShelvingWithoutExclusive }
                            // Hide "also been marked as Read" message if user changes exclusive shelving
                            onExclusiveShelvingChange =
                            { this.hideBookAlsoMarkedAsReadMessage }
                            />
          </div>
          {
            /* Done button gets replaced by add/cancel buttons when AddNewShelf
               is enabled */
            !this.state.wantToReadMenu.showAddShelfTextbox &&
            <button type="button"
                    className="gr-button wtrModal__closeButton"
                    aria-label="Close want to read menu"
                    onClick={ this.handleDoneClick }>
                    Done
            </button>
          }
          {
            this.state.showBookAlsoMarkedAsReadMessage &&
            <div className="wtrModal__alsoMarkedAsReadMessage">{BOOK_ALSO_MARKED_AS_READ_TEXT}</div>
          }
          </Modal>
        </div>
      );
    } else {
      return null;
    }
  },

  renderWantToReadRightButtonContents() {
    return (
      <div className="wantToReadButton__right">
        <span className="u-visuallyHidden">Shelving menu</span>
      </div>
    );
  },

  render() {
    let wantToReadLeft = "";
    const wantToReadClassLeft = classNames(
      "wantToReadButton__left",
      { "wantToReadButton__left--standalone": !this.props.showMenu },
      { wantToReadButton__leftUnshelved: this.unshelved() },
      { wantToReadButton__leftShelved: !this.unshelved() }
    );

    if (this.unshelved()) {
      wantToReadLeft = this.renderWantToReadLeftUnshelved();
    } else {
      wantToReadLeft = this.renderWantToReadLeftShelved();
    }
    const starsCx = classNames("wtrStarsContainer", {
      "wtrStarsContainer--alwaysDisplay" : this.props.showStars === ALWAYS_DISPLAY_STARS_PROP,
    });
    return (<div>
              <div className="wantToReadButton">
                <div className={wantToReadClassLeft}>
                  { wantToReadLeft }
                </div>
                {this.renderModal()}
                { this.renderWantToReadRight() }
              </div>
              {this.props.showStars ?
                <div className={starsCx}>
                  <UserRating bookId={this.props.bookId}
                              starsTrackingOptions={this.props.starsTrackingOptions} />
                </div> : null
              }
            </div>);
  },
});
