import Reflux from "reflux";
import Freezer from "freezer-js";
import NotificationsActions from "../react_actions/notifications_actions";

const state = new Freezer({});

const getState = function() {
  return state.get();
};

const store = Reflux.createStore({
  listenables: NotificationsActions,

  getInitialState: getState,

  getState,

  initializeWith(data) {
    state.get().reset(data);
  },

  updateWith (data) {
    this.initializeWith(data);
  },

  onLoadNotifications(notifications) {
    state.get().set("notifications", notifications);
    state.get().set("unreadCount", 0);
    state.get().set("unreadCountMore", false);
    this.notifyListeners();
  },

  onLoadError() {
    state.get().set("errorOccurred", true);
    this.notifyListeners();
  },

  onRequestNotifications() {
    if (!state.get().hasRequestedBefore) {
      $j.ajax({
        url: "/notifications/",
        type: "GET",
        success: (resp) => {
          NotificationsActions.loadNotifications(resp);
        },
        error: () => {
          NotificationsActions.loadError();
        },
      });
    }
    state.get().set("hasRequestedBefore", true);
  },

  notifyListeners() {
    this.trigger(getState());
  },

  reset() {
    state.get().reset({});
  },
});

export default store;
