import GrComponentFactory from "../../gr_component_factory";
import GrPropTypes from "../../shared/gr_prop_types";
import classNames from "classnames";
import ClickTrackingWrapper from "../../click_tracking_wrapper";
import ExpandableHtml from "../../user_content/expandable_html";
import { TRACKED_CLASSES } from "../../../modules/ads/click_tracking_helper";
import React from "react";

export default GrComponentFactory.createClass({
  displayName: "NativeFlexAdBody",

  propTypes: {
    creative: GrPropTypes.creative().isRequired,
    bodyClassNames: React.PropTypes.string,
  },

  renderImage() {
    const bodyImageClasses = classNames("gr-mediaBox__media",
      this.withBemModifiers("flexAdBody__image"));
    const bodyImage = <a target={this.props.creative.externalLink ? "_blank" : "_self"} rel="noopener noreferrer" href={this.props.creative.updateUrl} className="flexAdBody__imageLink">
      <img alt={this.props.creative.title} className={bodyImageClasses} src={this.props.creative.image} />
    </a>;
    return <ClickTrackingWrapper trackingOptions={TRACKED_CLASSES.sponsored_update_body_image} children={bodyImage} />;
  },

  renderExploreButton() {
    const micrositeLink = <a target={this.props.creative.externalLink ? "_blank" : "_self"} rel="noopener noreferrer" href={this.props.creative.callToActionUrl}
      className="gr-button u-marginTopMedium gr-microsite-link">
      {this.props.creative.callToActionText}
    </a>;
    return <ClickTrackingWrapper trackingOptions={TRACKED_CLASSES.cta_link} children={micrositeLink} />;
  },

  render () {
    const bodyClasses = classNames(this.props.bodyClassNames, "gr-mediaBox", "flexAdBody");
    const bodyTitleLinkClasses = classNames("flexAdBody__titleLink", "gr-hyperlink", "gr-hyperlink--naked");
    const titleLink = <a target={this.props.creative.externalLink ? "_blank" : "_self"} rel="noopener noreferrer" href={this.props.creative.updateUrl} className={bodyTitleLinkClasses}>
      {this.props.creative.title}
    </a>;
    return (
      <div className={bodyClasses}>
        {this.renderImage()}
        <div className="gr-mediaBox__desc">
          <div className="flexAdBody__title">
            <ClickTrackingWrapper trackingOptions={TRACKED_CLASSES.sponsored_update_body_title_link} children={titleLink} />
          </div>
          <div className="flexAdBody__description">
            <ExpandableHtml {...this.props.creative.description} />
          </div>
          {this.renderExploreButton()}
        </div>
      </div>
    );
  },
});
