import { PropTypes } from "react";

const AuthorBookImage = (props) => (
  <div>
    <a href={props.newsUrl}>
      <div
        className="editorialCard__image--interview editorialCard__image--interviewAuthor"
        style={{ backgroundImage: `url(${props.authorImageUrl})` }} />
      <div
        className="editorialCard__image--interview editorialCard__image--interviewBook"
        style={{ backgroundImage: `url(${props.bookImageUrl})` }} />
    </a>
  </div>
);

AuthorBookImage.propTypes = {
  authorImageUrl: PropTypes.string.isRequired,
  bookImageUrl: PropTypes.string.isRequired,
  newsUrl: PropTypes.string.isRequired,
};

export default AuthorBookImage;
