import GrComponentFactory from "../gr_component_factory";
import { PropTypes } from "react";
import GrPropTypes from "../shared/gr_prop_types";
import ReadingNotesHeader from "./reading_notes_header";
import AnnotatedBooksPage from "./annotated_books_page";

export default GrComponentFactory.createClass({
  displayName: "AnnotatedBooksLandingPage",

  propTypes: {
    user: GrPropTypes.user().isRequired,
    viewerIsOwner: PropTypes.bool.isRequired,
    canViewProfile: PropTypes.bool.isRequired,
    ownerHasLinkedAmazonAccount: PropTypes.bool.isRequired,
    headline: PropTypes.string.isRequired,
    createLinking: PropTypes.string,
    updateLinking: PropTypes.string,
    learnMore: PropTypes.string,
    contactUs: PropTypes.string,
    isSignedIn: React.PropTypes.bool.isRequired,
    signInRedirectUrl: React.PropTypes.string.isRequired,
  },

  renderView() {
    return (
      <div>
        <ReadingNotesHeader user={this.props.user}
                            headline={this.props.headline}
                            isSignedIn={this.props.isSignedIn}
                            signInRedirectUrl={this.props.signInRedirectUrl}
                            viewerIsOwner={this.props.viewerIsOwner} />

        <AnnotatedBooksPage user={this.props.user}
                            viewerIsOwner={this.props.viewerIsOwner}
                            canViewProfile={this.props.canViewProfile}
                            ownerHasLinkedAmazonAccount={this.props.ownerHasLinkedAmazonAccount}
                            createLinking={this.props.createLinking}
                            updateLinking={this.props.updateLinking}
                            learnMore={this.props.learnMore}
                            contactUs={this.props.contactUs}/>
      </div>
    );
  },

  render() {
    return (
      <div className="gr-annotatedBooksPage">
         {this.renderView()}
      </div>
    );
  },
});
