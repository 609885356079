import _ from "lodash";
import Reflux from "reflux";
import Freezer from "freezer-js";

import ShelvingsActions from "../react_actions/shelvings_actions";
import UserShelvesActions from "../react_actions/user_shelves_actions";
import WantToReadMenuActions from "../react_actions/want_to_read_menu_actions";
import { DEFAULT_SHELVES } from "../modules/default_shelves";

const defaultState = { defaultShelves: [],
                       customExclusiveShelves: [],
                       nonExclusiveShelves: [] };
const state = new Freezer(defaultState);

const getState = function() {
  return state.get();
};

const updateCount = (shelfName, shelfType, countDelta) => {
  const shelf = _.find(getState()[shelfType], { name: shelfName });
  if (shelf) {
    shelf.set("bookCount", shelf.bookCount + countDelta);
  }
};

export default Reflux.createStore({
  listenables: [UserShelvesActions,
                { bookShelved: ShelvingsActions.bookShelved,
                  bookUnshelved: ShelvingsActions.bookUnshelved }],

  getInitialState: state.get,
  getState,

  defaultShelves() {
    const shelves = getState().defaultShelves;
    if (shelves.length === 0) {
      return DEFAULT_SHELVES;
    } else {
      return { READ: _.find(shelves, "name", "read"),
               CURRENTLY_READING: _.find(shelves, "name", "currently-reading"),
               WANT_TO_READ: _.find(shelves, "name", "to-read") };
    }
  },

  initializeWith(shelves) {
    state.get().reset(shelves);
  },

  reset() {
    state.get().reset(defaultState);
  },

  updateWith(shelves) {
    state.get().reset(shelves);
  },

  onAddNonExclusiveShelf(newShelfName) {
    const currentNonExclusiveShelves = _.clone(state.get().nonExclusiveShelves) || [];
    const shelfNames = _.map(currentNonExclusiveShelves, (shelf) => shelf.name);
    if (!_.includes(shelfNames, newShelfName)) {
      currentNonExclusiveShelves.unshift( { name: newShelfName,
                                            url: "",
                                            displayName: newShelfName,
                                            bookCount: 0 } );
      state.get().set("nonExclusiveShelves", currentNonExclusiveShelves);
      WantToReadMenuActions.updateShelves();
      this.trigger(getState());
    }
  },

  onRemoveNonExclusiveShelf(shelfNameToRemove) {
    const currentNonExclusiveShelves = _.clone(state.get().nonExclusiveShelves) || [];
    const shelfNames = _.map(currentNonExclusiveShelves, (shelf) => shelf.name);
    if (_.includes(shelfNames, shelfNameToRemove)) {
      _.remove(currentNonExclusiveShelves, (shelf) => shelf.name === shelfNameToRemove);
      state.get().set("nonExclusiveShelves", currentNonExclusiveShelves);
      WantToReadMenuActions.updateShelves();
      this.trigger(getState());
    }
  },

  onBookShelved(options) {
    const shelves = options.shelves || {};
    _.each(shelves, (shelfNames, shelfType) => {
      _.each(shelfNames, (shelfName) => {
        updateCount(shelfName, shelfType, 1);
      });
    });
    this.trigger(getState());
  },

  onBookUnshelved(options) {
    const shelves = options.shelves || {};
    _.each(shelves, (shelfNames, shelfType) => {
      _.each(shelfNames, (shelfName) => {
        updateCount(shelfName, shelfType, -1);
      });
    });
    this.trigger(getState());
  },
});
