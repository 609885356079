import _ from "lodash";
import GrComponentFactory from "../gr_component_factory";

export default GrComponentFactory.createClass({
  displayName: "GenreList",

  renderMoreGenresLink() {
    return <li role="menuitem" className="genreList__genre">
      <a href="/genres"
        className="genreList__genreLink gr-hyperlink gr-hyperlink--naked">
        More Genres
      </a>
    </li>;
  },

  render() {
    const GENRES_IN_COLUMN = 10;
    // Generate columns of genres as sub-arrays
    const genreArrays = [];
    const genreCopy = this.props.genres.slice(0);
    while(genreArrays.length * GENRES_IN_COLUMN < this.props.genres.length) {
      genreArrays.push(genreCopy.splice(0, GENRES_IN_COLUMN));
    }
    const columnsCount = genreArrays.length;
    return (
      <div className="genreListContainer">
        <div className="siteHeader__heading siteHeader__title">
          Genres
        </div>
        {_.map(genreArrays, (genreList, col) => (<ul className="genreList" key={`genreList${col}`}>
            {_.map(genreList, (genre) => (
                <li role="menuitem" className="genreList__genre" key={genre.name}>
                  <a href={genre.url}
                     className="genreList__genreLink gr-hyperlink gr-hyperlink--naked">
                    {genre.name}
                  </a>
                </li>
            ))}
            {/* If this is the last column, add the more link to the end. */}
            {columnsCount === (col + 1) ? this.renderMoreGenresLink() : null}
          </ul>))}
      </div>
    );
  },
});
