import { Component, PropTypes } from "react";
import DotSeparatedElements from "../dot_separated_elements";
import NewsImage from "./news_image";
import AuthorBookImage from "./author_book_image";
import classnames from "classnames";

class NewsPreview extends Component {

  renderImageSection(item) {
    const { newsUrl, newsImageUrl, authorImageUrl, bookImageUrl } = item;

    if(newsImageUrl) {
      return <NewsImage newsImageUrl={newsImageUrl} newsUrl={newsUrl}/>;
    } else if(authorImageUrl && bookImageUrl) {
      return <AuthorBookImage newsUrl={newsUrl} authorImageUrl={authorImageUrl} bookImageUrl={bookImageUrl}/>;
    }

    return null;
  }

  renderTextSection(item) {
    const {
      likeCount,
      commentCount,
      title,
      excerpt,
      newsUrl,
    } = item;

    const { isMobile, showLikesComments } = this.props;

    const NewsHeader = isMobile ? "h4" : "a";

    const newsHeaderClass = classnames("gr-h4 gr-h4--serif", {
      "newsPreview__newsHeader--mobile": isMobile,
      newsPreview__newsHeader: !isMobile,
    });

    const likesAndCommentsTextClass = classnames({
      newsPreview__likesAndComments__text: !isMobile,
    });

    return (
      <div className="newsPreview__textSection">
        <div>
          <NewsHeader className={newsHeaderClass} href={newsUrl}>{title}</NewsHeader>
        </div>
        <div className="newsPreview__excerpt" dangerouslySetInnerHTML={{ __html: `${excerpt}` }}/>
        <div className="newsPreview__readMore">
          <a href={newsUrl}>Read more...</a>
        </div>
        {
          showLikesComments &&
            <div className="newsPreview__likesAndComments">
              <small className={likesAndCommentsTextClass}>
                <DotSeparatedElements elements={[`${likeCount} likes`, `${commentCount} comments`]} />
              </small>
            </div>
        }
      </div>
    );
  }

  render() {
    const { isMobile, imageOnLeft, isBookPage } = this.props;

    const sectionHeaderClass = classnames({
      brownBackground: !isMobile,
      "newsPreview__sectionHeader--mobile": isMobile,
      "newsPreview__sectionHeader--bookPage": isBookPage && !isMobile,
    });

    const itemClass = classnames("newsPreview__item", {
      "newsPreview__item--mobile": isMobile,
    });

    return (
      <div className="bigBox clearFloats">
        <div className="h2Container gradientHeaderContainer">
            <h2 className={sectionHeaderClass}>{this.props.sectionHeader}</h2>
        </div>
        {
          this.props.newsItems.map((item) => (
            <div key={item.key} className={itemClass}>
              {isMobile || imageOnLeft ? this.renderImageSection(item) : this.renderTextSection(item)}
              {isMobile || imageOnLeft ? this.renderTextSection(item) : this.renderImageSection(item)}
            </div>
          ))
        }
      </div>
    );
  }
}

NewsPreview.defaultProps = {
  likeCount: 0,
  commentCount: 0,
  isMobile: false,
  isBookPage: false,
  showLikesComments: true,
  imageOnLeft: false,
};

NewsPreview.propTypes = {
  sectionHeader: PropTypes.string,
  isMobile: PropTypes.bool,
  isBookPage: PropTypes.bool,
  showLikesComments: PropTypes.bool,
  imageOnLeft: PropTypes.bool,
  newsItems: PropTypes.arrayOf(PropTypes.shape({
    likeCount: PropTypes.number,
    commentCount: PropTypes.number,
    title: PropTypes.string.isRequired,
    excerpt: PropTypes.string.isRequired,
    newsImageUrl: PropTypes.string,
    authorImageUrl: PropTypes.string,
    bookImageUrl: PropTypes.string,
    newsUrl: PropTypes.string.isRequired,
  })),
};

export default NewsPreview;
