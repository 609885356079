import GrComponentFactory from "./gr_component_factory";
import Book from "./book";
import React from "react";
import RelativeTime from "./time/relative_time";
import ExpandableHtml from "./user_content/expandable_html";
import GrPropTypes from "./shared/gr_prop_types";
import ClickTrackingWrapper from "./click_tracking_wrapper";
import { TRACKED_CLASSES_PREMIUM_CAMPAIGN } from "../modules/ads/click_tracking_helper";

export default GrComponentFactory.createClass({
  displayName: "FeaturedContentGiveaway",
  propTypes: {
    giveaway: React.PropTypes.shape({
      book: React.PropTypes.object.isRequired,
      description: GrPropTypes.expandableHtml().isRequired,
      endAt: React.PropTypes.string.isRequired,
      giveawayUrl: React.PropTypes.string.isRequired,
    }).isRequired,
    isMobile: React.PropTypes.bool,
  },

  defaultProps: {
    isMobile: false,
  },

  render() {
    const giveaway = this.props.giveaway;
    return(
      <div className="featuredContentGiveaway">
        <Book {...giveaway.book} bookImageTrackingData={TRACKED_CLASSES_PREMIUM_CAMPAIGN.fcm_giveaway_image}
                                 bookTitleTrackingData={TRACKED_CLASSES_PREMIUM_CAMPAIGN.fcm_giveaway_title}
                                 authorLinkTrackingData={TRACKED_CLASSES_PREMIUM_CAMPAIGN.fcm_giveaway_author}
                                 bemModifiers={this.props.isMobile ? "mobile" : ""}>

          <ExpandableHtml {...giveaway.description} className="featuredContentGiveaway__description" />

          <div className="featuredContentGiveaway__expiration gr-metaText">
            Ends <RelativeTime time={giveaway.endAt} longFormat={true} />
          </div>

          <ClickTrackingWrapper trackingOptions={TRACKED_CLASSES_PREMIUM_CAMPAIGN.fcm_giveaway_button}>
            <a href={giveaway.giveawayUrl} className="gr-button">
              Learn more
            </a>
          </ClickTrackingWrapper>
        </Book>
      </div>
    );
  },
});
