import React from "react";
import _ from "lodash";
const dateValidator = function(props, propName, componentName) {
  const value = props[propName];
  const coercedValue = _.isString(value) ? new Date(value) : value;
  if (!_.isDate(coercedValue) || _.isNaN(coercedValue.getTime())) {
    return new Error(
      `Property '${propName}' must be a Date. ${componentName} was supplied: '${value}'.`
    );
  }
};

const naturalNumberValidator = function(props, propName, componentName) {
  const value = props[propName];
  if (value <= 0) {
    return new Error(
      `Property '${propName}' must be > 0. ${componentName} was supplied: '${value}'.`
    );
  }
};

// adapted from ReactPropTypes.js since it's not exported publicly
function createChainableTypeChecker(validate) {
  function checkType(isRequired, props, propName, componentName) {
    /* istanbul ignore next */
    if (!componentName) {
      componentName = "<<anonymous>>";
    }
    if (_.isNull(props[propName]) || _.isUndefined(props[propName])) {
      if (isRequired) {
        return new Error(
          `Required property \`${propName}\` was not specified in ` +
            `\`${componentName}\`.`
        );
      }
      return null;
    } else {
      return validate(props, propName, componentName);
    }
  }

  const chainedCheckType = checkType.bind(null, false);
  chainedCheckType.isRequired = checkType.bind(null, true);

  return chainedCheckType;
}

const merge = (oldValue, newValue) => _.merge({}, oldValue, newValue);

const user = (props = {}) => {
  const defaultProps = {
    viewableName: React.PropTypes.string.isRequired,
    profileImage: React.PropTypes.string.isRequired,
    profileUrl: React.PropTypes.string,
    informalName: React.PropTypes.string,
  };

  return React.PropTypes.shape(merge(defaultProps, props));
};

const book = (props = {}) => {
  const defaultProps = {
    bookId: React.PropTypes.string,
    workId: React.PropTypes.string,
    bookUrl: React.PropTypes.string,
    authorName: React.PropTypes.string,
    title: React.PropTypes.string,
    imageUrl: React.PropTypes.string,
    numPages: React.PropTypes.number,
  };

  return React.PropTypes.shape(merge(defaultProps, props));
};

const bookDescription = (props = {}) => {
  const defaultProps = truncatedHtml();

  return React.PropTypes.shape(merge(defaultProps, props));
};

const author = () =>
  React.PropTypes.shape({
    id: React.PropTypes.number.isRequired,
    name: React.PropTypes.string.isRequired,
    isGoodreadsAuthor: React.PropTypes.bool,
    profileImage: React.PropTypes.string,
    profileUrl: React.PropTypes.string.isRequired,
  });

const expandableHtml = () => ({
  html: React.PropTypes.string.isRequired,
  truncatedHtml: React.PropTypes.string,
});

const truncatedHtml = () => ({
  html: React.PropTypes.string,
  fullContentUrl: React.PropTypes.string,
  fullContentText: React.PropTypes.string,
  truncated: React.PropTypes.bool.isRequired,
  className: React.PropTypes.string,
  bookDescriptionTargetingData: React.PropTypes.object,
});

const friendNewsfeedResource = () =>
  React.PropTypes.shape({
    user: user().isRequired,
    friendUser: user().isRequired,
    isMyFriend: React.PropTypes.bool.isRequired,
    addFriendUrl: React.PropTypes.string,
    compareBooksUrl: React.PropTypes.string,
    ratingDetailsText: React.PropTypes.string,
    currentlyReadingBook: book(),
  });

const userFollowingNewsfeedResource = () =>
  React.PropTypes.shape({
    user: user().isRequired,
    followingUser: user().isRequired,
    ratingDetailsText: React.PropTypes.string,
    currentlyReadingBook: book(),
  });

const commentSubject = () =>
  React.PropTypes.shape({
    type: React.PropTypes.string,
    id: React.PropTypes.number,
    uri: React.PropTypes.string,
  });

const comment = () =>
  React.PropTypes.shape({
    canComment: React.PropTypes.bool.isRequired,
    commentable: React.PropTypes.bool.isRequired,
    subjectKey: React.PropTypes.string.isRequired,
    subject: commentSubject(),
    subjectUrl: React.PropTypes.string,
  });

const like = () =>
  React.PropTypes.shape({
    key: React.PropTypes.string.isRequired,
    label: React.PropTypes.string.isRequired,
    isLikeable: React.PropTypes.bool.isRequired,
  });

const authorBlogPostNewsfeedResource = () =>
  React.PropTypes.shape({
    author: author().isRequred,
    title: React.PropTypes.string,
    url: React.PropTypes.string,
  });

const blogNewsfeedResource = () =>
  React.PropTypes.shape({
    title: React.PropTypes.string,
    body: React.PropTypes.object.isRequired,
    url: React.PropTypes.string,
    imageUrl: React.PropTypes.string,
    typeDisplayName: React.PropTypes.string,
  });

const interviewNewsfeedResource = () =>
  React.PropTypes.shape({
    title: React.PropTypes.string,
    body: React.PropTypes.object,
    url: React.PropTypes.string,
    imageUrl: React.PropTypes.string,
    authorImageUrl: React.PropTypes.string,
    author: React.PropTypes.string,
    bookImageUrl: React.PropTypes.string,
    bookTitle: React.PropTypes.string,
    typeDisplayName: React.PropTypes.string,
  });

const authorFollowNewsfeedResource = () =>
  React.PropTypes.shape({
    user: user().isRequired,
    author: author().isRequred,
    userAuthorFollowingsUrl: React.PropTypes.string,
  });

const authorQaSettingNewsfeedResource = () =>
  React.PropTypes.shape({
    author: author().isRequired,
    message: React.PropTypes.string,
    url: React.PropTypes.string.isRequired,
  });

const quote = () =>
  React.PropTypes.shape({
    author: author().isRequired,
    body: React.PropTypes.object.isRequired,
    url: React.PropTypes.string.isRequired,
    quotesAuthorUrl: React.PropTypes.string.isRequired,
  });

const group = () =>
  React.PropTypes.shape({
    allUnreadPostsUrl: React.PropTypes.string.isRequired,
    description: React.PropTypes.string.isRequired,
    iconUrl: React.PropTypes.string.isRequired,
    id: React.PropTypes.number.isRequired,
    lastActiveAt: createChainableTypeChecker(dateValidator),
    membersCount: React.PropTypes.number,
    name: React.PropTypes.string.isRequired,
    url: React.PropTypes.string.isRequired,
  });

const topic = () =>
  React.PropTypes.shape({
    id: React.PropTypes.number.isRequired,
    title: React.PropTypes.string.isRequired,
    url: React.PropTypes.string.isRequired,
    totalPosts: React.PropTypes.string.isRequired,
    newPosts: React.PropTypes.string,
    newCommentsUrl: React.PropTypes.string,
    updatedAt: createChainableTypeChecker(dateValidator),
    folder: React.PropTypes.shape({
      name: React.PropTypes.string,
      url: React.PropTypes.string,
    }),
  });

const defaultPollVoteProps = {
  user: user().isRequired,
  pollName: React.PropTypes.string.isRequired,
  answer: React.PropTypes.object.isRequired,
  url: React.PropTypes.string.isRequired,
  imageUrl: React.PropTypes.string.isRequired,
  isGroupPoll: React.PropTypes.bool.isRequired,
  isChoicePoll: React.PropTypes.bool.isRequired,
  groupName: React.PropTypes.string,
  groupUrl: React.PropTypes.string,
};

const pollVoteNewsfeedResource = () =>
  React.PropTypes.shape(defaultPollVoteProps);

const choicePollVoteNewsfeedResource = () =>
  React.PropTypes.shape(
    merge(defaultPollVoteProps, {
      choiceYear: React.PropTypes.number.isRequired,
      choiceUrl: React.PropTypes.string.isRequired,
      badgeUrl: React.PropTypes.string.isRequired,
      votedBook: book().isRequired,
    })
  );

const communityQuestion = () => ({
  category: React.PropTypes.number.isRequired,
  questionText: React.PropTypes.string.isRequired,
  isSpoiler: React.PropTypes.bool.isRequired,
  questionUrl: React.PropTypes.string.isRequired,
  user: user().isRequired,
  authorQuestionsUrl: React.PropTypes.string,
  book: book(),
});

const communityAnswer = () => ({
  user: user().isRequired,
  answerText: React.PropTypes.shape(truncatedHtml()),
  isSpoiler: React.PropTypes.bool.isRequired,
  communityQuestion: React.PropTypes.shape(communityQuestion()).isRequired,
});

const creative = () =>
  React.PropTypes.shape({
    book: book(),
    customText: React.PropTypes.object,
    helpLink: React.PropTypes.string,
    defaultIcon: React.PropTypes.string,
    numAdditionalFriends: React.PropTypes.number,
    friendActionText: React.PropTypes.string,
    firstFriend: user(),
    customTextReview: React.PropTypes.object,
    similarBook: book(),
    similarBookShelf: React.PropTypes.string,
    similarBookShelfUrl: React.PropTypes.string,
    genres: React.PropTypes.array,
  });

const notification = (props = {}) => {
  const defaultProps = {
    actor: React.PropTypes.shape({
      viewableName: React.PropTypes.string,
      profileImage: React.PropTypes.string,
      profileUrl: React.PropTypes.string,
    }),
    actors: React.PropTypes.array,
    createdAt: React.PropTypes.string,
    body: React.PropTypes.string,
    resource: React.PropTypes.shape({
      text: React.PropTypes.string,
      url: React.PropTypes.string,
    }),
    viewed: React.PropTypes.boolean,
    moreActors: React.PropTypes.boolean,
  };

  return React.PropTypes.shape(merge(defaultProps, props));
};

const searchResult = (searchProp) =>
  React.PropTypes.shape({
    destination: React.PropTypes.string.isRequired,
    type: React.PropTypes.string.isRequired,
    object: searchProp,
  });

const authorSearchResult = () =>
  searchResult(
    React.PropTypes.shape({
      id: React.PropTypes.string.isRequired,
      name: React.PropTypes.string.isRequired,
      isGoodreadsAuthor: React.PropTypes.bool,
      profileImage: React.PropTypes.string,
    })
  );

const bookSearchResult = () =>
  searchResult(
    React.PropTypes.shape({
      id: React.PropTypes.string.isRequired,
      authorName: React.PropTypes.string.isRequired,
      isGoodreadsAuthor: React.PropTypes.bool,
      title: React.PropTypes.string.isRequired,
      imageUrl: React.PropTypes.string,
    })
  );

const userSearchResult = () =>
  searchResult(
    React.PropTypes.shape({
      id: React.PropTypes.string.isRequired,
      name: React.PropTypes.string.isRequired,
      profileImage: React.PropTypes.string,
    })
  );

const readingNotesAnnotatedBookItem = () =>
  React.PropTypes.shape({
    asin: React.PropTypes.string.isRequired,
    imageUrl: React.PropTypes.string.isRequired,
    title: React.PropTypes.string.isRequired,
    authorName: React.PropTypes.string.isRequired,
    sharedCount: React.PropTypes.number.isRequired,
    highlightCount: React.PropTypes.number,
    noteCount: React.PropTypes.number,
    readingNotesUrl: React.PropTypes.string.isRequired,
    knhCta: React.PropTypes.string.isRequired,
    noSharedAnnotations: React.PropTypes.bool.isRequired,
  });

const readingChallenge = () =>
  React.PropTypes.shape({
    title: React.PropTypes.string.isRequired,
    url: React.PropTypes.string.isRequired,
    id: React.PropTypes.number.isRequired,
    year: React.PropTypes.number.isRequired,
    type: React.PropTypes.string.isRequired,
    maxGoal: React.PropTypes.number.isRequired,
    isFinished: React.PropTypes.bool,
    primaryColor: React.PropTypes.string.isRequired,
    secondaryColor: React.PropTypes.string.isRequired,
    images: React.PropTypes.shape({
      badge: React.PropTypes.string.isRequired,
      completed: React.PropTypes.string.isRequired,
    }),
  });

const shareDialog = () => ({
  signedIn: React.PropTypes.bool,
  signInUrl: React.PropTypes.string,

  social: React.PropTypes.shape({
    inModal: React.PropTypes.shape({
      facebook: React.PropTypes.bool.isRequired,
      twitter: React.PropTypes.bool.isRequired,
      pinterest: React.PropTypes.bool.isRequired,
      copyLink: React.PropTypes.bool.isRequired,
    }).isRequired,

    outsideModal: React.PropTypes.shape({
      facebook: React.PropTypes.bool.isRequired,
      twitter: React.PropTypes.bool.isRequired,
      pinterest: React.PropTypes.bool.isRequired,
      copyLink: React.PropTypes.bool.isRequired,
    }).isRequired,

    fbOptions: React.PropTypes.object,
    twitterOptions: React.PropTypes.object,
    pinterestOptions: React.PropTypes.object,
  }).isRequired,

  sharingData: React.PropTypes.shape({
    resourceTypeFriendlyText: React.PropTypes.string.isRequired,
    resourceId: React.PropTypes.number.isRequired,
    sharerUserId: React.PropTypes.number,
  }).isRequired,

  includePreview: React.PropTypes.bool,
  previewData: React.PropTypes.object,
});

const giveawayLogEntry = () =>
  React.PropTypes.shape({
    giveaway_uri: React.PropTypes.string,
    created_at: React.PropTypes.string,
    user_uri: React.PropTypes.string,
    start_at: React.PropTypes.string,
    end_at: React.PropTypes.string,
    release_at: React.PropTypes.string,
    book_uri: React.PropTypes.string,
    country_codes: React.PropTypes.string,
    book_count: React.PropTypes.string,
    adults_only_flag: React.PropTypes.string,
    description: React.PropTypes.string,
    primary_genre: React.PropTypes.string,
    secondary_genre: React.PropTypes.string,
    contact_info: React.PropTypes.string,
    nonwinner_message: React.PropTypes.string,
  });

const reviewDraftItem = () => ({
  reviewDraftUri: React.PropTypes.string.isRequired,
  book: book().isRequired,
  reviewEditPath: React.PropTypes.string.isRequired,
  reviewText: React.PropTypes.shape(expandableHtml()).isRequired,
});

export default {
  user,
  author,
  blogNewsfeedResource,
  interviewNewsfeedResource,
  book,
  bookDescription,
  quote,
  commentSubject,
  comment,
  like,
  group,
  topic,
  authorBlogPostNewsfeedResource,
  authorFollowNewsfeedResource,
  authorQaSettingNewsfeedResource,
  communityQuestion,
  communityAnswer,
  friendNewsfeedResource,
  userFollowingNewsfeedResource,
  pollVoteNewsfeedResource,
  expandableHtml,
  truncatedHtml,
  choicePollVoteNewsfeedResource,
  date: createChainableTypeChecker(dateValidator),
  naturalNumber: createChainableTypeChecker(naturalNumberValidator),
  creative,
  notification,
  searchResults: {
    book: bookSearchResult,
    author: authorSearchResult,
    user: userSearchResult,
  },
  readingNotesAnnotatedBookItem,
  readingChallenge,
  reviewDraftItem,
  shareDialog,
  giveawayLogEntry,
};
