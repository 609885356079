import GoogleAdsStore from "../../react_stores/google_ads_store";
import GrComponentFactory from "../gr_component_factory";
import Reflux from "reflux";
import PageSkin from "../page_skin";
import { requestCreativeForAdSlot } from "../../modules/ads/google_ad_helper";

export default GrComponentFactory.createClass({
  displayName: "GooglePageSkin",

  mixins: [Reflux.connectFilter(GoogleAdsStore,
    "googleAdsStore",
    function (adsStoreState) {
      return adsStoreState.nativeAds[this.props.adId];
    }
  )],

  propTypes: {
    adId: React.PropTypes.string.isRequired,
    image: React.PropTypes.string,
    trackingOptions: React.PropTypes.shape({
      enableTracking: React.PropTypes.bool,
      adId: React.PropTypes.string,
    }).isRequired,
  },

  getStoreState() {
    return this.state.googleAdsStore;
  },

  componentDidMount() {
    if (this.getStoreState() && !this.getStoreState().creative) {
      const params = {
        googleHookSelector: this.refs.googleHook,
        creativeSelector: this.getStoreState().creativeSelector,
        dimensions: this.getStoreState().dimensions,
        isNativeAd: this.getStoreState().isNativeAd,
        path: this.getStoreState().path,
      };
      requestCreativeForAdSlot(this.props.adId, params);
    }
  },

  renderCreative() {
    return <PageSkin imageUrl={this.getStoreState().creative.image_url} backgroundColor={this.getStoreState().creative.background_color} />;
  },

  render() {
    if (this.getStoreState()) {
      const classes = this.getStoreState().className;
      const creativeBody = this.getStoreState().creative ?
        this.renderCreative() : null;
      return(
        <div className="pageskinContainer">
          {creativeBody}
          <div className={classes} id={this.props.adId} ref="googleHook" />
        </div>
      );
    }
    else {
      return null;
    }
  },
});
