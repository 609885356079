import _ from "lodash";
import Reflux from "reflux";
import Freezer from "freezer-js";
import DataTrackingOptions from "../react_actions/data_tracking/data_tracking_actions";
import GoogleAdsActions from "../react_actions/google_ads_actions";
import NewsfeedActions from "../react_actions/newsfeed_actions";
import { defineSlot, enableServices, requestCreative, slotToHash, getA9Sizes, fetchApstagBids, adSlotsDefined, lazyLoadInit }  from "../modules/ads/google_ad_helper";
import envUtils from "../modules/env_utils";

const defaultState = {
  targeting: {},
  ads: {},
  nativeAds: {},
  device_type: null,
}; // eslint-disable-line camelcase
const state = new Freezer(defaultState);

export const ImpressionCode = {
  success: "0",
  dfpError: "1",
  monolithError: "2",
  empty: "3",
};

/** When adding, updating or removing attribute(s), please notify the Data Engineering team 
so that they can update the CSA query on their end. **/
const CSAElementAttributes = {
  adUnit: "ad",
  lineItemId: "l_id",
  campaignId: "c_id",
  type: "type",
  requestId: "r_id",
  sessionId: "s_id",
  slotId: "slot-id"
};


export const CSA_ELEMENT_TYPE = "DFP";

const getState = () => state.get();

const resetTo = (data = {}) => {
  getState().reset(_.merge({}, defaultState, data));
};

const getStateForKey = (key) => {
  if (!_.has(getState().ads, key)) {
    //throw error
    if (!_.has(getState().nativeAds, key)) {
      throw new ReferenceError(`No such product existing for key ${key}`);
    }
    return getState().nativeAds[key];
  }
  return getState().ads[key];
};

const setDataForKey = (key, data) => {
  getStateForKey(key).set(data);
};

const adUnit = (currentSlotIdState) => currentSlotIdState.path.split("/", 3)[2];

/* eslint-disable camelcase */
const dfpTrackingInfo = (currentSlotIdState) => ({
  ad_unit: adUnit(currentSlotIdState),
  line_item_id: currentSlotIdState.lineItemId,
  campaign_id: currentSlotIdState.campaignId,
  ad_device_type: currentSlotIdState.adDeviceType,
});
/* eslint-enable camelcase */

const ajaxTrackingCall = (url, currentSlotIdState, additionalParams) => {
  const data = _.merge(dfpTrackingInfo(currentSlotIdState), additionalParams);

  $j.ajax({
    url,
    type: "GET",
    data,
  });
};

const setImpressionLogged = (slotId) => {
  setDataForKey(slotId, { impressionLogged: true });
};

/**
 * Tracks an element in DFP, Clickstream and CSA
 * @param {string} slotId Ad id.
 * @param {object} impressionCode Impression code to log in DFP.
 * @param {DOMNode} impressionElement The element to track in CSA.
 */
const trackImpression = ({ slotId, impressionCode, impressionElement }) => {
  if (!slotId) {
    return;
  }

  const currentSlotIdState = getStateForKey(slotId);

  if (currentSlotIdState && !currentSlotIdState.impressionLogged) {
    ajaxTrackingCall(
      currentSlotIdState.pmetImpressionTrackUrl,
      currentSlotIdState,
      { impression_output: impressionCode } // eslint-disable-line camelcase
    );

    // CSA Impression
    trackImpressionInCSA({ currentSlotIdState, impressionElement });

    setImpressionLogged(slotId);
  }
};

/**
 * Returns metadata object, which is used to set metadata attributes on
 * an element
 *
 * @param {object} currentSlotIdState The object which contains metadata.
 */
const getCSATrackingInfo = (currentSlotIdState) => {
  const { id, sid } = (window && window.ue) || {};

  return {
    [CSAElementAttributes.adUnit]: adUnit(currentSlotIdState),
    [CSAElementAttributes.lineItemId]: currentSlotIdState.lineItemId,
    [CSAElementAttributes.campaignId]: currentSlotIdState.campaignId,
    [CSAElementAttributes.type]: CSA_ELEMENT_TYPE,
    [CSAElementAttributes.requestId]: id,
    [CSAElementAttributes.sessionId]: sid,
    [CSAElementAttributes.slotId]: CSA_ELEMENT_TYPE
  };
};

/**
 * Sets metadata attribute on the impression element and tracks it in CSA
 *
 * @param {object} currentSlotIdState The object which contains metadata.
 * @param {DOMNode} impressionElement The element to track in CSA.
 */
const trackImpressionInCSA = ({
  currentSlotIdState, impressionElement,
}) => {

  if (!impressionElement || !currentSlotIdState) {
    return;
  }

  if (window.csa) {

    const csaTrackingInfo = getCSATrackingInfo(currentSlotIdState);
    
    // setting impression attributes on the element
    Object.values(CSAElementAttributes).forEach((attribute) => {
      if (csaTrackingInfo[attribute]) {
        impressionElement.setAttribute(
          `data-csa-c-${attribute}`,
          csaTrackingInfo[attribute]
        );
      }
    });

    // registering the element using CSA's programmatic API
    // For more details: https://info.analytics.a2z.com/#/docs/data_collection/csa/content_whitelisting
    window.csa("Content", { element: impressionElement });
  }
};

const handleClickTracker = (currentSlotIdState) => {
  ajaxTrackingCall(currentSlotIdState.pmetClickTrackUrl, currentSlotIdState, {
    click_type: "click",
  }); // eslint-disable-line camelcase
};

const nativeAdGetUrl = (resourceType) => {
  switch (resourceType) {
    case "NewsfeedAd":
      return "/dfp/ajax_native_ad";
    case "PageSkin":
      return "/dfp/ajax_premium_campaign_page_skin";
    case "FeaturedContent":
      return "/dfp/ajax_premium_campaign_featured_content";
  }
};

export default Reflux.createStore({
  listenables: [
    GoogleAdsActions,
    { trackClickEvent: DataTrackingOptions.trackClickEvent },
  ],

  initializeWith(data) {
    lazyLoadInit();
    data = _.merge({}, { callbacksAttached: [] }, data);
    resetTo(data);
    const gptSlots = [];
    const apsSlots = [];
    let a9Sizes;
    if (envUtils.hasDom() && !adSlotsDefined()) {
      const ads = _.merge({}, this.getState().ads, this.getState().nativeAds);
      _.map(ads, (ad, adId) => {
        if (!ad.isLazyLoaded) {
          defineSlot(adId, ad.path, ad.dimensions, ad.adSizeMapping);
          gptSlots.push(slotToHash(ad, adId));
          a9Sizes = getA9Sizes(ad.dimensions);
          if (!_.isEmpty(a9Sizes)) {
            apsSlots.push(slotToHash(ad, adId, a9Sizes));
          }
        }
      });
      enableServices();
      fetchApstagBids(gptSlots, _.compact(apsSlots));
    }
  },

  resetTo(data) {
    resetTo(data);
  },

  updateWith(data) {
    getState().set(_.merge({}, getState(), data));
  },

  onTrackClickEvent(event, source) {
    let target = event.target;
    let dfp, pmet, dfpData, pmetData;
    const regex = /news/;
    let adId = JSON.parse(source.getAttribute("data-tracking-adId"));
    adId = adId
      ? adId
      : _.findKey(getState().nativeAds, (ad) => ad.path.match(regex));

    //crawl up the source tree until you find the dfp and pmet tracking data
    if (adId) {
      while (target !== source) {
        if (target.getAttribute("data-tracking-dfp") && !dfp) {
          dfp = target.getAttribute("data-tracking-dfp");
          dfpData = JSON.parse(dfp);
          if (dfpData) {
            this.trackDfpClick(adId);
          }
        }
        if (target.getAttribute("data-tracking-pmet") && !pmet) {
          pmet = target.getAttribute("data-tracking-pmet");
          pmetData = JSON.parse(pmet);
          this.trackPmetClick(adId, pmetData);
        }
        target = target.parentNode;
      }
    }
  },

  mergeWith(data) {
    getState().set(_.merge({}, getState(), data));
  },

  onHasAttachedDfpCallbackForSlot(adId) {
    this.getState().set("callbacksAttached", [
      ...this.getState().callbacksAttached,
      adId,
    ]);
  },

  onCheckCallbacksAttached(resolve, reject, slotsToCheck) {
    const result = _.reduce(
      ["ads", "nativeAds"],
      (result, adType) => {
        const perAdType = _.reduce(
          _.keys(getState()[adType]),
          (result, adId) => {
            let intermediate;
            if (_.includes(slotsToCheck, adId)) {
              intermediate =
                getStateForKey(adId).isLazyLoaded ||
                _.includes(getState().callbacksAttached, adId);
            } else {
              intermediate = true;
            }
            return result && intermediate;
          },
          true
        );
        return result && perAdType;
      },
      true
    );
    result ? resolve(true) : setTimeout(() => resolve(false), 500);
  },

  onRequestCreativeForSlot(slotId, paramsForCreative) {
    if (window.googletag && window.googletag.cmd) {
      window.googletag.cmd.push(() => {
        requestCreative(slotId, paramsForCreative);
      });
    }
  },

  onRemoveAdSlot(slotId) {
    setDataForKey(slotId, {
      empty: true,
    });
    this.notifyListeners();
  },

  onTrackImpression({ slotId, impressionCode, impressionElement }) {
    trackImpression({ slotId, impressionCode, impressionElement });
  },

  onAttachServedBannerAdAttributesAndTracking({
    slotId,
    campaignId,
    lineItemId,
    creative,
    impressionCode,
    delayImpressionTracking,
    impressionElement,
  }) {
    setDataForKey(slotId, {
      campaignId,
      lineItemId,
      creative,
      impressionCode,
    });
    this.notifyListeners();
    const currentSlotIdState = getStateForKey(slotId);
    if (!creative) {
      trackImpression({ slotId, impressionCode: ImpressionCode.dfpError });
      return;
    }

    if (!delayImpressionTracking) {
      //Track impression on pmet
      trackImpression({ slotId, impressionCode, impressionElement });
    }

    //ad click tracker to creative DOM object
    currentSlotIdState.creative.on("click", "a", () => {
      currentSlotIdState.creative.context.click();
      handleClickTracker(currentSlotIdState);
    });
  },

  onAttachServedNativeAdAttributesAndTracking({
    slotId,
    campaignId,
    lineItemId,
    creative,
    sponsoredId,
    impressionCode,
    dfpUrl,
    adUnit,
    impressionElement,
  }) {
    setDataForKey(slotId, { campaignId, lineItemId, url: dfpUrl });
    const currentSlotIdState = getStateForKey(slotId);
    const resourceType = currentSlotIdState.resourceType;
    const url = nativeAdGetUrl(resourceType);
    if (!creative) {
      trackImpression({ slotId, impressionCode: ImpressionCode.dfpError });
      return;
    }

    if (currentSlotIdState.resourceType === "NewsfeedAd") {
      this.ajaxNewsfeedAd(
        slotId,
        lineItemId,
        sponsoredId,
        impressionCode,
        dfpUrl,
        adUnit,
        url,
        impressionElement
      );
    } else {
      this.ajaxPremiumCampaign(
        slotId,
        lineItemId,
        sponsoredId,
        impressionCode,
        dfpUrl,
        adUnit,
        url,
        impressionElement
      );
    }
  },

  ajaxNewsfeedAd(
    slotId,
    lineItemId,
    sponsoredId,
    impressionCode,
    dfpUrl,
    adUnit,
    url,
    impressionElement
  ) {
    $j.ajax({
      type: "GET",
      url,
      /* eslint-disable camelcase */
      data: {
        format: "json",
        id: sponsoredId,
        line_item_id: lineItemId,
        ad_unit: adUnit,
        ad_device_type: "desktop",
        react: true,
      },
      /* eslint-enable camelcase */
    }).done((json) => {
      if (json.ok) {
        setDataForKey(slotId, json);
        NewsfeedActions.updateNativeAdSlot(
          getStateForKey(slotId).id,
          getStateForKey(slotId)
        );
        this.notifyListeners();
        trackImpression({ slotId, impressionCode, impressionElement });
      } else {
        trackImpression({
          slotId,
          impressionCode: ImpressionCode.monolithError,
        });
      }
    });
  },

  ajaxPremiumCampaign(
    slotId,
    lineItemId,
    sponsoredId,
    impressionCode,
    dfpUrl,
    adUnit,
    url,
    impressionElement
  ) {
    $j.ajax({
      type: "GET",
      url,
      /* eslint-disable camelcase */
      data: {
        format: "json",
        id: sponsoredId,
        line_item_id: lineItemId,
        ad_unit: adUnit,
        ad_device_type: "desktop",
      },
      /* eslint-enable camelcase */
    }).done((json) => {
      if (json.ok) {
        setDataForKey(slotId, json);
        this.notifyListeners(getState());

        //Track impression on pmet
        trackImpression({ slotId, impressionCode, impressionElement });
      } else {
        trackImpression({
          slotId,
          impressionCode: ImpressionCode.monolithError,
        });
      }
    });
  },

  trackDfpClick(slotId) {
    const currentSlotIdState = getStateForKey(slotId);
    const dfpClickUrl = currentSlotIdState.url;
    const queryParams = _.merge(dfpTrackingInfo(currentSlotIdState), {
      click_type: "click",
    }); // eslint-disable-line camelcase
    const encodedQueryParams = encodeURIComponent(`?${$j.param(queryParams)}`);

    $j.ajax({
      type: "GET",
      url:
        dfpClickUrl + currentSlotIdState.pmetClickTrackUrl + encodedQueryParams,
    });
  },

  trackPmetClick(slotId, additionalParams) {
    const currentSlotIdState = getStateForKey(slotId);
    const queryParams = _.merge(
      dfpTrackingInfo(currentSlotIdState),
      additionalParams
    );

    $j.ajax({
      type: "GET",
      url: currentSlotIdState.pmetClickTrackUrl,
      data: queryParams,
    });
  },

  notifyListeners() {
    this.trigger(getState());
  },

  getInitialState(key) {
    if (!_.isUndefined(key)) {
      return getStateForKey(key);
    } else {
      return getState();
    }
  },

  getInitialStateForKey: getStateForKey,

  getState,

  reset: resetTo,
});
