import React from "react";
import Reflux from "reflux";
import GrComponentFactory from "../gr_component_factory";
import AuthorFollowActions from "../../react_actions/author_follow_actions";
import AuthorFollowStore from "../../react_stores/author_follow_store";
import classnames from "classnames";

export default GrComponentFactory.createClass({
  displayName: "AuthorFollowButton",

  mixins: [Reflux.connectFilter(AuthorFollowStore, "authorFollowStore", function(follows) {
    return follows[this.props.authorId];
  })],

  propTypes: {
    authorId: React.PropTypes.number.isRequired,
    className: React.PropTypes.string,
  },

  handleButtonClicked() {
    if (this.state.authorFollowStore.isFollowing) {
      AuthorFollowActions.unFollow(this.props.authorId);
    } else {
      AuthorFollowActions.follow(this.props.authorId);
      // After a follow attempt "Unfollow" should not appear until
      // mouse leaves and re-enters button
      this.setState({ isHovering: false });
    }
  },

  getButtonText() {
    if (this.state.authorFollowStore.isFollowing) {
      if (this.state.isHovering) {
        return "Unfollow";
      } else {
        return "Following";
      }
    } else {
      return "Follow author";
    }
  },

  render() {
    const cx = classnames("gr-button", this.props.className);
    return (
      <button className={cx}
              onClick={this.handleButtonClicked}
              onMouseEnter={() => {this.setState({ isHovering: true });}}
              onMouseLeave={() => {this.setState({ isHovering: false });}}
              disabled={this.state.authorFollowStore.buttonEnabled ? false : "disabled"} >
        {this.getButtonText()}
      </button>
    );
  },

});
