import React from "react";

export default React.createClass({
  propTypes: {
    componentName: React.PropTypes.string.isRequired,
  },
  render() {
    return <div className="gr-errorMessage">
             There was an issue rendering {this.props.componentName}
           </div>;
  },
});
