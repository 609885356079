import _ from "lodash";
import Reflux from "reflux";
import GrComponentFactory from "../gr_component_factory";
import FeaturedGenresList from "./featured_genres_list";
import Spinner from "../spinner";
import FeaturedGenresStore from "../../react_stores/featured_genres_store";
import UserAgentCapabilitiesStore from "../../react_stores/user_agent_capabilities_store";
import TabContent from "./../tab_content";

// This number should match the $nav-spotlight-min-width variable in
// gr/shared_values/dimensions.scss
const MIN_WIDTH_FOR_SPOTLIGHT_DISPLAY = 1108;

export default GrComponentFactory.createClass({
  displayName: "FeaturedGenres",

  mixins: [
    Reflux.connect(FeaturedGenresStore, "featuredGenres"),
    Reflux.connect(UserAgentCapabilitiesStore, "userAgentCapabilities"),
  ],

  render() {
    if (this.state.featuredGenres.featuredGenreBookInfo) {
      return (
        <div className="featuredGenres">
          <span className="featuredGenres__genreList">
            <FeaturedGenresList
              genres={_.pluck(this.state.featuredGenres.featuredGenreBookInfo, "genre")} />
          </span>
        </div>
      );
    } else if (this.state.featuredGenres.errorMessage) {
      return <span>{this.state.featuredGenres.errorMessage}</span>;
    } else {
      return (
        <div className="featuredGenres featuredGenres--sparse">
          <Spinner />
        </div>
      );
    }
  },
});
