import { PropTypes } from "react";
import GrComponentFactory from "../../gr_component_factory";
import Modal from "../../modal";
import FriendFollowActions from "../../../react_actions/user_relationships/friend_follow_actions";
import { getUnfollowModalId } from "../../../modules/modal_id_helper";

export default GrComponentFactory.createClass({
  displayName: "UnfollowConfirmationModal",

  propTypes: {
    displayUser: PropTypes.shape({
      firstName: React.PropTypes.string.isRequired,
      viewableName: React.PropTypes.string.isRequired,
      userId: React.PropTypes.number.isRequired,
    }).isRequired,

    fromDropdown: PropTypes.bool,
  },

  getDefaultProps() {
    return { fromDropdown: false };
  },

  handleConfirmUnfollow() {
    FriendFollowActions.unfollow(this.props.displayUser.userId);
  },

  render() {
    return(
      <Modal id={getUnfollowModalId(this.props.displayUser.userId, this.props.fromDropdown)}
             bemModifiers="friendFollowModule"
             onConfirmHandler={this.handleConfirmUnfollow}>
        <div className="friendFollowConfirmModal">
          <h2 className="gr-h2 gr-h2--serif">Unfollow {this.props.displayUser.viewableName}?</h2>
          <div className="friendFollowConfirmModal__body">
            This will remove {this.props.displayUser.firstName}'s activity from your updates feed.
          </div>
        </div>
      </Modal>
    );
  },
});
