/*
This component displays the 'Report Wrong Book' form.

Required props:
* `book`: Book JSON referring to the book this link lets you preview
* `modalId`: This component expects to be assigned an ID (since it relies on being opened by ReportWrongBookButton)
* `acknowledgmentModalId`: The ID of the acknowledgment modal to be displayed if this form is submitted.

*/

import React from "react";
import GrComponentFactory from "../gr_component_factory";
import GrPropTypes from "../shared/gr_prop_types";
import Modal from "../modal";
import ModalActions from "../../react_actions/modal_actions";
import _ from "lodash";

const FLAG_BOOK_REASONS = {
  wrongBook: "It’s the wrong book",
  wrongEdition: "It’s the wrong edition",
  other: "Other",
};

export default GrComponentFactory.createClass({
  displayName: "ReportWrongBookForm",

  propTypes: {
    book: GrPropTypes.book().isRequired,
    modalId: React.PropTypes.string.isRequired,
    acknowledgmentModalId: React.PropTypes.string.isRequired,
  },

  getAuthorString() {
    return `by ${  this.props.book.authorName}`;
  },

  showReportAcknowledgement() {
    ModalActions.openModal(this.props.acknowledgmentModalId);
  },

  closeModal(ev) {
    ev.preventDefault();
    ModalActions.closeModal();
  },

  renderWrongBookForm() {
    const wrongBookOptions = [];

    _.forEach(FLAG_BOOK_REASONS, (reasonText, reason) => {
      wrongBookOptions.push(
        <option value={reason} key={reason}>{reasonText}</option>
      );
    });

    return (
      <form className="previewBook__reportWrongBookForm"
            action="/kindle_book_mapping_flags"
            data-remote="true"
            method="post">
        We’d love your help. Let us know what’s wrong with this preview of&nbsp;
        <cite>
          {this.props.book.title}
        </cite>
        {this.props.book.authorName ? this.getAuthorString() : ""}
        .
        <br/>
        <input type="hidden"
               name="kindle_book_mapping_flag[book_id]"
               value={this.props.book.bookId} />
        <div className="gr-formInputContainer">
          <label className="gr-label" htmlFor="kindle_book_mapping_flag_reason">
            Problem
          </label>
          <select name="kindle_book_mapping_flag[reason]"
                  id="kindle_book_mapping_flag_reason">
            {wrongBookOptions}
          </select>
        </div>
        <div className="gr-formInputContainer">
          <label className="gr-label"
                 htmlFor="kindle_book_mapping_flag_other_reason">
            Details (if other)
          </label>
          <input name="kindle_book_mapping_flag[other_reason]"
                 id="kindle_book_mapping_flag_other_reason" />
        </div>
        <button className="gr-button gr-button--modalPrimaryLeft"
                type="submit"
                onClick={this.showReportAcknowledgement}>
          Report Problem
        </button>
        <button className="gr-buttonAsLink"
                onClick={this.closeModal}
                type="button">
          Cancel
        </button>
      </form>
    );
  },

  render() {
    return (
      <Modal id={this.props.modalId}>
        <h2>See a Problem?</h2>
        {this.renderWrongBookForm()}
      </Modal>
    );
  },
});
