// Confirmation modal for canceling friend request

import { PropTypes } from "react";
import GrComponentFactory from "../../gr_component_factory";
import Modal from "../../modal";
import FriendFollowActions from "../../../react_actions/user_relationships/friend_follow_actions";
import { getCancelFriendRequestModalId } from "../../../modules/modal_id_helper";

export default GrComponentFactory.createClass({
  displayName: "CancelFriendRequestConfirmationModal",

  propTypes: {
    displayUser: PropTypes.shape({
      firstName: React.PropTypes.string.isRequired,
      viewableName: React.PropTypes.string.isRequired,
      userId: React.PropTypes.number.isRequired,
    }).isRequired,

    fromDropdown: PropTypes.bool,
    friendRequestToId: PropTypes.number.isRequired,
  },

  getDefaultProps() {
    return { fromDropdown: false };
  },

  handleConfirmCancelFriendRequest() {
    FriendFollowActions.cancelFriendRequest(this.props.displayUser.userId,
                                            this.props.friendRequestToId);
  },

  render() {
    return(
      <Modal id={getCancelFriendRequestModalId(this.props.displayUser.userId, this.props.fromDropdown)}
             bemModifiers="friendFollowModule"
             onConfirmHandler={this.handleConfirmCancelFriendRequest}>
        <div className="friendFollowConfirmModal">
          <h2 className="gr-h2 gr-h2--serif">Cancel Friend Request to {this.props.displayUser.viewableName}?</h2>
          <div className="friendFollowConfirmModal__body">
            This will cancel your friend request and also unfollow {this.props.displayUser.firstName},
            removing their activity from your updates feed.
          </div>
        </div>
      </Modal>
    );
  },
});
