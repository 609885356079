import { PropTypes } from "react";
import { isEmpty } from "lodash";
import { trackNavEngage, trackNavEngageClick } from "../../modules/click_and_view_tracking";

// COMPONENTS
import FavoriteGenresPane from "./favorite_genres_pane";
import FeaturedGenres from "./featured_genres";
import GenreList from "./genre_list";
import MenuLink from "../menu_link";
import PrimaryNavMenu from "./primary_nav_menu";

// ACTIONS
import FeaturedGenresActions from "../../react_actions/featured_genres_actions";

const SpotLight = ({ signedIn, favoriteGenres, genres }) => {
  const classesWithModifier = (modifier) =>
    `siteHeader__spotlight siteHeader__spotlight--${modifier}`;

  if (signedIn) {
    if (!isEmpty(favoriteGenres)) {
      return <div className={classesWithModifier("browseMenu")}
                  onClick={(e) => trackNavEngageClick(e, "Browse_Spotlight")}>
               <FeaturedGenres favoriteGenres={favoriteGenres} />
             </div>;
    } else {
      return <div className={classesWithModifier("withoutSubMenu")}
                  onClick={(e) => trackNavEngageClick(e, "Browse_Spotlight")}>
               <FavoriteGenresPane genres={genres} />
             </div>;
    }
  } else {
    return <div className={classesWithModifier("withoutSubMenu")}
                onClick={(e) => trackNavEngageClick(e, "Browse_Spotlight")}>
             <GenreList genres={genres} />
           </div>;
  }
};

SpotLight.propTypes = {
  signedIn: PropTypes.bool,
  favoriteGenres: PropTypes.array,
  genres: PropTypes.array.isRequired,
};

export const BrowseMenu = (props) => (
  <PrimaryNavMenu tabName="siteHeaderBrowseMenu"
                  trigger={<span>Browse ▾</span>}
                  fallbackUrl={props.browseUrl}
                  bemModifiers={["siteHeaderBrowseMenu"]}
                  showingFavoriteGenres={isEmpty(props.favoriteGenres)}
                  onShowCallback={() => {
                    trackNavEngage("BrowseShow");
                    FeaturedGenresActions.trackMenuImpression();
                    FeaturedGenresActions.requestFeaturedBooks();
                  }}>
    <div className="siteHeader__browseMenuDropdown">
      <ul className="siteHeader__subNav">
        <MenuLink href={props.recommendationsUrl}
                  linkText="Recommendations"
                  linkClass="siteHeader__subNavLink" />
        <MenuLink href={props.choiceAwardsUrl}
                  linkText="Choice Awards"
                  linkClass="siteHeader__subNavLink" />
        <MenuLink href={props.genresIndexUrl}
                  linkText="Genres"
                  linkClass="siteHeader__subNavLink siteHeader__subNavLink--genresIndex" />
        <MenuLink href={props.giveawayUrl}
                  linkText="Giveaways"
                  linkClass="siteHeader__subNavLink" />
        <MenuLink href={props.newReleasesUrl}
                  linkText="New Releases"
                  linkClass="siteHeader__subNavLink" />
        <MenuLink href={props.listUrl}
                  linkText="Lists"
                  linkClass="siteHeader__subNavLink" />
        <MenuLink href={props.exploreUrl}
                  linkText="Explore"
                  linkClass="siteHeader__subNavLink" />
        <MenuLink href={props.newsUrl}
                  linkText="News & Interviews"
                  linkClass="siteHeader__subNavLink" />
      </ul>
      <SpotLight {...props} />
    </div>
  </PrimaryNavMenu>
);

BrowseMenu.propTypes = {
  adId: PropTypes.string,
  browseUrl: PropTypes.string.isRequired,
  choiceAwardsUrl: PropTypes.string.isRequired,
  genresIndexUrl: PropTypes.string.isRequired,
  exploreUrl: PropTypes.string.isRequired,
  giveawayUrl: PropTypes.string.isRequired,
  listUrl: PropTypes.string.isRequired,
  newReleasesUrl: PropTypes.string.isRequired,
  recommendationsUrl: PropTypes.string.isRequired,
  newsUrl: PropTypes.string.isRequired,
};

export default BrowseMenu;
