import GrComponentFactory from "./gr_component_factory";
import ModalActions from "../react_actions/modal_actions";
import ModalStore from "../react_stores/modal_store";
import React from "react";
import Reflux from "reflux";

export default GrComponentFactory.createClass({
  displayName: "ModalTrigger",

  mixins: [Reflux.connect(ModalStore, "modalStore")],

  propTypes: {
    id: React.PropTypes.string.isRequired,
    clickHandler: React.PropTypes.func,
  },

  isActive() {
    return this.state.modalStore.currentActiveModal === this.props.id;
  },

  openModal() {
    if (this.props.clickHandler) {
      this.props.clickHandler();
    }
    ModalActions.openModal(this.props.id);
  },

  render() {
    return(
      <a className={this.withBemModifiers("modalTrigger")}
         onClick={this.openModal}
         role="button"
         aria-expanded={this.isActive()}
         aria-haspopup={true}>
        {this.props.children}
      </a>
    );
  },
});
