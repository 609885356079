import React from "react";
import _ from "lodash";
import GrComponentFactory from "../gr_component_factory";
import Reflux from "reflux";
/* react-onclickoutside is a deprecated package that should not be used for new
   code. Instead of having an outside click close a module, there should be an
   explicit close affordances in the spec. */
import OnClickOutside from "react-onclickoutside";
import classNames from "classnames";
import keys from "../../modules/keys";

// STORE
import BookSearchStoreFactory from "../../react_stores/book_search_store_factory";
// COMPONENTS
import BookSearchResults from "./book_search_results";
import Spinner from "../spinner";
// MIXINS
import FactoryStoreMixin from "../../react_mixins/factory_store_mixin";

const DELAYED_SEARCH_MS = 300;

export default GrComponentFactory.createClass({
  displayName: "BookSearch",

  mixins: [OnClickOutside,
           Reflux.ListenerMixin,
           FactoryStoreMixin(BookSearchStoreFactory)],

  propTypes: {
    autoFocus: React.PropTypes.bool,
    onChooseResult: React.PropTypes.func.isRequired,
    maxResults: React.PropTypes.number,
    shelfName: React.PropTypes.string,
    searchPath: React.PropTypes.string.isRequired,
    closeAfterResultChosen: React.PropTypes.bool,
    autocompleteUrl: React.PropTypes.string.isRequired,
    signedIn: React.PropTypes.bool,
    deviceType: React.PropTypes.string
  },

  getDefaultProps() {
    return {
      autoFocus: false,
      maxResults: 5,
      closeAfterResultChosen: false,
    };
  },

  componentDidMount() {
    this.delayedSearch = _.debounce((event) => {
      this.actions.search(event.target.value, this.props.autocompleteUrl);
    }, DELAYED_SEARCH_MS);
    this.signedInStr = (this.props.signedIn ? "SignedIn" : "SignedOut");
  },

  hide() {
    this.actions.setShowResultsContainer(false);
  },

  show() {
    this.actions.setShowResultsContainer(true);
  },

  handleClickOutside() {
    this.hide();
  },

  handleSearch(event) {
    this.setState({searchBoxValue: event.target.value});

    if (!_.isEmpty(event.target.value)) {
      event.persist();
      this.delayedSearch(event);
    } else {
      this.hide();
    }
  },

  handleClick(event) {
    if (!_.isEmpty(this.state.query)) {
      this.handleSearch(event);
    }
  },

  handleChoice(book) {
    $j.post("/track/track_click",
        {
          feature: "NavSearch:" +
              this.props.deviceType + ":" +
              this.signedInStr +
              ":suggestion_click",
          pmet_value: book.rank
        });

    this.props.onChooseResult(book);
    if (this.props.closeAfterResultChosen) {
      this.hide();
    }
  },

  // Note: This method manipulates a datastore of the book objects, marking one as "active," which triggers
  //       book_search_result (which is listening) to add a special class. Only problem is that we don't know
  //       which link element is currently selected, so we can't trigger a click on the active one when a user
  //       presses Enter. Which is why the current method does a hard window.location move when an item is
  //       activated, which is all kinds of wrong. The implementation of this entire component set prevents
  //       normal browser behavior, and for that reason this should be re-engineered from the ground up. -Joey
  handleKeyPress(event) {
    const maxResults = Math.min(this.props.maxResults, this.state.results.length);
    if (keys.isEnter(event.keyCode)) {
      if (this.state.activeResult !== null) {
        this.handleChoice(this.state.activeResult);
        event.preventDefault();
      } else if (_.isEmpty(event.target.value)) {
        event.preventDefault();
      }
    } else if (keys.isArrowDown(event.keyCode)) {
      this.actions.selectNextResult(maxResults);
    } else if (keys.isArrowUp(event.keyCode)) {
      this.actions.selectPreviousResult(maxResults);
    } else if (keys.isEscape(event.keyCode)) {
      this.hide();
    }
  },

  logSubmit() {
    $j.post("/track/track_click",
        {feature: "NavSearch:" +
              this.props.deviceType + ":" +
              this.signedInStr +
              ":see_all_click"});
  },

  renderInputIcon() {
    const searchSpinnerClasses = this.withBemModifiers("searchBox__icon", "loadingSpinner");
    const searchIconClasses = classNames(
      "searchBox__icon--magnifyingGlass gr-iconButton",
      this.withBemModifiers("searchBox__icon")
    );
    if (this.state.showLoadingSpinner) {
      return <span className={searchSpinnerClasses}>
               <Spinner />
             </span>;
    } else {
      return <button type="submit"
                     className={searchIconClasses}
                     aria-label="Search" />;
    }
  },

  renderResultsContainer() {
    if (!_.isEmpty(this.state.query)) {
      return <BookSearchResults {...this.props}
                         {...this.state}
                         onChooseResult={this.handleChoice}
                         mouseoverAction={this.actions.selectSpecificResult}
      />
    }
  },

  render() {
    const searchBoxClasses = classNames(this.withBemModifiers("searchBox"));
    const searchInputClasses = classNames(this.withBemModifiers("searchBox__input"));

    return(
      <div acceptCharset="UTF-8"
           className={searchBoxClasses}
           onKeyDown={this.handleKeyPress}>
        <form autoComplete="off"
              action={this.props.searchPath}
              className="searchBox__form"
              role="search"
              aria-label="Search for books to add to your shelves"
              onSubmit={this.logSubmit}>
          <input className={searchInputClasses}
                 autoComplete="off"
                 autoFocus={this.props.autoFocus}
                 name="q"
                 type="text"
                 placeholder="Search books"
                 aria-label="Search books"
                 onFocus={this.show}
                 onClick={this.handleClick}
                 onChange={this.handleSearch}
                 value={this.state.searchBoxValue}
                 aria-controls="searchResults" />
          <input type="hidden"
                 name="qid"
                 value={this.state.results.length > 0 ? this.state.results[0].qid : ""} />
          {this.renderInputIcon()}
          { this.state.showResultsContainer ?
                this.renderResultsContainer() : null }
        </form>
      </div>
    );
  },

});
