import React from "react";
import GrComponentFactory from "./gr_component_factory";
import { camelCase } from "lodash";
import { trackNavEngageClick } from "../modules/click_and_view_tracking";

export const MENU_LINK_TRACKING_PREFIX = "MenuLink";

export default GrComponentFactory.createClass({
  displayName: "MenuLink",

  propTypes: {
    href: React.PropTypes.string,
    linkText: React.PropTypes.any,
    linkClass: React.PropTypes.string,
    method: React.PropTypes.oneOf(["POST", "PUT", "DELETE"]),
  },

  getFriendlyMenuLinkTrackingName(linkText) {
    if(typeof linkText === "string") {
      const camelCaseName = camelCase(linkText);
      const pascalCaseName = camelCaseName[0].toUpperCase() + camelCaseName.slice(1);
      return `${MENU_LINK_TRACKING_PREFIX}_${pascalCaseName}`;
    } else {
      return `${MENU_LINK_TRACKING_PREFIX}_Unknown`;
    }
  },

  render () {
    return (
      <li role={`menuitem ${this.props.linkText}`} className="menuLink" aria-label={this.props.linkText}>
        { this.props.children ?
          <span>{this.props.children}</span> :
          <a href={this.props.href}
             className={this.props.linkClass}
             data-method={this.props.method}
             onClick={(e) => trackNavEngageClick(e, this.getFriendlyMenuLinkTrackingName(this.props.linkText))}>
             {this.props.linkText}
          </a>
        }
      </li>
    );
  },
});
