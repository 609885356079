/*doc
---
title: Reading notes shelving modal trigger
name: reading_notes_shelving_modal_trigger
category: React
---

This component contains the reading note shelving modal trigger, which wraps the given html (innerHtml prop) in
a ModalTrigger for the ReadingNotesShelvingModal IF the given book is unshelved. In this case, the innerHtml
will be made "unclickable" so the user must shelve the book before they are able to make KNH visible.
If the book is shelved, this will simply render the innerHtml, which will function as expected.

At the time of writing, we have two distinct features on the KNH owner view that need to trigger the
ReadingNotesShelvingModal when clicked: the Share All KNH button in the banner, and each visibility toggle
for the individual reading notes. In the fullness of time, the button and the toggle may be rewritten in
React (as well as the entire page) and then we will not have to do strange/dangerous things with innerHtml.

The ShelvingsStore must be populated in order for this to function correctly.

Required props:

  * `bookId` (string): Book's ActiveRecord id
  * `innerHtml` (string): Html to act as the modal trigger. Needs to be html_safe

```rails_example
<% book = Book.first %>
<% html = "<div> Hey </div>" %>
<%= react_component 'ReactComponents.ReadingNotesShelvingModalTrigger',
                    {bookId: book.id.to_s, innerHtml: html.html_safe} %>
```
*/

import React from "react";
import GrComponentFactory from "../gr_component_factory";
import ModalTrigger from "../modal_trigger";
import ShelvingsStore from "../../react_stores/shelvings_store";

import { connectToLazyStore } from "../../react_mixins/gr_reflux";

export default GrComponentFactory.createClass({
  displayName: "ReadingNotesShelvingModalTrigger",

  propTypes: {
    bookId: React.PropTypes.string.isRequired,
    innerHtml: React.PropTypes.string.isRequired,
  },
  mixins: [connectToLazyStore(ShelvingsStore, "bookId", { stateKey: "shelvings" })],

  renderInnerHtml() {
    return (
      <span dangerouslySetInnerHTML={{ __html: this.props.innerHtml }}/>
    );
  },

  render() {
    if (this.state.shelvings.exclusiveShelfName === null) {
      return (
        <ModalTrigger id="readingNotesShelvingModal" bemModifiers="readingNotesShelving">
          <div className="readingNotesShelvingModalTrigger__unclickableInnerHtml">
            { this.renderInnerHtml() }
          </div>
        </ModalTrigger>
      );
    } else {
      return this.renderInnerHtml();
    }
  },
});
