import { connect, Provider } from "react-redux";
import GetACopyStore from "../../react_stores/buy_buttons/get_a_copy_store";
import { getACopyClosed } from "../../react_actions/buy_buttons/get_a_copy_actions";
import GetACopyInterstitial from "./get_a_copy_interstitial";
import { PropTypes } from "react";

export const ConditionalInterstitial = ({ interstitial, onClose }) => interstitial ? <GetACopyInterstitial {...interstitial}
                                                   onCloseHandler={onClose} />
  : <noscript />;

ConditionalInterstitial.propTypes = {
  interstitial: PropTypes.shape({
    book: PropTypes.object,
    bookLinks: PropTypes.array,
    glideButton: PropTypes.object,
  }),
  onClose: PropTypes.func,
};

const stateToProps = (state) => ({
  interstitial: state.activeGetACopyInterstitial,
});

const dispatchToProps = (dispatch) => ({
  onClose: () => dispatch(getACopyClosed()),
});

const ConnectedInterstitial = connect(stateToProps, dispatchToProps)(ConditionalInterstitial);

const GetACopyInterstitialWrapper = () => (
  <Provider store={GetACopyStore}>
    <ConnectedInterstitial />
  </Provider>
);

export default GetACopyInterstitialWrapper;
