// See documentation on https://www.goodreads.com/style_guide

import React from "react";
import GrComponentFactory from "../gr_component_factory";


export default GrComponentFactory.createClass({
  displayName: "Spoiler",

  propTypes: {
    html: React.PropTypes.string,
    spoilerWarning: React.PropTypes.oneOfType([
      React.PropTypes.string,
      React.PropTypes.element,
    ]),
  },

  componentWillMount() {
    if (this.props.html === null && this.props.children === null) {
      throw new Error("props.children and props.html cannot both be null");
    }
  },

  getDefaultProps() {
    return {
      html: null,
      spoilerWarning: null,
    };
  },

  getInitialState(){
    return {
      showSpoiler: false,
    };
  },

  onClickShowSpoiler(){
    this.setState({
      showSpoiler: true,
    });
  },

  onClickHideSpoiler(){
    this.setState({
      showSpoiler: false,
    });
  },

  getSpoilerHtml() {
    return {
      __html: this.props.html,
    };
  },

  renderSpoiler() {
    if (this.props.html) {
      return(
        <span className="spoiler__text u-marginRightTiny"
              dangerouslySetInnerHTML={this.getSpoilerHtml()}/>
      );
    } else {
      return this.props.children;
    }
  },

  render() {
    return (
      <span>
        {
          this.state.showSpoiler ?
            <span className="spoiler__textContainer">
              {this.renderSpoiler()}
              <button className="gr-buttonAsLink"
                      onClick={this.onClickHideSpoiler}>
                (hide spoiler)
              </button>
            </span>
            :
            <span>
              {this.props.spoilerWarning}
              <button className="gr-buttonAsLink"
                onClick={this.onClickShowSpoiler}>
                (view spoiler)
              </button>
            </span>
        }
      </span>
    );
  },
});
