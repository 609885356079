import Reflux from "reflux";
import Freezer from "freezer-js";
import FriendFollowActions from "../../react_actions/user_relationships/friend_follow_actions";
import {
  httpPost,
  httpDelete,
  DEFAULT_ERROR_MESSAGE,
} from "../../modules/ajax/ajax_helper";
import { get } from "lodash";
import { flashErrorMessage } from "../../modules/flash_message_helper";

const state = new Freezer({});

const getState = () => state.get();

export default Reflux.createStore({
  listenables: [FriendFollowActions],

  getState,

  getInitialState() {
    return getState();
  },

  initializeWith(data) {
    getState().reset(data);
  },

  // before every ajax request, always set inProgress to true
  startAjaxRequest(id) {
    getState()[id].set({ inProgress: true });
    this.trigger(getState());
  },

  // after every ajax request, always reset inProgress to false
  finishAjaxRequest(id) {
    getState()[id].set({ inProgress: false });
    this.trigger(getState());
  },

  // this is split off from ajax request cuz BlockUserStore uses this
  receiveUnfriend(id) {
    getState()[id].set({
      isFriend: false,
      isFollowing: false,
      isPendingFriendRequestTo: false,
    });

    this.trigger(getState());
  },

  onUnfriend(id) {
    this.startAjaxRequest(id);

    httpPost(`/friend/destroy/${id}.json`)
      .done(() => {
        this.receiveUnfriend(id);
      })
      .fail((error) => {
        flashErrorMessage(get(error, "message", DEFAULT_ERROR_MESSAGE));
      })
      .always(() => this.finishAjaxRequest(id));
  },

  onCancelFriendRequest(id, friendRequestId) {
    this.startAjaxRequest(id);

    httpPost(`/friend/cancel_request/${friendRequestId}.json`)
      .done(() => {
        getState()[id].set({
          isFollowing: false,
          isPendingFriendRequestTo: false,
        });
      })
      .fail((error) => {
        flashErrorMessage(get(error, "message", DEFAULT_ERROR_MESSAGE));
      })
      .always(() => this.finishAjaxRequest(id));
  },

  onFollow(id, data = {}) {
    this.startAjaxRequest(id);

    httpPost(`/user/${id}/followers.json`, { data })
      .done(() => {
        getState()[id].set({ isFollowing: true });
      })
      .fail((error) => {
        flashErrorMessage(get(error, "message", DEFAULT_ERROR_MESSAGE));
      })
      .always(() => this.finishAjaxRequest(id));
  },

  onUnfollow(id) {
    this.startAjaxRequest(id);

    httpDelete(`/user/${id}/followers/stop_following.json`)
      .done(() => {
        getState()[id].set({
          isFollowing: false,
          isPendingFriendRequestTo: false,
        });
      })
      .fail((error) => {
        flashErrorMessage(get(error, "message", DEFAULT_ERROR_MESSAGE));
      })
      .always(() => this.finishAjaxRequest(id));
  },

  onAcceptFriendRequest(id, friendRequestId) {
    this.startAjaxRequest(id);

    httpPost("/friend/confirm_request.json", { data: { id: friendRequestId } })
      .done(() => {
        getState()[id].set({
          isFriend: true,
          isPendingFriendRequestFrom: false,
        });
      })
      .fail((error) => {
        flashErrorMessage(get(error, "message", DEFAULT_ERROR_MESSAGE));
      })
      .always(() => this.finishAjaxRequest(id));
  },

  reset() {
    getState().reset({});
  },
});
