// See documentation on https://www.goodreads.com/style_guide

import React from "react";
import GrComponentFactory from "./gr_component_factory";
import TabActions from "../react_actions/tab_actions";

const eventTypes = {
  CLICK: "click",
  HOVER: "hover",
};

export default GrComponentFactory.createClass({
  displayName: "Tab",

  propTypes: {
    group: React.PropTypes.string.isRequired,
    name: React.PropTypes.string.isRequired,
    initiallySelected: React.PropTypes.bool,
    triggerEventType: React.PropTypes.string,
  },

  getDefaultProps() {
    return {
      initiallySelected: false,
      triggerEventType: eventTypes.CLICK,
    };
  },

  componentDidMount() {
    if (this.props.initiallySelected) {
      this.selectTab();
    }
  },

  selectTab() {
    TabActions.selectTab(this.props.name, this.props.group);
  },

  render() {
    const tabAttrs = {};
    switch(this.props.triggerEventType) {
      case eventTypes.CLICK:
        tabAttrs.onClick = this.selectTab;
        break;
      case eventTypes.HOVER:
        tabAttrs.onMouseOver = this.selectTab;
        break;
    }
    return (
      <span {...tabAttrs}>
        {this.props.children}
      </span>
    );
  },
});
