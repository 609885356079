import GrComponentFactory from "../gr_component_factory";
import NotificationsStore from "../../react_stores/notifications_store";
import React from "react";
import Reflux from "reflux";
import UserIcon from "../user/user_icon";

export default GrComponentFactory.createClass({
  displayName: "ProfileMenuJewel",

  mixins: [Reflux.connect(NotificationsStore, "notificationsStore")],

  propTypes: {
    notificationIconUrl: React.PropTypes.string,
    showNotificationsFlag: React.PropTypes.bool,
    tempFriendRequestCount: React.PropTypes.number,
    tempUnreadMessageCount: React.PropTypes.number,
    user: React.PropTypes.shape({
      viewableName: React.PropTypes.string.isRequired,
      profileImage: React.PropTypes.string.isRequired,
      profileUrl: React.PropTypes.string,
    }).isRequired,
  },

  getDefaultProps() {
    return {
      showNotificationsFlag: true,
      tempFriendRequestCount: 0,
      tempUnreadMessageCount: 0,
    };
  },

  notificationsCountString() {
    const state = this.state.notificationsStore;
    return (state.unreadCount + this.props.tempFriendRequestCount + this.props.tempUnreadMessageCount) +
             (state.unreadCountMore ? "+" : "");
  },

  render() {
    return (
      <span className="headerPersonalNav__icon">
        {this.renderNotificationsCountFlag()}
        <UserIcon user={this.props.user} includeLink={false} />
      </span>
    );
  },

  renderNotificationsCountFlag() {
    if (this.props.showNotificationsFlag === true) {
      if (this.state.notificationsStore.unreadCount > 0 ||
            this.props.tempFriendRequestCount > 0 ||
            this.props.tempUnreadMessageCount > 0) {
        return (
          <span className="headerPersonalNav__flag">{this.notificationsCountString()}</span>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  },
});
