import React from "react";
import GrComponentFactory from "./gr_component_factory";
import Reflux from "reflux";
import TabsStore from "../react_stores/tabs_store";

export default GrComponentFactory.createClass({
  displayName: "TabContent",

  mixins: [Reflux.connect(TabsStore, "tabsStore")],

  propTypes: {
    group: React.PropTypes.string.isRequired,
    name: React.PropTypes.string.isRequired,
  },

  isActive() {
    return this.props.name === this.state.tabsStore[this.props.group];
  },

  render() {
    return (
      <div>
        {this.isActive() ? this.props.children : null}
      </div>
    );
  },
});
