import "./_common";
import { GlobalComponents, GlobalStores } from "../react_isomorphic/global";
import { HeaderComponents, HeaderStores } from "../react_isomorphic/site_header";
import { ReadingNotesComponents, ReadingNotesStores } from "../react_isomorphic/reading_notes";
import { FriendFollowModuleComponents, FriendFollowModuleStores } from "../react_isomorphic/friend_follow_module";
import { NewsComponents } from "../react_isomorphic/news";
/*
  For simplicity we are just including any components and stores that can
  appear on different pages based on deploy service settings so we don't need
  different bundles based on settings and pages...yet.
*/
import { ExperimentComponents, ExperimentStores } from "../react_isomorphic/experiments";

import { merge } from "lodash";

window.ReactComponents = merge({},
  GlobalComponents,
  HeaderComponents,
  ExperimentComponents,
  ReadingNotesComponents,
  FriendFollowModuleComponents,
  NewsComponents
);

window.ReactStores = merge({},
  GlobalStores,
  HeaderStores,
  ExperimentStores,
  ReadingNotesStores,
  FriendFollowModuleStores
);
