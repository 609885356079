import { isEmpty, merge } from "lodash";
import React from "react";
import BemModifiers from "../react_mixins/bem_modifiers";
import DeployServiceMixin from "../react_mixins/deploy_service";
import ReactPureRenderMixin from "react-addons-pure-render-mixin";
import TrackingDataAttributesMixin from "../react_mixins/tracking_data_attributes_mixin";
import ErrorMessage from "./error_message";
import envUtils from "../modules/env_utils";
import GrErrorReporting from "../modules/gr_error_reporting";
const DEFAULT_MIXINS = [ReactPureRenderMixin, BemModifiers, DeployServiceMixin, TrackingDataAttributesMixin];

/**
 * A factory that creates React class
 *
 * Params:
 * spec: object - React component definition. The definition expects `render`
 * function and `displayName` attribute. If not an error will be thrown.
 * options: {
 *   showErrorMessage: boolean - if true, shows an error message
 * }
 */

const DEFAULT_OPTIONS = {
  showErrorMessage: process.env.ENV === envUtils.DEVELOPMENT,
};

const wrapRenderFunction = function(spec, options) {
  const originalRender = spec.render;
  options = merge({}, DEFAULT_OPTIONS, options);
  spec.render = function() {
    try {
      return originalRender.apply(this, arguments);
    } catch (error) {
      GrErrorReporting.notifyException(error, `Failed to render ${spec.displayName}`);
      if (options.showErrorMessage) {
        return <ErrorMessage componentName = {spec.displayName} />;
      } else {
        return null;
      }
    }
  };
};

// throw an error if a valid component name. The component name is useful for debugging.
const validateComponentNameRequired = (componentName) => {
  if (isEmpty(componentName)) {
    throw new Error("GrError: Missing component name. Please " +
      "make sure `displayName` attribute is defined while creating a React class " +
      "using GrComponentFactory.createClass.");
  }
};

const addMixins = (spec) => {
  const mixins = spec.mixins || [];
  spec.mixins = mixins.concat(DEFAULT_MIXINS);
};

const getComponentSpec = (spec, options) => {
  addMixins(spec);
  // If we are in a test environment (TEST var set in gulpfile), do not catch
  // rendering errors in components
  if (process.env.ENV !== envUtils.TEST) {
    wrapRenderFunction(spec, options);
  }
  return spec;
};

export default {
  createClass(spec, options = {}) {
    validateComponentNameRequired(spec.displayName);
    return React.createClass(getComponentSpec(spec, options));
  },
};
