/*doc
---
title: Drop Down List
name: drop_down_list
category: React
---

This is a basic menu list component that will take in arbitrary component
children and display them in a list view. If the number of children is > the
passed in maxListItems prop, the children will be appropriately truncated, and
a "More" link will appear that will link to a passed in moreURL prop.

PROPS:

OPTIONAL - moreLinkURL - url for the "More" link to redirect to. Does not display
                         the "More" link if no URL is passed in.
           moreLinkText - display text for the link. Defaults to "More"

*/
import React from "react";
import GrComponentFactory from "./gr_component_factory";

export default GrComponentFactory.createClass({
  displayName: "DropDownList",

  propTypes: {
    showMoreLink: React.PropTypes.bool,
    moreLinkURL: React.PropTypes.string,
    moreLinkText: React.PropTypes.string,
  },

  getDefaultProps() {
    return { showMoreLink: false,
             moreLinkURL: null,
             moreLinkText: "…More" };
  },

  render() {
    return(
      <div>
        { this.props.children }
        { this.props.showMoreLink && this.props.moreLinkURL !== null ?
          <a className="dropDownList__listItem--moreLink" href={this.props.moreLinkURL}>
            {this.props.moreLinkText}
          </a> :
          null }
      </div>
    );
  },

});
