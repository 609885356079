const KEYCODES = {
  enter: 13,
  escape: 27,
  space: 32,
  leftArrow: 37,
  upArrow: 38,
  rightArrow: 39,
  downArrow: 40,
};

export default {
  isEnter(keyCode) {
    return keyCode === KEYCODES.enter;
  },

  isEscape(keyCode) {
    return keyCode === KEYCODES.escape;
  },

  isSpace(keyCode) {
    return keyCode === KEYCODES.space;
  },

  isArrowLeft(keyCode) {
    return keyCode === KEYCODES.leftArrow;
  },

  isArrowUp(keyCode) {
    return keyCode === KEYCODES.upArrow;
  },

  isArrowRight(keyCode) {
    return keyCode === KEYCODES.rightArrow;
  },

  isArrowDown(keyCode) {
    return keyCode === KEYCODES.downArrow;
  },

  isDropdownOpenerKey(keyCode) {
    return this.isEnter(keyCode) ||
           this.isSpace(keyCode) ||
           this.isArrowDown(keyCode);
  },

  isDefaultOpenerKey(keyCode) {
    return this.isEnter(keyCode) ||
           this.isSpace(keyCode);
  },

  isDefaultCloserKey(keyCode) {
    return this.isEscape(keyCode);
  },

  KEYCODES,
};
