import React from "react";
import GrComponentFactory from "../gr_component_factory";
import classNames from "classnames";

export default GrComponentFactory.createClass({
  displayName: "FlagForAbuseLink",
  propTypes: {
    flagForAbuseUrl: React.PropTypes.string.isRequired,
    resourceType: React.PropTypes.string,
  },

  render() {
    const flagClasses = classNames("gr-metaText",
                                 "gr-hyperlink--naked",
                                 "gr-hyperlink",
                                 "gr-flag");
    const flagTitle = `Flag this ${this.props.resourceType || "comment"} as inappropriate.`;
    return <a href={this.props.flagForAbuseUrl}
              className={flagClasses}
              title={flagTitle}>
             Flag
           </a>;
  },
});
