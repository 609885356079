import React from "react";
import _ from "lodash";
import GrComponentFactory from "../gr_component_factory";
import GrPropTypes from "../shared/gr_prop_types";
import CommentActions from "../../react_actions/comment_actions";
import CommentsStore from "../../react_stores/comments_store";
import UserIcon from "../user/user_icon";
import { connectToLazyStore } from "../../react_mixins/gr_reflux";
import classNames from "classnames";

export default GrComponentFactory.createClass({
  displayName: "CommentForm",

  propTypes: {
    authorUser: GrPropTypes.user().isRequired,
    subjectKey: React.PropTypes.string.isRequired,
    subject: React.PropTypes.shape({
      type: React.PropTypes.string,
      id: React.PropTypes.number,
      uri: React.PropTypes.string }),
    placeholder: React.PropTypes.string,
  },

  mixins: [connectToLazyStore(CommentsStore, "subjectKey")],

  getDefaultProps() {
    return {
      placeholder: "Write a comment…",
    };
  },

  addNewComment(event) {
    if (!this.isCommentEmpty()) {
      CommentActions.addNewComment({
        subjectKey: this.props.subjectKey,
        subject: this.props.subject,
        body: this.state.unsavedComment,
        authorUser: this.props.authorUser,
      });
    }
    event.preventDefault();
  },

  isCommentEmpty() {
    return _.isEmpty(_.trim(this.state.unsavedComment));
  },

  onTextChange(event) {
    CommentActions.commentEntered(this.props.subjectKey, event.target.value);
  },

  onFocus() {
    CommentActions.textareaFocused(this.props.subjectKey);
  },

  onBlur() {
    CommentActions.textareaUnfocused(this.props.subjectKey);
  },

  componentDidUpdate() {
    if (this.state.textareaFocusRequested === true) {
      this.refs.commentBodyTextarea.focus();
    }
  },

  render() {
    const commentFormClassName = classNames(this.withBemModifiers("gr-commentForm"), "gr-mediaBox");
    return <div className={commentFormClassName}>
      <div className="gr-mediaBox__media u-noLineHeight">
        <UserIcon user={this.props.authorUser}/>
      </div>
      <form className="gr-mediaBox__desc"
            method="post" action="/comment">
        <input type="hidden"
               name="id"
               value={this.props.subject.id}/>
        <input type="hidden"
               name="type"
               value={this.props.subject.type}/>
        <textarea className="gr-textarea gr-commentForm__textarea"
                  name="comment[body_usertext]"
                  disabled={this.state.saveInProgress}
                  onChange={this.onTextChange}
                  onFocus={this.onFocus}
                  onBlur={this.onBlur}
                  rows={this.state.textareaRows}
                  value={this.state.unsavedComment}
                  ref="commentBodyTextarea"
                  placeholder={this.props.placeholder}
                  aria-label={this.props.placeholder}/>
        { this.state.showCommentButton ?
          <button className="gr-commentForm__submitButton gr-button gr-button--small"
                  disabled={this.state.saveInProgress || this.isCommentEmpty()}
                  onClick={this.addNewComment}
                  type="submit">
            Comment
          </button> :
          null }
      </form>
    </div>;
  },
});
