import React from "react";
import GrComponentFactory from "../gr_component_factory";
import ExpandableHtml from "../user_content/expandable_html";
import HiddenHtml from "../user_content/hidden_html";
import GrPropTypes from "../shared/gr_prop_types";
import UserProfileLink from "../user/user_profile_link";
import UserIcon from "../user/user_icon";
import CommentRightSideInformation from "./right_side_information";

/*
 Comment displays a single comment from a user.
*/
export default GrComponentFactory.createClass({
  displayName: "Comment",

  propTypes: {
    body: React.PropTypes.shape(GrPropTypes.expandableHtml()),
    createdAt: GrPropTypes.date.isRequired,
    authorUser: GrPropTypes.user().isRequired,
    subjectKey: React.PropTypes.string.isRequired,
    subject: GrPropTypes.commentSubject(),
    deletableByCurrentUser: React.PropTypes.bool.isRequired,
    blockedByCurrentUser: React.PropTypes.bool,
    showTimestamp: React.PropTypes.bool,
    uri: React.PropTypes.string.isRequired,
    flagForAbuseUrl: React.PropTypes.string.isRequired,
  },

  getDefaultProps() {
    return {
      blockedByCurrentUser: false,
    };
  },

  getInitialState() {
    return { showCommentDeletingSpinner: false };
  },

  renderBody() {
    if (this.props.blockedByCurrentUser) {
      const unhideText = "unhide comment";
      return (
        <HiddenHtml unhideText={unhideText}>
          <ExpandableHtml {...this.props.body} />
        </HiddenHtml>
      );
    } else {
      return (
        <ExpandableHtml {...this.props.body} />
      );
    }
  },

  render() {
    return  <div className="gr-comment gr-mediaBox">
              <div className="gr-mediaBox__media">
               <UserIcon user={this.props.authorUser} />
              </div>
              <div className="gr-mediaBox__desc
                              gr-mediaBox__desc--clearfixOverflow">
                <CommentRightSideInformation
                  {...this.props} />
                <UserProfileLink user={this.props.authorUser} />
                {this.props.blockedByCurrentUser ? <strong> (blocked)</strong> : null}
                <div className="gr-comment__body">
                  {this.renderBody()}
                </div>
              </div>
            </div>;
  },
});
