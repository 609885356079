import { TOGGLE_SEARCH } from "../../react_actions/header/search_actions";

export default (state = {}, action) => {
  switch (action.type) {
    case TOGGLE_SEARCH:
      return Object.assign({}, state, { searchIsActive: action.searchIsActive });
    default:
      return state;
  }
};
