// React component for dropdown list in FriendFollowModule.
// Handles logic for what available options there are depending on
// friend/follow state.

import Reflux from "reflux";
import GrComponentFactory from "../gr_component_factory";
import FriendFollowModuleStore from "../../react_stores/user_relationships/friend_follow_module_store";
import BlockUserStore from "../../react_stores/user/show/block_user_store";
import Dropdown from "../dropdown";
import ModalTrigger from "../modal_trigger";
import CancelFriendRequestConfirmationModal from "./confirmation_modals/cancel_friend_request_confirmation_modal";
import UnfriendConfirmationModal from "./confirmation_modals/unfriend_confirmation_modal";
import UnfollowConfirmationModal from "./confirmation_modals/unfollow_confirmation_modal";
import BlockUserConfirmationModal from "./confirmation_modals/block_user_confirmation_modal";

import { getUnfollowModalId, getCancelFriendRequestModalId, getUnfriendModalId, getBlockUserModalId, getUnblockUserModalId } from "../../modules/modal_id_helper";

export default GrComponentFactory.createClass({
  displayName: "FriendFollowDropdown",

  mixins: [Reflux.connect(FriendFollowModuleStore, "ffStore"),
           Reflux.connect(BlockUserStore, "buStore")],

  propTypes: {
    displayUser: React.PropTypes.shape({
      firstName: React.PropTypes.string.isRequired,
      viewableName: React.PropTypes.string.isRequired,
      userId: React.PropTypes.number.isRequired,
    }).isRequired,
    displayMobileDropdown: React.PropTypes.bool.isRequired,
    sendMessageUrl: React.PropTypes.string.isRequired,
    recommendBooksUrl: React.PropTypes.string.isRequired,
    compareBooksUrl: React.PropTypes.string.isRequired,
    flagAbuseUrl: React.PropTypes.string.isRequired,
    addFriendUrl: React.PropTypes.string.isRequired,
    renderFriendButton: React.PropTypes.bool.isRequired,
    friendButtonInDropdown: React.PropTypes.bool.isRequired,
    friendRequestToId: React.PropTypes.number,
  },

  renderRecommendBooksLink() {
    if (this.state.ffStore.isFriend) {
      return(
        <a className="friendFollowModuleDropdownMenu__dropdownLink"
           href={this.props.recommendBooksUrl}
           rel="nofollow"
           data-testid="recommendBooks">
          Recommend books
        </a>
      );
    }
  },

  shouldRenderCancelFriendRequestModal() {
    return this.state.ffStore.isFollowing &&
           this.state.ffStore.isPendingFriendRequestTo &&
           this.props.friendRequestToId;
  },

  shouldRenderUnfollowModal() {
    return this.state.ffStore.isFollowing &&
           !this.state.ffStore.isPendingFriendRequestTo;
  },

  renderFollowingLinks() {
    if (this.shouldRenderCancelFriendRequestModal()) {
      return(
        <div className="friendFollowModuleDropdownMenu__dropdownLink">
          <ModalTrigger id={getCancelFriendRequestModalId(this.props.displayUser.userId, true)}>
            <button className="gr-buttonAsLink gr-buttonAsLink--black" type="button" data-testid="cancelFriendRequest">
              Cancel friend request
            </button>
          </ModalTrigger>
        </div>
      );
    }
    else if (this.shouldRenderUnfollowModal()) {
      return(
        <div className="friendFollowModuleDropdownMenu__dropdownLink">
          <ModalTrigger id={getUnfollowModalId(this.props.displayUser.userId, true)}>
            <button className="gr-buttonAsLink gr-buttonAsLink--black" type="button" data-testid="unfollow">
              Unfollow
            </button>
          </ModalTrigger>
        </div>
      );
    }
  },

  shouldRenderUnfriendModal() {
    return this.state.ffStore.isFriend;
  },

  renderFriendLinks() {
    if (this.state.ffStore.isFriend) {
      return(
        <div>
          <div className="friendFollowModuleDropdownMenu__dropdownLink">
            <ModalTrigger id={getUnfriendModalId(this.props.displayUser.userId, true)}>
              <button className="gr-buttonAsLink gr-buttonAsLink--black" type="button" data-testid="unfriend">
                Unfriend {this.props.displayUser.firstName}
              </button>
            </ModalTrigger>
          </div>
        </div>
      );
    } else {
      if (this.props.friendButtonInDropdown && this.props.renderFriendButton
          && !this.state.ffStore.isPendingFriendRequestTo) {
        return(
          <a className="friendFollowModuleDropdownMenu__dropdownLink"
             href={this.props.addFriendUrl}
             rel="nofollow"
             data-testid="addFriend">
             Add friend
          </a>
        );
      }
    }
  },

  renderBlockUserText() {
    if (this.state.buStore.isBlocked) {
      return "Unblock";
    } else {
      return "Block this member";
    }
  },

  renderBlockUserLink() {
    let buttonText= this.renderBlockUserText();

    const modalId = this.state.buStore.isBlocked ?
      getUnblockUserModalId(this.props.displayUser.userId, true) :
      getBlockUserModalId(this.props.displayUser.userId, true);

    return(
      <div className="friendFollowModuleDropdownMenu__dropdownLink">
        <ModalTrigger id={modalId}>
          <button className="gr-buttonAsLink friendFollowModule__blockedUserText friendFollowModule__blockedUserText--black"
                  type="button" data-testid="blockUser">
            {buttonText}
          </button>
        </ModalTrigger>
      </div>
    );
  },

  renderBlockUserConfirmationModal(){
    return(
      <BlockUserConfirmationModal displayUser={this.props.displayUser}
                                    isBlocked={this.state.buStore.isBlocked}
                                    fromDropdown={true} />
    );
  },

  renderDropdownList() {
    return(
      <div className="friendFollowModuleDropdownMenu">
        <a className="friendFollowModuleDropdownMenu__dropdownLink"
           href={this.props.sendMessageUrl}
           rel="nofollow"
           data-testid="message">
          Message
        </a>

        {this.renderRecommendBooksLink()}

        <a className="friendFollowModuleDropdownMenu__dropdownLink"
           href={this.props.compareBooksUrl}
           rel="nofollow"
           data-testid="compareBooks">
          Compare books
        </a>

        {this.renderFollowingLinks()}
        {this.renderFriendLinks()}
        <a className="friendFollowModuleDropdownMenu__dropdownLink"
           href={this.props.flagAbuseUrl}
           rel="nofollow"
           data-testid="flagAbuse">
          Report this account
        </a>
        {this.renderBlockUserLink()}
      </div>
    );
  },

  renderMobileDropdownList() {
    return(
      <div className="friendFollowModuleDropdownMenu">
        <a className="friendFollowModuleDropdownMenu__dropdownLink"
           href={this.props.flagAbuseUrl}
           rel="nofollow"
           data-testid="flagAbuse">
          Report this account
        </a>

        {this.renderBlockUserLink()}
      </div>
    );
  },

  renderDropdownTrigger() {
    return(
      <div>
        <span className="friendFollowModuleCaret__text">
          More
        </span>
        <div className="friendFollowModuleCaret__image" />
      </div>
    );
  },

  renderMobileDropdownTrigger() {
    return(
      <div className="friendFollowModuleMobileDropdownTrigger" />
    );
  },

  render() {
    if (this.props.displayMobileDropdown){
      return(
        <div className="friendFollowModuleMobileDropdown">
          <Dropdown trigger={this.renderMobileDropdownTrigger()}
                  triggerEventType={Dropdown.TriggerEventTypes.CLICK}
                  tooltipText="More"
                  bemModifiers="friendFollowModuleMobile"
                  fallbackUrl={this.props.flagAbuseUrl}
                  children={this.renderMobileDropdownList()} />
          {this.renderBlockUserConfirmationModal()}
        </div>
      )
    }
    return(
      <div className="friendFollowModuleCaret">
        <Dropdown trigger={this.renderDropdownTrigger()}
                  triggerEventType={Dropdown.TriggerEventTypes.CLICK}
                  tooltipText="More"
                  bemModifiers="friendFollowModule"
                  children={this.renderDropdownList()} />

        {this.shouldRenderCancelFriendRequestModal() &&
          <CancelFriendRequestConfirmationModal displayUser={this.props.displayUser}
                                                isPendingFriendRequestTo={this.state.ffStore.isPendingFriendRequestTo}
                                                friendRequestToId={this.props.friendRequestToId}
                                                fromDropdown={true} />
        }
        {this.shouldRenderUnfollowModal() &&
          <UnfollowConfirmationModal displayUser={this.props.displayUser}
                                     fromDropdown={true} />
        }
        {this.shouldRenderUnfriendModal() &&
          <UnfriendConfirmationModal displayUser={this.props.displayUser}
                                     fromDropdown={true} />
        }
        {this.renderBlockUserConfirmationModal()}
      </div>
    );
  },
});
