import React from "react";
import GRComponentFactory from "../gr_component_factory";
import { URLTrackingParams } from "../../analytics/url_tracking_params";
import envUtils from "../../modules/env_utils";

import BookSearch from "../search/book_search";

export default GRComponentFactory.createClass({

  displayName: "NavSearch",

  propTypes: {
    autocompleteUrl: React.PropTypes.string,
    autoFocus: React.PropTypes.bool,
    searchPath: React.PropTypes.string,
    bemModifiers: React.PropTypes.string,
    signedIn: React.PropTypes.bool,
    mobviousDeviceType: React.PropTypes.string
  },

  getDefaultProps() { return { autoFocus: false, bemModifiers: "navbar" }; },

  onSearchSelection(result) {
    let url = `${result.bookUrl}?${URLTrackingParams.BOOK_SHOW_INTERACTION_PARAM}&${URLTrackingParams.FROM_SEARCH_PARAM}`;
    if (result.qid!==null){
      url = url.concat(`&${URLTrackingParams.SEARCH_IDENTIFIER_PARAM}=${result.qid}`);
      if (result.rank!==null && result.rank > 0){
        url = url.concat(`&${URLTrackingParams.SEARCH_RANK_PARAM}=${result.rank}`);
      }
    }
    envUtils.setLocationUrl(url);
  },

  render() {
    // Note: The BookSearch component is also called by add_book_search, which has a different onChooseResult action.
    //       These should probably be separated into two separate components, with some shared styles and UI logic.
    return (
        <BookSearch autoFocus={this.props.autoFocus}
                    bemModifiers={this.props.bemModifiers}
                    searchPath={this.props.searchPath}
                    autocompleteUrl={this.props.autocompleteUrl}
                    signedIn={this.props.signedIn}
                    deviceType={this.props.mobviousDeviceType}
                    onChooseResult={this.onSearchSelection} />
    );
  },
});
