import Cookies from "../shared/cookies";
import React from "react";
import GrComponentFactory from "../gr_component_factory";
import classNames from "classnames";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import createLogger from "redux-logger";
import { isEmpty } from "lodash";
import { acknowledgeEvent } from "../../modules/shared/touch_not_ready_helper";
import { trackNavEngageClick } from "../../modules/click_and_view_tracking";

// COMPONENTS
import FavoriteGenresForm from "../favorite_genres_form";
import Modal from "../modal";
import ModalTrigger from "../modal_trigger";
import PersonalNav from "./personal_nav";
import PersonalNavDrawer, {
  personalNavDrawerModalId
} from "./personal_nav_drawer";
import PrimaryNavMenus from "./primary_nav_menus";
import ProfileMenuJewel from "./profile_menu_jewel";
import HeaderLogo from "./header_logo";
import SearchToggle, { SEARCH_TOGGLE_TYPES } from "../search/search_toggle";
import NavSearch from "./nav_search";
import PrimaryNavSeparateLine from "./primary_nav_separate_line";

// REDUCER
import headerReducer from "../../react_reducers/header/header_reducer";

// CONSTANTS
import envUtils from "../../modules/env_utils";

const middlewares = [];
if (envUtils.isDevelopment() && envUtils.hasDom()) {
  middlewares.push(createLogger());
}

const store = createStore(headerReducer, {}, applyMiddleware(...middlewares));

export default GrComponentFactory.createClass({
  displayName: "Header",

  getInitialState() {
    return { scrolling: false, userLikelyHasAccount: false };
  },

  propTypes: {
    adId: React.PropTypes.string,
    autocomleteUrl: React.PropTypes.string,
    browseUrl: React.PropTypes.string,
    choiceAwardsUrl: React.PropTypes.string,
    communityUrl: React.PropTypes.string,
    discussionsUrl: React.PropTypes.string,
    favoriteGenres: React.PropTypes.array,
    friendIconUrl: React.PropTypes.string,
    genres: React.PropTypes.array.isRequired,
    genresIndexUrl: React.PropTypes.string,
    exploreUrl: React.PropTypes.string,
    giveawayUrl: React.PropTypes.string,
    groupsUrl: React.PropTypes.string,
    featuredAskAuthorUrl: React.PropTypes.string,
    helpUrl: React.PropTypes.string,
    homeUrl: React.PropTypes.string,
    listUrl: React.PropTypes.string,
    logo: React.PropTypes.shape({
      imageUrl: React.PropTypes.string,
      clickthroughUrl: React.PropTypes.string,
      altText: React.PropTypes.string
    }),
    messageIconUrl: React.PropTypes.string,
    myBooksUrl: React.PropTypes.string,
    myFriendsUrl: React.PropTypes.string,
    myGroupsUrl: React.PropTypes.string,
    myQuotesUrl: React.PropTypes.string,
    myReadingChallengeUrl: React.PropTypes.string,
    myRecsUrl: React.PropTypes.string,
    name: React.PropTypes.string,
    newReleasesUrl: React.PropTypes.string,
    notificationIconUrl: React.PropTypes.string,
    peopleUrl: React.PropTypes.string,
    preferences: React.PropTypes.object,
    profileImage: React.PropTypes.string,
    profileUrl: React.PropTypes.string,
    quotesUrl: React.PropTypes.string,
    recommendationsUrl: React.PropTypes.string,
    signedIn: React.PropTypes.bool,
    searchPath: React.PropTypes.string,
    signInUrl: React.PropTypes.string,
    signInWithReturnUrl: React.PropTypes.bool,
    signUpUrl: React.PropTypes.string,
    tempFriendRequestCount: React.PropTypes.number,
    tempUnreadMessageCount: React.PropTypes.number,
    topFullImage: React.PropTypes.shape({
      clickthroughUrl: React.PropTypes.string,
      altText: React.PropTypes.string,
      backgroundColor: React.PropTypes.string,
      xs: React.PropTypes.object,
      md: React.PropTypes.object
    }),
  },

  componentDidMount() {
    // See also ApplicationHelper#set_sign_in_user_cookies
    if (Cookies.get("likely_has_account") === "true") {
      this.setState({ userLikelyHasAccount: true });
    }
  },

  handleScrollBegin() {
    this.setState({ scrolling: true });
  },

  handleScrollEnd() {
    this.setState({ scrolling: false });
  },

  handleProfileModalTriggerClick(e) {
    acknowledgeEvent();
    e.preventDefault();
    trackNavEngageClick(e, "ProfileModalTrigger");
  },

  render() {
    return (
      <Provider store={store}>
        <header>
          {this.renderTopFullImage()}
          <div className="siteHeader__topLine gr-box gr-box--withShadow">
            <div className="siteHeader__contents">
              <div className="siteHeader__topLevelItem siteHeader__topLevelItem--searchIcon">
                <SearchToggle toggleType={SEARCH_TOGGLE_TYPES.ICON} />
              </div>
              <HeaderLogo {...this.props.logo} />

              <nav className="siteHeader__primaryNavInline">
                <PrimaryNavMenus {...this.props} />
              </nav>
              <NavSearch {...this.props} />
              {this.props.signedIn
                ? this.renderPersonalNav()
                : this.renderLoginLinks()}
              {this.renderProfileDrawerTrigger()}
              {this.renderModals()}
              {this.renderPersonalNavDrawer()}
            </div>
          </div>
          <PrimaryNavSeparateLine {...this.props} />
        </header>
      </Provider>
    );
  },

  renderPersonalNav() {
    return (
      <div className="siteHeader__personal">
        <PersonalNav {...this.props} />
      </div>
    );
  },

  renderPersonalNavDrawer() {
    return <PersonalNavDrawer {...this.props} />;
  },

  renderProfileDrawerTrigger() {
    if (this.props.signedIn) {
      const user = {
        viewableName: this.props.name,
        profileImage: this.props.profileImage,
        profileUrl: this.props.profileUrl
      };
      return (
        <div className="siteHeader__topLevelItem siteHeader__topLevelItem--profileIcon">
          <span
            className="headerPersonalNav"
            data-ux-click
            onClick={(e) => this.handleProfileModalTriggerClick(e)}
          >
            <ModalTrigger id={personalNavDrawerModalId}>
              <ProfileMenuJewel {...this.props} user={user} />
            </ModalTrigger>
          </span>
        </div>
      );
    } else {
      const buttonClass = classNames("gr-button", "gr-button--dark");
      if (this.state.userLikelyHasAccount) {
        return (
          <div className="siteHeader__topLevelItem siteHeader__topLevelItem--signUp">
            <a
              href={this.getSignInUrl()}
              className={buttonClass}
              rel="nofollow"
              onClick={(e) => trackNavEngageClick(e, "SignIn")}
            >
              Sign in
            </a>
          </div>
        );
      } else {
        return (
          <div className="siteHeader__topLevelItem siteHeader__topLevelItem--signUp">
            <a
              href={this.props.signUpUrl}
              className={buttonClass}
              rel="nofollow"
              onClick={(e) => trackNavEngageClick(e, "SignUp")}
            >
              Sign up
            </a>
          </div>
        );
      }
    }
  },

  getSignInUrl() {
    const currentPath = envUtils.getCurrentPath();
    if (this.props.signInWithReturnUrl) {
      return `${this.props.signInUrl}?returnurl=${currentPath}`;
    } else {
      return this.props.signInUrl;
    }
  },

  renderLoginLinks() {
    return (
      <ul className="siteHeader__personal">
        <li className="siteHeader__topLevelItem siteHeader__topLevelItem--signedOut">
          <a
            href={this.getSignInUrl()}
            rel="nofollow"
            className="siteHeader__topLevelLink"
            onClick={(e) => trackNavEngageClick(e, "SignIn")}
          >
            Sign In
          </a>
        </li>
        <li className="siteHeader__topLevelItem siteHeader__topLevelItem--signedOut">
          <a
            href={this.props.signUpUrl}
            rel="nofollow"
            className="siteHeader__topLevelLink"
            onClick={(e) => trackNavEngageClick(e, "Join")}
          >
            Join
          </a>
        </li>
      </ul>
    );
  },

  renderModals() {
    if (this.props.signedIn) {
      return (
        <Modal id="favGenres">
          <FavoriteGenresForm
            favoriteGenres={this.props.favoriteGenres}
            genres={this.props.genres}
          />
        </Modal>
      );
    } else {
      return null;
    }
  },

  renderTopFullImage() {
    if (!isEmpty(this.props.topFullImage)) {
      return (
        <div
          className="siteHeader__topFullImageContainer"
          style={{ backgroundColor: this.props.topFullImage.backgroundColor }}
        >
          {/* Breakpoint values based on header breakpoint values. Review
              siteHeader CSS for any breakpoint CSS that needs to be revised
              to match the image sizes and breakpoints used here. */}
          {/* See also https://w.amazon.com/index.php/Goodreads/Engineering/Site_header_top_banner */}
          <a
            className="siteHeader__topFullImageLink"
            href={this.props.topFullImage.clickthroughUrl}
          >
            <picture>
              <source
                media="(min-width: 768px)"
                srcSet={`${this.props.topFullImage.md["1x"]} 1x, ${this.props.topFullImage.md["2x"]} 2x`}
              />
              <img
                alt={this.props.topFullImage.altText}
                className="siteHeader__topFullImage"
                src={this.props.topFullImage.xs["1x"]}
                srcSet={`${this.props.topFullImage.xs["2x"]} 2x`}
              />
            </picture>
          </a>
        </div>
      );
    }
  }
});
