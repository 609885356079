export const ICON_SIZES = {
  SMALL: "small",
  MEDIUM: "medium",
  LARGE: "large",
};

export const ICON_SHAPES = {
  CIRCLE: "circular",
  ROUND_SQUARE: "roundSquare",
  RECTANGULAR: "rectangular",
};
