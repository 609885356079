import React from "react";
import Reflux from "reflux";
import GrComponentFactory from "../gr_component_factory";

import MenuLink from "../menu_link";
import PrimaryNavMenu from "./primary_nav_menu";
import { trackNavEngage } from "../../modules/click_and_view_tracking";

export default GrComponentFactory.createClass({
  displayName: "CommunityMenu",

  propTypes: {
    communityUrl: React.PropTypes.string.isRequired,
    featuredAskAuthorUrl: React.PropTypes.string.isRequired,
    groupsUrl: React.PropTypes.string.isRequired,
    discussionsUrl: React.PropTypes.string.isRequired,
    quotesUrl: React.PropTypes.string.isRequired,
    peopleUrl: React.PropTypes.string,
  },

  renderMenuLink(url, linkText) {
    if (url) {
      return <MenuLink href={url}
                       linkText={linkText}
                       linkClass="siteHeader__subNavLink" />;
    } else {
      return null;
    }
  },

  render() {
    const communityTrigger = <span>Community &#9662;</span>;
    return (
        <PrimaryNavMenu tabName="siteHeaderCommunityMenu"
                        trigger={communityTrigger}
                        fallbackUrl={this.props.communityUrl}
                        onShowCallback={() => trackNavEngage("CommunityShow")}>
          <ul className="siteHeader__subNav">
            {this.renderMenuLink(this.props.groupsUrl, "Groups")}
            {this.renderMenuLink(this.props.discussionsUrl, "Discussions")}
            {this.renderMenuLink(this.props.quotesUrl, "Quotes")}
            {this.renderMenuLink(this.props.featuredAskAuthorUrl, "Ask the Author")}
            {this.renderMenuLink(this.props.peopleUrl, "People")}
          </ul>
        </PrimaryNavMenu>
    );
  },
});
