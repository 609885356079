import Reflux from "reflux";
import Freezer from "freezer-js";
import ShareDialogActions from "../react_actions/share_dialog_actions";
import { httpPost } from "../modules/ajax/ajax_helper";
import { astralLength } from "../modules/string_helper";
import { get } from "lodash";

const state = new Freezer({});

const getState = function() {
  return state.get();
};

export const MAXIMUM_SHARING_TEXT_LENGTH = 420;
export const WARNING_SHARING_TEXT_LENGTH = 100;

export default Reflux.createStore({

  listenables: [ShareDialogActions],

  getState,

  getInitialState() {
    return getState();
  },

  initializeWith(data) {
    getState().reset(data);
  },

  onUpdateSharingTextInputValue(val) {
    const textTooLong = astralLength(val) > MAXIMUM_SHARING_TEXT_LENGTH;
    const disableShareModalButton = textTooLong;

    getState().set({
      sharingTextInputValue: val,
      shareModalButtonDisabled: disableShareModalButton,
      textTooLong,
    });
    this.trigger(getState());
  },

  onSubmitShare(sharingData, sharingTextInputValue) {
    getState().set({ shareModalButtonDisabled: true, error: false });
    this.trigger(getState());
    const sharingCreateData = {
      resource_id: sharingData.resourceId,
      resource_type: sharingData.resourceTypeFriendlyText,
      sharing_text: sharingTextInputValue,
    };

    if (sharingData.sharerUserId) {
      sharingCreateData.sharer_user_id = sharingData.sharerUserId;
    }

    httpPost("/sharings", {
      format: "json",
      data: {
        sharing: sharingCreateData,
      },
    }).done((response) => {
      getState().set({
        shared: true,
        sharingTextInputValue: "",
        sharingUrl: response.sharingUrl,
        sharingDisplayName: response.sharingDisplayName,
      });
    }).fail((error) => {
      const errorText = get(error, ["responseJSON", "message"]);
      getState().set({ error: true, errorText });
    }).always(() => {
      getState().set({ shareModalButtonDisabled: false });
      this.trigger(getState());
    });
  },

  onOpenShareModal(resourceTypeIdentifier) {
    getState().set({ shared: false, error: false });
    this.trigger(getState());
    const openModalMetricByResourceType = `OpenSharingModal_${  resourceTypeIdentifier}`;
    httpPost("/track/track_click", {
      data: {
        feature: openModalMetricByResourceType,
      },
    });
  },

  reset() {
    getState().reset({});
  },
});
