import React from "react";
import Reflux from "reflux";
import GrComponentFactory from "../gr_component_factory";
import GrPropTypes from "../shared/gr_prop_types";
import Spinner from "../spinner";
import FlagForAbuseLink from "../flagged_resources/flag_for_abuse_link";
import RelativeTime from "../time/relative_time";
import DotSeparatedElements from "../dot_separated_elements";
import CommentActions from "../../react_actions/comment_actions";
import UserAgentCapabilitiesStore
  from "../../react_stores/user_agent_capabilities_store";
import envUtils from "../../modules/env_utils";

export default GrComponentFactory.createClass({
  displayName: "CommentRightSideInformation",
  mixins: [Reflux.connect(UserAgentCapabilitiesStore, "userAgentCapabilities")],
  propTypes: {
    showTimestamp: React.PropTypes.bool,
    deletableByCurrentUser: React.PropTypes.bool.isRequired,
    subject: GrPropTypes.commentSubject().isRequired,
    uri: React.PropTypes.string.isRequired,
    subjectKey: React.PropTypes.string.isRequired,
    createdAt: GrPropTypes.date.isRequired,
    flagForAbuseUrl: React.PropTypes.string.isRequired,
  },

  getDefaultProps() {
    return { showTimestamp: true };
  },

  getInitialState() {
    return { showCommentDeletingSpinner: false };
  },

  deleteComment() {
    const commentData = { subject: this.props.subject,
                          subjectKey: this.props.subjectKey };
    if (window.confirm("Are you sure you want to delete this comment?")) {
      this.setState({ showCommentDeletingSpinner: true });
      CommentActions.deleteComment(this.props.subjectKey, this.props.uri, commentData);
    }
  },

  getDeleteLink() {
    if (this.props.deletableByCurrentUser) {
      if (this.state.showCommentDeletingSpinner) {
        return <Spinner ref="spinner" />;
      } else {
        return <a className="gr-comment__deleteLink
                             gr-hyperlink--naked
                             gr-hyperlink"
                  ref="deleteLink"
                  href=""
                  onClick={this.deleteComment}
                  aria-label="Delete comment">
                 Delete
               </a>;
      }
    }
  },

  getFlag() {
    const fullFlagUrl = `${this.props.flagForAbuseUrl}&return_url=${encodeURIComponent(envUtils.getLocationUrl())}`;
    return <FlagForAbuseLink flagForAbuseUrl={fullFlagUrl} />;
  },

  getTimestamp() {
    if (this.props.showTimestamp) {
      return <small className="gr-comment__timestamp">
               <RelativeTime time={this.props.createdAt} />
             </small>;
    }
  },

  getRightSideElements() {
    return [this.getTimestamp(), this.getDeleteLink(), this.getFlag()];
  },

  render() {
    return <div className="gr-comment__rightSideInformation">
             <DotSeparatedElements elements={this.getRightSideElements()} />
           </div>;
  },

});
