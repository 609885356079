import Reflux from "reflux";
import Freezer from "freezer-js";
import _ from "lodash";

import UserFollowActions from "../../react_actions/user/user_follow_actions";

const state = new Freezer({});

const getState = () => state.get();

const getResourceValue = (resource, key) => getState()[resource][key];

const setRelationshipData = (userId, value) => getState()[userId].set(value);

const getRelationshipData = (userId, key) => getState()[userId][key];

const clearErrorState = (userId) => {
  setRelationshipData(userId, { error: null });
};

const toggleUserFollowStoreData = (userId) => {
  const isFollowing = getRelationshipData(userId, "isFollowing");
  const newValue = {
    isFollowing: !isFollowing,
  };
  setRelationshipData(userId, newValue);
};

const enableButton = (userId) => {
  setRelationshipData(userId, { buttonEnabled: true });
};

const disableButton = (userId) => {
  setRelationshipData(userId, { buttonEnabled: false });
};

const store = Reflux.createStore({
  listenables: UserFollowActions,
  getState,
  getInitialState: getState,

  initializeWith(rawData) {
    const newValue = _.transform(rawData, (scrubbedData, userRelationship, userId) => {
      scrubbedData[userId] = {
        error: null,
        buttonEnabled: true,
        isBlocked: userRelationship.isBlocked,
        allowsFollow: userRelationship.allowsFollow,
        source: userRelationship.source || false,
        // Valid follow status defined in modules/user/friend_status_values.js
        isFollowing: userRelationship.isFollowing,
        friendshipStatus: userRelationship.friendshipStatus,
      };
    });
    getState().set(newValue);
  },

  updateWith(data) {
    this.initializeWith(data);
  },

  reset() {
    getState().reset({});
  },

  onFollow(userId) {
    // If we came from a Kindle Notes and Highlights page, include that in post for metrics counting in rails
    const source = getResourceValue(userId, "source");
    const fromAnnotatedBookPage = (source === "AnnotatedBooksPage");
    const fromReadingNotesListPage = (source === "ReadingNotesListPage");

    disableButton(userId);
    clearErrorState(userId);
    this.trigger(this.getState());
    $j.post(`/user/${userId}/followers`, {
      format: "json",
      from_annotated_books_page: fromAnnotatedBookPage,
      from_reading_notes_list_page: fromReadingNotesListPage,
    }).done((resp) => {
      // In both success and failure service responds with status 200
      // simple {status: "success"} message if successful
      // and 200 response {status: "error", "message": "blah"} if not
      if (resp.status === "success") {
        toggleUserFollowStoreData(userId);
      } else {
        // TODO-KCW-10739 - Better error state handling
        const data = { error: resp.message };
        setRelationshipData(userId, data);
      }
    }).always(() => {
      enableButton(userId);
      this.trigger(this.getState());
    });
  },

  onUnFollow(userId) {
    disableButton(userId);
    clearErrorState(userId);
    this.trigger(this.getState());
    $j.ajax({
      type: "DELETE",
      url: `/user/${userId}/followers/stop_following`,
      data: {
        format: "json",
      },
    }).done(() => {
      toggleUserFollowStoreData(userId);
    }).fail(() => {
      // On fail, the service returns a 404 with empty head.
      // TODO-KCW-10739 - Better error state handling
    }).always(() => {
      enableButton(userId);
      this.trigger(this.getState());
    });
  },
});

export default store;
