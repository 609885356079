import Reflux from "reflux";
import GrComponentFactory from "../gr_component_factory";
import GrPropTypes from "../shared/gr_prop_types";

import { FRIEND_STATUS } from "../../modules/user/friend_status_values";
import UserFollowActions from "../../react_actions/user/user_follow_actions";
import UserFollowStore from "../../react_stores/user/user_follow_store";

/* Display follow actions for a user.
/  Note, if user is an author, use AuthorFollowButton instead
/  Note, does not currently work on signed-out view (ajax returns error state)
/   see https://issues.labcollab.net/browse/KCW-10729
*/
export default GrComponentFactory.createClass({
  displayName: "UserFollowWidget",

  mixins: [Reflux.connectFilter(UserFollowStore, "userFollowStore", function(follows) {
    return follows[this.props.displayUser.id];
  })],

  propTypes: {
    displayUser: GrPropTypes.user().isRequired,
  },

  renderFollowStatus() {
    const store = this.state.userFollowStore;
    if (store.isFollowing) {
      // Use first name if available, otherwise use viewableName (which could contain last name)
      const name = this.props.displayUser.firstName || this.props.displayUser.viewableName;
      return (
        <div className="gr-box gr-box--inline gr-box--marginRightSmall">
          You are following {name}.
        </div>
      );
    } else {
      return (
        <button className="gr-button gr-button--small gr-box gr-box--inline gr-box--marginRightSmall"
                onClick={() => UserFollowActions.follow(this.props.displayUser.id)}
                disabled={store.buttonEnabled ? false : "disabled"} >
          Follow
        </button>
      );
    }
  },

  renderUnfollowButtonIfNeeded() {
    const store = this.state.userFollowStore;
    if (store.isFollowing && store.friendshipStatus !== FRIEND_STATUS.REQUEST_PENDING_TO) {
      // You cannot unfollow if you have a friend request pending to this user
      // (you can only cancel friend requests on another page like the profile page)
      return (
        <button className="gr-button gr-buttonAsLink gr-buttonAsLink--quiet"
                onClick={() => UserFollowActions.unFollow(this.props.displayUser.id)}
                disabled={store.buttonEnabled ? false : "disabled"} >
          <span className="gr-metaText">Unfollow</span>
        </button>
      );
    }
  },

  shouldHideFollowButtons() {
    /* Do not render follow buttons if
    /  - you are looking at your own profile (don't follow self)
    /  - you are already friends (to avoid friend status confusion)
    /  - the other user does not allow follows without friending
    /  - the other user has blocked you
    */
    const followStoreData = this.state.userFollowStore;
    return (followStoreData.friendshipStatus == FRIEND_STATUS.FRIEND ||
            followStoreData.friendshipStatus == FRIEND_STATUS.SELF ||
            followStoreData.isBlocked ||
            !followStoreData.allowsFollow);
  },

  render() {
    if (this.shouldHideFollowButtons()) {
      return null;
    }

    return (
      <div className="userFollowWidget">
        {this.renderFollowStatus()}
        {this.renderUnfollowButtonIfNeeded()}
      </div>
    );
  },
});
