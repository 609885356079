import React from "react";
import GrComponentFactory from "../gr_component_factory";
import ShapeIcon from "../shape_icon";
import { ICON_SIZES } from "../../modules/shape_icon_values";

export default GrComponentFactory.createClass({
  displayName: "UserIcon",

  propTypes: {
    user: React.PropTypes.shape({
      viewableName: React.PropTypes.string.isRequired,
      profileImage: React.PropTypes.string.isRequired,
      profileUrl: React.PropTypes.string,
    }).isRequired,
    includeLink: React.PropTypes.bool,
    size: React.PropTypes.oneOf([ICON_SIZES.SMALL, ICON_SIZES.MEDIUM, ICON_SIZES.LARGE]),
  },

  getDefaultProps() {
    return {
      includeLink: true,
      size: ICON_SIZES.SMALL,
    };
  },

  render () {
    const user = this.props.user;

    return <ShapeIcon imageUrl={user.profileImage}
                      imageAlt={user.viewableName}
                      linkUrl={user.profileUrl}
                      border={true}
                      {...this.props} />;
  },

});
