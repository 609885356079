/**
 * Environment specific utility module to support shared code. Its using commonJS style exports instead
 * of ES6 exports as its used in gulpfile.js which is not ES6 yet.
 */
module.exports = {
  PRODUCTION: "production",
  DEVELOPMENT: "development",
  TEST: "test",

  /**
   * Returns true if DOM is available for rendering.
   * @return {boolean}
   */
  hasDom() {
    return !!window.document;
  },

  /**
   * Returns true if there is no DOM available for rendering.
   * @return {boolean}
   */
  domUnavailable() {
    return !this.hasDom();
  },

  isDevelopment() {
    return process.env.ENV === this.DEVELOPMENT;
  },

  getCurrentPath(){
    if (window && window.location) {
      return window.encodeURIComponent(window.location.pathname + window.location.search);
    }
  },

  /**
   * Sets window location href to the given url. Why a separate method? To easily test it as its
   * not trivial to mock object attributes in unit testing.
   *
   * @param url String - new location url
   */
  setLocationUrl(url) {
    if(window){
      window.location.href = url;
    }
  },

  getLocationUrl() {
    if (window && window.location) {
      return window.location.href;
    }
  },

  // Another window.location accessor, to make stubbing in tests easier.
  getLocationPathname() {
    if (window && window.location) {
      return window.location.pathname;
    }
  },
};
