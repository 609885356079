import _ from "lodash";
import Reflux from "reflux";
import Freezer from "freezer-js";
// ACTIONS
import BookSearchActionsFactory from "../react_actions/book_search_actions_factory";

const factory = function() {
  const state = new Freezer({ showLoadingSpinner: false,
                              showResultsContainer: false,
                              results: [],
                              query: "",
                              activeResult: null
  });
  const actions = BookSearchActionsFactory();

  const updateState = function(results) {
    const values = state.get();
    values.results.reset(results);
  };

  const store = Reflux.createStore({
    listenables: actions,
    getInitialState: state.get,
    getState: state.get,
    actions,

    initializeWith(data) {
      state.get().set("showLoadingSpinner", data.showLoadingSpinner)
        .set("showResultsContainer", data.showResultsContainer)
        .set("query", data.query)
        .set("activeResult", data.activeResult)
        .results.reset(data.results);
    },

    reset() {
      state.get().reset({});
    },

    setValue(key, value) {
      const stateValues = state.get();
      stateValues.set(key, value);
    },

    onSelectSpecificResult(bookId) {
      const newActiveResult = _.find(this.getState().results, (result) => result.bookId === bookId);
      this.setValue("activeResult", newActiveResult);
      this.trigger(this.getState());
    },

    onSetShowResultsContainer(value) {
      this.setValue("showResultsContainer", value);
      this.setValue("activeResult", null);
      this.trigger(this.getState());
    },

    onSelectNextResult(maxResults) {
      const results = this.getState().results;
      const currentIndex = _.indexOf(results, this.getState().activeResult);
      this.setValue("activeResult", results[(currentIndex + 1) % maxResults]);
      this.trigger(this.getState());
    },

    onSelectPreviousResult(maxResults) {
      const results = this.getState().results;
      const activeResult = this.getState().activeResult;
      const currentIndex = _.indexOf(results, activeResult);
      if (currentIndex === 0 || activeResult === null) {
        this.setValue("activeResult", results[maxResults - 1]);
      } else {
        this.setValue("activeResult", results[(currentIndex - 1) % maxResults]);
      }
      this.trigger(this.getState());
    },

    onSearchResultsFound(query, results) {
      updateState(results);
      this.setValue("query", query);
      this.setValue("showLoadingSpinner", false);
      this.setValue("activeResult", null);
      this.setValue("showResultsContainer", true);
      this.trigger(this.getState());
    },

    onSearch(searchValue, searchUrl) {
      this.setValue("showLoadingSpinner", true);
      this.trigger(this.getState());
      $j.ajax({
        url: searchUrl,
        data: { format: "json",
                q: searchValue },
        success: (resp) => {
          actions.searchResultsFound(searchValue, resp);
        },
      });
    },
  });

  return store;
};

export default factory;
