import { Component, PropTypes } from "react";
import NativeFlexAdBody from "./native_flex_ad_body";
import ShapeIcon from "../../shape_icon";
import { ICON_SIZES } from "../../../modules/shape_icon_values";
import NativeAdCaret from "./native_ad_caret";
import classNames from "classnames";
import NativeAdSponsoredLabel from "./native_ad_sponsored_label";

export class NativeFlexAd extends Component {

  constructor(props) {
    super(props);
  }

  getHeaderText() {
    return {
      __html: this.props.creative.headerText,
    };
  }

  renderCaret() {
    return <div className="googleNativeAd__caret">
      <NativeAdCaret
        helpLink={this.props.creative.helpLink}
        updateId={this.props.id}
        signedIn={this.props.signedIn}/>
    </div>;
  }

  isDesktopNewsfeed() {
    return this.props.isNewsfeed && !this.props.isMobile;
  }

  isMobileNewsfeed() {
    return this.props.isNewsfeed && this.props.isMobile;
  }

  renderIcon() {
    return <div className="gr-newsfeedItem__headerIcon gr-mediaBox__media gr-mediaBox__media--marginSmall">
      <ShapeIcon imageUrl={this.props.creative.defaultIcon}
        size={ICON_SIZES.MEDIUM}
        imageAlt="Sponsored Item Icon" border={true}/>
    </div>;
  }

  render() {
    const bodyClassNames = classNames({
      "gr-newsfeedItem__body nativeAd__newsfeedBody": this.isDesktopNewsfeed(),
    });
    const sponsoredLine = <NativeAdSponsoredLabel
      sponsoredBy={this.props.creative.sponsoredBy}
      sponsoredUrl={this.props.creative.sponsoredUrl} />;

    const bodyClasses = classNames( "gr-mediaBox", {
      "gr-newsfeedItem gr-mediaBox": this.isDesktopNewsfeed(),
      "googleNativeAd__item ": !this.isDesktopNewsfeed(),
      "feedItem feedItem--ad": this.isMobileNewsfeed(),
    });
    return <div className={bodyClasses} ref='nativeAd'>
      {this.isDesktopNewsfeed() && this.renderIcon()}
      <div className="u-marginBottomSmall">
        {this.renderCaret()}
        <div className="nativeAd__headerText">
          <div className="gr-newsfeedItem__headerText"
                  dangerouslySetInnerHTML={this.getHeaderText()} />
        </div>
        {this.props.creative.isSponsored ? sponsoredLine : null}
        <NativeFlexAdBody creative={this.props.creative} bodyClassNames={bodyClassNames} />
      </div>
    </div>;
  }
}

NativeFlexAd.propTypes = {
  id: PropTypes.string,
  creative: PropTypes.shape().isRequired,
  signedIn: PropTypes.bool,
  isNewsfeed: PropTypes.bool,
  isMobile: PropTypes.bool,
};

export default NativeFlexAd;
