import React from "react";
import _ from "lodash";
import GrComponentFactory from "../gr_component_factory";
import { trackNavEngageClick } from "../../modules/click_and_view_tracking";
import { MENU_LINK_TRACKING_PREFIX } from "../menu_link";
// COMPONENTS
import MenuLink from "../menu_link";

export default GrComponentFactory.createClass({
  displayName: "ProfileMenu",

  propTypes: {
    author: React.PropTypes.shape({
      id: React.PropTypes.number,
      editProfileUrl: React.PropTypes.string,
      profileUrl: React.PropTypes.string,
      dashboardUrl: React.PropTypes.string,
      listAGiveawayUrl: React.PropTypes.string,
    }),
    commentsUrl: React.PropTypes.string,
    discussionsUrl: React.PropTypes.string,
    editFavGenresUrl: React.PropTypes.string,
    groupInvitesCount: React.PropTypes.number,
    helpUrl: React.PropTypes.string,
    myFriendsUrl: React.PropTypes.string,
    myGroupsUrl: React.PropTypes.string,
    myQuotesUrl: React.PropTypes.string,
    myReadingChallengeUrl: React.PropTypes.string,
    myRecsUrl: React.PropTypes.string,
    name: React.PropTypes.string,
    pendingRecsCount: React.PropTypes.number,
    profileEditUrl: React.PropTypes.string,
    profileUrl: React.PropTypes.string,
    readingNotesUrl: React.PropTypes.string,
    signOutUrl: React.PropTypes.string,
  },

  isAuthor() {
    return !_.isUndefined(this.props.author);
  },

  groupInvitesMessage(){
    const invitesCount = this.props.groupInvitesCount;
    if (invitesCount === 0) {
      return "No invitations";
    } else if (invitesCount === 1) {
      return "1 invitation";
    } else {
      return `${invitesCount  } invitations`;
    }
  },

  renderGroupInvites() {
    if(this.props.groupInvitesCount > 0) {
      return (<span className="gr-smallAlert u-marginLeftXSmall">
                           {this.groupInvitesMessage()}
                         </span>);
    }
  },

  renderPendingRecs() {
    if(this.props.pendingRecsCount > 0) {
      return (<span className="gr-smallAlert u-marginLeftXSmall">
                          {`${this.props.pendingRecsCount} new`}
                         </span>);
    }
  },

  renderAuthorDashboardLink() {
    if (this.isAuthor()) {
      return (<MenuLink href={this.props.author.dashboardUrl}
                linkText={"Author Dashboard"}
                linkClass="siteHeader__subNavLink" />);
    }
  },

  renderListAGiveawayLink() {
    if (this.isAuthor()) {
      return (<MenuLink href={this.props.author.listAGiveawayUrl}
                        linkText={"List a Giveaway"}
                        linkClass="siteHeader__subNavLink" />);
    }
  },

  render() {
    const editProfileUrl = !this.isAuthor() ? this.props.profileEditUrl : this.props.author.editProfileUrl;

    return (
      <ul>
        <MenuLink linkText={"Profile"}>
          <a href={this.props.profileUrl}
             className="siteHeader__subNavLink"
             onClick={(e) => trackNavEngageClick(e, `${MENU_LINK_TRACKING_PREFIX}_Profile`)}>
            Profile
          </a>
        </MenuLink>
        {this.renderAuthorDashboardLink()}
        {this.renderListAGiveawayLink()}
        <MenuLink href={this.props.myFriendsUrl}
                  linkText={"Friends"}
                  linkClass="siteHeader__subNavLink" />
        <MenuLink linkText={"Groups"}>
          <a href={this.props.myGroupsUrl}
             className="siteHeader__subNavLink"
             onClick={(e) => trackNavEngageClick(e, `${MENU_LINK_TRACKING_PREFIX}_MyGroups`)}>
              Groups
             {this.renderGroupInvites()}
          </a>
        </MenuLink>
        <MenuLink href={this.props.discussionsUrl}
                  linkText={"Discussions"}
                  linkClass="siteHeader__subNavLink" />
        <MenuLink href={this.props.commentsUrl}
                  linkText={"Comments"}
                  linkClass="siteHeader__subNavLink" />
        <MenuLink href={this.props.myReadingChallengeUrl}
                  linkText={"Reading Challenge"}
                  linkClass="siteHeader__subNavLink" />
        <MenuLink href={this.props.readingNotesUrl}
                  linkText={"Kindle Notes & Highlights"}
                  linkClass="siteHeader__subNavLink" />
        <MenuLink href={this.props.myQuotesUrl}
                  linkText={"Quotes"}
                  linkClass="siteHeader__subNavLink" />
        <MenuLink href={this.props.editFavGenresUrl}
                  linkText={"Favorite genres"}
                  linkClass="siteHeader__subNavLink" />
        <MenuLink linkText={"Friends' recommendations"}>
          <a href={this.props.myRecsUrl}
             className="siteHeader__subNavLink"
             onClick={(e) => trackNavEngageClick(e, `${MENU_LINK_TRACKING_PREFIX}_FriendsRecommendations`)}>
            Friends’ recommendations
            {this.renderPendingRecs()}
          </a>

        </MenuLink>
        <MenuLink href={editProfileUrl}
                  linkText={"Account settings"}
                  linkClass="siteHeader__subNavLink u-topGrayBorder" />
        <MenuLink href={this.props.helpUrl}
                  linkText={"Help"}
                  linkClass="siteHeader__subNavLink" />
        <MenuLink href={this.props.signOutUrl}
                  linkText={"Sign out"}
                  linkClass="siteHeader__subNavLink"
                  method="POST" />
      </ul>
    );
  },
});
