/* Include this mixin for component support of multiple stores.

   Expects the given store to be a factory that includes an instanced version
   of component actions.

   See BookSearchStore and BookSearchActions for an example.
*/

export default function(storeFactory) {
  return {
    getInitialState() {
      this.store = storeFactory();
      return this.store.getInitialState();
    },
    componentWillMount() {
      this.actions = this.store.actions;
      this.unsubscribe = this.store.listen(this.onStoreChange);
    },
    componentWillUnmount() {
      this.unsubscribe();
    },
    onStoreChange(state) {
      this.setState(state);
    },
  };
}
