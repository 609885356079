/*
StringCounter React component
Props:
counterType: count by "character" or by "word"
maxLength: max text length, inclusive
text: string to count
warnLength: number of elements remaining to display a counter
  - ex. warnLength: 100 //displays at 100 elements remaining
  - if omitted, will always show counter

* becomes visible: maxLength - text.length <= warnLength
* becomes (red) alert: text.length > maxLength
*/

import { PropTypes } from "react";
import GrComponentFactory from "../gr_component_factory";
import { astralLength, countWords } from "../../modules/string_helper";
import pluralize from "pluralize";

export default GrComponentFactory.createClass({
  displayName: "StringCounter",

  propTypes: {
    counterType: PropTypes.oneOf(["character", "word"]).isRequired,
    maxLength: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
    warnLength: PropTypes.number,
  },

  renderWarning(diff) {
    let cname;
    if (diff < 0) {
      cname = "gr-smallAlert";
    }

    return(
      <span className={ cname } role="alert">
        { diff } { pluralize(this.props.counterType, diff) } left
      </span>
    );
  },

  countType() {
    if (this.props.counterType === "character") {
      return astralLength(this.props.text);
    } else if (this.props.counterType === "word") {
      return countWords(this.props.text);
    } else {
      return null;
    }
  },

  render() {
    const textLength = this.countType(this.props.text);

    const diff = this.props.maxLength - textLength;
    const shouldRender = this.props.warnLength ? diff <= this.props.warnLength : true;
    if (shouldRender) {
      return <div>{ this.renderWarning(diff) }</div>;
    } else {
      return null;
    }
  },
});
