import LikeStore from "../../react_stores/like_store";
import CommentsStore from "../../react_stores/comments_store";

import CommentForm from "../../react_components/comment/comment_form";
import Comments from "../../react_components/comment/comments";
import LikeButton from "../../react_components/like/like_button";
import LikeInformation from "../../react_components/like/like_information";
import LikeSimpleFooter from "../../react_components/like/like_simple_footer";

export const OldHomepageComponents = {
  CommentForm,
  Comments,
  LikeButton,
  LikeInformation,
  LikeSimpleFooter,
};

export const OldHomepageStores = {
  CommentsStore,
  LikeStore,
};
