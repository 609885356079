/**
 * Helper module for making AJAX posts to track_controller from React
 */

import { httpPost } from "./ajax/ajax_helper";
import envUtils from "../modules/env_utils";

export const TRACKING_URLS = {
  CLICK: "/track/track_click",
  MOBILE_SETTINGS: "/track/track_mobile_settings",
};

/*
 * Track event via AJAX post to the tracking url
 * Format the type and metric labels to comply with the action's expectation
 * The url param value must be a url in TRACKING_URLS
 */
function track(type, url, metric) {
  switch(url) {
    case TRACKING_URLS.CLICK:
      httpPost(url, {
        data: {
          feature: `${type}_${metric}`,
        },
      });
      break;
    case TRACKING_URLS.MOBILE_SETTINGS:
      httpPost(url, {
        data: {
          type,
          metric,
        },
      });
      break;
    default:
      break;
  }
}

export function trackClick(url, metric) {
  track("click", url, metric);
}

export function trackView(url, metric) {
  track("view", url, metric);
}

/*
 * Track engagement with header/nav feature
 * Namespace nav engagement metrics with Nav prefix
 * Do not use for onClick events; use trackNavEngageClick
 */
export function trackNavEngage(feature) {
  track("Nav", TRACKING_URLS.CLICK, feature);
}

/*
 * Track clicks on header/nav feature
 * Handle link targets, so AJAX is fired before navigation
 */
export function trackNavEngageClick(e, feature) {
  if (e && e.target) {
    const target = e.target;
    if (target.tagName === "A" && !e.defaultPrevented) {
      e.preventDefault();
      trackNavEngage(feature);
      envUtils.setLocationUrl(target.href);
    } else {
      trackNavEngage(feature);
    }
  }
}
