import GrComponentFactory from "./gr_component_factory";
import TruncatedHtml from "./user_content/truncated_html";
import GrPropTypes from "./shared/gr_prop_types";
import classnames from "classnames";

export default GrComponentFactory.createClass({
  displayName: "BookDescription",

  propTypes: {
    bookDescription: GrPropTypes.bookDescription().isRequired,
    className: React.PropTypes.string,
    bookDescriptionTargetingData: React.PropTypes.object,
  },

  getDefaultProps() {
    return {
      bookDescription: {
        html: "",
        truncated: false,
        fullContentUrl: "",
      },
    };
  },

  render() {
    const cx = classnames(this.props.className, "gr-book__description");
    const description = this.props.bookDescription;
    return (
      <div className={cx}>
        {
          description.html ?
            <TruncatedHtml {...description}
                          fullContentText="Continue reading"
                          bookDescriptionTargetingData={this.props.bookDescriptionTargetingData}/>
            : null
        }
        {this.props.children}
      </div>
    );
  },
});
