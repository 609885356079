import React from "react";
import GrComponentFactory from "./gr_component_factory";
import classNames from "classnames";
import FavoriteGenresFormActions from "../react_actions/favorite_genres_form_actions";

export default GrComponentFactory.createClass({
  displayName: "GenreCheckbox",

  propTypes: {
    genre: React.PropTypes.string,
    isFavorited: React.PropTypes.bool.isRequired,
  },

  onCheckboxChange() {
    if (this.props.isFavorited) {
      FavoriteGenresFormActions.deselectFavoriteGenre(this.props.genre);
    } else {
      FavoriteGenresFormActions.selectFavoriteGenre(this.props.genre);
    }
  },

  render() {
    const genreLabelClasses =
      classNames("gr-genresForm__genreLabel",
                 { "gr-genresForm__genreLabel--selected" : this.props.isFavorited });
    return (
      <label className={genreLabelClasses}>
        <input name={`favorites[${this.props.genre}]`} type="hidden" value="false" />
        <input name={`favorites[${this.props.genre}]`} type="checkbox" value="true"
          data-genre={this.props.genre} ref="checkbox"
          defaultChecked={this.props.isFavorited} onChange={this.onCheckboxChange} />
        <span className="u-verticalAlignMiddle u-marginLeftTiny">
          { this.props.genre }
        </span>
      </label>
    );
  },
});
