import React from "react";
import GrComponentFactory from "../gr_component_factory";
import NavSearch from "../header/nav_search";
import SearchToggle, { SEARCH_TOGGLE_TYPES } from "../search/search_toggle";

export default GrComponentFactory.createClass({
  displayName: "SearchMobileNav",

  propTypes: {
    autocompleteUrl: React.PropTypes.string.isRequired,
    searchPath: React.PropTypes.string.isRequired,
  },

  render() {
    return (
      <div className="siteHeader__primaryNavSeparateLine siteHeader__primaryNavSeparateLine--search">
        <NavSearch {...this.props}
                   autoFocus={true}
                   bemModifiers="primaryNavSeparateLine" />
        <SearchToggle toggleType={SEARCH_TOGGLE_TYPES.CANCEL}/>
      </div>
    );
  },
});
