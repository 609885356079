// Share Preview card for topics

import ShareDialogPreview from "./share_dialog_preview";
import { PropTypes } from "react";
import GrComponentFactory from "../../gr_component_factory";
import TruncatedHtml from "../../user_content/truncated_html";

export default GrComponentFactory.createClass({
  displayName: "ShareDialogTopicPreview",

  propTypes: {
    topicTitle: PropTypes.string.isRequired,
    groupTitle: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    description: PropTypes.string,
  },

  render() {
    const secondaryText = `${this.props.groupTitle} - Discussion`;
    return(
      <ShareDialogPreview
        primaryText={this.props.topicTitle}
        secondaryText={secondaryText}
        imageUrl={this.props.imageUrl}>
        <div className="sharePreview__description">
          <TruncatedHtml html={this.props.description} truncated={false} />
        </div>
      </ShareDialogPreview>
    );
  },
});
