// Google ad store connection to native ads
// This component calls request native ad which pull the native ad id from the iframe
// and requests the native ad from ajax_native_ad in dfp_controller

import GoogleAdsStore from "../../../react_stores/google_ads_store";
import GrComponentFactory from "../../gr_component_factory";
import Reflux from "reflux";
import NativeAdBody from "./native_ad_body";
import { requestCreativeForAdSlot, lazyLoadOnView } from "../../../modules/ads/google_ad_helper";
import classNames from "classnames";

export default GrComponentFactory.createClass({
  displayName: "GoogleNativeAd",

  mixins: [Reflux.connectFilter(GoogleAdsStore,
                                "googleAdsStore",
                                function (adsStoreState) {
                                  return adsStoreState.nativeAds[this.props.adId];
                                }
  )],

  propTypes: {
    adId: React.PropTypes.string.isRequired,
    trackingOptions: React.PropTypes.shape({
      enableTracking: React.PropTypes.bool,
      adId: React.PropTypes.string,
    }).isRequired,
    className: React.PropTypes.string,
    signedIn: React.PropTypes.bool,
    allowBehavioralTargeting: React.PropTypes.bool,
    isMobile: React.PropTypes.bool,
    isNewsfeed: React.PropTypes.bool,
  },

  getDefaultProps() {
    return {
      isMobile: false,
      isNewsfeed: true,
      signedIn: true,
    };
  },

  getStoreState() {
    return this.state.googleAdsStore;
  },

  componentDidMount() {
    if (this.getStoreState() && !this.getStoreState().creative) {
      const params = {
        googleHookSelector: this.refs.googleHook,
        creativeSelector: this.getStoreState().creativeSelector,
        dimensions: this.getStoreState().dimensions,
        isNativeAd: this.getStoreState().isNativeAd,
        lazyLoadedNewsfeedAd: this.getStoreState().lazyLoadedNewsfeedAd,
        path: this.getStoreState().path,
        isLazyLoaded: this.getStoreState().isLazyLoaded,
      };
      if (this.getStoreState().lazyLoadedNewsfeedAd || this.getStoreState().isLazyLoaded) {
        lazyLoadOnView(function(event) {
          if (event.target.id === this.adId) {
            requestCreativeForAdSlot(this.adId, this.params);
          }
        }.bind({ adId: this.props.adId, params }));
      } else {
        requestCreativeForAdSlot(this.props.adId, params);
      }
    }
  },

  render() {
    if (this.getStoreState()) {
      // IMPORTANT: We must keep the googleHook div visible in order to preserve
      //            viewable impressions!
      const googleHookClasses = classNames("googleNativeAd__googleHook", this.getStoreState().className, "lazyload");
      const nativeAdClasses = classNames("googleNativeAd", this.props.className);
      const nativeAdBody = this.getStoreState().creative ?
        <NativeAdBody creative={this.getStoreState().creative}
          adId={this.props.adId}
          deviceType={this.getStoreState().deviceType}
          signedIn={this.props.signedIn}
          allowBehavioralTargeting={this.props.allowBehavioralTargeting}
          isMobile={this.props.isMobile}
          isNewsfeed={this.props.isNewsfeed} /> : null;
      return(
        <div className={nativeAdClasses}>
          <div className={googleHookClasses} id={this.props.adId} ref="googleHook" >
            {nativeAdBody}
          </div>
        </div>
      );
    }
    else {
      return null;
    }
  },
});
