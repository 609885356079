import _ from "lodash";
import GrComponentFactory from "../gr_component_factory";
import Reflux from "reflux";
import Header from "./header";
import CurrentUserStore from "../../react_stores/current_user_store";
import FavoriteGenresStore from "../../react_stores/favorite_genres_store";

export default GrComponentFactory.createClass({
  displayName: "HeaderStoreConnector",

  mixins: [Reflux.connect(CurrentUserStore, "currentUser"),
           Reflux.connect(FavoriteGenresStore, "favoriteGenres")],

  render() {
    const currentUser = this.state.currentUser.currentUser;
    const signedIn = !_.isEmpty(currentUser);
    const props = {
      adId: this.state.favoriteGenres.adId,
      signedIn,
      genres: this.state.favoriteGenres.allGenres,
      favoriteGenres: this.state.favoriteGenres.favoriteGenres,
    };
    if (signedIn) {
      _.merge(props, currentUser);
    }
    _.merge(props, this.props);

    return (
      <Header {...props} />
    );
  },
});
