/**
 * gr_image_registry.js.erb stores Rails-generated image paths in window.
 * This module serves as an interface to those image paths; JavaScript code under app/gulp
 * should import this module rather than try to access `window` directly.
 *
 * refer to:
 * /rails-root/app/assets/javascripts/shared_all_formats/gr_image_registry.js.erb
 * @returns {*}
 */

export function getImages() {
  // window.GrImageRegistry is not available to server side rendering and throws
  // JS errors when accessing images from the object. Returning an empty object to avoid those
  // server side errors.
  return window.GrImageRegistry || {};
}
