/*
React Component: ShareableLinkButton

Modal with shareable link and description. Includes a copy link button
for copying shareable link to clipboard.
*/

import { PropTypes } from "react";
import GrComponentFactory from "../gr_component_factory";
import ModalTrigger from "../modal_trigger";
import Modal from "../modal";
import Spinner from "../spinner";
import { httpRequest } from "../../modules/ajax/ajax_helper";
import FlashMessage from "../flash_message";

export default GrComponentFactory.createClass({
  displayName: "ShareableLinkbutton",

  propTypes: {
    getShareableLinkURL: PropTypes.string.isRequired,
    resourceType: PropTypes.string.isRequired,
    buttonText: PropTypes.string,
  },

  getShareableLinkModalId() {
    return "shareableLinkModal";
  },

  getInitialState() {
    return {
      error: false,
      fetching: false,
      copiedToClipboard: false,
      shareableLink: null,
      ttl: null,
    };
  },

  // Expects a JSON response with a link(string) and a ttl(seconds)
  requestLink() {
    httpRequest(this.props.getShareableLinkURL, {
      method: "GET",
    }).done(({ link, ttl }) => {
      this.setState({ shareableLink: link, ttl });
    }).fail(() => {
      this.setState({ error: true });
    }).always(() => {
      this.setState({ fetching: false });
    });
  },

  // Initially requests a link. Subsequent modal triggers keep fetched link.
  handleClick() {
    if (this.state.shareableLink) {
      return null;
    } else {
      return this.setState({ fetching: true, error: false }, this.requestLink);
    }
  },

  copyLinkToClipboard() {
    this.selectShareableLinkInput();
    document.execCommand("copy");
    return this.setState({ copiedToClipboard: true });
  },

  selectShareableLinkInput() {
    return this.shareableLinkInput.select();
  },

  getButtonText() {
    return this.props.buttonText || "Get Link"
  },

  renderError() {
    if (this.state.error) {
      const flashMessage = "Oops, something went wrong. Please try again.";
      return(
        <FlashMessage category="error" message={flashMessage} />
      );
    } else {
      return null;
    }
  },

  renderModal() {
    if (!this.state.shareableLink) {
      return null;
    } else {
      const expireTime = parseInt(this.state.ttl / 86400); // seconds to days
      return (
        <Modal id={this.getShareableLinkModalId()}
               centered={true}
               bemModifiers="share">
          <div className="shareableLinkModalContainer">
            <div className="u-paddingBottomMedium">
              <h2 className="gr-h2">
                Share this {this.props.resourceType} link with others
              </h2>
            </div>

            <div className="u-fullWidth u-paddingBottomTiny">
              <label htmlFor="inviteUrl">
                Invite Link:
              </label>
              <input className="shareableLinkInput"
                     ref={(input) => { this.shareableLinkInput = input; }}
                     readOnly={true}
                     id="inviteUrl"
                     type="text"
                     value={this.state.shareableLink}
                     onClick={this.selectShareableLinkInput} />
            </div>

            <div className="u-paddingBottomMedium">
              {this.state.ttl &&
                `This link will expire after ${expireTime} days. `}
              {this.state.copiedToClipboard && "Link copied to clipboard!"}
            </div>

            <div>
              <button type="button"
                      className="gr-button"
                      onClick={this.copyLinkToClipboard}>
                Copy link to clipboard
              </button>
            </div>
          </div>
        </Modal>
      );
    }
  },

  renderModalTrigger() {
    if (this.state.fetching) {
      return <Spinner isSmall={true} />;
    } else {
      return (
        <ModalTrigger id="shareableLinkModal">
          <button type="button"
                  className="gr-button"
                  onClick={this.handleClick}>
            {this.getButtonText()}
          </button>
        </ModalTrigger>
      );
    }
  },

  render() {
    return (
      <div className="u-paddingTopBottomTiny">
        <div>
          {this.renderModalTrigger()}
          {this.renderModal()}
          {this.renderError()}
        </div>
      </div>
    );
  },
});
