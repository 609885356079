import React from "react";
import GrComponentFactory from "../gr_component_factory";
import GrPropTypes from "../shared/gr_prop_types";
import classnames from "classnames";
import AuthorBadge from "../author/author_badge";

export default GrComponentFactory.createClass({
  displayName: "BookSearchResult",

  propTypes: {
    book: GrPropTypes.book({ qid: React.PropTypes.string, rank: React.PropTypes.number }).isRequired,
    onChooseResult: React.PropTypes.func.isRequired,
  },

  render(){
    const linkClasses = classnames("gr-mediaFlexbox",
      "gr-book--navbar",
      "gr-hyperlink",
      "gr-hyperlink--naked",
      "gr-hyperlink--noUnderline",
      "gr-bookSearchResults__result");
    // Note: We should be adding a url to the href attribute on these links elements, but we can't
    //       because there are JS handlers up and down this component chain that hijack normal link
    //       element behavior. That should not be - this whole thing should be re-engineered to make
    //       better use of default browser behavior.
    return <a className={linkClasses}
              href="#"
              aria-label="Search result"
              onClick={ () => { this.props.onChooseResult(this.props.book); }}>
             <img className="gr-mediaFlexbox__media"
                  src={this.props.book.imageUrl}
                  alt="" />
             <div className="gr-mediaFlexbox__desc">
               <div className="gr-book__title gr-book__title--navbar u-defaultType">
                 {this.props.book.title}
               </div>
               <div className="gr-book__author gr-book__author--navbar">
                 by {this.props.book.author.name}
                 <AuthorBadge {...this.props.book.author} />
               </div>
             </div>
           </a>;
  },
});
