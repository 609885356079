import Reflux from "reflux";

import GrComponentFactory from "../gr_component_factory";
import GrPropTypes from "../shared/gr_prop_types";
import Modal from "../modal";
import ModalTrigger from "../modal_trigger";
import ModalStore from "../../react_stores/modal_store";
import NewsfeedActions from "../../react_actions/newsfeed_actions";
import ReportWrongBookButton from "./report_wrong_book_button";
import ReportWrongBookForm from "./report_wrong_book_form";
import WrongBookReportAcknowledgment from "./wrong_book_report_acknowledgment";
import UserAgentCapabilities from "../../react_stores/user_agent_capabilities_store";
import { TRACKED_CLASSES } from "../../modules/ads/click_tracking_helper";

export const shouldDisplayPreviewBookButton = (book, userAgentCapabilities) => {
  if (userAgentCapabilities && userAgentCapabilities.touch === true) {
    return false;
  }
  return !!book.kcrPreviewUrl;
};

export default GrComponentFactory.createClass({
  displayName: "PreviewBook",

  mixins: [Reflux.connect(ModalStore, "modalStore"),
           Reflux.connect(UserAgentCapabilities, "userAgentCapabilities")],

  propTypes: {
    book: GrPropTypes.book().isRequired,
  },

  getDefaultProps() {
    return {
      trackingOptions: TRACKED_CLASSES.preview_book,
    };
  },

  getInitialState() {
    return {
      previewViewed: false,
      uniqueId: Math.random(1000000),
    };
  },

  getReportWrongBookModalId() {
    return `report-${  this.props.book.kcrPreviewUrl  }${this.state.uniqueId}`;
  },

  getPreviewModalId() {
    return this.props.book.kcrPreviewUrl + this.state.uniqueId;
  },

  getAcknowledgmentModalId() {
    return `acknowledge-${  this.props.book.kcrPreviewUrl  }${this.state.uniqueId}`;
  },

  openBookPreview() {
    NewsfeedActions.previewBook();
    this.setState({ previewViewed: true });
  },

  render() {
    let previewBookModalContents = null;
    let wrongBookForm = null;
    let acknowledgmentModal = null;

    const previewModalId = this.getPreviewModalId();
    const reportWrongBookModalId = this.getReportWrongBookModalId();
    const acknowledgmentModalId = this.getAcknowledgmentModalId();

    if (this.state.modalStore.currentActiveModal === previewModalId) {
      previewBookModalContents = (
        <div className="previewBook__container">
          <div className="previewBook__topBanner">
            <div className="previewBook__headerText">
              Preview -&nbsp;
              <cite>
                {this.props.book.title}
              </cite>
            </div>
            <ReportWrongBookButton book={this.props.book} modalId={reportWrongBookModalId}/>
          </div>
          <iframe className="previewBook__kcrPreview" src={this.props.book.kcrPreviewUrl} />
        </div>
      );
    }

    if (this.state.previewViewed) {
      wrongBookForm = (
        <ReportWrongBookForm book={this.props.book}
                             modalId={reportWrongBookModalId}
                             acknowledgmentModalId={acknowledgmentModalId}/>
      );
      acknowledgmentModal = (
        <WrongBookReportAcknowledgment modalId={acknowledgmentModalId}/>
      );
    }
    if (shouldDisplayPreviewBookButton(this.props.book, this.state.userAgentCapabilities)) {
      return (
        <span className="previewBook">
        <ModalTrigger id={previewModalId}>
          <button aria-label={`Preview ${this.props.book.title}`}
                  className="gr-buttonAsLink
                             previewBook__openPreview"
                  onClick={this.openBookPreview}>
              Preview Book
          </button>
        </ModalTrigger>
          {wrongBookForm}
          {acknowledgmentModal}
        <Modal id={previewModalId} bemModifiers="preview">
          {previewBookModalContents}
        </Modal>
      </span>
      );
    } else {
      return null;
    }
  },
});
