import GrComponentFactory from "../gr_component_factory";
import ShapeIcon from "../shape_icon";
import { ICON_SIZES } from "../../modules/shape_icon_values";

export default GrComponentFactory.createClass({
  displayName: "WelcomeAnnouncement",

  propTypes: {
    imageUrl: React.PropTypes.string.isRequired,
  },

  renderIcon() {
    return <ShapeIcon
      imageUrl={this.props.imageUrl}
      size={ICON_SIZES.MEDIUM}
      imageAlt="Welcome to Goodreads"
      border={true}/>;
  },

  render() {
    return (
      <div className="siteAnnouncement siteAnnouncement--welcome">
        <div className="siteAnnouncement__welcomeIcon">
          {this.renderIcon()}
        </div>
        <div className="siteAnnouncement__welcomeBody">
          <h5 className="siteAnnouncement__announcementText">Welcome to Goodreads</h5>
          <span>
            Meet your favorite book, find your reading community, and manage your reading life.
          </span>
        </div>
      </div>
    );
  },
});
