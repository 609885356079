import Reflux from "reflux";
import Freezer from "freezer-js";
import ModalActions from "../react_actions/modal_actions";

const state = new Freezer({});

const getState = function() {
  return state.get();
};

const store = Reflux.createStore({
  listenables: ModalActions,

  getInitialState: getState,

  getState,

  initializeWith(data) {
    state.get().reset(data);
  },

  onOpenModal(modalId) {
    state.get().set("currentActiveModal", modalId);
    this.notifyListeners();
  },

  onCloseModal() {
    state.get().set("currentActiveModal", null);
    this.notifyListeners();
  },

  notifyListeners() {
    this.trigger(getState());
  },

  reset() {
    state.get().reset({});
  },
});

export default store;
