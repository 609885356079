// React Component for blog preview card in share dialog

import { PropTypes } from "react";
import GrComponentFactory from "../../gr_component_factory";
import TruncatedHtml from "../../user_content/truncated_html";

export default GrComponentFactory.createClass({
  displayName: "ShareDialogBlogPreview",

  propTypes: {
    title: PropTypes.string.isRequired,
    imageUrl: PropTypes.string,
    description: PropTypes.string.isRequired,
  },

  // Because of unique template, cannot reuse ShareDialogPreview
  render() {
    return(
      <div className="gr-mediaFlexbox shareModal__previewContainer shareModal__previewContainer--blog">
        <div className="gr-mediaFlexbox__media sharePreview__image sharePreview__image--blog">
          <img
            className="gr-mediaFlexbox__media sharePreview__imageFullWidth"
            src={this.props.imageUrl}
            alt={this.props.title}
          />
        </div>

        <div className="gr-mediaFlexbox__desc">
          <div className="gr-h3 gr-h3--serif gr-h3--noMargin">
            {this.props.title}
          </div>

          <div className="u-marginTopXSmall">
            <div className="sharePreview__description">
              <TruncatedHtml html={this.props.description} truncated={false} />
            </div>
          </div>
        </div>
      </div>
    );
  },
});
