/*
  This store allows users to make books as not interested. Not interested
  books will not show up in native ad slots
*/

import { merge } from "lodash";
import Reflux from "reflux";
import Freezer from "freezer-js";
import { httpPost, httpDelete } from "../modules/ajax/ajax_helper";

import UserNotInterestedActions from "../react_actions/user_not_interested_actions";

/*
  A book with ID 0 is used to set default state. This book is never modified, it
  is only used to created other books with individual states as creatives appear
  from dfp.
*/
const DEFAULT_STATE = { default: { isFlashMessage: false, isClosed: false } };
const state = new Freezer(DEFAULT_STATE);

const getState = () => state.get();

const reset = (data) => {
  getState().reset(merge({}, DEFAULT_STATE, data));
};

export default Reflux.createStore({
  listenables: [UserNotInterestedActions],

  getState,
  getInitialState: getState,

  reset() {
    reset({});
  },

  setDataForKey(bookId, data) {
    const newState = {};
    newState[bookId] = data;
    getState().set(merge({}, getState(), newState));
  },

  onAddBookIDToState(bookId){
    this.setDataForKey(bookId, getState().default);
    this.trigger(getState());
  },

  onIsFlashMessageForBook(bookId) {
    getState()[bookId].isFlashMessage;
  },

  onCloseFlashMessage(bookId) {
    this.setDataForKey(bookId, { isClosed: true });
    this.trigger(getState());
  },

  userNotInterestedHttpPost(bookId) {
    return httpPost("/user_not_interested_works", {
      data: {
        user_not_interested_work: {
          book_id: bookId,
          source: "react",
        },
      },
    });
  },

  onUserNotInterested(bookId) {
    this.userNotInterestedHttpPost(bookId).done(() => {
      this.setDataForKey(bookId, { isFlashMessage: true });
      this.trigger(getState());
    }).fail(() => {
      window.alert("There was an error marking this book as not interested, please try again.");
    });
  },

  undoUserNotInterestedHttpDelete(bookId) {
    return httpDelete("/user_not_interested_works/undo", {
      data: {
        user_not_interested_work: { book_id: bookId },
      },
    });
  },

  onUndoUserNotInterested(bookId) {
    this.undoUserNotInterestedHttpDelete(bookId).done(() => {
      this.setDataForKey(bookId, { isFlashMessage: false });
      this.trigger(getState());
    }).fail(() => {
      window.alert("There was an error undoing this action, please try again.");
    });
  },
});
