import React from "react";
import GrComponentFactory from "./gr_component_factory";

export default GrComponentFactory.createClass({
  displayName: "SponsoredFooter",

  propTypes: {
    sponsoredName: React.PropTypes.string,
  },

  renderSponsoredName() {
    if (this.props.sponsoredName) {
      return <span className="featuredContentModule__sponsoredName">{this.props.sponsoredName}</span>;
    }
  },

  render() {
    const footerClasses = "featuredContentModule__sponsoredFooter";
    const sponsoredLabel = this.props.sponsoredName ? "Sponsored by " : "Sponsored";
    return <div className={footerClasses}>
      <span className="featuredContentModule__sponsoredBadgeIcon"/>
      <span className="featuredContentModule__sponsoredLabel">{sponsoredLabel}</span>
      {this.renderSponsoredName()}
    </div>;
  },
});
