import GrComponentFactory from "./gr_component_factory";
import GrPropTypes from "./shared/gr_prop_types";
import Book, { VALID_BOOK_SIZES } from "./book";
import WantToReadButton from "./want_to_read/want_to_read_button";
import { random, values } from "lodash";
import CommunityRating from "./community_rating";

export default GrComponentFactory.createClass({
  displayName: "BookWithWTRButton",

  propTypes: {
    book: GrPropTypes.book().isRequired,
    body: React.PropTypes.element,
    bookSize: React.PropTypes.oneOf(values(VALID_BOOK_SIZES)),
    bookClassName: React.PropTypes.string,
    responsiveWtrButton: React.PropTypes.bool,
  },

  getDefaultProps() {
    return {
      bookSize: VALID_BOOK_SIZES.LARGE,
      responsiveWtrButton: false,
    };
  },

  renderBookBody() {
    return this.props.body ? this.props.body : null;
  },

  renderCommunityRating() {
    const book = this.props.book;
    return(
      <CommunityRating rating={parseFloat(book.avgRating)}
                       displayAvg={false}
                       displayAvgRatingText={false}
                       ratingsCount={book.ratingsCount}
                       size={CommunityRating.ICON_SIZES.MEDIUM} />);
  },

  renderWtrButton() {
    return <WantToReadButton
      bookTitle={this.props.book.title}
      uniqueId={random(1000000).toString()}
      bookId={this.props.book.bookId}
      withModalShelvingMenu={this.props.responsiveWtrButton}
      showStars={!this.props.responsiveWtrButton} />;
  },

  render() {
    return (<Book className={this.props.bookClassName} {...this.props.book} size={this.props.bookSize}>
            {this.props.responsiveWtrButton && this.renderCommunityRating()}
            {this.renderWtrButton()}
            {this.renderBookBody()}
            </Book>);
  },
});
