import classNames from "classnames";

export const DEFAULT_SHELVES = {
  READ: {
    name: "read",
    displayName: "Read",
  },
  CURRENTLY_READING: {
    name: "currently-reading",
    displayName: "Currently Reading",
  },
  WANT_TO_READ: {
    name: "to-read",
    displayName: "Want to Read",
  },
};

export const SHELF_TYPES = {
  DEFAULT: "defaultShelves",
  CUSTOM_EXCLUSIVE: "customExclusiveShelves",
  NONEXCLUSIVE: "nonExclusiveShelves",
};

export const getExclusiveShelfType = (shelfName) => {
  if (shelfName === DEFAULT_SHELVES.READ.name
      || shelfName === DEFAULT_SHELVES.CURRENTLY_READING.name
      || shelfName === DEFAULT_SHELVES.WANT_TO_READ.name) {
    return SHELF_TYPES.DEFAULT;
  } else {
    return SHELF_TYPES.CUSTOM_EXCLUSIVE;
  }
};

// returns check icon style that matches exclusive shelf
export const checkIconClassnames = (shelfName) => classNames({
  "wantToReadButton__checkIcon--toRead": shelfName === DEFAULT_SHELVES.WANT_TO_READ.name,
  "wantToReadButton__checkIcon--currentlyReading": shelfName === DEFAULT_SHELVES.CURRENTLY_READING.name,
  "wantToReadButton__checkIcon--read": shelfName === DEFAULT_SHELVES.READ.name,
  "wantToReadButton__checkIcon--other": shelfName && getExclusiveShelfType(shelfName) === SHELF_TYPES.CUSTOM_EXCLUSIVE,
});
