import _ from "lodash";
import Reflux from "reflux";
import Freezer from "freezer-js";
import FeaturedGenresActions from "../react_actions/featured_genres_actions";
import ShelvingsActions from "../react_actions/shelvings_actions";
import FavoriteGenresStore from "../react_stores/favorite_genres_store";

const state = new Freezer({});

const getState = function() {
  return state.get();
};

const store = Reflux.createStore({
  listenables: FeaturedGenresActions,

  init() {
    FavoriteGenresStore.listen(() => {
      FeaturedGenresActions.requestFeaturedBooks();
    });
  },

  initializeWith(featuredGenreBookInfo) {
    state.get().reset({ featuredGenreBookInfo });
  },

  getInitialState: getState,

  getState,

  onRequestFeaturedBooks() {
    if (_.isEmpty(FavoriteGenresStore.getState().favoriteGenres)) {
      this.saveNewFeaturedBooks([]);
      return;
    }
    if (!getState().requestedFeaturedBooksBefore) {
      $j.get("/genres/featured_books").done((resp) => {
        this.saveNewFeaturedBooks(resp.genres);
        ShelvingsActions.addShelvings(resp.shelvings);
      }).fail((resp) => {
        FeaturedGenresActions.displayError(resp);
      });
      getState().set("requestedFeaturedBooksBefore", true);
    }
  },

  onTrackMenuImpression() {
    $j.get("/dfp/browse_menu_impression");
  },

  saveNewFeaturedBooks(featuredGenreBookInfo) {
    getState().set("featuredGenreBookInfo", featuredGenreBookInfo);
    this.notifyListeners();
  },

  onDisplayError() {
    getState().set("errorMessage", "Error loading featured books in genre.");
    this.notifyListeners();
  },

  notifyListeners() {
    this.trigger(getState());
  },

  reset() {
    getState().reset({});
  },
});

export default store;
