import { ImpressionCode } from "../../react_stores/google_ads_store";
import GoogleAdsActions from "../../react_actions/google_ads_actions";
import _ from "lodash";

// Lazy load expand controls when we start loading ads before they become viewable
// The default from lazysizes is 370 which is too sensitive for our ads metrics
const LAZY_LOAD_EXPAND = 200;

const A9_SIZES = ["300x250", "728x90", "970x90", "320x50"];

// googletag helper methods
const allGptSlots = () => window.googletag.pubads().getSlots();
const getIdFromGptSlot = (slot) => slot.getSlotElementId();
const refreshSlots = (slots) => window.googletag.pubads().refresh(slots);

/*
 * This method defines the behavior after DFP indicates that a slot has finished
 * rendering. Here we track impressions. If the slot is a native ad, we will call
 * `attachServedNativeAdAttributesAndTracking` to insert the native creative.
 */
const attachCallbacksAfterSlotRender = (adId, params) => {
  window.googletag.pubads().addEventListener("slotRenderEnded", (event) => {
    if (event.slot.getSlotId().getDomId() === adId) {
      if (event.isEmpty) {
        GoogleAdsActions.removeAdSlot(adId);
        GoogleAdsActions.trackImpression({ slotId: adId, impressionCode: ImpressionCode.empty });
      } else {
        const iframe = $j(params.googleHookSelector).find(
          "iframe[id^=\"google_ads_iframe_\"]"
        );
        const creative = iframe.contents().find(params.creativeSelector);
        const adUnit = event.slot.getAdUnitPath().split("/", 3)[2];

        /** Ad element to track in CSA */
        const impressionElement = document.getElementById(`${adId}`);

        if (params.isNativeAd) {
          const sponsoredId = creative.data("id");
          const clickUrl = creative.data("url");

          if (sponsoredId && clickUrl) {
            GoogleAdsActions.attachServedNativeAdAttributesAndTracking({
              slotId: adId,
              campaignId: event.campaignId,
              lineItemId: event.lineItemId,
              creative,
              sponsoredId,
              impressionCode: ImpressionCode.success,
              dfpUrl: creative.data("url"),
              adUnit,
              impressionElement,
            });
          } else {
            GoogleAdsActions.trackImpression({ slotId: adId, impressionCode: ImpressionCode.empty });
          }
        } else {
          GoogleAdsActions.attachServedBannerAdAttributesAndTracking(
            { slotId: adId,
              campaignId: event.campaignId,
              lineItemId: event.lineItemId,
              creative,
              impressionCode: ImpressionCode.success,
              delayImpressionTracking: params.delayImpressionTracking,
              impressionElement,
            }
          );
        }
        if (params.dimensions === "160x600") {
          $j(".mainContentContainer").addClass("adSkinEnabled");
        }
      }
    }
  });
};

/**
* This method is responsible for attaching an event listener to the ad slot and
* for calling `display` on it. If the ad is lazy loaded, it will additionally
* define and refresh the slot.
*
* @param adId (required) id used by google to find the ad on the page
* @param params (required) contains information about the ad slot
        googleHookSelector (required) used to find the iframe within ad div
        creativeSelector (required) used to find the creatixve ID in the iframe
        dimensions (required) used to add styling to ad units
        isNativeAd (required) check if ad unit is native and look for sponsored update
        deviceType (optional) only used for poll requests to track device type
        delayImpressionTracking (optional) delay impression tracking
              until ad is viewed in navbar
*/
export const requestCreative = (adId, params = {}) => {
  const googletag = window.googletag;

  attachCallbacksAfterSlotRender(adId, params);
  GoogleAdsActions.hasAttachedDfpCallbackForSlot(adId);

  if (params.lazyLoadedNewsfeedAd) {
    // For lazy loaded newsfeed ads, manually define the slot and refresh it
    // after calling googletag.display. We do this here to ensure that
    // the above listener is defined first.
    const ad = params.lazyLoadedNewsfeedAd;
    const slot = window.googletag.defineSlot(
      `/4215/${ad.name}`,
      ad.dimensions,
      adId
    );
    slot.addService(window.googletag.pubads());
    googletag.display(adId);
    refreshSlots([slot]);
  } else if (params.isLazyLoaded) {
    const creativeSize = dimensionsExplode(params.dimensions);
    googletag.defineSlot(params.path, creativeSize, adId).addService(googletag.pubads());
    const slotHash = [{
      slotID: adId, slotName: params.path, sizes: [creativeSize],
    }];
    // A9 only allows the following ad slot sizes
    // 300x250, 728x90, 970x90 and 320x50
    const a9Sizes = getA9Sizes(params.dimensions);
    const a9Hash = _.isEmpty(a9Sizes)
      ? []
      : [slotToHash(params.path, adId, a9Sizes)];
    fetchApstagBids(slotHash, a9Hash);
  } else {
    googletag.cmd.push(() => {
      googletag.display(adId);
    });
  }
};

/**
 * Request Creative callback for ad slot
 * and mark ad as requested
 *
 */
export function requestCreativeForAdSlot(adId, params = {}) {
  GoogleAdsActions.requestCreativeForSlot(adId, params);
}

/**
 * Define Ad Slot for ad units defined in dfp ads resources.
 * All ad units are defined when the store is initialized for
 * roadblock ads
 *
 * @param adId (required) add Id to the slot for googletags callback
 * @param slotPath (required) define path for ad unit
 * (i.e. /4215/goodr.book.show.300x250)
 * @param dimension (required) slot size (e.g. "300x250"). For multi-size ads,
 * this should be an array of slot sizes (e.g. ["970x66", "970x90", "970x250"])
 */
export function defineSlot(adId, slotPath, dimension, adSizeMapping) {
  if (window.googletag && window.googletag.cmd) {
    window.googletag.cmd.push(() => {
      const googletag = window.googletag;
      // If this is a multiple-sized ad slot, pass DFP an array of the eligible
      // slot sizes
      const creativeSize = dimensionsExplode(dimension);
      if (adSizeMapping) {
        let mapping = googletag.sizeMapping();
        _.map(adSizeMapping, (pair) => {
          const pairingArray = _.map(pair, (val) => {
            const splitStr = val.split("x");
            return splitStr[0]
              ? [parseInt(splitStr[0]), parseInt(splitStr[1])]
              : [];
          });
          mapping = mapping.addSize(pairingArray[0], pairingArray[1]);
        });
        googletag
          .defineSlot(slotPath, creativeSize, adId)
          .defineSizeMapping(mapping.build())
          .setCollapseEmptyDiv(true)
          .addService(googletag.pubads());
      } else {
        const slot = googletag.defineSlot(slotPath, creativeSize, adId);
        slot.addService(googletag.pubads());
      }
    });
  }
}

export function enableServices() {
  if (window.googletag && window.googletag.cmd) {
    window.googletag.cmd.push(() => {
      const googletag = window.googletag;
      googletag.enableServices();
    });
  }
}

export function dimensionsExplode(dimensions) {
  let dimensionsArray;
  if (Array.isArray(dimensions)) {
    dimensionsArray = _.map(dimensions, (val) => {
      const splitStr = val.split("x");
      return [parseInt(splitStr[0]), parseInt(splitStr[1])];
    });
  } else {
    const splitStr = dimensions.split("x");
    dimensionsArray = [parseInt(splitStr[0]), parseInt(splitStr[1])];
  }
  return dimensionsArray;
}

// A9 only allows the following ad slot sizes
// 300x250, 728x90, 970x90 and 320x50
export function validateA9Sizes(val) {
  if (A9_SIZES.includes(val)){
    return true;
  } else {
    return false;
  }
}

// Dimensions is either an array of sizes for the ad
// slot or one size represented as a string.
export function getA9Sizes(dimensions) {
  let dimensionsArray = [];
  if (Array.isArray(dimensions)) {
    const validA9Dimensions = _.filter(dimensions, (val) => validateA9Sizes(val));
    dimensionsArray = _.map(validA9Dimensions, (val) => {
      const splitStr = val.split("x");
      return [parseInt(splitStr[0]), parseInt(splitStr[1])];
    });
  } else {
    if (validateA9Sizes(dimensions)){
      const splitStr = dimensions.split("x");
      dimensionsArray = [[parseInt(splitStr[0]), parseInt(splitStr[1])]];
    }
  }
  return dimensionsArray;
}

// This function converts an ad object (from the Google Ads Store data)
// into a hash compatible with the apstag library.
export function slotToHash(ad, adId, a9Sizes=[]) {
  // Prepare slot data for apstag

  const dimensions = Array.isArray(ad.dimensions) ?
    ad.dimensions : [ad.dimensions];
  const dimensionsMapped = _.isEmpty(a9Sizes) ? _.map(dimensions, (val) => {
    const splitStr = val.split("x");
    return [parseInt(splitStr[0]), parseInt(splitStr[1])];
  }): a9Sizes;
  return {
    slotID: adId,
    slotName: ad.path,
    sizes: dimensionsMapped,
  };
}

// This function takes an array of slot hashes (outputs from slotToHash) and
// submits them to apstag, which returns bids on those slots from A9. These bids
// are added to the targeting values of the slots, and a
// googletag.pubads().refresh() request is made for all slots passed in.
//
// NOTE: ADS WILL NOT DISPLAY PROPERLY UNTIL THIS FUNCTION IS CALLED FOR THE
// SLOTS TO BE DISPLAYED!
export const fetchApstagBids = (gptSlots, apsSlots) => {
  const googletag = window.googletag;
  if (googletag && googletag.cmd) {
    googletag.cmd.push(() => {
      const apstag = window.apstag;
      if (Array.isArray(gptSlots) && gptSlots.length) {
        _.isEmpty(apsSlots) ? googletag.cmd.push(() => checkCallbacksAndRefresh(gptSlots)) :
          apstag.fetchBids({
            slots: apsSlots,
          }, () => {
            googletag.cmd.push(() => checkCallbacksAndRefresh(gptSlots));
          });
      }
    });
  }
};

const checkCallbacksAndRefresh = (apsSlots) => {
  const apstag = window.apstag;
  const apsSlotIds = apsSlots.map((apsSlot) => apsSlot.slotID);
  const gptSlots = allGptSlots().filter((gptSlot) =>
    apsSlotIds.includes(getIdFromGptSlot(gptSlot))
  );
  new Promise((resolve, reject) =>
    GoogleAdsActions.checkCallbacksAttached(resolve, reject, apsSlotIds)
  )
    .then(() => {
      apstag.setDisplayBids();
      refreshSlots(gptSlots);
    })
    .catch(() => null);
};

// This is here because jQuery can't be stubbed directly in tests.
export function lazyLoadOnView(callback) {
  $j(document).on("lazybeforeunveil", callback);
}

// Set Lazyload params on google ad store initialization
export function lazyLoadInit() {
  window.lazySizesConfig = window.lazySizesConfig || {};
  window.lazySizesConfig.expand = LAZY_LOAD_EXPAND;
}

export function adSlotsDefined() {
  const googletag = window.googletag;
  return (
    typeof googletag === "function" && googletag.pubads().getSlots().length > 0
  );
}
