import { PropTypes } from "react";
import Modal from "../modal";
import Book from "../book";
import CommunityRating from "../community_rating";
import GlideButton from "./glide_button";
import GetACopyLink from "./get_a_copy_link";

const GetACopyInterstitial = ({ book, bookLinks, glideButton, onCloseHandler }) => (
  <Modal id="GetACopyInterstitial"
         onCloseHandler={onCloseHandler}
         showByDefault={true}
         centered={true}
         bemModifiers="small">
    <h3 className="gr-h3 gr-h3--noTopMargin">
      Get a Copy
    </h3>
    <Book {...book}
          className="u-paddingBottomSmall u-bottomGrayBorder u-marginBottomSmall"
          size="large">
      <CommunityRating rating={parseFloat(book.avgRating)}
                       displayAvg={true}
                       displayAvgRatingText={false}
                       size="medium" />
    </Book>
    <div className="u-textAlignCenter">
      <p className="gr-metaText gr-metaText--upper u-fontSizeDefault">
        Available At:
      </p>
      { glideButton ? <GlideButton {...glideButton} bookId={book.bookId} /> : null }
      { bookLinks.map((link) => <GetACopyLink key={link.name} {...link} />) }
      <p className="u-fontSizeDefault">
        <a href={book.moreStoresUrl}>
          More store options
        </a>
      </p>
    </div>
  </Modal>
);

GetACopyInterstitial.propTypes = {
  book: PropTypes.shape(Book.propTypes).isRequired,
  bookLinks: PropTypes.array.isRequired,
  glideButton: PropTypes.object,
  onCloseHandler: PropTypes.func,
};

export default GetACopyInterstitial;
