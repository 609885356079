import React from "react";
import GrComponentFactory from "../gr_component_factory";
import ModalTrigger from "../modal_trigger";

export default GrComponentFactory.createClass({
  displayName: "FavoriteGenresPane",

  propTypes: {
    favoriteGenres: React.PropTypes.array,
    genres: React.PropTypes.array.isRequired,
  },

  render() {
    return (
      <div className="favoriteGenresPane">
        <div className="favoriteGenresPane__title">
          What do you like to read?
        </div>
        <div className="favoriteGenresPane__description">
          Choose your favorite genres to get personalized book recommendations.
        </div>
        <div className="favoriteGenresPane__buttonContainer">
          <ModalTrigger id="favGenres">
            <button className="gr-button gr-button--dark">
              Choose Favorite Genres
            </button>
          </ModalTrigger>
        </div>
        <a href="/genres" className="favoriteGenresPane__browseGenres">
          Browse Genres
        </a>
      </div>
    );
  },
});
