import React from "react";
import GrComponentFactory from "./gr_component_factory";
import classNames from "classnames";
import { ICON_SIZES, ICON_SHAPES } from "../modules/shape_icon_values";
import { values } from "lodash";

export default GrComponentFactory.createClass({
  displayName: "ShapeIcon",

  propTypes: {
    imageUrl: React.PropTypes.string.isRequired,
    imageAlt: React.PropTypes.string.isRequired,
    includeLink: React.PropTypes.bool,
    linkUrl: React.PropTypes.string,
    size: React.PropTypes.oneOf(values(ICON_SIZES)),
    shape: React.PropTypes.oneOf(values(ICON_SHAPES)),
    border: React.PropTypes.bool,
  },

  getShapeClass(modifier=null) {
    let name = `${this.props.shape}Icon`;
    if (modifier) {
      name += `--${modifier}`;
    }
    return name;
  },

  getDefaultProps() {
    return  {
      includeLink: false,
      size: ICON_SIZES.SMALL,
      shape: ICON_SHAPES.CIRCLE,
      border: false,
    };
  },

  render () {
    const classes = classNames(this.props.className, {
      [`${this.getShapeClass()}`]: true,
      [`${this.getShapeClass("medium")}`]: this.props.size === ICON_SIZES.MEDIUM,
      [`${this.getShapeClass("large")}`]: this.props.size === ICON_SIZES.LARGE,
      [`${this.getShapeClass("border")}`]: this.props.border,
    });

    if (this.props.includeLink && this.props.linkUrl) {
      return (
        <a href={this.props.linkUrl}>
          <img className={classes} src={this.props.imageUrl} alt={this.props.imageAlt}/>
        </a>
      );
    }
    else {
      return (
        <img className={classes} src={this.props.imageUrl} alt={this.props.imageAlt}/>
      );
    }
  },

});
