/*doc
---
title: Review Cta
category: React
---

This component displays the review Cta to the user to prompt them to leave a
review or rating on a book. 

Required props:

* `bookId`: Book ID
* `user`: Current user
* `destinationPath`: The destination Url on clicking on the write a review button or rating widget
* `bookTitle`: Title of the book

Optional props:

* `actionType`: The Clickstream metric for the write a review button click
* `ratingClickstreamRef`: The ref tag for the Clickstream rating action context
*/

import { Component, PropTypes } from "react";
import { ICON_SHAPES } from "../../modules/shape_icon_values";
import Modal from "../modal";
import ModalTrigger from "../modal_trigger";
import { STAR_SIZES } from "../user_rating";
import UserIcon from "../user/user_icon";
import UserRating from "../user_rating";
import UserProfileLink from "../user/user_profile_link"
import WriteAReviewButton from "../review/write_a_review_button"

export const CTA_TEXT_SIGNED_IN = " start your review of ";
export const CTA_TEXT_SIGNED_OUT = "Start your review of ";

export default class ReviewCta extends Component {
  constructor(props) {
    super(props);
  }

  goToDestination() {
    window.location.assign(this.props.destinationPath);
  }

  render() {
    return (
      <div className="reviewCta">
        <div className="reviewCta__left">
          <UserIcon user={this.props.user} shape={ICON_SHAPES.RECTANGULAR}/> 
        </div>
        <div className="reviewCta__right">
          <div className="u-marginLeftXSmall reviewCta__label">
            <UserProfileLink className="reviewCta__userProfileLink" user={this.props.user}/>
            {this.props.user.viewableName ? CTA_TEXT_SIGNED_IN : CTA_TEXT_SIGNED_OUT}
            {this.props.bookTitle}
          </div>
          <div className="reviewCta__controls">
            <UserRating bookId={this.props.bookId}
                        starSize={ STAR_SIZES.MEDIUM }
                        handleClick={this.goToDestination.bind(this)}
                        displayLabel={false}
                        clickstreamRef={this.props.ratingClickstreamRef}/>
            <WriteAReviewButton destinationPath={this.props.destinationPath}
                                actionType={this.props.actionType}
                                bookId={this.props.bookId}
                                className="reviewCta__writeButton"/> 
          </div>
        </div>
      </div>
    );
  }
}

ReviewCta.propTypes = {
  bookId: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  destinationPath: PropTypes.string.isRequired,
  bookTitle: PropTypes.string.isRequired,
  actionType: PropTypes.string,
  ratingClickstreamRef: PropTypes.string,
};