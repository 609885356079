import GrComponentFactory from "./gr_component_factory";
import React from "react";
import FeaturedContentBlog from "./featured_content_blog";
import FeaturedContentBook from "./featured_content_book";
import FeaturedContentList from "./featured_content_list";
import FeaturedContentGenre from "./featured_content_genre";
import FeaturedContentGiveaway from "./featured_content_giveaway";
import SponsoredFooter from "./sponsored_footer";
import classNames from "classnames";

export default GrComponentFactory.createClass({
  displayName: "FeaturedContentModule",
  propTypes: {
    type: React.PropTypes.string.isRequired,
    resource: React.PropTypes.object.isRequired,
    sponsorName: React.PropTypes.string,
    isSponsored: React.PropTypes.bool,
    isMobile: React.PropTypes.bool,
    hasBottomBorder: React.PropTypes.bool,
    isInline: React.PropTypes.bool,
  },

  defaultProps: {
    isMobile: false,
    isInline: false,
    hasBottomBorder: false,
  },

  headerText() {
    switch(this.props.type) {
      case "Blog":
        return "Featured Blog Post";
      case "Book":
        return "Featured Book";
      case "Giveaway":
        return "Featured Giveaway";
      case "List":
        return "Featured List";
      case "Genre":
        return "Featured Genre";
      default:
        return "Featured Content";
    }
  },

  renderContent() {
    switch(this.props.type) {
      case "Blog":
        return this.renderBlog();
      case "Book":
        return this.renderBook();
      case "Giveaway":
        return this.renderGiveaway();
      case "List":
        return this.renderList();
      case "Genre":
        return this.renderGenre();
      default:
        return null;
    }
  },

  renderHeader() {
    if (this.props.isMobile) {
      return(<h3 className="gr-h3">
             {this.headerText()}
             </h3>
      );
    } else {
      const headerClassNames = classNames("featuredContentModule__header", {
        "featuredContentModule__header--inline": this.props.isInline,
      });
      return(<div className={headerClassNames}>
             {this.headerText()}
             </div>
      );
    }
  },

  renderFooter() {
    if (this.props.isSponsored) {
      return(<SponsoredFooter
        isSponsored={true}
        sponsoredName={this.props.sponsorName} />
      );
    } else {
      return null;
    }
  },

  renderBlog() {
    return(<FeaturedContentBlog
           blog={this.props.resource}
           isMobile={this.props.isMobile}
           isInline={this.props.isInline} />
    );
  },

  renderBook() {
    return(<FeaturedContentBook
           book={this.props.resource}
           isMobile={this.props.isMobile}
           isInline={this.props.isInline} />
    );
  },

  renderGiveaway() {
    return(<FeaturedContentGiveaway
           giveaway={this.props.resource}
           isMobile={this.props.isMobile} />
    );
  },

  renderList() {
    return(<FeaturedContentList
           list={this.props.resource}
           isMobile={this.props.isMobile}
           isInline={this.props.isInline} />
    );
  },

  renderGenre() {
    return(<FeaturedContentGenre
           genre={this.props.resource}
           isMobile={this.props.isMobile}
           isInline={this.props.isInline} />
    );
  },

  render() {
    const moduleClasses = classNames("featuredContentModule", {
      "featuredContentModule--mobile": this.props.isMobile,
      "featuredContentModule--inline": this.props.isInline,
    });
    let borderClass = "";
    if (this.props.isMobile) {
      if(this.props.hasBottomBorder){
        borderClass = "featuredContentBorder__underline";
      }
      else{
        borderClass = "featuredContentBorder__overline";
      }
    }
    return(
      <div className = {borderClass}>
        <section className={moduleClasses}>
          {this.renderHeader()}
          {this.renderContent()}
          {this.renderFooter()}
        </section>
      </div>
    );
  },
});
