import React from "react";
import GrComponentFactory from "./gr_component_factory";
import { getImages } from "../modules/shared/image_registry";
import classnames from "classnames";

import { VARIANTS } from "../modules/close_button_values";

export default GrComponentFactory.createClass({
  displayName: "CloseButton",

  propTypes: {
    action: React.PropTypes.func.isRequired,
    variant: React.PropTypes.oneOf([VARIANTS.DEFAULT, VARIANTS.DARK_BG]),
  },

  getDefaultProps() {
    return { variant: VARIANTS.DEFAULT };
  },

  render() {
    const cx = classnames("gr-iconButton", this.props.className);
    let buttonImage;
    if (this.props.variant === VARIANTS.DARK_BG) {
      buttonImage = getImages().closeIcons.darkBackground;
    } else {
      buttonImage = getImages().closeIcon;
    }
    return <button type="button" className={cx} onClick={this.props.action}>
      <img alt="Dismiss" src={buttonImage} />
    </button>;
  },
});
