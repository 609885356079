import _ from "lodash";
import Reflux from "reflux";
import Freezer from "freezer-js";
import LikeActions from "../react_actions/like_actions";
import NewsfeedUpdatesMixin, { NEWSFEED_DEPENDENT_STORE_KEYS } from "./shared/newsfeed_updates_mixin";
import GrErrorReporting from "../modules/gr_error_reporting";
import { httpPost, httpDelete } from "../modules/ajax/ajax_helper";

const state = new Freezer({});

const getState = () => state.get();

const getStateForKey = (key) => {
  if (!_.has(getState(), key)) {
    getState().set(key,
      {
        likeCount: 0,
        isLiked: false,
        showLoadingSpinner: false,
      });
  }
  return getState()[key];
};

const setResourceValue = function(resource, key, value) {
  getState()[resource].set(key, value);
};

const getResourceValue = function(resource, key) {
  return getState()[resource][key];
};

const toggleLikeStoreData = function(resource) {
  const likeCount = getResourceValue(resource, "likeCount");
  const isLiked = getResourceValue(resource, "isLiked");
  setResourceValue(resource, "isLiked", !isLiked);
  setResourceValue(resource, "likeCount", getResourceValue(resource, "isLiked") ?
    likeCount + 1 : likeCount - 1);
};

const store = Reflux.createStore({
  listenables: LikeActions,
  mixins: [NewsfeedUpdatesMixin(NEWSFEED_DEPENDENT_STORE_KEYS.LIKES)],
  getState,

  getInitialState(key) {
    if (!_.isUndefined(key)) {
      return getStateForKey(key);
    } else {
      return getState();
    }
  },

  getInitialStateForKey: getStateForKey,

  reset() {
    state.get().reset({});
  },

  initializeWith(data) {
    _.each(_.keys(data), (key) => {
      const scrubbedData = data[key];
      scrubbedData.likedBy = scrubbedData.likedBy || false;
      scrubbedData.likeCount = scrubbedData.likeCount || 0;
      scrubbedData.showLoadingSpinner = false;
      getState().set(key, scrubbedData);
    });
  },

  updateWith(data) {
    this.initializeWith(data);
  },

  triggerLoadingSpinner(resource) {
    const oldValue = getResourceValue(resource, "showLoadingSpinner");
    setResourceValue(resource, "showLoadingSpinner", !oldValue);
  },

  onLike(resource) {
    this.triggerLoadingSpinner(resource);
    this.trigger(this.getState());
    httpPost("/rating", {
      format: "json",
      data: {
        rating: {
          resource_type: getResourceValue(resource, "resourceType"),
          resource_id: getResourceValue(resource, "resourceId"),
          resource_uri: getResourceValue(resource, "resourceUri"),
          rating: 1,
        },
      },
    }).done((resp) => {
      toggleLikeStoreData(resource);
      setResourceValue(resource, "ratingId", resp.rating_id);
      this.trigger(getState());
    }).fail((resp) => {
      const message = resp.responseText;
      if (message) {
        GrErrorReporting.notifyUser(message);
      }
    }).always(() => {
      this.triggerLoadingSpinner(resource);
      this.trigger(getState());
    });
  },

  onUnLike(resource) {
    this.triggerLoadingSpinner(resource);
    this.trigger(this.getState());
    const ratingId = getResourceValue(resource, "ratingId");
    let url = "";
    let data = {};
    if (ratingId) {
      url = `/rating/${  ratingId}`;
      data = {
        format: "json",
      };
    } else {
      url = "/rating/";
      data = {
        format: "json",
        resource_uri: getResourceValue(resource, "resourceUri"),
      };
    }

    httpDelete(url, { data })
      .done(() => {
        toggleLikeStoreData(resource);
        this.trigger(getState());
      })
      .fail((resp) => {
        let errorMessage = "Unable to unlike.";
        if (resp.status == 404) {
          errorMessage += " You may have already unliked.";
        } else if (resp.responseText) {
          errorMessage += resp.responseText;
        }
        errorMessage += " Please refresh the page and try again.";
        GrErrorReporting.notifyUser(errorMessage);
      })
      .always(() => {
        this.triggerLoadingSpinner(resource);
        this.trigger(getState());
      });
  },
});

export default store;
