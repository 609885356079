import React from "react";
import GrComponentFactory from "./gr_component_factory";
import { lazyLoadOnView } from "../modules/ads/google_ad_helper";

export default GrComponentFactory.createClass({
  displayName: "ScrollTracker",

  propTypes: {
    trackedEventId: React.PropTypes.string.isRequired,
    viewportExpansion: React.PropTypes.number,
  },

  getDefaultProps() {
    return {
      viewportExpansion: 0,
    };
  },

  componentDidMount() {
    lazyLoadOnView((event) => {
      if (event.target.id === this.props.trackedEventId) {
        $j.post("/track/track", {
          tid: this.props.trackedEventId,
        });
      }
    });
  },

  render() {
    return <div id={this.props.trackedEventId} className="lazyload" data-expand={`${this.props.viewportExpansion}`} />;
  },
});
