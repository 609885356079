/*
Component to implement the "Browse" and "Community" menus in the responsive
navbar, and possibly other similar menus in the future.

Requires a "tabName" prop, similar to the "name" prop of Tab, to identify this
menu among all navbar menus.

Originally forked from Dropdown.
*/
import React from "react";
import _ from "lodash";
import Reflux from "reflux";
import GrComponentFactory from "../gr_component_factory";
import classNames from "classnames";
import OnClickOutside from "react-onclickoutside";
import keys from "../../modules/keys";
import TabActions from "../../react_actions/tab_actions";
import TabsStore from "../../react_stores/tabs_store";
import UserAgentCapabilitiesStore
  from "../../react_stores/user_agent_capabilities_store";
import { acknowledgeEvent } from "../../modules/shared/touch_not_ready_helper";

export const PRIMARY_NAV_MENU_TAB_GROUP = "siteHeaderPrimaryNav";

export default GrComponentFactory.createClass({
  displayName: "Dropdown",

  mixins: [OnClickOutside,
           Reflux.connect(TabsStore, "tabsStore"),
           Reflux.connect(UserAgentCapabilitiesStore, "userAgentCapabilities")],

  propTypes: {
    fallbackUrl: React.PropTypes.string,
    onShowCallback: React.PropTypes.func,
    tabName: React.PropTypes.string.isRequired,
    tooltipText: React.PropTypes.string,
    showingFavoriteGenres: React.PropTypes.bool,
    trigger: React.PropTypes.oneOfType([
      React.PropTypes.string,
      React.PropTypes.element,
    ]).isRequired,
  },

  hide() {
    TabActions.deselectTab(this.props.tabName, PRIMARY_NAV_MENU_TAB_GROUP);
  },

  isMenuActive() {
    return this.props.tabName === this.state.tabsStore[PRIMARY_NAV_MENU_TAB_GROUP];
  },

  show() {
    TabActions.selectTab(this.props.tabName, PRIMARY_NAV_MENU_TAB_GROUP);
    if (this.props.onShowCallback) {
      this.props.onShowCallback();
    }
  },

  toggle(ev) {
    acknowledgeEvent(ev);
    if (this.props.children) {
      ev.preventDefault();
    }
    this.isMenuActive() ? this.hide() : this.show();
  },

  openList(ev) {
    if (keys.isDropdownOpenerKey(ev.keyCode)) {
      ev.preventDefault();
      if (keys.isArrowDown(ev.keyCode)) {
        this.show();
      }
      else {
        this.toggle(ev);
      }
    }
    else if (keys.isDefaultCloserKey(ev.keyCode)) {
      ev.preventDefault();
      this.hide();
    }
  },

  handleClickOutside() {
    // On touch devices that display dropdown components, touch gestures such as
    // panning and zooming trigger this event listener. We disabled hiding in
    // this listener for touch devices because hiding the dropdown on touch
    // gestures is disruptive.
    if (!this.state.userAgentCapabilities.touch) {
      this.hide();
    }
  },

  triggerClasses() {
    return classNames(
      this.withBemModifiers(["primaryNavMenu__trigger"]),
      { "primaryNavMenu__trigger--buttonReset": _.isUndefined(this.props.fallbackUrl) },
      { "primaryNavMenu__trigger--active": this.isMenuActive() }
    );
  },

  renderTriggerButton() {
    return <button className={this.triggerClasses()}
                   aria-haspopup="true"
                   aria-expanded={this.isMenuActive()}
                   onClick={this.toggle}
                   onKeyDown={this.openList}
                   title={this.props.tooltipText}
                   data-ux-click>
             {this.props.trigger}
           </button>;
  },

  renderTriggerLink() {
    return <a className={this.triggerClasses()}
              href={this.props.fallbackUrl}
              onClick={this.toggle}
              onKeyDown={this.openList}
              role="button"
              aria-haspopup="true"
              aria-expanded={this.isMenuActive()}
              title={this.props.tooltipText}
              data-ux-click>
              {this.props.trigger}
            </a>;
  },

  render() {
    /* Setting "ignore-react-onclickoutside" prevents a bug I was seeing where
       the menu was closing unexpectedly when clicking on the menu. (Should
       only close on clicks outside.) This *shouldn't* be necessary, but it
       fixes the problem, so I am going to run with it.
       Ref: https://github.com/Pomax/react-onclickoutside/tree/v4.5.0#marking-elements-as-skip-over-this-one-during-the-event-loop */
    const dropdownClasses = classNames(this.withBemModifiers("primaryNavMenu"),
                                     "ignore-react-onclickoutside");

    const dropdownContentsClasses = classNames(
      "primaryNavMenu__menu",
      { "primaryNavMenu__menu--show": this.isMenuActive() },
      "gr-box gr-box--withShadowLarge",
      { "wide": this.props.showingFavoriteGenres}
  );

    const trigger = _.isUndefined(this.props.fallbackUrl) ?
      this.renderTriggerButton() : this.renderTriggerLink();
    const dropdownContents =
      <div className={dropdownContentsClasses}
           role="menu">
        {this.props.children}
      </div>;

    return(
      <div className={dropdownClasses}>
        {trigger}
        {dropdownContents}
      </div>
    );
  },
});
