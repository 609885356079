import React from "react";
import _ from "lodash";
import GrComponentFactory from "./gr_component_factory";
import Reflux from "reflux";
import GenreCheckbox from "./genre_checkbox";
import ModalActions from "./../react_actions/modal_actions";
import FavoriteGenresFormActions from "../react_actions/favorite_genres_form_actions";
import FavoriteGenresFormStore from "../react_stores/favorite_genres_form_store";

export default GrComponentFactory.createClass({
  displayName: "FavoriteGenresForm",

  mixins: [Reflux.connect(FavoriteGenresFormStore, "favoriteGenresFormStore")],

  propTypes: {
    favoriteGenres: React.PropTypes.array,
    genres: React.PropTypes.array.isRequired,
  },

  getDefaultProps() {
    return {
      favoriteGenres: [],
    };
  },

  onSubmit(event) {
    FavoriteGenresFormActions.submitSelectedGenres();
    ModalActions.closeModal();
    event.preventDefault();
  },

  renderGenreCheckboxes() {
    return (
      <div className="gr-genresForm__checkBoxes">
        { _.map(this.props.genres, (genre) => (
            <GenreCheckbox genre={genre.name} key={genre.name}
              isFavorited={_.includes(this.state.favoriteGenresFormStore.selectedGenres, genre.name)} />
        ))}
      </div>);
  },

  render() {
    return(
      <div className="gr-genresForm">
        <div className="gr-genresForm__title">
          Follow Your Favorite Genres
        </div>
        <div className="gr-genresForm__description">
          We use your favorite genres to make better book recommendations and
          tailor what you see in your Updates feed.
        </div>
        <form action="/user/edit_fav_genres" data-remote="true" method="post">
          { this.renderGenreCheckboxes() }
          <button type="submit"
                  onClick={this.onSubmit}
                  className="gr-button gr-button--large">
            Save
          </button>
        </form>
      </div>
    );
  },
});
