/**
 * Acknowledge event for Touch Not Ready (TNR) metrics
 * If an event is acknowledged, the TNR count should not be incremented
 * The custom ACK count for the element should be incremented
 * See custom_touch_not_ready.js
 * The native browser event must be accessed from React's SyntheticEvent wrapper
 *
 * References:
 * https://w.amazon.com/bin/view/Latency/Projects/TouchNotReady/DeveloperDocs
 * https://reactjs.org/docs/events.html
 */

export function acknowledgeEvent(event) {
  // Only acknowledge if native event available and TNR library loaded
  if (event && event.nativeEvent && event.nativeEvent.acknowledge) {
    event.nativeEvent.acknowledge(event.currentTarget);
  }
}