import Reflux from "reflux";

export default Reflux.createActions([
  "updateProgress",
  "closeUpdateProgressForm",
  "openUpdateProgressForm",
  "refreshReadingSessions",
  "shelveBookSuccess",
  "loadAllReadingSessions",
]);
