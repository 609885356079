// React Component for book preview card in share dialog. Similar to Book Component
// but display only (no links).

import { PropTypes } from "react";
import GrComponentFactory from "../../gr_component_factory";
import ShareDialogPreview from "./share_dialog_preview";
import CommunityRating from "../../community_rating";
import AuthorBadge from "../../author/author_badge";

export default GrComponentFactory.createClass({
  displayName: "ShareDialogBookPreview",

  propTypes: {
    author: PropTypes.shape({
      name: PropTypes.string.isRequired,
      isGoodreadsAuthor: PropTypes.bool.isRequired,
    }).isRequired,

    rating: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    ratingsCount: PropTypes.number.isRequired,
    textReviewsCount: PropTypes.number.isRequired,
  },

  renderAuthorText() {
    return(
      <div>
        by { this.props.author.name }
        <AuthorBadge isGoodreadsAuthor={ this.props.author.isGoodreadsAuthor } />
      </div>
    );
  },

  render() {
    return(
      <ShareDialogPreview
        primaryText={this.props.title}
        secondaryText={this.renderAuthorText()}
        imageUrl={this.props.imageUrl}>
        <div className="u-marginTopXSmall">
          <CommunityRating
            rating={parseFloat(this.props.rating)}
            displayAvg={true}
            displayAvgRatingText={false}
            ratingsCount={this.props.ratingsCount}
            size={CommunityRating.ICON_SIZES.MEDIUM}
            textReviewsCount={this.props.textReviewsCount} />
        </div>
      </ShareDialogPreview>
    );
  },
});
