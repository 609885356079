/*doc
---
title: Twitter share
name: twitter_share
category: React
---

This component displays the twitter share button that is used in the share module.

Required props:

* `options` (object): Options for twitter share (text, url)

Optional props:

* `metricName` (string): Full name or modifier for metric name
* `windowSpecs` (shape): Options for twitter child window

*/

import React from "react";
import GrComponentFactory from "./../gr_component_factory";
import { httpPost } from "../../modules/ajax/ajax_helper";
import ChildWindowHelper from "../../modules/child_window_helper";

export default GrComponentFactory.createClass({
  displayName: "TwitterShare",

  propTypes: {
    options: React.PropTypes.shape({
      text: React.PropTypes.string,
      url: React.PropTypes.string,
      via: React.PropTypes.string,
    }).isRequired,
    metricName: React.PropTypes.string,
    windowSpecs: React.PropTypes.object,
  },

  getDefaultProps() {
    return {
      // default window specs hard-coded here - cannot access browser window on server-side for centering
      windowSpecs: { left: 400,
                     top: 200,
                     width: 600,
                     height: 500 },
    };
  },

  onTwitterButtonClick() {
    if (this.props.metricName) {
      httpPost("/track/track_click", {
        data: {
          feature: this.props.metricName,
        },
      });
    }
    ChildWindowHelper.openSocialShareWindow("https://twitter.com/intent/tweet/", this.props.options, this.props.windowSpecs);
  },

  render() {
    return (
      <button className={`${this.withBemModifiers("shareSocialButtons__button")} shareSocialButtons__button--twitter`} 
              aria-label="Share to Twitter"
              type="button"
              onClick={() => this.onTwitterButtonClick()} />
    );
  },
});
