import { PropTypes } from "react";

const NewsImage = (props) => (
  <div>
    <a href={props.newsUrl}>
      <img className="newsPreview__newsImage" src={props.newsImageUrl} />
    </a>
  </div>
);

NewsImage.propTypes = {
  newsImageUrl: PropTypes.string.isRequired,
  newsUrl: PropTypes.string.isRequired,
};

export default NewsImage;
