import GrComponentFactory from "./gr_component_factory";
import React from "react";

export default GrComponentFactory.createClass({
  displayName: "PageSkin",
  propTypes: {
    imageUrl: React.PropTypes.string,
    backgroundColor: React.PropTypes.string,
  },

  render() {
    const divStyle = {};
    if (this.props.imageUrl) {
      divStyle.backgroundImage = `url(${this.props.imageUrl})`;
      divStyle.backgroundRepeat = "no-repeat";
      divStyle.backgroundPosition = "center top";
    }
    if (this.props.backgroundColor) {
      divStyle.backgroundColor = this.props.backgroundColor;
    }
    // Add link for background
    return <div className="pageskin" style={divStyle} />;
  },
});
