import Reflux from "reflux";

export default Reflux.createActions([
  // accepts a single string arg that is the contents of the message
  "requestCreativeForSlot",
  "displayCreativeForSlot",
  "removeAdSlot",
  "trackImpression",
  "attachServedNativeAdAttributesAndTracking",
  "attachServedBannerAdAttributesAndTracking",
  "hasAttachedDfpCallbackForSlot",
  "checkCallbacksAttached",
]);
