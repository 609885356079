// React Component for follow button in FriendFollowModule. Handles both
// follow/unfollow states.

import React from "react";
import classNames from "classnames";
import GrComponentFactory from "../gr_component_factory";
import FriendFollowActions from "../../react_actions/user_relationships/friend_follow_actions";
import UnfollowConfirmationModal from "./confirmation_modals/unfollow_confirmation_modal";
import CancelFriendRequestConfirmationModal from "./confirmation_modals/cancel_friend_request_confirmation_modal";
import {
  getUnfollowModalId,
  getCancelFriendRequestModalId,
} from "../../modules/modal_id_helper";
import ModalTrigger from "../modal_trigger";
import Spinner from "../spinner";

export default GrComponentFactory.createClass({
  displayName: "FollowButton",

  propTypes: {
    displayUser: React.PropTypes.shape({
      firstName: React.PropTypes.string.isRequired,
      viewableName: React.PropTypes.string.isRequired,
      userId: React.PropTypes.number.isRequired,
    }).isRequired,
    isFollowing: React.PropTypes.bool.isRequired,
    isPendingFriendRequestTo: React.PropTypes.bool.isRequired,
    friendRequestToId: React.PropTypes.number,
    fromUserShowPage: React.PropTypes.bool,
    inProgress: React.PropTypes.bool,
  },

  getInitialState() {
    return {
      isHovering: false,
    };
  },

  getDefaultProps() {
    return {
      fromUserShowPage: false,
      inProgress: false,
    };
  },

  handleMouseEnter() {
    this.setState({ isHovering: true });
  },

  handleMouseLeave() {
    this.setState({ isHovering: false });
  },

  handleFollow() {
    FriendFollowActions.follow(this.props.displayUser.userId, {
      from_user_show_page: this.props.fromUserShowPage,
    });
  },

  renderFollowButton() {
    const className = classNames(
      this.withBemModifiers("friendFollowButton"),
      "friendFollowButton--dark"
    );
    return (
      <button className={className} onClick={this.handleFollow} type="button">
        Follow
      </button>
    );
  },

  renderInProgressButton() {
    return (
      <button
        className={this.withBemModifiers("friendFollowButton")}
        onClick={this.handleFollow}
        type="button">
        <Spinner />
      </button>
    );
  },

  renderUnfollowButton() {
    const className = classNames(
      this.withBemModifiers("friendFollowCheckmark"),
      "u-inlineBlock"
    );
    const buttonText = this.state.isHovering ? (
      "Unfollow"
    ) : (
      <span>
        <div className={className} />
        Following
      </span>
    );

    // render cancel friend request modal over unfollow modal when user clicks
    // "Unfollow" if current user has pending friend request to display user
    let modal, modalId;
    if (this.props.isPendingFriendRequestTo && this.props.friendRequestToId) {
      modal = (
        <CancelFriendRequestConfirmationModal
          displayUser={this.props.displayUser}
          friendRequestToId={this.props.friendRequestToId}
        />
      );
      modalId = getCancelFriendRequestModalId(this.props.displayUser.userId);
    } else {
      modal = (
        <UnfollowConfirmationModal displayUser={this.props.displayUser} />
      );
      modalId = getUnfollowModalId(this.props.displayUser.userId);
    }

    return (
      <div className="u-inlineBlock">
        <ModalTrigger id={modalId}>
          <button
            className={this.withBemModifiers("friendFollowButton")}
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
            type="button">
            {buttonText}
          </button>
        </ModalTrigger>

        {modal}
      </div>
    );
  },

  render() {
    if (this.props.inProgress) {
      return this.renderInProgressButton();
    }
    return this.props.isFollowing
      ? this.renderUnfollowButton()
      : this.renderFollowButton();
  },
});
