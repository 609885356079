import React from "react";
import _ from "lodash";
import GrComponentFactory from "../gr_component_factory";
import GrPropTypes from "../shared/gr_prop_types";
import Comment from "./comment";
import PaginationLinks from "../pagination_links";
import CommentsStore from "../../react_stores/comments_store";
import CommentActions from "../../react_actions/comment_actions";
import Spinner from "../spinner";
import { connectToLazyStore } from "../../react_mixins/gr_reflux";
import classNames from "classnames";

const DEFAULT_MAX_COMMENTS = 3;
const FIRST_PAGE = 1;

/*
 Comments displays a list of comments.
*/
export default GrComponentFactory.createClass({
  displayName: "Comments",

  propTypes: {
    subjectKey: React.PropTypes.string.isRequired,
    subject: GrPropTypes.commentSubject(),
    subjectUrl: React.PropTypes.string,
    showTimestamp: React.PropTypes.bool,
  },

  getDefaultProps() {
    return { showTimestamp: true };
  },

  mixins: [connectToLazyStore(CommentsStore, "subjectKey")],

  seeMoreCommentsText() {
    if (this.state.numComments == 1) {
      return "See 1 comment";
    } else {
      return `See all ${this.state.numComments} comments`;
    }
  },

  showCommentsForPage(page) {
    CommentActions.showCommentsForPage({
      subjectKey: this.props.subjectKey,
      subject: this.props.subject,
      page,
    });
  },

  showMoreClicked(event) {
    CommentActions.showSeeMoreLoadingSpinner(this.props.subjectKey);
    this.showCommentsForPage(FIRST_PAGE);
    event.preventDefault();
  },

  renderSeeMoreComments() {
    const numComments = this.state.numComments;
    if (numComments > DEFAULT_MAX_COMMENTS || numComments > this.state.comments.length) {
      return (
        <div className="gr-comments__seeAllLinkBox">
          { this.state.showSeeMoreLoadingSpinner ?
            <span>
              <span className="gr-hyperlink gr-hyperlink--bold">
                {this.seeMoreCommentsText()}
              </span>
              <span id="showMoreSpinner" className="u-marginLeftTiny">
                <Spinner />
              </span>
            </span> :
            <a href={this.props.subjectUrl}
               className="gr-hyperlink gr-hyperlink--bold"
               onClick={this.showMoreClicked}>
              {this.seeMoreCommentsText()}
            </a>
          }
        </div>
      );
    } else {
      return null;
    }
  },

  renderComments() {
    const comments = this.state.showPagination ?
      this.state.comments :
      _.takeRight(this.state.comments, DEFAULT_MAX_COMMENTS);
    return _.map(comments, (comment) => (<Comment key={comment.uri}
                      subjectKey={this.props.subjectKey}
                      subject={this.props.subject}
                      showTimestamp={this.props.showTimestamp}
                      {...comment} />));
  },

  render() {
    const commentsClassName = classNames(this.withBemModifiers("gr-comments"),
                                       "gr-box",
                                       "gr-box--hiddenOverflow",
                                       "u-defaultType");
    if (this.state.numComments > 0) {
      return <div className={commentsClassName}>
              {this.state.showPagination ?
                null :
                this.renderSeeMoreComments()}
              {this.renderComments()}
              { this.state.showPagination ?
                <div className="u-topGrayBorder">
                  <PaginationLinks subjectKey={this.props.subjectKey}
                                   totalSize={this.state.numComments}
                                   currentPageNumber={this.state.currentPage}
                                   perPage={10}
                                   changePageAction={this.showCommentsForPage}
                                   className="gr-paginationLinks__links" />
                </div> :
                null }
             </div>;
    } else {
      return null;
    }
  },
});
