import Reflux from "reflux";

export default Reflux.createActions([
  /*
   * Indicates a user has changed their favorite genres
   * required arg: array of favorited genre strings
   *                This should be the complete set of their favorite genres,
   *                even if they were previously favorited.
  */
  "changeFavoriteGenres",
]);
