import React from "react";
import { map } from "lodash";
import { httpPost } from "../../modules/ajax/ajax_helper";
import { setLocationUrl } from "../../modules/env_utils";
import FlashMessage from "../flash_message";

export class LibrarianQuiz extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props,
      userAnswers: {},
      gradingInProgress: false,
    };

    this.renderManualLinkBox = this.renderManualLinkBox.bind(this);
    this.gradeQuiz = this.gradeQuiz.bind(this);
    this.addUserAnswer = this.addUserAnswer.bind(this);
    this.renderAnswerRadioInput = this.renderAnswerRadioInput.bind(this);
    this.renderButton = this.renderButton.bind(this);
    this.renderStatusFlashMessage = this.renderStatusFlashMessage.bind(this);
  }

  gradeQuiz() {
    this.setState({ gradingInProgress: true });
    httpPost("/librarian/submit_quiz", {
      data: { userAnswers: this.state.userAnswers },
    }).done((response) => {
      setLocationUrl(response.redirect);
    });
  }

  addUserAnswer(questionId, answerId) {
    const newUserAnswers = this.state.userAnswers;
    newUserAnswers[questionId] = answerId;
    this.setState({ userAnswers: newUserAnswers });
  }

  renderStatusFlashMessage() {
    if (this.state.statusMessage && !this.state.adminViewing){
      if (!this.state.passedQuiz) {
        return <FlashMessage category="error" message={this.state.statusMessage}/>;
      } else {
        return <FlashMessage category="info" message={this.state.statusMessage}/>;
      }
    } else {
      return null;
    }
  }

  renderAnswerRadioInput(question, answerId) {
    const questionId = question.questionId;
    if (this.state.finishedQuiz) {
      return <input name={`quizQuestion${questionId}`}
                    value={answerId}
                    type="radio"
                    checked={question.userAnswer === answerId}
                    disabled={true}
                    className="gr-radioButtonGroup__button" />;
    } else {
      return <input name={`quizQuestion${questionId}`}
                    value={answerId}
                    type="radio"
                    onChange={() => { this.addUserAnswer(questionId, answerId); }}
                    className="gr-radioButtonGroup__button" />;
    }
  }

  renderManualLinkBox(question) {
    if (this.state.finishedQuiz && !question.isUserAnswerCorrect) {
      return <div className="gr-infoMessageBox">
        Incorrect answer! Please consult the{" "}
        <a href={question.manualLink}
           className="gr-hyperlink">Librarian Manual</a>{" "}
        for more information.
      </div>;
    } else {
      return null;
    }
  }

  renderButton() {
    if (this.state.finishedQuiz) {
      if (this.state.passedQuiz && !this.state.adminViewing) {
        return <a className="gr-button" href="/librarian/apply_librarian">Apply</a>;
      } else {
        return null;
      }
    } else {
      return <button className="gr-button"
                     onClick={this.gradeQuiz}
                     disabled={this.state.gradingInProgress}>
        Submit
      </button>;
    }
  }

  render() {
    return(
      <div>
        { this.renderStatusFlashMessage() }
        { map(this.state.attemptAnswers, (question) =>
          <fieldset className="gr-radioButtonGroup u-marginTopMedium u-marginBottomMedium" key={question.questionId}>
            { this.renderManualLinkBox(question) }
            <legend className="mediumLargeText fullSizeContainer">{question.questionText}</legend>
            { map(question.answers, (answer, index) =>
              <label key={answer.answerId} className="gr-radioButtonGroup__choice gr-label gr-label--muted">
                { this.renderAnswerRadioInput(question, answer.answerId) }
                { String.fromCharCode(65+index) }: {answer.answerText}
              </label>
            )}
          </fieldset>
        )}
        { this.renderButton() }
      </div>
    );
  }
}

LibrarianQuiz.propTypes = {
  finishedQuiz: React.PropTypes.bool,
  passedQuiz: React.PropTypes.bool,
  adminViewing: React.PropTypes.bool,
  statusMessage: React.PropTypes.string,
  attemptAnswers: React.PropTypes.array,
};

export default LibrarianQuiz;
