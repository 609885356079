export default {

  /**
   * Initializes modernizr touch check on DOM Ready
   */
  initializeModernizrTouchCheck: () => {
    document.addEventListener("DOMContentLoaded", () => {
      if (Modernizr && Modernizr.touchevents) {
        window.ReactActions.UserAgentCapabilitiesActions.userAgentGainsTouch();
      } else {
        window.ReactActions.UserAgentCapabilitiesActions.userAgentLosesTouch();
      }
    });
  },
};
