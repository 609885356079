import Reflux from "reflux";

export default Reflux.createActions({
  addShelvings: {},
  bookShelved: {},
  bookUnshelved: {},
  shelveBook: {},
  toggleNonExclusiveShelving: {},
  rateBook: { children: ["newBook", "failed"] },
  unshelveBook: {},
});
