/*
  Interface to check state of frontend Deploy Services.
*/
import React from "react";
import _ from "lodash";

export default {
  propTypes: {
    deployServices: React.PropTypes.arrayOf(React.PropTypes.string),
  },

  isFeatureEnabled(featureCode) {
    return (_.indexOf(this.deployServices(), featureCode) >= 0);
  },
  deployServices() {
    if (this.props.deployServices === undefined) {
      throw `trying to access Deploy Services from component, but component does not contain deployServices prop (${  this.constructor.displayName  })`;
    }
    return this.props.deployServices;
  },
};
