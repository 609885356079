import AnnotatedBooksLandingPage from "../react_components/reading_notes/annotated_books_landing_page";
import AuthorFollowButton from "../react_components/author/author_follow_button";
import CommentForm from "../react_components/comment/comment_form";
import Comments from "../react_components/comment/comments";
import LikeButton from "../react_components/like/like_button";
import LikeInformation from "../react_components/like/like_information";
import ReadingNotesAnnotator from "../react_components/reading_notes/reading_notes_annotator";
import ReadingNotesHeader from "../react_components/reading_notes/reading_notes_header";
import ReadingNotesShelvingModal from "../react_components/reading_notes/reading_notes_shelving_modal";
import ReadingNotesShelvingModalTrigger from "../react_components/reading_notes/reading_notes_shelving_modal_trigger";
import UserFollowWidget from "../react_components/user/user_follow_widget";
import UserIcon from "../react_components/user/user_icon";
import WantToReadButton from "../react_components/want_to_read/want_to_read_button";
import WantToReadWithStars from "../react_components/want_to_read/want_to_read_with_stars";

import AnnotatedBooksStore from "../react_stores/reading_notes/annotated_books_store";
import AuthorFollowStore from "../react_stores/author_follow_store";
import CommentsStore from "../react_stores/comments_store";
import LikeStore from "../react_stores/like_store";
import ShelvingsStore from "../react_stores/shelvings_store";
import UserFollowStore from "../react_stores/user/user_follow_store";
import UserShelvesStore from "../react_stores/user_shelves_store";
import UserAgentCapabilitiesStore from "../react_stores/user_agent_capabilities_store";

export const ReadingNotesComponents = {
  AnnotatedBooksLandingPage,
  AuthorFollowButton,
  CommentForm,
  Comments,
  LikeButton,
  LikeInformation,
  ReadingNotesAnnotator,
  ReadingNotesHeader,
  ReadingNotesShelvingModal,
  ReadingNotesShelvingModalTrigger,
  UserFollowWidget,
  UserIcon,
  WantToReadButton,
  WantToReadWithStars,
};

export const ReadingNotesStores = {
  AnnotatedBooksStore,
  AuthorFollowStore,
  CommentsStore,
  LikeStore,
  ShelvingsStore,
  UserFollowStore,
  UserShelvesStore,
  UserAgentCapabilitiesStore,
};
