import React from "react";
import { values, isEmpty } from "lodash";
import classNames from "classnames";
import CloseButton from "./close_button";

// Should be kept in sync with ApplicationController::FLASH_MESSAGE_TYPES
const validCategories = {
  ERROR: "error",
  INFO: "info",
  NOTICE_DEPRECATED: "notice",
  SUCCESS: "success",
  WARNING: "warning",
};

export default React.createClass({
  displayName: "FlashMessage",
  propTypes: {
    category: React.PropTypes.oneOf(values(validCategories)),
    message: React.PropTypes.string.isRequired,
    dismissable: React.PropTypes.bool
  },

  getDefaultProps() {
    return {
      undismissable: false 
    };
  },

  getInitialState() {
    return {
      isVisible: true,
    };
  },

  hideMessage() {
    this.setState({ isVisible: false });
  },

  flashMessageMarkup() {
    return { __html: this.props.message };
  },

  renderCloseButton() {
    if (!this.props.undismissable) {
      return (
        <CloseButton action={this.hideMessage}
          className="gr-flashMessage__dismissButton" />
      )
    }
  },

  render() {
    const { category, message, ...props } = this.props;
    if(!isEmpty(this.props.message)) {
      const cx = classNames(
        "gr-flashMessage",
        {
          "gr-flashMessage--dismissed": !this.state.isVisible,
          "gr-flashMessage--error": category === validCategories.ERROR,
          "gr-flashMessage--info": category === validCategories.INFO,
          "gr-flashMessage--warning": category === validCategories.WARNING,
          "gr-flashMessage--success": category === validCategories.SUCCESS,
        }
      );

      return(
        <div className={cx} role="alert">
          {this.renderCloseButton()}
          <span dangerouslySetInnerHTML={this.flashMessageMarkup()} />
        </div>
      );
    } else {
      return null;
    }
  }

});
