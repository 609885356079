import GrComponentFactory from "./gr_component_factory";
import Book from "./book";
import BookDescription from "./book_description";
import React from "react";
import ClickTrackingWrapper from "./click_tracking_wrapper";
import classNames from "classnames";
import { TRACKED_CLASSES_PREMIUM_CAMPAIGN } from "../modules/ads/click_tracking_helper";

export default GrComponentFactory.createClass({
  displayName: "FeaturedContentBook",
  propTypes: {
    book: React.PropTypes.object.isRequired,
    isMobile: React.PropTypes.bool,
    isInline: React.PropTypes.bool,
  },

  defaultProps: {
    isMobile: false,
    isInline: false,
  },

  renderImage() {
    const bookImage = (<img alt={this.props.book.title}
                     src={this.props.book.imageUrl}
                     className={classNames("featuredContentBook__imageMobile")}
                     />
    );
    const bodyImage = (<a href={this.props.book.bookCoverUrl || this.props.book.bookUrl}
                     className={classNames("featuredContentBook__imageLinkMobile")}>
                     {bookImage}
                     </a>
    );
    return <ClickTrackingWrapper trackingOptions={TRACKED_CLASSES_PREMIUM_CAMPAIGN.fcm_book_image} children={bodyImage} />;
  },

  renderDescription() {
    if (this.props.book.description) {
      return(<BookDescription bookDescription={this.props.book.description}
             className={classNames("featuredContentBook__description")}
             bookDescriptionTargetingData={TRACKED_CLASSES_PREMIUM_CAMPAIGN.fcm_book_description} />
      );
    } else {
      return null;
    }
  },

  renderLearnMore() {
    const classesObject= classNames("gr-button", "featuredContentBook__button", {
      "featuredContentBook__button--mobile": this.props.isMobile,
      "featuredContentBook__button--inline": this.props.isInline,
    });
    const learnMore = (<a href={this.props.book.bookUrl}
                    className={classesObject}>
                    Learn more
                    </a>
    );
    return <ClickTrackingWrapper trackingOptions={TRACKED_CLASSES_PREMIUM_CAMPAIGN.fcm_book_button} children={learnMore} />;
  },

  render() {
    if (this.props.isMobile) {
      return(
        <div className={classNames("featuredContentBook", "featuredContentBook--mobile")}>
          {this.renderImage()}
          {this.renderDescription()}
          {this.renderLearnMore()}
        </div>
      );
    } else {
      return(
        <div className="featuredContentBook">
          <Book {...this.props.book}
            bookImageTrackingData={TRACKED_CLASSES_PREMIUM_CAMPAIGN.fcm_book_image}
            bookTitleTrackingData={TRACKED_CLASSES_PREMIUM_CAMPAIGN.fcm_book_title}
            authorLinkTrackingData={TRACKED_CLASSES_PREMIUM_CAMPAIGN.fcm_book_author}>
            {this.renderDescription()}
            {this.renderLearnMore()}
          </Book>
        </div>
      );
    }
  },
});
