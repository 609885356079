import _ from "lodash";
import Reflux from "reflux";
import Freezer from "freezer-js";
import GrErrorReporting from "../modules/gr_error_reporting";
import CommentActions from "../react_actions/comment_actions";
import NewsfeedUpdatesMixin, { NEWSFEED_DEPENDENT_STORE_KEYS } from "./shared/newsfeed_updates_mixin";

const defaultState = {};
const state = new Freezer(defaultState);
const MAX_TEXTAREA_ROWS = 2;
const MAX_COMMENTS_PER_PAGE = 10;

const getState = function() {
  return state.get();
};

const resetTo = function(data) {
  getState().reset(_.merge({}, defaultState, data));
};

const getStateForKey = (key) => {
  if (!_.has(getState(), key)) {
    getState().set(key,
      {
        numComments: 0,
        unsavedComment: "",
        comments: [],
        saveInProgress: false,
        showCommentButton: false,
        textareaRows: 1,
        showPagination: false,
        currentPage: 1,
        showSeeMoreLoadingSpinner: false,
      });
  }
  return getState()[key];
};

const setDataForKey = (key, data) => {
  getStateForKey(key).set(data);
};

export default Reflux.createStore({
  listenables: CommentActions,
  mixins: [NewsfeedUpdatesMixin(NEWSFEED_DEPENDENT_STORE_KEYS.COMMENTS)],

  initializeWith(data) {
    resetTo(data);
  },

  updateWith(data) {
    const transformedData = _.transform(data, (result, commentData, key) => {
      result[key] = {
        numComments: commentData.numComments || 0,
        unsavedComment: commentData.unsavedComment || "",
        comments: commentData.comments || [],
        showCommentButton: false,
        saveInProgress: false,
        textareaRows: 1,
        deletableByCurrentUser: commentData.deletableByCurrentUser,
        blockedByCurrentUser: commentData.blockedByCurrentUser || false,
      };
    });
    getState().set(transformedData);
  },

  onTextareaFocusRequested(subjectKey) {
    setDataForKey(subjectKey, {
      textareaFocusRequested: true,
    });
    this.notifyListeners();
  },

  onTextareaFocused(subjectKey) {
    setDataForKey(subjectKey, { textareaRows: MAX_TEXTAREA_ROWS,
                                textareaFocusRequested: false,
                                showCommentButton: true });
    this.notifyListeners();
  },

  onTextareaUnfocused(subjectKey) {
    const comment = getStateForKey(subjectKey).unsavedComment;
    if (_.isEmpty(_.trim(comment))) {
      setDataForKey(subjectKey, { textareaRows: 1 });
    }
    this.notifyListeners();
  },

  onShowSeeMoreLoadingSpinner(subjectKey) {
    setDataForKey(subjectKey, { showSeeMoreLoadingSpinner: true });
    this.notifyListeners();
  },

  onDeleteComment(subjectKey, commentUri, commentData) {
    const subjectData = getStateForKey(subjectKey);
    const updatedNum = subjectData.numComments - 1;
    const commentIndex = _.findIndex(subjectData.comments, (comment) => comment.uri === commentUri);
    if (commentIndex >= 0) {
      subjectData.set("numComments", updatedNum);
      subjectData.comments.splice(commentIndex, 1);
      if (!subjectData.showPagination) { this.notifyListeners(); }
      $j.post(`/comment/destroy?comment_uri=${commentUri}`).done(() => {
        if (subjectData.showPagination) {
          if (getStateForKey(subjectKey).comments.length === 0 && subjectData.currentPage > 1) {
            commentData.page = subjectData.currentPage - 1;
            CommentActions.showCommentsForPage(commentData);
          } else if (subjectData.currentPage < Math.ceil(updatedNum / MAX_COMMENTS_PER_PAGE)) {
            commentData.page = subjectData.currentPage;
            CommentActions.showCommentsForPage(commentData);
          } else {
            this.notifyListeners();
          }
        }
      });
    }
  },

  onAddNewComment(commentData) {
    const comment = {
      body: getStateForKey(commentData.subjectKey).unsavedComment,
      authorUser: commentData.authorUser,
      updatedAtMessage: "Just now",
    };
    setDataForKey(commentData.subjectKey, { saveInProgress: true });

    this.notifyListeners();
    $j.post("/comment.json",
      {
        type: commentData.subject.type,
        id: commentData.subject.id,
        resource_uri: commentData.subject.uri,
        comment: {
          body_usertext: comment.body,
        },
        digest_default: true,
        respect_update_feed_preference: true,
        react_framework: true,
      }).done((resp) => {
      const subjectData = getStateForKey(commentData.subjectKey);
      const updatedNum = subjectData.numComments + 1;
      subjectData.set({
        unsavedComment: "",
        saveInProgress: false,
        numComments: updatedNum,
        showCommentButton: false,
      });
      if (subjectData.comments.length < MAX_COMMENTS_PER_PAGE) {
        const comment = resp;
        subjectData.comments.push({
          createdAt: new Date(),
          body: comment.body,
          deletableByCurrentUser: comment.deletableByCurrentUser,
          blockedByCurrentUser: comment.blockedByCurrentUser,
          authorUser: commentData.authorUser,
          updatedAtMessage: "Just now",
          uri: comment.uri,
          flagForAbuseUrl: comment.flagForAbuseUrl,
        });
      } else {
        commentData.page = Math.ceil(updatedNum / MAX_COMMENTS_PER_PAGE);
        CommentActions.showCommentsForPage(commentData);
      }
      this.onTextareaUnfocused(commentData.subjectKey);
    }).fail((resp) => {
      let errorMessage = null;
      if (resp.status === 404) {
        errorMessage = "Sorry, this post has been deleted.";
      } else {
        errorMessage = "Sorry, your comment cannot be posted. ";
        if (resp.responseJSON && resp.responseJSON.errors) {
          errorMessage += resp.responseJSON.errors.join(", ");
        }
      }

      if (resp.responseJSON && resp.responseJSON.errors && resp.responseJSON.errors.length === 1
          && resp.responseJSON.errors.includes("There was a problem saving your comment.")) {
        // for blocked content, silently fail and fake adding the message to throw off spammers
        errorMessage = null;
        const subjectData = getStateForKey(commentData.subjectKey);
        subjectData.comments.push({
          createdAt: new Date(),
          body: {
            html: comment.body,
          },
          deletableByCurrentUser: true,
          blockedByCurrentUser: false,
          authorUser: commentData.authorUser,
          updatedAtMessage: "Just now",
          uri: "",
          flagForAbuseUrl: "#",
        });

        setDataForKey(commentData.subjectKey, {
          unsavedComment: "",
          numComments: subjectData.numComments + 1,
        });
      }

      if (errorMessage) {
        GrErrorReporting.notifyUser(errorMessage);
      }

      setDataForKey(commentData.subjectKey, { saveInProgress: false, showCommentButton: false });
      CommentActions.textareaUnfocused(commentData.subjectKey);
    }
    );
  },

  onShowCommentsForPage(commentData) {
    const page = commentData.page > 0 ? commentData.page : 1;
    $j.get("/comment/index.json",
      {
        type: commentData.subject.type,
        id: commentData.subject.id,
        resource_uri: commentData.subject.uri,
        page,
        per_page: MAX_COMMENTS_PER_PAGE,
        reverse: true,
        react_framework: true,
      }).done((resp) => {
      setDataForKey(commentData.subjectKey, {
        comments: resp.comments,
        showPagination: true,
        currentPage: page,
        showSeeMoreLoadingSpinner: false,
      });
      this.notifyListeners();
    });
  },

  onCommentEntered(key, comment) {
    setDataForKey(key, { unsavedComment: comment });
    this.notifyListeners();
  },

  getState,
  getInitialState(key) {
    if (!_.isUndefined(key)) {
      return getStateForKey(key);
    } else {
      return getState();
    }
  },
  getInitialStateForKey: getStateForKey,

  reset() {
    resetTo({});
  },

  notifyListeners() {
    this.trigger(getState());
  },
});
