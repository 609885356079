import _ from "lodash";
import Reflux from "reflux";
import Freezer from "freezer-js";
import AnnotatedBooksActions from "../../react_actions/reading_notes/annotated_books_actions";

const state = new Freezer({
  annotatedBooksCollection: [],
  nextToken: null,
  showSpinner: true,
  showError: false,
});

const getState = function() {
  return state.get();
};

const store = Reflux.createStore({
  listenables: [AnnotatedBooksActions],

  getInitialState: getState,

  getState,

  updateWith(annotatedBooksCollection, nextToken) {
    let newCollection = _.union(state.get().annotatedBooksCollection, annotatedBooksCollection);

    newCollection = _.uniq(newCollection, (annotatedBookData) => annotatedBookData.asin);

    getState().set({ annotatedBooksCollection: newCollection,
                     nextToken,
                     showError: false });

    this.notifyListeners();
  },

  showSpinner(spinnerState) {
    getState().set("showSpinner", spinnerState);
    this.notifyListeners();
  },

  showError() {
    getState().set("showError", true);
    this.notifyListeners();
  },

  notifyListeners() {
    this.trigger(getState());
  },

  reset() {
    getState().reset({});
  },

  onLoadAnnotatedBooks(userId) {
    const path = `/notes/${userId}/load_more`;
    this.showSpinner(true);

    const params = {};
    if (state.get().nextToken) {
      params.start_token = state.get().nextToken;
    }

    return $j.get(path, params)
      .done((resp) => {
        this.updateWith(resp.annotated_books_collection, resp.next_token);
      })
      .fail(() => {
        this.showError();
      })
      .always(() => {
        this.showSpinner(false);
      });
  },
});

export default store;
