// See documentation on https://www.goodreads.com/style_guide

import GrComponentFactory from "../gr_component_factory";

export default GrComponentFactory.createClass({
  displayName: "HiddenHtml",

  propTypes: {
    unhideText: React.PropTypes.string,
  },

  getDefaultProps() {
    return {
      unhideText: "unhide",
    };
  },

  getInitialState() {
    return {
      expanded: false,
    };
  },

  expand() {
    this.setState({ expanded: true });
  },

  renderUnhideButton() {
    return (
      <button className="gr-buttonAsLink"
              onClick={this.expand}>
        {this.props.unhideText}
      </button>
    );
  },

  renderContent() {
    if (this.state.expanded) {
      return (
        this.props.children
      );
    } else {
      return (
        <span>
          {this.renderUnhideButton()}
        </span>
      );
    }
  },

  render() {
    return (
      <div>
        {this.renderContent()}
      </div>
    );
  },
});
