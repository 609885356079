import GrComponentFactory from "./gr_component_factory";
import { PropTypes } from "react";
import EnvUtils from "../modules/env_utils";
import { map } from "lodash";
// react-router imports
import Router from "react-router/lib/Router";
import RouterContext from "react-router/lib/RouterContext";
import Route from "react-router/lib/Route";
import Link from "react-router/lib/Link";
import browserHistory from "react-router/lib/browserHistory";
import match from "react-router/lib/match";
import classNames from "classnames";

const JOBS_BASE_PATH = "/jobs";
const HEADING_TITLE_TEXT = "Find your next job";
const HEADING_SUBTITLE_TEXT = "Want to be part of creating innovative experiences for the largest community of readers? We’re hiring!";
const HEADING_SUBTITLE_NO_JOBS_TEXT = "Sorry, we do not have any open positions at this time. Please check again soon!";
const LOCATION_HEADING_TEXT = "Location City";
const SUBMITTED_APP_TEXT = "Check on a submitted application";
const SUBMITTED_APP_URL = "https://account.amazon.jobs";
const JOB_TITLE_SUBSTRINGS_TO_STRIP = [" - Goodreads", ", Goodreads"];
const BACK_TO_JOBS_TEXT = "Back to all jobs";
const BASIC_QUALS_HEADING_TEXT = "Basic Qualifications:";
const PREF_QUALS_HEADING_TEXT = "Preferred Qualifications:";
const LEGAL_TEXT = "Qualified applicants with criminal histories will be considered in a manner consistent with all applicable local, state and federal laws, including the San Francisco Fair Chance Ordinance.";
const APPLY_BUTTON_TEXT = "Apply now";
const APPLY_URL_EXTENSION = "apply";
const BODY_CLASS = "jobsListBody";

export const JobsListComponent = GrComponentFactory.createClass({
  displayName: "JobsList",

  getJobs() {
    return this.props.route.jobs;
  },

  getSelectedJobData() {
    return this.getJobs() ? this.getJobs()[this.props.location.query.id] : null;
  },

  displayableJobTitle(title) {
    let titleToDisplay = title;
    JOB_TITLE_SUBSTRINGS_TO_STRIP.forEach((substring) => {
      titleToDisplay = titleToDisplay.replace(substring, "");
    });
    return titleToDisplay;
  },

  renderBackToAllJobsButton() {
    return (
      <Link to={JOBS_BASE_PATH}>
        <span className="gr-hyperlink">{BACK_TO_JOBS_TEXT}</span>
      </Link>
    );
  },

  renderHeading() {
    const jobsListHeadingSubtitleClass = classNames( this.withBemModifiers("jobsListHeading__subtitle"),
      { "jobsListHeading__subtitle--jobSelected": this.getSelectedJobData() }
    );
    return (
      <div className="gr-row gr-justify-content-center">
        <div className="gr-col gr-col-md-7 gr-col-lg-8">
          <h2 className="gr-marketingPageH2 jobsListHeading__title">{ HEADING_TITLE_TEXT }</h2>
          <p className={jobsListHeadingSubtitleClass}>{ this.getJobs() ? HEADING_SUBTITLE_TEXT : HEADING_SUBTITLE_NO_JOBS_TEXT }</p>
        </div>
      </div>
    );
  },

  renderLocation(normalizedLocation) {
    const locationClass = classNames( this.withBemModifiers("jobsListItem__location"),
      { "jobsListItem__location--jobSelected": this.getSelectedJobData() }
    );
    return (
      <div className={locationClass}>
        {normalizedLocation.replace(", USA", "")}
      </div>
    );
  },

  renderJobItem(id) {
    const job = this.getJobs()[id];
    if (job) {
      return (
        <div key={id} id={id} className="gr-row jobsListItem">
          <div className="gr-col gr-col-md-6 gr-col-lg-7 gr-col-md-offset-1">
            <Link to={`${JOBS_BASE_PATH}?id=${id}`}>
              <span className="gr-hyperlink gr-d-block jobsListItem__link">
                {this.displayableJobTitle(job.title)}
              </span>
            </Link>
            <div className="gr-d-block gr-d-md-none">
              {this.renderLocation(job.location)}
            </div>
          </div>
          <div className="gr-col gr-col-md-4 gr-col-lg-3 gr-d-none gr-d-md-flex">
            {this.renderLocation(job.location)}
          </div>
        </div>
      );
    } else {
      return null;
    }
  },

  renderLocationHeading() {
    return (
      <div className="gr-col gr-col-md-4 gr-col-lg-3 gr-d-none gr-d-md-flex">
        <h3 className="gr-h3">{LOCATION_HEADING_TEXT}</h3>
      </div>
    );
  },

  renderCategoryHeading(category) {
    const categoryKeys = Object.keys(this.props.route.categories);
    const isFirstCategoryHeading = category === categoryKeys[0];
    return (
      <div className="gr-row">
        <div className="gr-col gr-col-md-6 gr-col-lg-7 gr-col-md-offset-1">
          <h3 className="gr-h3">{category}</h3>
        </div>
        {isFirstCategoryHeading && this.renderLocationHeading()}
      </div>
    );
  },

  getJobMarkup(markup) {
    return { __html: markup };
  },

  renderSelectedJob() {
    const jobData = this.getSelectedJobData();
    const selectedJobTitle = this.displayableJobTitle(jobData.title);
    return (
      <div className="jobsListSelectedJob">
        <h4 className="gr-marketingPageH4">{ selectedJobTitle }</h4>
        {this.renderLocation(jobData.location)}
        <p dangerouslySetInnerHTML={this.getJobMarkup(jobData.description)} className="jobsListSelectedJob__description"/>
        <h5 className="gr-marketingPageH5">{BASIC_QUALS_HEADING_TEXT}</h5>
        <div dangerouslySetInnerHTML={this.getJobMarkup(jobData.basic_qualifications)} className="jobsListSelectedJob__basicQuals"/>
        <h5 className="gr-marketingPageH5">{PREF_QUALS_HEADING_TEXT}</h5>
        <div dangerouslySetInnerHTML={this.getJobMarkup(jobData.preferred_qualifications)} className="jobsListSelectedJob__preferredQuals"/>
      </div>
    );
  },

  renderCheckJobApp() {
    const checkAppClass = classNames(
      { "gr-hyperlink": this.getSelectedJobData() },
      { "gr-button gr-button--large gr-button--fullWidth gr-button--teal": !this.getSelectedJobData() }
    );
    return <a href={SUBMITTED_APP_URL} className={checkAppClass}>{SUBMITTED_APP_TEXT}</a>;
  },

  renderApplicationButtons() {
    const jobData = this.getSelectedJobData();
    return (
      <div className="jobsListSelectedJob__applicationButtons">
        <div className="jobsListApplyButton gr-d-md-inline">
          <a href={`${SUBMITTED_APP_URL}${JOBS_BASE_PATH}/${jobData.id_icims}/${APPLY_URL_EXTENSION}`} className="gr-button gr-button--large gr-button--teal">
            {APPLY_BUTTON_TEXT}
          </a>
        </div>
        <div className="jobsListCheckJobAppContainer gr-d-md-inline">
          {this.renderCheckJobApp()}
        </div>
      </div>
    );
  },

  render() {
    if (this.getSelectedJobData()) {
      return (
        <div className={BODY_CLASS}>
          {this.renderHeading()}
          <div className="gr-row gr-justify-content-center">
            <div className="gr-col gr-col-md-10 gr-col-lg-8">
              <div className="jobsListBackToAllJobsContainer--top">
                {this.renderBackToAllJobsButton()}
              </div>
              {this.renderSelectedJob()}
              <div className="jobsListLegalText">
                {LEGAL_TEXT}
              </div>
              {this.renderApplicationButtons()}
              <div className="jobsListBackToAllJobsContainer--bottom">
                {this.renderBackToAllJobsButton()}
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      const jobsListCheckJobAppContainerClass = classNames( "gr-row gr-justify-content-center jobsListCheckJobAppContainer--jobList",
        { "jobsListCheckJobAppContainer--noJobs": !this.getJobs() }
      );
      return (
        <div className={BODY_CLASS}>
          {this.renderHeading()}
          {map(this.props.route.categories, (jobIds, category) => (
            <div key={category} className="jobsListCategory">
              {this.renderCategoryHeading(category)}
              {map(jobIds, (jobId) => this.renderJobItem(jobId))}
            </div>
          ))}
          <div className={jobsListCheckJobAppContainerClass}>
            <div className="gr-col gr-col-10 gr-col-md-6 gr-col-lg-4">
              {this.renderCheckJobApp()}
            </div>
          </div>
        </div>
      );
    }
  },

});

const JobsListRouter = (props) => {
  const routes = <Route path={`${JOBS_BASE_PATH}(?id=*)`}
                        component={JobsListComponent}
                        onChange={  () => {
                          const scrollToElement = document.getElementsByClassName(BODY_CLASS)[0].parentElement;
                          if (scrollToElement !== null) {
                            scrollToElement.scrollIntoView(true);
                          }
                        }}
                        {...props} />;
  // server side rendering
  if (EnvUtils.domUnavailable()) {
    let routeProps;
    let location = JOBS_BASE_PATH;
    if (props.selectedId) {
      location += `?id=${props.selectedId}`;
    }
    match({ routes, location }, (_error, _redirectLocation, _routeProps) => {
      routeProps = _routeProps;
    });
    return <RouterContext {...routeProps} />;
  } else {
    return (
      <Router history={browserHistory}>
        {routes}
      </Router>
    );
  }
};

JobsListRouter.propTypes = {
  selectedId: PropTypes.string,
  categories: PropTypes.object,
  jobs: PropTypes.object,
};

export default JobsListRouter;
