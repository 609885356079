import _ from "lodash";
import Reflux from "reflux";
import Freezer from "freezer-js";

import FavoriteGenresActions from "../react_actions/favorite_genres_actions";

const defaultState = { allGenres: [], favoriteGenres: [] };
const state = new Freezer(defaultState);

const getState = function() {
  return state.get();
};

const reset = function(data) {
  getState().reset(_.merge({}, defaultState, data));
};

const serializeGenres = function(favoritedGenres) {
  return _.inject(getState().allGenres, (data, genre) => {
    const name = genre.name;
    data.favorites[name] = _.includes(favoritedGenres, name);
    return data;
  }, { favorites: {} });
};

export default Reflux.createStore({
  listenables: [FavoriteGenresActions],

  initializeWith(data) {
    _.each(_.keys(data), (key) => {
      if (!_.has(defaultState, key)) {
        throw new Error(`Unexpected key passed to initializeWith. Received ${key}. Expected one of ${_.keys(defaultState)}`);
      }
    });
    reset(data);
  },

  updateWith (data) {
    getState().set(data);
  },

  getState,
  getInitialState: getState,

  reset() {
    reset();
  },

  notifyListeners() {
    this.trigger(getState());
  },

  onChangeFavoriteGenres(favoritedGenres) {
    const afterChangesPersisted = () => {
      getState().favoriteGenres.reset(favoritedGenres);
      this.notifyListeners();
    };
    $j.post("/user/edit_fav_genres",
            _.merge(serializeGenres(favoritedGenres), { format: "json" }),
            afterChangesPersisted
    );
  },
});
