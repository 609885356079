import { setLocationUrl } from "../env_utils";
/**
 * If we receive a 403, redirect.
 *
 * Port of the Flight mixin app/assets/javascripts/components/mixins/auth_redirect.js
 *
 * @param jqXHR
 * @returns {boolean} true if it was a 403 and will be redirected. false otherwise.
 *                    if true, it's best not to try to execute any more code since it
 *                    may not have time to run before window.unload
 */
export function redirectOnAuthFailure(jqXHR) {
  if (jqXHR && jqXHR.status === 403) {
    const data = jqXHR.responseJSON;
    if (data.redirect) {
      setLocationUrl(data.redirect);
      return true;
    }
  }
  return false;
}
