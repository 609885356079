/*
This component displays the acknowledgment after reporting a wrong book preview.

Required props:
* `modalId`: This component expects to be assigned an ID (since it relies on
             being opened by submitting a wrong book report from the book preview
             modal)
*/

import React from "react";
import GrComponentFactory from "../gr_component_factory";
import Modal from "../modal";
import ModalActions from "../../react_actions/modal_actions";

export default GrComponentFactory.createClass({
  displayName: "WrongBookReportAcknowledgment",

  propTypes: {
    modalId: React.PropTypes.string.isRequired,
  },

  render() {
    return (
      <div className="previewBook__wrongBookReportAcknowledgment">
        <Modal id={this.props.modalId}>
          Thanks for telling us about the problem.
          <div>
            <button className="gr-button"
                    onClick={ModalActions.closeModal}>
                Close
            </button>
          </div>
        </Modal>
      </div>
    );
  },
});
