import GrComponentFactory from "./gr_component_factory";
import GrPropTypes from "./shared/gr_prop_types";
import Reflux from "reflux";
import ModalTrigger from "./modal_trigger";
import ShareButton from "./share_button";
import Modal from "./modal";
import ShareSocial from "./share_social";
import StringCounter from "./user_content/string_counter";

import {
  MAXIMUM_SHARING_TEXT_LENGTH,
  WARNING_SHARING_TEXT_LENGTH,
} from "../react_stores/share_dialog_store";

import ShareDialogStore from "../react_stores/share_dialog_store";

import ShareDialogActions from "../react_actions/share_dialog_actions";
import classNames from "classnames";
import envUtils from "../modules/env_utils";
import FlashMessage from "./flash_message";

// Preview Components
import ShareDialogBookPreview from "./sharing/previews/share_dialog_book_preview";
import ShareDialogTopicPreview from "./sharing/previews/share_dialog_topic_preview";
import ShareDialogBlogPreview from "./sharing/previews/share_dialog_blog_preview";
import ShareDialogInterviewPreview from "./sharing/previews/share_dialog_interview_preview";

export default GrComponentFactory.createClass({
  displayName: "ShareDialog",

  mixins: [Reflux.connect(ShareDialogStore, "sharingDialog")],

  propTypes: GrPropTypes.shareDialog(),

  getDefaultProps() {
    return {
      includePreview: false,
    };
  },

  getInitialState() {
    return {
      uniqueId: Math.random(1000000),
    };
  },

  getResourceTypeIdentifier() {
    return this.props.sharingData.resourceTypeFriendlyText.replace(/\s/g, "").toLowerCase();
  },

  getShareModalId() {
    return `share-${  this.getResourceTypeIdentifier()  }-modal${  this.state.uniqueId}`;
  },

  socialExists(location) {
    return (this.props.social[location].facebook || this.props.social[location].twitter ||
      this.props.social[location].pinterest || this.props.social[location].copyLink);
  },

  // Optional preview components go here
  renderPreview() {
    if (this.props.includePreview) {
      let preview;
      const friendlyResourceName = this.props.sharingData.resourceTypeFriendlyText;

      // add a case for your resource's friendly name here
      switch (friendlyResourceName) {
        case "book":
          preview = <ShareDialogBookPreview {...this.props.previewData} />;
          break;
        case "discussion":
          preview = <ShareDialogTopicPreview {...this.props.previewData} />;
          break;
        case "blog":
          preview = <ShareDialogBlogPreview {...this.props.previewData} />;
          break;
        case "interview":
          preview = <ShareDialogInterviewPreview {...this.props.previewData} />;
          break;
        default:
          // do nothing
      }

      if (preview) {
        return preview;
      }
    } else {
      return null;
    }
  },

  renderSocialInModal(socialPromptStart) {
    if (this.socialExists("inModal")) {
      const socialPrompt = `${socialPromptStart} share on:`;
      return (
        <div className="shareModal__socialContainer">
          <div className="shareModal__socialPrompt">
            { socialPrompt }
          </div>

          <ShareSocial {...this.props.social.inModal}
            bemModifiers="modal"
            fbOptions={this.props.social.fbOptions}
            twitterOptions={this.props.social.twitterOptions}
            pinterestOptions={this.props.social.pinterestOptions}
            metricName={`${this.getResourceTypeIdentifier()}_sharingModal`} />
        </div>
      );
    } else {
      return null;
    }
  },

  renderCharacterCounter() {
    const text = this.state.sharingDialog.sharingTextInputValue || "";

    return (
      <StringCounter
        counterType="character"
        maxLength={ MAXIMUM_SHARING_TEXT_LENGTH }
        warnLength={ WARNING_SHARING_TEXT_LENGTH }
        text={ text } />
    );
  },

  handleShare() {
    const data = this.props.sharingData;
    const value = this.state.sharingDialog.sharingTextInputValue;
    ShareDialogActions.submitShare(data, value);
  },

  renderSharePrompt() {
    const bottomClassNames = classNames(
      "shareModal__bottomContainer",
      { "shareModal__bottomContainer--noSocial": !this.socialExists("inModal"),
        "shareModal__bottomContainer--social": this.socialExists("inModal") }
    );
    return (
      <span className="shareModal">
        <h2 className="gr-h3 gr-h3--serif shareModal__heading">
          Share this {this.props.sharingData.resourceTypeFriendlyText} on Goodreads
        </h2>

        { this.renderShareError() }

        <textarea aria-label="Share text"
          placeholder={`Say something about this ${this.props.sharingData.resourceTypeFriendlyText}...`}
          value={this.state.sharingDialog.sharingTextInputValue}
          onChange={(e) => { ShareDialogActions.updateSharingTextInputValue(e.target.value); }}
          className="gr-textInput shareModal__textInput"/>

        {this.renderCharacterCounter()}

        {this.renderPreview()}

        <button className="gr-button gr-button--Large shareModal__button"
          onClick={this.handleShare}
          type="button"
          disabled={this.state.sharingDialog.shareModalButtonDisabled}>
          Share
        </button>

        <div className={ bottomClassNames }>
          {this.renderSocialInModal("Or")}
        </div>
      </span>
    );
  },

  renderShareSuccess() {
    return (
      <span className="shareModal">
        <h2 className="gr-h3 gr-h3--serif shareModal__heading">Shared!</h2>

        <h3 className="gr-hyperlink shareModal__link">
          <a href={this.state.sharingDialog.sharingUrl}
            aria-label={`Link to ${this.state.sharingDialog.sharingDisplayName}`}>
            View post
          </a>
        </h3>

        <div className="shareModal__bottomContainer shareModal__bottomContainer--shared">
          {this.renderSocialInModal("Also")}
        </div>
      </span>
    );
  },

  renderShareError() {
    if (this.state.sharingDialog.error) {
      const resourceType = this.props.sharingData.resourceTypeFriendlyText;
      const flashMessage = this.state.sharingDialog.errorText ||
        `Something went wrong, and your ${ resourceType } wasn't shared. Please try again later.`;
      return(
        <FlashMessage
          category="error"
          message={ flashMessage } />
      );
    } else {
      return null;
    }
  },

  renderModalContent() {
    if (this.state.sharingDialog.shared) {
      return this.renderShareSuccess();
    } else {
      return this.renderSharePrompt();
    }
  },

  renderSocialOutsideModal() {
    if (this.socialExists("outsideModal")) {
      return (
        <ShareSocial {...this.props.social.outsideModal}
          fbOptions={this.props.social.fbOptions}
          twitterOptions={this.props.social.twitterOptions}
          pinterestOptions={this.props.social.pinterestOptions}
          metricName={`${this.getResourceTypeIdentifier()}_shareModuleBar`}
          bemModifiers="shareBar" />
      );
    } else {
      return null;
    }
  },

  shouldRedirect() {
    return this.props.signInUrl && !this.props.signedIn;
  },

  handleClick() {
    if(this.shouldRedirect()) {
      this.redirectToSignIn();
    } else {
      ShareDialogActions.openShareModal(this.getResourceTypeIdentifier());
    }
  },

  getSignInUrl() {
    const currentPath = envUtils.getCurrentPath();
    return `${this.props.signInUrl}?returnurl=${currentPath}`;
  },

  redirectToSignIn(){
    envUtils.setLocationUrl(this.getSignInUrl());
  },

  // prevents modal from opening while redirecting to login if user is signed out
  renderModal() {
    if (this.shouldRedirect()) {
      return null;
    } else {
      return (
        <Modal id={ this.getShareModalId() } bemModifiers="share">
          { this.renderModalContent() }
        </Modal>
      );
    }
  },

  render() {
    return (
      <div>
        { this.renderModal() }

        <div className="shareModuleBar">
          <ModalTrigger
            id={ this.getShareModalId() }
            clickHandler={ this.handleClick }
            bemModifiers="shareModule">
            <ShareButton />
          </ModalTrigger>

          {this.renderSocialOutsideModal()}
        </div>
      </div>
    );
  },
});
