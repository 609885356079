import React from "react";
import { map } from "lodash";
import { compact } from "lodash";
import { transform } from "lodash";
import GrComponentFactory from "./gr_component_factory";

export default GrComponentFactory.createClass({
  displayName: "DotSeparatedElements",

  propTypes: {
    elements: React.PropTypes.arrayOf(
      React.PropTypes.oneOfType([
        React.PropTypes.element,
        React.PropTypes.string])).isRequired,
    separator: React.PropTypes.element,
  },

  getDefaultProps() {
    return {
      separator: <span> &middot; </span>,
    };
  },

  joinElementsWithSeparator(){
    const elements = compact(this.props.elements);
    return transform(elements, (result, element, index) => {
      result.push(element);
      if (index + 1 !== elements.length) {
        result.push(this.props.separator);
      }
    });
  },

  render(){
    return (
      <span className={this.props.className}>
        {
          map(this.joinElementsWithSeparator(), (element, index) => (<span key={index}>
                      {element}
                    </span>))
        }
      </span>
    );
  },
});
