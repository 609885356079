import { Component, PropTypes } from "react";
import GrPropTypes from "../../shared/gr_prop_types";
import Dropdown from "../../dropdown";
import ClickTrackingWrapper from "../../click_tracking_wrapper";
import UserNotInterestedActions from "../../../react_actions/user_not_interested_actions";
import { TRACKED_CLASSES } from "../../../modules/ads/click_tracking_helper";
import { map } from "lodash";

export class NativeAdCaret extends Component {

  constructor(props) {
    super(props);
  }

  renderNotInterested() {
    if (this.props.book && this.props.signedIn) {
      const notInterButtonAction =
        () => UserNotInterestedActions.userNotInterested(this.props.book.bookId);
      const notInterButton = <button className="gr-buttonAsLink nativeAdCaretMenu__button"
        onClick={notInterButtonAction}>
        I’m not interested in this book
      </button>;
      return <ClickTrackingWrapper children={notInterButton} trackingOptions={TRACKED_CLASSES.newsfeed_caret_not_interested}/>;
    } else {
      return null;
    }
  }

  caretList() {
    const whyAmISeeingThisLink = <a className="nativeAdCaretMenu__link" href={this.props.helpLink}>
      Why am I seeing this?
    </a>;
    return <div className="nativeAdCaretMenu">
      {this.renderNotInterested()}
      {map(this.props.additionalLinks, (link) => <div className="nativeAdCaretMenu__button" key={link}>link</div>)}
      {}
      <ClickTrackingWrapper children={whyAmISeeingThisLink}
        trackingOptions={TRACKED_CLASSES.newsfeed_caret_help}/>
    </div>;
  }

  render () {
    return <div className="nativeAdCaret">
      <Dropdown trigger={<div className="nativeAdCaret nativeAdCaret__image" aria-label="Sponsored item options" />}
        triggerEventType={Dropdown.TriggerEventTypes.CLICK}
        bemModifiers="caret"
        children={this.caretList()}/>
    </div>;
  }
}

NativeAdCaret.propTypes = {
  book: GrPropTypes.book(),
  helpLink: PropTypes.string,
  updateId: PropTypes.string,
  additionalLinks: PropTypes.arrayOf(PropTypes.elements),
  signedIn: PropTypes.bool,
};

NativeAdCaret.defaultProps = {
  trackingOptions: TRACKED_CLASSES.newsfeed_caret,
  signedIn: true,
};

export default NativeAdCaret;
