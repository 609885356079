import DataTrackingActions from "../../react_actions/data_tracking/data_tracking_actions";

const isTrackingEnabled = (sourceElement) => sourceElement.getAttribute("data-tracking-enabletracking") === "true";

/**
 * This function is a JavaScript DOM event listener implementation to handle any type of
 * user interaction on a DOM Element. The listener is attached by
 * react_mixins/tracking_data_attributes_mixin.js mixin for components with
 * `trackingOptions.enableTracking` prop set to true. The listener will trigger a react action when
 * it first encounters data-tracking-enabletracking="true" data attribute within the source element.
 *
 * @param event Object - JavaScript event object
 */
export function handleDataTracking(event) {
  let source = event.target;
  const trackingRootNode = event.currentTarget;

  let dataTrackingEnabled = false;

  if(source === trackingRootNode) {
    dataTrackingEnabled = isTrackingEnabled(source);
  } else {
    //when clicked on one of the child elements of trackingRootNode element.
    while (trackingRootNode.contains(source)) {
      dataTrackingEnabled = isTrackingEnabled(source);
      if (dataTrackingEnabled) {
        break;
      }
      source = source.parentNode;
    }
  }

  if (dataTrackingEnabled) {
    DataTrackingActions.trackClickEvent(event, source);
  }
}
