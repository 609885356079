/*
  Bem modifiers allows class modifiers to be passed in from parent components
  to child components to change styling based on the parent component.
*/
import React from "react";
import _ from "lodash";
import classNames from "classnames";

export default {
  propTypes: {
    bemModifiers: React.PropTypes.oneOfType([
      React.PropTypes.string,
      React.PropTypes.arrayOf(React.PropTypes.string),
    ]),
  },
  // Expects `cls` to be a string holding a CSS class name. Returns string
  // holding that class, plus that class with the BEM modifier suffix(es)
  // (supplied as `bemModifiers` prop) appended.
  withBemModifiers(cls, modifiers=null) {
    if (modifiers === null) {
      modifiers = this.props.bemModifiers;
    }
    if (modifiers) {
      if (_.isString(modifiers)) {
        return `${cls} ${cls}--${modifiers}`;
      } else if (_.isArray(modifiers)) {
        const retval = classNames(
          cls,
          _.map(modifiers, (mod) => `${cls}--${mod}`)
        );
        return retval;
      }
    } else {
      return cls;
    }
  },

  withBemElement(cls, elem) {
    if (this.props.bemModifiers) {
      if (_.isString(this.props.bemModifiers)) {
        return `${cls}--${this.props.bemModifiers}__${elem}`;
      } else if (_.isArray(this.props.bemModifiers)) {
        return classNames( _.map(this.props.bemModifiers, (mod) => `${cls}--${mod}__${elem}`));
      }
    } else {
      return `${cls}__${elem}`;
    }
  },
};
