/*doc
---
title: Reading notes shelving modal
name: reading_notes_shelving_modal
category: React
---

This component contains the reading note shelving modal, which is shown when a user attempts to make a note and/or highlight
visible before shelving a book. There should only be one ReadingNotesShelvingModal per page. In order for the WTR to work properly,
the UserShelvesStore and ShelvingsStore must be initialized.

Required props:

  * `book` (shape): Contains information about the book needed for the WTR button and book image
    * `id` (string): Book's ActiveRecord id
    * `title` (string): Title of the book
    * `imageUrl` (string): Image url for the book's cover

```rails_example
<% @book = Book.first %>
<%= react_component 'ReactComponents.ReadingNotesShelvingModal',
                    {book: {id: @book.id, title: @book.title, imageUrl: @book.large_image_url}} %>
```
*/

import React from "react";
import GrComponentFactory from "../gr_component_factory";
import Modal from "../modal";
import WantToReadButton from "../../react_components/want_to_read/want_to_read_button";

export const MESSAGE = "To make your notes and highlights visible, first add this book to one of your shelves.";

export default GrComponentFactory.createClass({
  displayName: "ReadingNotesShelvingModal",

  propTypes: {
    book: React.PropTypes.shape({
      id: React.PropTypes.string.isRequired,
      title: React.PropTypes.string.isRequired,
      imageUrl: React.PropTypes.string.isRequired,
    }).isRequired,
  },

  renderModalContent() {
    return (
      <div className="gr-mediaFlexbox">
        <img className="gr-mediaFlexbox__media u-marginRightMedium"
             src={this.props.book.imageUrl}
             alt={this.props.book.title} />
        <div className="gr-mediaFlexbox__desc">
          <h3 className="gr-h3 gr-h3--noTopMargin gr-h3--serif">
            Add your book
          </h3>
          <div className="readingNotesShelvingModal__text">
            { MESSAGE }
          </div>
          <WantToReadButton bookId={this.props.book.id}
                            bookTitle={this.props.book.title}
                            uniqueId="readingNotesShelvingModalWTR" />
        </div>
      </div>
    );
  },

  render() {
    return (
      <Modal id="readingNotesShelvingModal" bemModifiers="readingNotesShelving">
        {this.renderModalContent()}
      </Modal>
    );
  },
});
