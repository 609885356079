import React from "react";
import _ from "lodash";
import GrComponentFactory from "../gr_component_factory";
import Reflux from "reflux";
import LikeStore from "../../react_stores/like_store";

export default GrComponentFactory.createClass({
  displayName: "LikeInformation",

  mixins: [Reflux.connectFilter(LikeStore, "likeStore", function(likes) {
    return likes[this.props.resourceKey];
  })],

  propTypes: {
    resourceKey: React.PropTypes.string.isRequired,
  },

  getNameLink() {
    const likedBy = this.state.likeStore.likedBy;
    const likedByUser = !_.isUndefined(likedBy.friends) ?
      likedBy.friends[0] :
      likedBy.others[0];

    return (
      <a href={likedByUser.profile_url}
        className="gr-hyperlink gr-hyperlink--bold likeInformation__name">
        {likedByUser.name}
      </a>
    );
  },

  getPluralPerson(count) {
    if (count > 1) {
      return "people";
    }
    return "person";
  },

  getYouLikeMessage() {
    return(<span className="likeInformation__name">You</span>);
  },

  getYouAndAnotherPersonLikeMessage() {
    return(
      <span>
        { this.getYouLikeMessage() } and { this.getNameLink() }
      </span>
    );
  },

  getYouAndManyPeopleLikeMessage() {
    const likeState = this.state.likeStore;
    const count = likeState.likeCount - 2;
    return(
      <span>
        { this.getYouLikeMessage() }, { this.getNameLink() },
        {" and "}
        <a className="gr-hyperlink gr-hyperlink--bold"
           href={likeState.likersListUrl}>
          {count}
          {" other "}
          {this.getPluralPerson(count)}
        </a>
      </span>
    );
  },

  getSomeoneLikeMessage() {
    return(<span>{ this.getNameLink() }</span>);
  },

  getManyPeopleLikeMessage() {
    const likeState = this.state.likeStore;
    const count = likeState.likeCount - 1;
    return(
      <span>
        { this.getNameLink() }
        { " and " }
        <a className="gr-hyperlink gr-hyperlink--bold"
           href={likeState.likersListUrl}>
          {count}
          {" other "}
          {this.getPluralPerson(count)}
        </a>
      </span>
    );
  },

  getLikeMessage() {
    const likeState = this.state.likeStore;
    if (likeState.isLiked) {
      switch(likeState.likeCount) {
        case 1:
          return this.getYouLikeMessage();
        case 2:
          return this.getYouAndAnotherPersonLikeMessage();
        default:
          return this.getYouAndManyPeopleLikeMessage();
      }
    } else {
      switch(likeState.likeCount) {
        case 1:
          return this.getSomeoneLikeMessage();
        default:
          return this.getManyPeopleLikeMessage();
      }
    }
  },

  render() {
    const likeState = this.state.likeStore;
    if (!likeState.isLiked) {
      if (_.isEmpty(likeState.likedBy)) {
        return null;
      }
    }
    return (
      <div className="likeInformation u-defaultType">
        {this.getLikeMessage()} liked this
      </div>
    );
  },
});
