import React from "react";
import GrComponentFactory from "../gr_component_factory";
import LikeStore from "../../react_stores/like_store";
import { connectToLazyStore } from "../../react_mixins/gr_reflux";

export default GrComponentFactory.createClass({
  displayName: "LikeCount",

  mixins: [connectToLazyStore(LikeStore, "resourceKey")],

  propTypes: {
    resourceKey: React.PropTypes.string.isRequired,
  },

  getPluralLike(likeCount) {
    if (likeCount === 1) {
      return "like";
    } else {
      return "likes";
    }
  },

  render() {
    if (this.state.likeCount > 0) {
      return (
            <span>
              <a className="gr-hyperlink--quiet"
                 href={this.state.likersListUrl}>
                {this.state.likeCount} {this.getPluralLike(this.state.likeCount)}
              </a>
              {" · "}
            </span>);
    } else {
      return null;
    }
  },
});
