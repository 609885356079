/*eslint-disable camelcase*/
export const TRACKED_CLASSES = {
  // If you change the click_type here, please make sure to also change dfp_entry.rb
  //
  // The classes listed here are all classes tracked, other than the "more"
  // class found in mobile.

  // Book Ads
  book_image: {
    dfp: true,
    pmet: { click_type: "book_image" },
  },
  book_title: {
    dfp: true,
    pmet: { click_type: "book_title" },
  },
  author_link: {
    dfp: true,
    pmet: { click_type: "author_name" },
  },
  full_content_link: {
    dfp: true,
    pmet: { click_type: "body_click" },
  },
  user_profile: {
    dfp: true,
    pmet: { click_type: "social" },
  },
  want_to_read_button: {
    dfp: true,
    pmet: { click_type: "wtr_button" },
  },

  // Custom Ads
  sponsored_update_body_image: {
    dfp: true,
    pmet: { click_type: "image" },
  },
  cta_link: {
    dfp: true,
    pmet: { click_type: "cta_click" },
  },
  sponsored_label: {
    dfp: true,
    pmet: { click_type: "sponsored" },
  },

  // Other click tracking
  newsfeed_caret_not_interested: {
    dfp: false,
    pmet: { click_type: "not_interested" },
  },
  newsfeed_caret_help: {
    dfp: false,
    pmet: { click_type: "why" },
  },
  newsfeed_caret: {
    dfp: false,
    pmet: { click_type: "caret" },
  },

  // Desktop-Only
  user_rating_star: {
    dfp: true,
    pmet: { click_type: "rating" },
  },
  preview_book: {
    dfp: true,
    pmet: { click_type: "preview" },
  },
  sponsored_update_body_title_link: {
    dfp: true,
    pmet: { click_type: "sponsored_title" },
  },
};

export const TRACKED_CLASSES_AUDIBLE = {
  AEP_Desktop_Browse_Image: {
    dfp: true,
    pmet: { pmet_click_tid: "AEP_Desktop_Browse_Image" },
  },
  AEP_Desktop_Browse_Title: {
    dfp: true,
    pmet: { pmet_click_tid: "AEP_Desktop_Browse_Title" },
  },
  AEP_Desktop_Browse_ExploreButton: {
    dfp: true,
    pmet: { pmet_click_tid: "AEP_Desktop_Browse_ExploreButton" },
  },
  AEP_Desktop_Browse_SponsoredBy: {
    dfp: true,
    pmet: { pmet_click_tid: "AEP_Desktop_Browse_SponsoredBy" },
  },
  AEP_Desktop_Video_ExternalLink: {
    pmet: { pmet_click_tid: "AEP_Desktop_Video_ExternalLink",
            no_redirect: true },
  },
  AEP_Desktop_Sponsored_BookImage: {
    pmet: { pmet_click_tid: "AEP_Desktop_Sponsored_BookImage",
            no_redirect: true },
  },
  AEP_Desktop_Sponsored_BookTitle: {
    pmet: { pmet_click_tid: "AEP_Desktop_Sponsored_BookTitle",
            no_redirect: true },
  },
  AEP_Desktop_Sponsored_AuthorLink: {
    pmet: { pmet_click_tid: "AEP_Desktop_Sponsored_AuthorLink",
            no_redirect: true },
  },
  AEP_Desktop_Sponsored_WantToRead: {
    pmet: { pmet_click_tid: "AEP_Desktop_Sponsored_WantToRead",
            no_redirect: true },
  },
  AEP_Desktop_Sponsored_Stars: {
    pmet: { pmet_click_tid: "AEP_Desktop_Sponsored_Stars",
            no_redirect: true },
  },
  AEP_Desktop_Sponsored_BookDescription: {
    pmet: { pmet_click_tid: "AEP_Desktop_Sponsored_BookDescription",
            no_redirect: true },
  },
  AEP_Desktop_Sponsored_BookExternalLink: {
    pmet: { pmet_click_tid: "AEP_Desktop_Sponsored_BookExternalLink",
            no_redirect: true },
  },
  AEP_Desktop_Carousel_BookImage: {
    pmet: { pmet_click_tid: "AEP_Desktop_Carousel_BookImage",
            no_redirect: true },
  },
  AEP_Desktop_Carousel_WantToRead: {
    pmet: { pmet_click_tid: "AEP_Desktop_Carousel_WantToRead",
            no_redirect: true },
  },
  AEP_Mobile_Carousel_BookImage: {
    pmet: { pmet_click_tid: "AEP_Mobile_Carousel_BookImage",
            no_redirect: true },
  },
  AEP_Mobile_Carousel_WantToRead: {
    pmet: { pmet_click_tid: "AEP_Mobile_Carousel_WantToRead",
            no_redirect: true },
  },
  AEP_Mobile_Carousel_BookTitle: {
    pmet: { pmet_click_tid: "AEP_Mobile_Carousel_BookTitle",
            no_redirect: true },
  },
  AEP_Mobile_Carousel_Author: {
    pmet: { pmet_click_tid: "AEP_Mobile_Carousel_Author",
            no_redirect: true },
  },

};

export const TRACKED_CLASSES_PREMIUM_CAMPAIGN = {
  fcm_blog_title: {
    dfp: true,
    pmet: { click_type: "fcm_blog_title" },
  },
  fcm_blog_image: {
    dfp: true,
    pmet: { click_type: "fcm_blog_image" },
  },
  fcm_blog_button: {
    dfp: true,
    pmet: { click_type: "fcm_blog_button" },
  },
  fcm_book_author: {
    dfp: true,
    pmet: { click_type: "fcm_book_author" },
  },
  fcm_book_image: {
    dfp: true,
    pmet: { click_type: "fcm_book_image" },
  },
  fcm_book_title: {
    dfp: true,
    pmet: { click_type: "fcm_book_title" },
  },
  fcm_book_button: {
    dfp: true,
    pmet: { click_type: "fcm_book_button" },
  },
  fcm_book_description: {
    dfp: true,
    pmet: { click_type: "fcm_book_description" },
  },
  fcm_giveaway_author: {
    dfp: true,
    pmet: { click_type: "fcm_giveaway_author" },
  },
  fcm_giveaway_button: {
    dfp: true,
    pmet: { click_type: "fcm_giveaway_button" },
  },
  fcm_giveaway_image: {
    dfp: true,
    pmet: { click_type: "fcm_giveaway_image" },
  },
  fcm_giveaway_title: {
    dfp: true,
    pmet: { click_type: "fcm_giveaway_title" },
  },
  fcm_list_button: {
    dfp: true,
    pmet: { click_type: "fcm_list_button" },
  },
  fcm_list_books: {
    dfp: true,
    pmet: { click_type: "fcm_list_books" },
  },
  fcm_list_title: {
    dfp: true,
    pmet: { click_type: "fcm_list_title" },
  },
  fcm_genre_title: {
    dfp: true,
    pmet: { click_type: "fcm_genre_title" },
  },
  fcm_genre_image: {
    dfp: true,
    pmet: { click_type: "fcm_genre_image" },
  },
  fcm_genre_button: {
    dfp: true,
    pmet: { click_type: "fcm_genre_button" },
  },
};
/*eslint-enable*/
