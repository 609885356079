import GrComponentFactory from "../gr_component_factory";
import DotSeparatedElements from "../dot_separated_elements";
import React from "react";

export default GrComponentFactory.createClass({
  displayName: "EditorialBlogThumbnail",
  propTypes: {
    likesCountText: React.PropTypes.string,
    commentsCountText: React.PropTypes.string,
    imageUrl: React.PropTypes.string,
    blogPostUrl: React.PropTypes.string.isRequired,
    title: React.PropTypes.string.isRequired,
    header: React.PropTypes.string.isRequired,
  },

  renderBlogLink(text) {
    return <span className="gr-metaText">
             {text}
           </span>;
  },

  renderImage() {
    if (this.props.imageUrl) {
      return <a href={this.props.blogPostUrl}>
               <img className="gr-editorialBlogPost__image"
                    src={this.props.imageUrl}
                    alt={`${this.props.title}`} />
             </a>;
    } else {
      return null;
    }
  },

  renderLikesAndComments() {
    const likesLink = this.renderBlogLink(this.props.likesCountText);
    const commentsLink = this.renderBlogLink(this.props.commentsCountText);
    if (this.props.likesCountText && this.props.commentsCountText) {
      return <DotSeparatedElements elements={[likesLink, commentsLink]} />;
    } else if (this.props.likesCountText) {
      return likesLink;
    } else if (this.props.commentsCountText) {
      return commentsLink;
    } else {
      return null;
    }
  },

  render() {
    return <section className="gr-editorialBlogPost">
             <article>
               <h3>
                 {this.props.header}
               </h3>
               <a href={this.props.blogPostUrl} className="gr-editorialBlogPost__title">
                 {this.props.title}
               </a>
               {this.renderImage()}
               <div className="gr-editorialBlogPost__likesAndComments">
                {this.renderLikesAndComments()}
               </div>
            </article>
           </section>;
  },
});
