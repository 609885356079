import GoogleAdsStore from "../../react_stores/google_ads_store";
import GrComponentFactory from "../gr_component_factory";
import Reflux from "reflux";
import FeaturedContentModule from "../featured_content_module";
import { requestCreativeForAdSlot } from "../../modules/ads/google_ad_helper";
import classNames from "classnames";

const BLOCK_CLASS_NAME = "googleFeaturedContentModule";

export default GrComponentFactory.createClass({
  displayName: "GoogleFeaturedContentModule",

  mixins: [Reflux.connectFilter(GoogleAdsStore,
    "googleAdsStore",
    function (adsStoreState) {
      return adsStoreState.nativeAds[this.props.adId];
    }
  )],

  propTypes: {
    adId: React.PropTypes.string.isRequired,
    trackingOptions: React.PropTypes.shape({
      enableTracking: React.PropTypes.bool,
      adId: React.PropTypes.string,
    }).isRequired,
    isMobile: React.PropTypes.bool,
    hasBottomBorder: React.PropTypes.bool,
    isInline: React.PropTypes.bool,
  },

  defaultProps: {
    isMobile: false,
    isInline: false,
    hasBottomBorder: false,
  },

  getStoreState() {
    return this.state.googleAdsStore;
  },

  componentDidMount() {
    if (this.getStoreState() && !this.getStoreState().creative) {
      const params = {
        googleHookSelector: this.refs.googleHook,
        creativeSelector: this.getStoreState().creativeSelector,
        dimensions: this.getStoreState().dimensions,
        isNativeAd: this.getStoreState().isNativeAd,
        path: this.getStoreState().path,
      };
      requestCreativeForAdSlot(this.props.adId, params);
    }
  },

  renderCreative() {
    const creative = this.getStoreState().creative;
    return(<FeaturedContentModule type={creative.type}
           resource={creative.resource}
           sponsorName={creative.sponsorName}
           isSponsored={creative.isSponsored}
           isMobile={this.props.isMobile}
           isInline={this.props.isInline}
           hasBottomBorder={this.props.hasBottomBorder} />
    );
  },

  render() {
    if (this.getStoreState()) {
      const className = classNames(BLOCK_CLASS_NAME, this.getStoreState().className);
      const creativeBody = this.getStoreState().creative ?
        this.renderCreative() : null;
      return (
        <div>
          {creativeBody}
          <div className={className} id={this.props.adId} ref="googleHook" />
        </div>
      );
    } else {
      return null;
    }
  },
});
