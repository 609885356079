import { Component, PropTypes } from "react";
import CloseButton from "../../close_button";
import UserNotInterestedActions from "../../../react_actions/user_not_interested_actions";
import classNames from "classnames";

export class UserNotInterestedMessage extends Component {

  constructor(props) {
    super(props);
  }

  renderUndoButton(book) {
    return <button className="gr-buttonAsLink" onClick={() => {
      UserNotInterestedActions.undoUserNotInterested(book.bookId, this.props.id); }
    }>
      Undo
    </button>;
  }

  render() {
    const book = this.props.creative.book;
    if (this.props.isNewsfeed) {
      const userNotInterestedMessage = <span className="gr-newsfeedItem__flashMessage--body">
        Thanks! We won't recommend{" "}
        <a href={book.bookUrl}>{book.title}</a>
        {" "}again!{" "}
        {this.renderUndoButton(book)}
      </span>;
      return <div className="gr-newsfeedItem gr-newsfeedItem__flashMessage" role="alert">
        <div className="gr-newsfeedItem__close">
          <CloseButton action={() => {UserNotInterestedActions.closeFlashMessage(book.bookId);}}/>
        </div>
        {userNotInterestedMessage}
      </div>;
    } else {
      const userNotInterestedMessage =
        <span className="userNotInterested__message">
          Thanks! We won't recommend{" "}
          <a href={book.bookUrl}>{book.title}</a>
          {" "}again!{" "}
          {this.renderUndoButton(book)}
        </span>;
      const userNotInterestedClassname = classNames(this.props.className, "userNotInterested");
      return <div className={userNotInterestedClassname} role="alert">
        <div className="userNotInterested__close">
          <CloseButton action={() => {UserNotInterestedActions.closeFlashMessage(book.bookId);}}/>
        </div>
        {userNotInterestedMessage}
      </div>;
    }
  }
}

UserNotInterestedMessage.propTypes = {
  id: PropTypes.string,
  isNewsfeed: PropTypes.bool,
  className: PropTypes.string,
  creative: PropTypes.shape(),
};

export default UserNotInterestedMessage;
