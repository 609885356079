import React from "react";
import GrComponentFactory from "../gr_component_factory";
import LikeCount from "./like_count";
import LikeButton from "./like_button";

export default GrComponentFactory.createClass({
  displayName: "LikeSimpleFooter",

  propTypes: {
    resourceKey: React.PropTypes.string.isRequired,
    resourceLabel: React.PropTypes.string.isRequired,
    isLikeable: React.PropTypes.bool.isRequired,
    showAsLink: React.PropTypes.bool,
  },

  render() {
    return (
      <span>
        <LikeCount resourceKey={this.props.resourceKey} />
        { this.props.isLikeable ?
            <LikeButton resourceKey={this.props.resourceKey}
              resourceLabel={this.props.resourceLabel}
              showAsLink={this.props.showAsLink} />
          : null }
      </span>
    );
  },
});
