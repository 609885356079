// Cookie manipulation functions. Originally copied from javascripts/shared_all
// formats/cookies with the intention to replace with the react-cookies package,
// but that never happened.

export default {
  get(name) {
    let value = null;
    const $j = window.$j;
    if ($j) {
      $j.each(document.cookie.split("; "), (i, cookie) => {
        const cookiePair = cookie.split("=");
        if(cookiePair[0] === name) {
          value = cookiePair[1];
        }
      });
    }
    return value;
  },

  set(name, value, daysToExpire) {
    daysToExpire = daysToExpire || 365;

    const date = new Date();
    date.setTime(date.getTime() + (daysToExpire * 24 * 60 * 60 * 1000));
    const expires = `; expires=${  date.toGMTString()}`;
    document.cookie =
      `${name  }=${  encodeURIComponent(value)  }${expires  }; path=/`;
  },
};
