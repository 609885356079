/*
  This store maps book IDs to the current user's shelving data for that book.

  The same shelving could appear in ShelvingsStore multiple times. For example,
  if a user has shelved book ID 1, and book ID 2 is another edition of the same
  Work as book ID 1, ShelvingsStore might contain keys for 1, 2, or both:

  {1: {…,
       book: {
         bookId: 1,
         …
       }},
   2: {…,
       book: {
         bookId: 1,
         …
       }},
  }
*/
import _ from "lodash";
import Reflux from "reflux";
import Freezer from "freezer-js";
import ShelvingsActions from "../react_actions/shelvings_actions";
import ReadingSessionActions from "../react_actions/reading_session_actions";
import UserShelvesActions from "../react_actions/user_shelves_actions";
import NewsfeedUpdatesMixin, { NEWSFEED_DEPENDENT_STORE_KEYS } from "./shared/newsfeed_updates_mixin";
import { SHELF_TYPES, DEFAULT_SHELVES, getExclusiveShelfType } from "../modules/default_shelves";
import { BOOK_ORIGINS } from "../modules/book_origins";
import { httpPost } from "../modules/ajax/ajax_helper";

const state = new Freezer({});

const getState = () => state.get();

const getStateForKey = (key) => {
  if (!_.has(getState(), key)) {
    getState().set(key,
      {
        exclusiveShelfName: null,
        nonExclusiveShelfNames: null,
        exclusiveShelfDisplayName: null,
        isShelvingInProgress: false,
        targetExclusiveShelfName: null,
      });
  }
  return getState()[key];
};

// Update all states with a given book id included in them
const setStateForBookId = (bookId, data) => {
  // merges with existing data
  getStateForKey(bookId).set(data);

  _.each(getState(), (shelvingState, key) => {
    if(shelvingState.book && shelvingState.book.bookId === bookId) {
      getStateForKey(key).set(data);
    }
  });
};

const addToExclusiveShelf = (bookId, shelf, responseData) => {
  setStateForBookId(bookId, { exclusiveShelfName: shelf.name,
                              exclusiveShelfDisplayName: shelf.displayName });
  if (typeof(responseData) !== "undefined") {
    setStateForBookId(bookId, { book: responseData.book,
                                updatedAt: Date.parse(responseData.updatedAt) });
  }
  ReadingSessionActions.refreshReadingSessions();
};

const deleteShelving = (bookId, shelf, newShelf) => {
  setStateForBookId(bookId, { exclusiveShelfName: null,
                              exclusiveShelfDisplayName: null,
                              rating: 0,
                              nonExclusiveShelfNames: null });
  if (newShelf) {
    UserShelvesActions.removeNonExclusiveShelf(shelf.name);
  }
};

const unsetRating = (bookId) => {
  setStateForBookId(bookId, { rating: 0 });
};

const setRating = (bookId, value, bookData = null) => {
  addToReadShelfIfUnshelved(bookId, bookData);
  setStateForBookId(bookId, { rating: value });
};

const addToNonExclusiveShelf = (bookId, shelf, bookData = null) => {
  const state = getStateForKey(bookId);
  const shelfNames = _.clone(state.nonExclusiveShelfNames) || [];
  shelfNames.push(shelf.name);
  setStateForBookId(bookId, { nonExclusiveShelfNames: shelfNames });
  addToReadShelfIfUnshelved(bookId, bookData);
  UserShelvesActions.addNonExclusiveShelf(shelf.name);
};

const removeFromNonExclusiveShelf = (bookId, shelf, newShelf) => {
  const state = getStateForKey(bookId);
  let shelfNames = _.clone(state.nonExclusiveShelfNames);
  shelfNames = _.without(shelfNames, shelf.name);
  if (newShelf) {
    UserShelvesActions.removeNonExclusiveShelf(shelf.name);
  }
  setStateForBookId(bookId, { nonExclusiveShelfNames: shelfNames });
};

const addToReadShelfIfUnshelved = (bookId, bookData = null) => {
  const state = getStateForKey(bookId);
  if (state.exclusiveShelfName === null) {
    addToExclusiveShelf(bookId, DEFAULT_SHELVES.READ, bookData);
  }
};

const addToShelfRequest = (data, origin = null, progress_update_text = null) => {
  let args = data;
  if(origin) {
    args = _.merge({}, data, {
      book_origin: origin,
    });
  }
  if(progress_update_text) {
    args = _.merge({}, args, {
      progress_update_text,
    });
  }
  args = _.merge({}, args, { v:2 });
  return httpPost("/shelf/add_to_shelf.json", { data: args });
};

export default Reflux.createStore({
  listenables: ShelvingsActions,
  mixins: [NewsfeedUpdatesMixin(NEWSFEED_DEPENDENT_STORE_KEYS.SHELVINGS)],

  getState,

  getInitialState(key) {
    if (!_.isUndefined(key)) {
      return getStateForKey(key);
    } else {
      return getState();
    }
  },

  initializeWith(data) {
    state.get().reset(data);
    this.notifyListeners();
  },

  reset() {
    state.get().reset({});
  },

  notifyListeners() {
    this.trigger(getState());
  },

  updateWith(shelvings) {
    const shelvingData = _.transform(shelvings, (result, shelving, key) => {
      result[key].isShelvingInProgress = false;
      result[key].updatedAt = Date.parse(shelving.updatedAt);
      return result;
    }, shelvings);

    this.getState().set(shelvingData);
    this.notifyListeners();
  },

  onShelveBook(bookId, shelf, origin = null, progress_update_text = null) {
    const currentCustomExclusiveShelf = getStateForKey(bookId).exclusiveShelfName;
    setStateForBookId(bookId, { isShelvingInProgress: true, targetExclusiveShelfName: shelf.name });
    const data = { book_id: bookId, name: shelf.name };
    this.notifyListeners();
    addToShelfRequest(data, origin, progress_update_text).done((responseData) => {
      addToExclusiveShelf(bookId, shelf, responseData);
      responseData = responseData || {};
      responseData.shelves = {};
      responseData.shelves[getExclusiveShelfType(shelf.name)] = [shelf.name];
      ShelvingsActions.bookShelved(responseData);

      // if moving book to another exclusive shelf, remove it from the previous one
      if (currentCustomExclusiveShelf) {
        const shelves = {};
        shelves[getExclusiveShelfType(currentCustomExclusiveShelf)] = [currentCustomExclusiveShelf];
        ShelvingsActions.bookUnshelved({ shelves });
      }

      if(origin === BOOK_ORIGINS.CURRENTLY_READING_WIDGET) {
        ReadingSessionActions.shelveBookSuccess();
      }
    }).fail(() => {
      window.alert("There was an error adding the book to your shelf, please try again.");
    }).always(() => {
      setStateForBookId(bookId, { isShelvingInProgress: false, targetExclusiveShelfName: null });
      this.notifyListeners();
    });
  },

  onUnshelveBook(bookId) {
    setStateForBookId(bookId, { isShelvingInProgress: true });
    this.notifyListeners();
    httpPost("/review/destroy", {
      data: {
        id: bookId,
      },
    }).done(() => {
      const shelves = {};
      shelves[getExclusiveShelfType(getStateForKey(bookId).exclusiveShelfName)] = [getStateForKey(bookId).exclusiveShelfName];
      shelves[SHELF_TYPES.NONEXCLUSIVE] = getStateForKey(bookId).nonExclusiveShelfNames || [];
      ShelvingsActions.bookUnshelved({ shelves });

      deleteShelving(bookId);
    }).fail((response) => {
      if (response.status === 403 && response.getResponseHeader("errorType") === "frozen") {
        window.alert("Something went wrong with your request. Please try again later.");
      } else {
        window.alert("There was an error removing the book from your shelves, please try again.");
      }
    }).always(() => {
      setStateForBookId(bookId, { isShelvingInProgress: false });
      this.notifyListeners();
    });
  },

  onToggleNonExclusiveShelving(bookId, shelf, newShelf, title) {
    const exclusiveShelfName = getStateForKey(bookId).exclusiveShelfName;
    const nonExclusiveShelfNames = getStateForKey(bookId).nonExclusiveShelfNames;
    const isAddingToShelf = !_.includes(nonExclusiveShelfNames, shelf.name);
    const isBookShelved = getState()[bookId] && getStateForKey(bookId).exclusiveShelfName !== null;
    const bookData = { book: { bookId, title } };

    if (isAddingToShelf) {
      addToReadShelfIfUnshelved(bookId, bookData);
      addToNonExclusiveShelf(bookId, shelf, bookData);
    } else {
      removeFromNonExclusiveShelf(bookId, shelf, false);
    }
    this.notifyListeners();

    const data = {
      book_id: bookId,
      name: shelf.name,
      a: isAddingToShelf ? "" : "remove",
    };

    addToShelfRequest(data).done(() => {
      const shelves = {};
      if (isAddingToShelf) {
        // if book is not on an exclusive shelf already, also add it to the READ shelf
        shelves[SHELF_TYPES.DEFAULT] = !exclusiveShelfName ? [DEFAULT_SHELVES.READ.name] : [];
        shelves[SHELF_TYPES.NONEXCLUSIVE] = [shelf.name];
        ShelvingsActions.bookShelved({ shelves });
      } else {
        shelves[SHELF_TYPES.NONEXCLUSIVE] = [shelf.name];
        ShelvingsActions.bookUnshelved({ shelves });
      }
    }).fail(() => {
      window.alert("There was an error adding the book to your shelf, please try again.");
      if (!isBookShelved) {
        deleteShelving(bookId, shelf, newShelf);
      } else if (isAddingToShelf) {
        removeFromNonExclusiveShelf(bookId, shelf, newShelf);
      } else {
        addToNonExclusiveShelf(bookId, shelf, bookData);
      }
      this.notifyListeners();
    });
  },

  onRateBook(bookId, rating, title, clickstreamRef = null, ratingContainer, handleClick) {
    let prevRating = 0;
    let isBookShelved = false;
    const bookData = { book: { bookId, title } };
    if (getState()[bookId]) {
      prevRating = getStateForKey(bookId).rating;
      isBookShelved = getStateForKey(bookId).exclusiveShelfName !== null;
      if (prevRating === 0) {
        ShelvingsActions.rateBook.newBook();
      }
    }
    setRating(bookId, rating, bookData);
    this.notifyListeners();

    let ratePostUrl = "/review/rate";
    let ratePostData = {
      format: "json",
      id: bookId,
      rating,
      stars_click: true
    };
    if (clickstreamRef) {
      const clickstreamRefNumRating = clickstreamRef.replace("str", `st${rating}`);
      ratePostUrl += `?ref=${clickstreamRefNumRating}`
    }
    const store = this;
    httpPost(ratePostUrl, {
      data: ratePostData,
      error: ( xhr, aja_xOptions, thrownError ) => {
        if (xhr.status === 403 && xhr.getResponseHeader("errorType") === "frozen") {
          window.alert("This book has temporary limitations on submitting ratings and reviews. This may be because we've detected unusual behavior that doesn't follow our review guidelines.");
        } else if (xhr.status !== 403) {
          window.alert("There was an error saving your rating, please try again.");
        }
        if (prevRating > 0) {
          setRating(bookId, prevRating, bookData);
        } else if (isBookShelved) {
          unsetRating(bookId);
          ShelvingsActions.rateBook.failed();
        } else {
          deleteShelving(bookId, null, false);
          ShelvingsActions.rateBook.failed();
        }
        store.notifyListeners();
      },
      success: ( response ) => {
        ratingContainer.focus();
        if (handleClick) {
          handleClick();
        }
        store.notifyListeners();
      },
    });
  },

  onAddShelvings(shelvings) {
    this.updateWith(shelvings);
    this.notifyListeners();
  },
});
