// See documentation on https://www.goodreads.com/style_guide

import React from "react";
import _ from "lodash";
import GrComponentFactory from "./gr_component_factory";
import classNames from "classnames";
import ShelvingsActions from "../react_actions/shelvings_actions";
import ShelvingsStore from "../react_stores/shelvings_store";
import ClickTrackingWrapper from "./click_tracking_wrapper";
import { connectToLazyStore } from "../react_mixins/gr_reflux";
import { TRACKED_CLASSES } from "../modules/ads/click_tracking_helper";

export const STAR_SIZES = {
  SMALL: "smallStars",
  MEDIUM: "mediumStars",
  LARGE: "largeStars",
};

export default GrComponentFactory.createClass({
  displayName: "UserRating",

  mixins: [connectToLazyStore(ShelvingsStore, "bookId")],

  propTypes: {
    bookId: React.PropTypes.string.isRequired,
    bookTitle: React.PropTypes.string,
    starsTrackingOptions: React.PropTypes.object,
    starSize: React.PropTypes.string,
    displayLabel: React.PropTypes.bool,
    handleClick: React.PropTypes.func,
    clickstreamRef: React.PropTypes.string,
  },

  getDefaultProps() {
    return {
      starsTrackingOptions: TRACKED_CLASSES.user_rating_star,
      displayLabel: true,
      starSize: STAR_SIZES.SMALL
    };
  },

  setHoverRating(rating) {
    this.setState({ hoverRating: rating });
  },

  removeHoverRating() {
    this.setState({ hoverRating: null });
  },

  isShelved() {
    return this.state.exclusiveShelfName !== null;
  },

  updateRating(starNum, ev) {
    ev.preventDefault();
    if (this.isShelved()) {
      ShelvingsActions.rateBook(this.state.book.bookId, starNum, this.state.book.title, this.props.clickstreamRef || null, this.refs.ratingContainer, this.props.handleClick);
    } else {
      ShelvingsActions.rateBook(this.props.bookId, starNum, this.props.bookTitle, this.props.clickstreamRef || null, this.refs.ratingContainer, this.props.handleClick);
    }
  },

  starLabel(starNum) {
    let starLabel;
    if (this.state.exclusiveShelfName !== null && this.state.rating > 0) {
      starLabel = `Change rating from ${this.state.rating} to ${starNum} `;
    } else {
      starLabel = `Rate ${starNum} `;
    }
    starLabel += (starNum === 1) ? "star" : "stars";
    return starLabel;
  },

  renderStar(starIndex) {
    const starNum = starIndex + 1;
    const savedRating = this.state.exclusiveShelfName !== null ? this.state.rating : 0;
    const currentRating =
      this.state.hoverRating ? this.state.hoverRating : savedRating;
    const starClass = classNames(
      "userRating__star",
      { "userRating__star--selected": starNum <= currentRating },
       `userRating__star--${this.props.starSize}`
    );
    const starButton =
      <button className={starClass}
              title={this.starLabel(starNum)}
              onClick={this.updateRating.bind(this, starNum)}
              onMouseEnter={this.setHoverRating.bind(this, starNum)} />;
    return <ClickTrackingWrapper children={starButton}
            trackingOptions={this.props.starsTrackingOptions}
            key={starNum}/>;
  },

  renderTextLabel() {
    if (this.props.displayLabel) {
      const labelClass = classNames(
        "userRating__label",
        "gr-metaText",
        { "userRating__label--largeStars": this.props.starSize === STAR_SIZES.LARGE }
      );
      return (
        <div className={labelClass}>
          { this.state.exclusiveShelfName !== null && this.state.rating > 0 ?
            "Your rating:" : "Rate it:" }
        </div>
      );
    }
  },

  render () {
    const containerClass = classNames(
      "userRating",
      { "userRating--largeStars": this.props.starSize === STAR_SIZES.LARGE  }
    );
    return (
      <div className={containerClass}>
        { this.renderTextLabel() }
        <div className="userRating__starsWrapper"
             tabIndex="0"
             ref="ratingContainer"
             role="group"
             onMouseLeave={this.removeHoverRating}>
          { _.times(5, function(starIndex) {
            return this.renderStar(starIndex);
          }, this)}
        </div>
      </div>
    );
  },
});
