import { httpGet } from "../../modules/ajax/ajax_helper";

export const GET_A_COPY_ACTIONS = {
  LOADED_SUCCESS: "GET_A_COPY_LOADED_SUCCESS",
  INTERSTITIAL_CLOSED: "GET_A_COPY_INTERSTITIAL_CLOSED",
};

export const getACopyLoadedSuccess = (data) => ({
  type: GET_A_COPY_ACTIONS.LOADED_SUCCESS,
  data,
});

export const getACopyClosed = () => ({
  type: GET_A_COPY_ACTIONS.INTERSTITIAL_CLOSED,
});

export const getACopyClickedHandler = (getACopyDataUrl) => (dispatch) => {
  httpGet(getACopyDataUrl).done((data) => {
    dispatch(getACopyLoadedSuccess(data));
  });
};
