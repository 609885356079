import GrComponentFactory from "../gr_component_factory";
import { PropTypes } from "react";
import GrPropTypes from "../shared/gr_prop_types";
import Reflux from "reflux";
import { isEmpty } from "lodash";
import { BOOK_LOAD_ERROR_MSG } from "../../modules/reading_notes/reading_notes_helper";
import AnnotatedBooksList from "./annotated_books_list";
import FlashMessage from "../flash_message";
import AnnotatedBooksActions from "../../react_actions/reading_notes/annotated_books_actions";
import AnnotatedBooksStore from "../../react_stores/reading_notes/annotated_books_store";
import Spinner from "../spinner";

export default GrComponentFactory.createClass({
  displayName: "AnnotatedBooksPage",

  mixins: [Reflux.connect(AnnotatedBooksStore, "annotatedBooksStore")],

  propTypes: {
    user: GrPropTypes.user().isRequired,
    viewerIsOwner: PropTypes.bool.isRequired,
    canViewProfile: PropTypes.bool.isRequired,
    ownerHasLinkedAmazonAccount: PropTypes.bool.isRequired,
    createLinking: PropTypes.string,
    updateLinking: PropTypes.string,
    learnMore: PropTypes.string,
    contactUs: PropTypes.string,
  },

  loadAnnotatedBooksHandler() {
    AnnotatedBooksActions.loadAnnotatedBooks(this.props.user.id);
  },

  componentWillMount() {
    if (this.props.ownerHasLinkedAmazonAccount) {
      AnnotatedBooksActions.loadAnnotatedBooks(this.props.user.id);
    }
  },

  render() {
    const state = this.state.annotatedBooksStore;
    let view;

    if (this.props.viewerIsOwner) {
      if (!this.props.ownerHasLinkedAmazonAccount) {
        view = this.renderUnlinkedView();
      } else if (isEmpty(state.annotatedBooksCollection)) {
        if (state.showSpinner) {
          view = this.renderLoadingView();
        } else {
          view = this.renderEmptyOwnerView();
        }
      } else {
        view = this.renderOwnerCollection();
      }
    } else {
      if (!this.props.canViewProfile || !this.props.ownerHasLinkedAmazonAccount) {
        view = this.renderEmptyVisitorView();
      } else if (isEmpty(state.annotatedBooksCollection)) {
        if (state.showSpinner) {
          view = this.renderLoadingView();
        } else {
          view = this.renderEmptyVisitorView();
        }
      } else {
        view = this.renderVisitorCollection();
      }
    }

    return (
      <div className="annotatedBooksPage">
        { (state.showError) ?
          <FlashMessage category="error"
                        message={BOOK_LOAD_ERROR_MSG}/> :
          null
        }
        {view}
      </div>
    );
  },

  renderLoadingView() {
    return (
      <div className="annotatedBooksPage__loading">
        <Spinner/>
      </div>
    );
  },

  renderUnlinkedView() {
    return (
      <div className="annotatedBooksPage__unlinked">
        <div className="annotatedBooksPage__unlinked__gkLogo"/>
        <p className="annotatedBooksPage__unlinked__description">
          Link your Goodreads and Amazon accounts to access your
          Kindle notes and highlights on Goodreads.
        </p>
        <p className="annotatedBooksPage__unlinked__learnMore">
          <a href={this.props.learnMore}>
            Learn More about Kindle Notes & Highlights
          </a>
        </p>
        <div className="annotatedBooksPage__unlinked__linkBtn">
          <a href={this.props.createLinking}>
            <button className="gr-button gr-button--dark gr-button--fullWidth">
              Connect your accounts
            </button>
          </a>
        </div>
      </div>
    );
  },

  renderEmptyOwnerView() {
    return (
      <div className="annotatedBooksPage__empty">
        <div className="annotatedBooksPage__empty__description">
          It looks like you don’t have any Kindle ebooks with notes or highlights.
          <a className="annotatedBooksPage__empty__description__link" href={this.props.learnMore}>Learn More</a>
          about Kindle Notes & Highlights and
          <a className="annotatedBooksPage__empty__description__link" href={this.props.contactUs}>contact us</a>
          if you have any questions.
        </div>
        <div className="annotatedBooksPage__empty__content">
          <div className="annotatedBooksPage__empty__gkLogo"/>
          <p className="annotatedBooksPage__emptyOwner__status">
            Your Goodreads account is connected to your Amazon account.
          </p>
          <div className="annotatedBooksPage__empty__updateLink">
            <a href={this.props.updateLinking}>
              Update account linking
            </a>
          </div>
        </div>
      </div>
    );
  },

  // This should not occur since the visitor is linked to this page from
  // an already existing KNH set belonging to the owner. This means that
  // the owner already has notes shared, is linked and that the visitor already
  // belongs to the visibility group set in preferences.
  renderEmptyVisitorView() {
    return (
      <div className="annotatedBooksPage__empty">
        <div className="annotatedBooksPage__empty__description">
          {this.props.user.firstName} has not posted any Kindle Notes & Highlights yet.
          <a className="annotatedBooksPage__empty__description__link" href={this.props.learnMore}>
            Learn More about Kindle Notes & Highlights
          </a>
        </div>
      </div>
    );
  },

  renderOwnerCollection() {
    const state = this.state.annotatedBooksStore;
    return (
      <div className="annotatedBooksPage__collection">
        <div className="annotatedBooksPage__collection__descriptionContainer">
          <div className="annotatedBooksPage__collection__description">
            It’s easy to review, edit, and share the notes and highlights
            you make in Kindle books.
          </div>

          <div className="annotatedBooksPage__collection__description">
            Below are all of the books in your Kindle Library with highlights,
            starting with the most recently updated.
          </div>
        </div>
        <AnnotatedBooksList annotatedBooksCollection={state.annotatedBooksCollection}/>
        { this.renderLoadMore() }
      </div>
    );
  },

  renderVisitorCollection() {
    const state = this.state.annotatedBooksStore;
    return (
      <div className="annotatedBooksPage__collection">
        <div className="annotatedBooksPage__collection__descriptionContainer">
          Join in the conversation. View, like, and comment on {this.props.user.firstName}’s notes and highlights.
        </div>
        <AnnotatedBooksList annotatedBooksCollection={state.annotatedBooksCollection}/>
      </div>
    );
  },

  renderLoadMore() {
    const state = this.state.annotatedBooksStore;
    return (
      <div className="annotatedBooksPage__collection__loadMoreContainer">
        { state.showSpinner ?
            <Spinner/> :
          state.nextToken ?
            <button className="annotatedBooksPage__collection__loadMoreButton gr-button gr-button--dark" onClick={this.loadAnnotatedBooksHandler}>
              Load More
            </button> :
            null
        }
      </div>
    );
  },
});
