import Reflux from "reflux";
import Freezer from "freezer-js";
import TabActions from "../react_actions/tab_actions";

const state = new Freezer({});

const getState = function() {
  return state.get();
};

const store = Reflux.createStore({
  listenables: TabActions,

  getInitialState: getState,

  getState,

  initializeWith(groupsToActiveNames) {
    state.get().reset(groupsToActiveNames);
  },

  updateWith (data) {
    getState().set(data);
  },

  onDeselectTab(tabName, tabGroupName) {
    if (state.get()[tabGroupName] === tabName) {
      state.get().set(tabGroupName, null);
      this.notifyListeners();
    }
  },

  onSelectTab(tabName, tabGroupName) {
    state.get().set(tabGroupName, tabName);
    this.notifyListeners();
  },

  notifyListeners() {
    this.trigger(getState());
  },

  reset() {
    state.get().reset({});
  },
});

export default store;
