import classNames from "classnames";
import Reflux from "reflux";
import React from "react";
import { map, take, random } from "lodash";

import Dropdown from "../dropdown";
import GrComponentFactory from "../gr_component_factory";
import Tab from "./../tab";
import UserAgentCapabilitiesStore from "../../react_stores/user_agent_capabilities_store";

const MAX_GENRES = 8;

export default GrComponentFactory.createClass({
  displayName: "FeaturedGenresList",

  mixins: [Reflux.connect(UserAgentCapabilitiesStore, "userAgentCapabilities")],

  propTypes: {
    adId: React.PropTypes.string,
    genres: React.PropTypes.array.isRequired,
  },

  onClick(ev) {
    ev.preventDefault();
  },

  allGenresLink() {
    return (<li role="menuitem" className="genreList__genre" key="allGenres" aria-label="All Genres">
              <a href="/genres"
                 className="siteHeader__subNavLink">
                All Genres
              </a>
            </li>);
  },

  renderGenreLink(genre) {
    const cx = classNames(["genreList__genreLink",
                           "gr-hyperlink",
                           "gr-hyperlink--naked"]);
    const link = <a href={genre.url} className={cx}>
                  {genre.name}
               </a>;
    if (this.state.userAgentCapabilities.touch === true) {
      return link;
    } else {
      return <Tab group="featuredGenres"
                  name={`featuredGenre-${genre.name}`}
                  triggerEventType={Dropdown.TriggerEventTypes.HOVER}>
               {link}
             </Tab>;
    }
  },

  renderFavoritesList() {
    const numGenres = MAX_GENRES;
    return <div className="featuredGenres__list genreList genreList--sidebar">
      <div className="siteHeader__title siteHeader__heading">
        <span className="featuredGenres__listTitle">Favorite Genres</span>
      </div>
      <ul>
        {map(take(this.props.genres, numGenres), (genre, index) => (
            <li role="menuitem" className="genreList__genre" key={genre.name} aria-label={genre.name}>
              {this.renderGenreLink(genre)}
            </li>))}
        { this.allGenresLink() }
      </ul>
    </div>;
  },

  render() {
    return (
      <div>
        {this.renderFavoritesList()}
      </div>
    );
  },
});
