import Reflux from "reflux";
import Freezer from "freezer-js";
import _ from "lodash";

import AuthorFollowActions from "../react_actions/author_follow_actions";
import NewsfeedUpdatesMixin, { NEWSFEED_DEPENDENT_STORE_KEYS } from "./shared/newsfeed_updates_mixin";

const state = new Freezer({});

const getState = () => state.get();

const setResourceValue = (resource, value) => getState()[resource].set(value);

const getResourceValue = (resource, key) => getState()[resource][key];

const toggleAuthorFollowStoreData = (resource) => {
  const followingCount = getResourceValue(resource, "followingCount");
  const isFollowing = getResourceValue(resource, "isFollowing");
  const newValue = {
    isFollowing: !isFollowing,
    followingCount: isFollowing ? followingCount - 1 : followingCount + 1,
  };
  setResourceValue(resource, newValue);
};

const toggleButtonEnabled = (resource) => {
  const oldValue = getResourceValue(resource, "buttonEnabled");
  setResourceValue(resource, { buttonEnabled: !oldValue });
};

const store = Reflux.createStore({
  listenables: AuthorFollowActions,
  mixins: [NewsfeedUpdatesMixin(NEWSFEED_DEPENDENT_STORE_KEYS.AUTHOR_FOLLOWS)],
  getInitialState: getState,

  getState,

  initializeWith(rawData) {
    const newValue = _.transform(rawData, (scrubbedData, authorFollow, authorId) => {
      scrubbedData[authorId] = {
        isFollowing: authorFollow.isFollowing || false,
        followingCount: authorFollow.followingCount || 0,
        authorFollowingId: authorFollow.authorFollowingId || null,
        source: authorFollow.source || false,
        buttonEnabled: true,
      };
    });
    getState().set(newValue);
  },

  updateWith(data) {
    this.initializeWith(data);
  },

  reset() {
    getState().reset({});
  },

  onFollow(resource) {
    // If we came from a knh page, include that in post for metrics counting in rails
    const source = getResourceValue(resource, "source");
    const fromAnnotatedBookPage = (source === "AnnotatedBooksPage");
    const fromReadingNotesListPage = (source === "ReadingNotesListPage");

    // Disable follow button
    toggleButtonEnabled(resource);
    this.trigger(this.getState());
    $j.post("/author_followings", {
      format: "json",
      id: resource,
      from_annotated_books_page: fromAnnotatedBookPage,
      from_reading_notes_list_page: fromReadingNotesListPage,
    })
      .done((resp) => {
        // POST /author_followings returns the url of the created author
        // following object
        const newValue = {
          authorFollowingId: parseInt(_.words(resp.url, /[0-9]+/)[0], 10),
        };
        setResourceValue(resource, newValue);
        toggleAuthorFollowStoreData(resource);
        toggleButtonEnabled(resource);
        this.trigger(this.getState());
      }).fail((error) => {
        // Redirect to sign_in if authentication fails
        if (error && error.status === 403 && error.responseJSON) {
          location.href = error.responseJSON.redirect;
        }
      });
  },

  onUnFollow(resource) {
    // Disable unfollow button
    toggleButtonEnabled(resource);
    this.trigger(this.getState());
    $j.ajax({
      type: "DELETE",
      url: `/author_followings/${getResourceValue(resource, "authorFollowingId")}`,
      data: {
        format: "json",
      },
    })
      .done(() => {
        const newValue = {
          authorFollowingId: null,
        };
        setResourceValue(resource, newValue);
        toggleAuthorFollowStoreData(resource);
        toggleButtonEnabled(resource);
        this.trigger(this.getState());
      });
  },
});

export default store;
