import GrComponentFactory from "../gr_component_factory";
import React from "react";

const MEDIUM_DEVICE_BREAKPOINT = 768;

export default GrComponentFactory.createClass({
  displayName: "AmazonLoginButton",

  getInitialState() {
    return {
      popup: false,
    };
  },

  propTypes: {
    amazonSignInUrl: React.PropTypes.string.isRequired,
    enabled: React.PropTypes.bool,
    fullWidth: React.PropTypes.bool,
  },

  getDefaultProps() {
    return {
      enabled: false,
      fullWidth: false,
    };
  },

  componentDidMount() {
    this.setState({
      popup: window.innerWidth > MEDIUM_DEVICE_BREAKPOINT, // or smaller
    });
  },

  handleButtonPress() {
    if (this.state.popup) {
      window.open(this.props.amazonSignInUrl, "", "width=900,height=540");
    } else {
      window.location.assign(this.props.amazonSignInUrl);
    }
  },

  render() {
    if (this.props.enabled) {
      return (
        <button className={ `gr-button gr-button--amazon gr-button--auth ${this.props.fullWidth ? "gr-button--fullWidth" : ""}` }
          onClick={ this.handleButtonPress } >
          <div className="gr-mediaFlexbox gr-mediaFlexbox--alignItemsCenter gr-mediaFlexbox--justifyHorizontalContentCenter">
            <div className="gr-mediaFlexbox__media gr-button--amazon__icon" />
            <div className="gr-mediaFlexbox__desc">
              Continue with Amazon
            </div>
          </div>
        </button>
      );
    }
    return (null);
  },
});
