import GrComponentFactory from "./gr_component_factory";
import React from "react";
import { map } from "lodash";
import ClickTrackingWrapper from "./click_tracking_wrapper";
import { TRACKED_CLASSES_PREMIUM_CAMPAIGN } from "../modules/ads/click_tracking_helper";
import classNames from "classnames";

export default GrComponentFactory.createClass({
  displayName: "FeaturedContentList",
  propTypes: {
    list: React.PropTypes.shape({
      listUrl: React.PropTypes.string.isRequired,
      title: React.PropTypes.string.isRequired,
      topBooksCovers: React.PropTypes.array.isRequired,
    }).isRequired,
    isMobile: React.PropTypes.bool,
    isInline: React.PropTypes.bool,
  },

  defaultProps: {
    isMobile: false,
    isInline: false,
  },

  renderBookCovers() {
    const bookCoverImageClassName =
      this.props.isMobile ?
        "featuredContentList__bookCoverImage--allowOverlap" :
        "featuredContentList__bookCoverImage--noOverlap";
    const bookCoverClassName = classNames("featuredContentList__bookCovers",
      { "featuredContentList__bookCovers--inline": this.props.isInline });

    return <div className={bookCoverClassName}>
      <ClickTrackingWrapper trackingOptions={TRACKED_CLASSES_PREMIUM_CAMPAIGN.fcm_list_books}>
        <a href={this.props.list.listUrl}>
          {map(this.props.list.topBooksCovers, (bookCoverUrl) => (
              <div key={bookCoverUrl} className="featuredContentList__bookCoverContainer">
                <img src={bookCoverUrl}
                     alt={this.props.list.title}
                     className={bookCoverImageClassName} />
              </div>
          ))}
        </a>
      </ClickTrackingWrapper>
    </div>;
  },

  renderCTA() {
    if (this.props.isMobile) {
      return (
        <ClickTrackingWrapper trackingOptions={TRACKED_CLASSES_PREMIUM_CAMPAIGN.fcm_list_button}>
          <a className="gr-button gr-button--fullWidth u-marginBottomSmall u-marginTopSmall"
             href={this.props.list.listUrl}>
            View list
          </a>
        </ClickTrackingWrapper>
      );
    } else {
      return null;
    }
  },

  render() {
    const list = this.props.list;
    const fcmListClassNames = classNames("featuredContentList",
      { "featuredContentList--inline": this.props.isInline });
    const fcmListTitleClassNames = classNames("u-bold featuredContentList__title",
      { "featuredContentList__title--inline": this.props.isInline });
    const fcmListDetailsContainerClassNames = classNames("u-bold featuredContentList__listDetailsContainer",
      { "featuredContentList__listDetailsContainer--inline": this.props.isInline });
    const fcmListDetailsClassNames = classNames("u-bold featuredContentList__listDetails",
      { "featuredContentList__listDetails--inline": this.props.isInline });
    return(
      <div className={fcmListClassNames}>
        {this.renderBookCovers()}
        <ClickTrackingWrapper trackingOptions={TRACKED_CLASSES_PREMIUM_CAMPAIGN.fcm_list_title}>
          <a href={list.listUrl} className={fcmListTitleClassNames}>
            {list.title}
          </a>
        </ClickTrackingWrapper>
        <div className={fcmListDetailsContainerClassNames}>
          <div className={fcmListDetailsClassNames}>
            {list.numBooks.toLocaleString()} books · {list.numVoters.toLocaleString()} voters
          </div>
        </div>
        {this.renderCTA()}
      </div>
    );
  },
});
