import appendQuery from "append-query";

export default {

  /**
   * Opens child window for sharing on external social platforms
   * 
   * @param baseUrl - url to connect to platform
   * @param options - object of url parameters that send share item data to social window
   * @param windowSpecs - position and dimensions of child window
   */
  openSocialShareWindow(baseUrl, options, windowSpecs) {
    const socialShareUrl = appendQuery(baseUrl, options);
    const shareWindowPosition = `left=${windowSpecs.left}, top=${windowSpecs.top}`;
    const shareWindowDimensions = `width=${windowSpecs.width}, height=${windowSpecs.height}`;
    const shareWindowSpecs = `${shareWindowPosition}, ${shareWindowDimensions}, menubar=no, scrollbar=no`;
    window.open(socialShareUrl, "_blank", shareWindowSpecs);
  },
};
