/*
 WantToReadWithStars is a wrapper component around the WantToReadButton
 component and the UserRating component.
*/

import React from "react";
import GrComponentFactory from "../gr_component_factory";
import WantToReadButton from "./want_to_read_button";
import UserRating from "../user_rating";

import { random } from "lodash";

export default GrComponentFactory.createClass({
  displayName: "WantToReadWithStars",

  propTypes: {
    bookId: React.PropTypes.string.isRequired,
    uniqueId: React.PropTypes.string.isRequired,
    bookTitle: React.PropTypes.string.isRequired,
  },

  render() {
    return (<div>
              <WantToReadButton
                bookId={this.props.bookId}
                uniqueId={random(1000000).toString()}
                bookTitle={this.props.bookTitle} />
              <UserRating bookId={this.props.bookId}
                          bookTitle={this.props.bookTitle} />
            </div>);
  },
});
