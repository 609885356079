/*
These components and stores are only needed depending on the settings of certain
Deploy Services (often more than one in combination). Rather than create lots
of different bundles for each combination, for now we're just including all of
these in one file for simplicity for inclusion elsewhere but still showing
what feature area each supports to make it easier to (re)move them later.
*/
import { ConversationsComponents, ConversationsStores } from "./conversations";
import { OldHomepageComponents, OldHomepageStores } from "./old_homepage";
import { ReviewShowComponents, ReviewShowStores } from "./review_show";
import { merge } from "lodash";

export const ExperimentComponents = merge({},
                                          ConversationsComponents,
                                          OldHomepageComponents,
                                          ReviewShowComponents);

export const ExperimentStores = merge({},
                                      ConversationsStores,
                                      OldHomepageStores,
                                      ReviewShowStores);
